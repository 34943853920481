import axios from 'axios';
import { axiosStaffInstance } from '../../axiosConfig';

export const fetchFormOptionsData = async () => {
  try {
    const response = await axiosStaffInstance.get("v1/FormData");
    return response.data;
  } catch (error) {
    // Handle error
    console.error('Error fetching data:', error);
    throw error;
  }
};

// ! CREATE new STAFF......
export const postStaffData = async (requestData) => {
  try {
    const response = await axiosStaffInstance.post("v1/Staff", requestData);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// ! UPDATE EXISTING STAFF......
export const updateStaffData = async (requestData) => {
  try {
    // console.log("requestData in updateStaffData",requestData);
    let {id, form_data} = requestData
    for (let pair of form_data.entries()) {
      console.log("PUT API HIT...",pair[0]+ ', '+ pair[1]); 
    }
    const response = await axiosStaffInstance.put(`v1/Staff/${id}`, form_data);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// ! GET ALL STAFF data......
export const getAllStaffData = async (requestData) => {
  try {
    const response = await axiosStaffInstance.get("v1/Staff");
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// ! DELETE staff data
export const deleteStaffData = async (staffId) => {
  try {
    const response = await axiosStaffInstance.delete(`v1/Staff/${staffId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting data:', error);
    throw error;
  }
};
