import { axiosInstance, instanceAuth } from '../axiosConfig';


export const getClassesByCampusId = async (campusId) => {
  try {
    console.log(campusId, 'geClassesByCampusId');
    const response = await instanceAuth.get(`Class?CampusId=${campusId}`);
    return response;
  } catch (error) {
    console.log('error updating geClassesByCampusId...', error);
    throw error;
  }
};

export const postAssignSectionData = async (data) => {
  try {
    console.log(data, 'postAssignSectionData');
    const response = await instanceAuth.post('ClassSection', data);
    return response;
  } catch (error) {
    console.log('error updating postAssignSection...', error);
    throw error;
  }
};
export const updateAssignSectionWithId = async (data) => {
  try {
    console.log(data, 'postAssignSectionData');
    let { id, _capacity } = data;
    const response = await instanceAuth.put(`ClassSection/${id}`, _capacity);
    return response;
  } catch (error) {
    console.log('error updating postAssignSection...', error);
    throw error;
  }
};


  export const getAssignSectionByFilteration = async (url) => {
    try {
      console.log(url,"getAssignSectionByFilteration");
      const response = await instanceAuth.get(`${url}`)
      return response;
    } catch (error) {
      console.log('error updating getAssignSectionByFilteration...', error);
       
      throw error;
    }
  };

  export const getAssignSectionByFilterationForCreate = async (url) => {
    try {
      console.log(url,"getAssignSectionByFilterationForCreate");
      const response = await instanceAuth.get(`${url}`)
      return response;
    } catch (error) {
      console.log('error updating getAssignSectionByFilteration...', error);
       
      throw error;
    }
  };
  export const getAllAssignSectionWithoutFilter = async (campusId) => {
    try {
      // console.log(departmentId, campusId,classesId,"getAssignSectionByFilteration");
      const response = await instanceAuth.get(`ClassSection?CampusId=${campusId}`)
      return response;
    } catch (error) {
      console.log('error updating getAssignSectionByFilteration...', error);
      throw error;
    }
  };


export const deleteSectionById = async (id) => {
  try {
    console.log(id, 'deleteSectionById');
    const response = await instanceAuth.delete(`ClassSection/${id}`);
    return response;
  } catch (error) {
    console.log('error deleteSectionById......', error);
    throw error;
  }
};

export const getCourseAndSectionByClassAndCampusAndDepartmentIdHelper = async ({
  classId,
  campusId,
  departmentId,
}) => {
  try {
    const courseResponse = await instanceAuth.get(`Courses/`, {
      params: {
        classId: classId,
      },
    });
    let sectionResponse;
    if (departmentId) {
      sectionResponse = await instanceAuth.get(
        `ClassSection?CampusId=${campusId}&DepartmentId=${departmentId}&ClassId=${classId}`
      );
    } else {

      sectionResponse = await instanceAuth.get(
        `ClassSection?CampusId=${campusId}&ClassId=${classId}`
      );
    }

    console.log("COURSE", sectionResponse);
    const response = {
      courses: courseResponse,
      sections: sectionResponse,
    };
    return response;
  } catch (error) {
    console.log('error getting course and section data...', error);
    throw error;
  }
};
