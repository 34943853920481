import React, { useContext, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import NavLinks from "../SideNav/NavLinks";
import {
  LEFT_ADMIN_NAVIGATION,
  LEFT_User_NAVIGATION,
} from "../../common/data/navbarLinks";
import SideBarContext from "../../context/sidebar.context";
import { useSelector } from "react-redux";
import { allLogin } from "../../redux/slice/Authentication/loginSlice";

const SidebarContent = () => {
  const ref = useRef();
  const { userRole } = useSelector(allLogin);
  const route = localStorage.getItem("userRole");
  const change = JSON.parse(route);
  // console.log('userRole', change?.role);
  // console.log('navigation', Navigation);
  const { activeStates, setActiveStates } = useContext(SideBarContext);

  console.log(activeStates);
  return (
    <React.Fragment>
      <SimpleBar ref={ref} className="vertical-simplebar">
        <nav className={`sidebar-nav`}>
          <ul className="metismenu">
            {change?.role === "Admin" ? (
              <>
                {LEFT_ADMIN_NAVIGATION.map((link, ind) => (
                  <NavLinks
                    icon={link.icon}
                    link={link.link}
                    linkName={link.linkName}
                    key={ind}
                    subNavs={link.subNavs}
                    activeStates={activeStates}
                    setActiveStates={(e) => setActiveStates(e)}
                  />
                ))}
              </>
            ) : (
              <>
                {LEFT_User_NAVIGATION.map((link, ind) => (
                  <NavLinks
                    icon={link.icon}
                    link={link.link}
                    linkName={link.linkName}
                    key={ind}
                    subNavs={link.subNavs}
                    activeStates={activeStates}
                    setActiveStates={(e) => setActiveStates(e)}
                  />
                ))}
              </>
            )}
          </ul>
        </nav>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
