import {
  FaChalkboardTeacher,
  FaGraduationCap,
  FaShuttleVan,
} from 'react-icons/fa';
import { FaArrowsDownToPeople, FaPeopleGroup } from 'react-icons/fa6';
import { FaLock } from 'react-icons/fa';
import { PiStudentFill } from 'react-icons/pi';
import { GrResources } from 'react-icons/gr';
import { IoNotifications } from 'react-icons/io5';
import { MdPayments, MdScreenshotMonitor } from 'react-icons/md';
import { decodeJWT } from '../../helpers/jwt-token-access/tokenMethods';

const LEFT_ADMIN_NAVIGATION = [
  { linkName: 'Dashboard', link: '/dashboard', icon: <MdScreenshotMonitor /> },
  {
    linkName: "Academics",
    subNavs: [
      // { linkName: 'Assignments', link: '/academics/assignments' },
      { linkName: "Classes", link: "/academics/Classes" },
      // { linkName: 'Assign Section', link: '/academics/asignsection' },
      // { linkName: 'Sections', link: '/academics/Sections' },
      { linkName: "Courses", link: "/academics/courses" },
      { linkName: "Assign Courses", link: "/academics/assigncourses" },
      { linkName: "Student", link: "/students/catalog" },
      // { linkName: 'Alumni', link: '/students/alumni' },
      {
        linkName: "Attendance",
        link: "/students/attendance",
      },
      // { linkName: 'Campus', link: '/academics/campus' },
      // { linkName: 'Department', link: '/academics/department' },
      // { linkName: 'Exams & Quizzes', link: '/academics/ExamsQuiz' },
      // { linkName: 'Syllabus', link: '/academics/Syllabus' },

      // { linkName: "Academic Year", link: "/AcademicYear/" },
      { linkName: 'Timetable', link: '/academics/Timetable' },
      { linkName: 'Classroom', link: '/academics/classroom' },
    ],
    icon: <FaGraduationCap />,
  },
  {
    linkName: 'Admission',
    subNavs: [
      {
        linkName: 'Applicants',
        link: '/Admission/Applicants',
      },
      {
        linkName: 'Post Interviews',
        link: '/admission/postInterviews',
      },
      {
        linkName: 'Post Offer',
        link: '/admission/postOffer',
      },
    ],
    icon: <FaArrowsDownToPeople />,
  },
  // {
  //   linkName: 'Finance',
  //   subNavs: [
  //     { linkName: 'Fees', link: '/Finance/Fees' },
  //     { linkName: 'Invoices', link: '/Finance/Invoices' },
  //     {
  //       linkName: 'Payroll',
  //       // link: "/Finance/Payroll"
  //     },
  //   ],
  //   icon: <MdPayments />,
  // },
  // {
  //   linkName: 'Notification',
  //   subNavs: [
  //     {
  //       linkName: 'Email/Whatsapp',
  //       // link: "/Notification/Channels"
  //     },
  //     {
  //       linkName: 'NoticeBoard',
  //       // link: "/Notification/NoticeBoard"
  //     },
  //   ],
  //   icon: <IoNotifications />,
  // },
  // {
  //   linkName: 'Resources',
  //   subNavs: [
  //     {
  //       linkName: 'Certificate',
  //       //  link: "/Resources/Certificate"
  //     },
  //     {
  //       linkName: 'Communities',
  //       //  link: "/Resources/Communities"
  //     },
  //     {
  //       linkName: 'Events',
  //       // link: "/Resources/Events"
  //     },
  //     {
  //       linkName: 'ID Cards',
  //       // link: "/Resources/IDCards"
  //     },
  //     {
  //       linkName: 'Medical',
  //       //  link: "/Resources/Medical"
  //     },
  //     {
  //       linkName: 'Sports',
  //       //  link: "/Resources/Sports"
  //     },
  //   ],
  //   icon: <GrResources />,
  // },
  // {
  //   linkName: 'Staff',
  //   subNavs: [
  //     { linkName: 'Attendance', link: '/staff/attendance' },
  //     { linkName: 'Catalog', link: '/staff/catalog' },
  //     { linkName: 'Leave', link: '/staff/Leave' },
  //   ],
  //   icon: <FaPeopleGroup />,
  // },
  // {
  //   linkName: 'Teacher',
  //   subNavs: [
  //     { linkName: 'Catalog', link: '/teachers/catalog' },
  //     { linkName: 'Feedback', link: '/teachers/feedback' },
  //   ],
  //   icon: <FaChalkboardTeacher />,
  // },
  // {
  //   linkName: 'Students',
  //   subNavs: [
  //     // { linkName: 'New Student', link: '/students/newstudent' },
  //     // { linkName: 'Catalog', link: '/students/catalog' },
  //     // // { linkName: 'Alumni', link: '/students/alumni' },
  //     // {
  //     //   linkName: 'Attendance',
  //     //   link: '/students/attendance',
  //     // },
  //     // {
  //     //   linkName: 'Academics',
  //     //   //  link: "/students/Catalogue"
  //     // },
  //     // {
  //     //   linkName: 'Parent',
  //     //   //  link: "/students/parent"
  //     // },
  //     // {
  //     //   linkName: 'PersonalInfo',
  //     //   // link: "/students/personalInfo"
  //     // },
  //     // {
  //     //   linkName: 'PTM',
  //     //   //  link: "/students/ptm"
  //     // },
  //   ],
  //   icon: <PiStudentFill />,
  // },
  // {
  //   linkName: 'Transport',
  //   subNavs: [
  //     {
  //       linkName: 'Buses',
  //       //  link: "/Transport/Buses"
  //     },
  //     {
  //       linkName: 'Drivers',
  //       //  link: "/Transport/Drivers"
  //     },
  //     {
  //       linkName: 'Expenses',
  //       //  link: "/Transport/Expenses"
  //     },
  //     {
  //       linkName: 'GPS',
  //       //  link: "/Transport/GPS"
  //     },
  //   ],
  //   icon: <FaShuttleVan />,
  // },
];

const LEFT_User_NAVIGATION = [
  { linkName: 'Dashboard', link: '/dashboard', icon: <MdScreenshotMonitor /> },
  {
    linkName: "Academics",
    subNavs: [
      // { linkName: 'Assignments', link: '/academics/assignments' },
      { linkName: "Classes", link: "/academics/Classes" },
      // { linkName: 'Assign Section', link: '/academics/asignsection' },
      // { linkName: "Sections", link: "/academics/Sections" },
      { linkName: "Courses", link: "/academics/courses" },
      { linkName: "Assign Courses", link: "/academics/assigncourses" },
      { linkName: "Catalog", link: "/students/catalog" },
      // { linkName: 'Alumni', link: '/students/alumni' },
      {
        linkName: "Attendance",
        link: "/students/attendance",
      },
      // { linkName: 'Campus', link: '/academics/campus' },
      // { linkName: 'Department', link: '/academics/department' },
      // { linkName: 'Exams & Quizzes', link: '/academics/ExamsQuiz' },
      // { linkName: 'Syllabus', link: '/academics/Syllabus' },
      // { linkName: "Academic Year", link: "/AcademicYear/" },
      { linkName: 'Timetable', link: '/academics/Timetable' },
      { linkName: 'Classroom', link: '/academics/classroom' },
    ],
    icon: <FaGraduationCap />,
  },
  {
    linkName: 'Admission',
    subNavs: [
      {
        linkName: 'Applicants',
        link: '/Admission/Applicants',
      },
      {
        linkName: 'Post Interviews',
        link: '/admission/postInterviews',
      },
      {
        linkName: 'Post Offer',
        link: '/admission/postOffer',
      },
    ],
    icon: <FaArrowsDownToPeople />,
  },
  // {
  //   linkName: 'Finance',
  //   subNavs: [
  //     { linkName: 'Fees', link: '/Finance/Fees' },
  //     { linkName: 'Invoices', link: '/Finance/Invoices' },
  //     {
  //       linkName: 'Payroll',
  //       // link: "/Finance/Payroll"
  //     },
  //   ],
  //   icon: <MdPayments />,
  // },
  // {
  //   linkName: 'Notification',
  //   subNavs: [
  //     {
  //       linkName: 'Email/Whatsapp',
  //       // link: "/Notification/Channels"
  //     },
  //     {
  //       linkName: 'NoticeBoard',
  //       // link: "/Notification/NoticeBoard"
  //     },
  //   ],
  //   icon: <IoNotifications />,
  // },
  // {
  //   linkName: 'Resources',
  //   subNavs: [
  //     {
  //       linkName: 'Certificate',
  //       //  link: "/Resources/Certificate"
  //     },
  //     {
  //       linkName: 'Communities',
  //       //  link: "/Resources/Communities"
  //     },
  //     {
  //       linkName: 'Events',
  //       // link: "/Resources/Events"
  //     },
  //     {
  //       linkName: 'ID Cards',
  //       // link: "/Resources/IDCards"
  //     },
  //     {
  //       linkName: 'Medical',
  //       //  link: "/Resources/Medical"
  //     },
  //     {
  //       linkName: 'Sports',
  //       //  link: "/Resources/Sports"
  //     },
  //   ],
  //   icon: <GrResources />,
  // },
  // {
  //   linkName: 'Staff',
  //   subNavs: [
  //     { linkName: 'Attendance', link: '/staff/attendance' },
  //     { linkName: 'Catalog', link: '/staff/catalog' },
  //     { linkName: 'Leave', link: '/staff/Leave' },
  //   ],
  //   icon: <FaPeopleGroup />,
  // },
  // {
  //   linkName: 'Teacher',
  //   subNavs: [
  //     { linkName: 'Catalog', link: '/teachers/catalog' },
  //     { linkName: 'Feedback', link: '/teachers/feedback' },
  //   ],
  //   icon: <FaChalkboardTeacher />,
  // },
  // {
  //   linkName: 'Students',
  //   subNavs: [
  //     // { linkName: 'New Student', link: '/students/newstudent' },
  //     { linkName: 'Catalog', link: '/students/catalog' },
  //     // { linkName: 'Alumni', link: '/students/alumni' },
  //     {
  //       linkName: 'Attendance',
  //       link: '/students/attendance',
  //     },
  //     // {
  //     //   linkName: 'Academics',
  //     //   //  link: "/students/Catalogue"
  //     // },
  //     // {
  //     //   linkName: 'Parent',
  //     //   //  link: "/students/parent"
  //     // },
  //     // {
  //     //   linkName: 'PersonalInfo',
  //     //   // link: "/students/personalInfo"
  //     // },
  //     // {
  //     //   linkName: 'PTM',
  //     //   //  link: "/students/ptm"
  //     // },
  //   ],
  //   icon: <PiStudentFill />,
  // },
  // {
  //   linkName: 'Transport',
  //   subNavs: [
  //     {
  //       linkName: 'Buses',
  //       //  link: "/Transport/Buses"
  //     },
  //     {
  //       linkName: 'Drivers',
  //       //  link: "/Transport/Drivers"
  //     },
  //     {
  //       linkName: 'Expenses',
  //       //  link: "/Transport/Expenses"
  //     },
  //     {
  //       linkName: 'GPS',
  //       //  link: "/Transport/GPS"
  //     },
  //   ],
  //   icon: <FaShuttleVan />,
  // },
];

export { LEFT_ADMIN_NAVIGATION, LEFT_User_NAVIGATION };
