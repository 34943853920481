import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { MdDelete, MdModeEdit, MdOutlineViewAgenda } from "react-icons/md";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import Loader from "../../../components/loader/Loader";
import {
  ClassRoomDelete,
  ClassRoomFetch,
  allClassroom,
} from "../../../redux/slice/academics/classroomSlice";

let timetableDummyData = [
  {
    id: 1,
    class: 3,
    course: 5,
    day: "Wednesday",
    startTime: "13:00",
    endTime: "13:45",
  },
];

export default function ClassRoomView(props) {
  const dispatch = useDispatch();
  const { campus, currentCampus } = useSelector(allCampus);
  const { classroomsData, loading, totalPages } = useSelector(allClassroom);
  const [appliedFilters, setAppliedFilters] = useState({
    currentClass: "0",
    course: "0",
    day: "",
  });
  const [coursesArray, setCoursesArray] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  //pagination working
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  // function for paginate buttons
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
      };
      dispatch(ClassRoomFetch(data));
    } else {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        CampusId: Number(currentCampus),
      };
      dispatch(ClassRoomFetch(data));
    }
  };
  // End of Pagination Working

  useEffect(() => {
    setCurrentPage(1);
    let data = {};
    if (currentCampus == 0) {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
      };
      dispatch(ClassRoomFetch(data));
    } else {
      data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
        CampusId: Number(currentCampus),
      };
      dispatch(ClassRoomFetch(data));
    }
  }, [currentCampus, itemsPerPage, appliedFilters]);

  const handleView = (time) => {
    console.log("all view", time);
  };

  const findCampusName = (campus_id) => {
    if (campus && campus.length > 0) {
      const campusObj = campus.find((c) => c.id === campus_id);
      return campusObj ? campusObj.name : "";
    }
    return "";
  };

  const handleUpdate = (time) => {
    props.forUpdate(time);
  };

  const handleDelete = () => {
    console.log("delete time id", deleteId);
    dispatch(ClassRoomDelete(Number(deleteId)));
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <div>
        <div className="row">
          <div className="col col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title fs-5 mb-4 bold text-uppercase font-weight-bold">
                  Classrooms
                </h5>
                <div className="my-2 w-100 d-flex">
                  <div className="w-75 d-flex">
                    {/* <FilterInput
                      options={singleClasses}
                      value={appliedFilters.currentClass}
                      label="All Classes"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          currentClass: e.target.value,
                        }));
                      }}
                    /> */}
                  </div>
                  <select
                    className="form-control w-25"
                    style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                    value={itemsPerPage}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setItemsPerPage(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Items per page
                    </option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
                <div className="table-responsive mt-4">
                  {loading ? (
                    <span className="m-4">
                      <Loader />
                    </span>
                  ) : (
                    <Table className="table-centered">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Campus</th>
                          <th scope="col">MAC Address </th>
                          <th scope="col">Description</th>
                          <th scope="Action">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {classroomsData.map((row, index) => (
                          <tr key={index}>
                            <td>{row?.name}</td>
                            <td>{findCampusName(row?.campusId)}</td>
                            <td>{row?.macAddress}</td>
                            <td>{row?.description}</td>
                            <td className="d-flex">
                              {/* <MdOutlineViewAgenda
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleView(row)}
                                size={18}
                              /> */}
                              <MdModeEdit
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleUpdate(row)}
                                size={18}
                              />
                              <MdDelete
                                style={{ cursor: "pointer" }}
                                className="text-danger"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeleteId(row.id);
                                }}
                                size={18}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
                <div className="mt-3">
                  <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <IoArrowBackCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages).keys()].map((number) => (
                      <PaginationItem
                        key={number}
                        active={number + 1 === currentPage}
                      >
                        <PaginationLink onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink
                        next
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <IoArrowForwardCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={deleteModal} centered>
          <Card>
            <CardBody className="mt-2">
              <h5 style={{ fontWeight: "normal" }}>
                Are You Sure. You want to Delete ?
              </h5>
            </CardBody>
            <CardFooter>
              <button
                className="btn btn-danger me-2"
                onClick={() => handleDelete()}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setDeleteModal(false)}
              >
                No
              </button>
            </CardFooter>
          </Card>
        </Modal>
      </div>
    </React.Fragment>
  );
}
