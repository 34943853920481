import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
} from "../../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function PersonalInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  personalInfo,
  forView,
}) {
  let infoObject = "personal";
  let mobileRegex = /^\+92\d{10}$/;
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  let nicRegex = /^[0-9]{5}[0-9]{7}[0-9]$/;
  useEffect(() => {
    const keysToExclude = ["birthCertificate", "b_form"];
    const isAnyKeyEmpty = Object.keys(personalInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return personalInfo[key] === "" || personalInfo[key] === null;
      });
    console.log("is any key empty", isAnyKeyEmpty);
    if (!isAnyKeyEmpty) {
      const isValidMobile = mobileRegex.test(personalInfo.mobileNumber);
      const isValidEmail = emailRegex.test(personalInfo.emailAddress);
      const isValidNic = nicRegex.test(personalInfo.bForm);
      if (isValidMobile && isValidEmail && isValidNic) {
        updateTabComplete(true);
      } else {
        updateTabComplete(false);
      }
    } else {
      if (tabs[0].complete) {
        updateTabComplete(false);
      }
    }
  }, [personalInfo]);
  console.log("for-view", forView);
  return (
    <React.Fragment>
      <form>
        <div className="row">
          {!forView ? (
            <InputField
              label="Name"
              compulsory={true}
              disabled={forView}
              type="text"
              value={personalInfo.name}
              placeholder="Type Name"
              onChange={(e) =>
                handleChangeInfo(infoObject, "name", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Name</label>
              <p className="">{personalInfo.name}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              label="Date of Birth"
              compulsory={true}
              disabled={forView}
              type="date"
              value={
                personalInfo.dateOfBirth
                  ? new Date(personalInfo.dateOfBirth)
                      .toISOString()
                      .split("T")[0]
                  : ""
              }
              placeholder="Type Date of Birth"
              onChange={(e) =>
                handleChangeInfo(infoObject, "dateOfBirth", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Date of Birth</label>
              <p className="">
                {personalInfo.dateOfBirth
                  ? new Date(personalInfo.dateOfBirth)
                      .toISOString()
                      .split("T")[0]
                  : ""}
              </p>
            </div>
          )}
          {!forView ? (
            <InputField
              label="B Form / CNIC"
              compulsory={true}
              disabled={forView}
              type="nic"
              value={personalInfo.bForm}
              placeholder="Type B Form"
              onChange={(e) =>
                handleChangeInfo(infoObject, "bForm", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>B Form / CNIC</label>
              <p className="">{personalInfo.bForm}</p>
            </div>
          )}
          {!forView ? (
            <SelectField
              label="Nationality"
              compulsory={true}
              disabled={forView}
              value={personalInfo.nationality}
              selectOptions={["Pakistani", "American", "British"]}
              onChange={(e) =>
                handleChangeInfo(infoObject, "nationality", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Nationality</label>
              <p className="">{personalInfo.nationality}</p>
            </div>
          )}
          {!forView ? (
            <SelectField
              label="Gender"
              compulsory={true}
              disabled={forView}
              value={personalInfo.gender}
              selectOptions={["Male", "Female"]}
              onChange={(e) =>
                handleChangeInfo(infoObject, "gender", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Gender</label>
              <p className="">{personalInfo.gender}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              label="Permanent Address"
              compulsory={true}
              type="text"
              disabled={forView}
              value={personalInfo.permanentAddress}
              placeholder="Type Permanent Address"
              onChange={(e) =>
                handleChangeInfo(infoObject, "permanentAddress", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Permanent Address</label>
              <p className="">{personalInfo.permanentAddress}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              label="Residential Address"
              compulsory={true}
              disabled={forView}
              type="text"
              value={personalInfo.residentialAddress}
              placeholder="Type Residential Address"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  "residentialAddress",
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Residential Address</label>
              <p className="">{personalInfo.residentialAddress}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              label="City"
              disabled={forView}
              compulsory={true}
              value={personalInfo.city}
              placeholder="Type City"
              onChange={(e) =>
                handleChangeInfo(infoObject, "city", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>City</label>
              <p className="">{personalInfo.city}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Area"
              compulsory={true}
              value={personalInfo.area}
              placeholder="Type Area"
              onChange={(e) =>
                handleChangeInfo(infoObject, "area", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Area</label>
              <p className="">{personalInfo.area}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mobile Number"
              compulsory={true}
              type="tel"
              value={personalInfo.mobileNumber}
              placeholder="Type Mobile Number"
              onChange={(e) =>
                handleChangeInfo(infoObject, "mobileNumber", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mobile Number</label>
              <p className="">{personalInfo.mobileNumber}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Email Address"
              compulsory={true}
              type="email"
              value={personalInfo.emailAddress}
              placeholder="Type Email Address"
              onChange={(e) =>
                handleChangeInfo(infoObject, "emailAddress", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Email Address</label>
              <p className="">{personalInfo.emailAddress}</p>
            </div>
          )}
          <InputImageField
            disabled={forView}
            label="Passport Size Picture"
            compulsory={true}
            value={personalInfo.passportSizePicture}
            type="file"
            placeholder="Upload Passport Size Picture"
            onChange={(e) =>
              handleFileChange(infoObject, "passportSizePicture", e)
            }
          />
          <InputImageField
            disabled={forView}
            label="Birth Certificate"
            compulsory={false}
            value={personalInfo.birthCertificate}
            type="file"
            placeholder="Upload Birth Certificate"
            onChange={(e) =>
              handleFileChange(infoObject, "birthCertificate", e)
            }
          />
          <InputImageField
            disabled={forView}
            label="B-Form"
            compulsory={false}
            value={personalInfo.b_form}
            type="file"
            placeholder="Upload B-Form"
            onChange={(e) => handleFileChange(infoObject, "b_form", e)}
          />
        </div>
      </form>
    </React.Fragment>
  );
}
