import React, { useEffect, useState, useContext } from 'react';
import SideBarContext from '../../../context/sidebar.context';
import { InputField, SelectField } from './input-fields';
import { TabContent, TabPane, Modal } from 'reactstrap';
import { IoIosWarning } from 'react-icons/io';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  addStudent,
  updateStudent,
  createdStudent,
} from '../../../redux/slice/students/studentsSlice';
import { ClassesData, allClasses } from '../../../redux/slice/classesSlice';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import PersonalInfoForm from './CreateStudent/personal-info';
import ParentsInfoForm from './CreateStudent/parents-info';
import EmergencyContactForm from './CreateStudent/emergency-contact';
import EducationInfoForm from './CreateStudent/education-info';
import MedicalInfoForm from './CreateStudent/medical-info';
import CurrrentStatusForm from './CreateStudent/current-status';
import axios from 'axios';

import axiosInstance from '../../../api/axiosConfig';
import { CampusData } from '../../../redux/slice/academics/CampusSlice';

function calculateAge(dateOfBirth) {
  var today = new Date();
  var birthDate = new Date(dateOfBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default function CreateStudent(props) {
  const { setActiveStates } = useContext(SideBarContext);
  const [apiresponse, setApiresponse] = useState([]);
  const dispatch = useDispatch();
  const { classes } = useSelector(allClasses);
  const [modal, setModal] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [tabs, setTabs] = useState([
    { no: 1, tab: 'Personal Information', complete: false },
    { no: 2, tab: 'Parent Information', complete: false },
    { no: 3, tab: 'City School Association', complete: false },
    { no: 4, tab: 'Emergency Contact', complete: false },
    { no: 5, tab: 'Education History', complete: false },
    { no: 6, tab: 'A-level Subjects', complete: false },
    { no: 7, tab: 'Medical Information', complete: false },
    // { no: 8, tab: 'Current Status', complete: false },
  ]);

  const [createStudentData, setCreateSudentData] = useState({
    personal: {
      studentId: `std#${(Math.random() * 1000).toFixed()}`,
      name: '',
      dateOfBirth: '',
      bForm: '',
      nationality: '',
      gender: '',
      permanentAddress: '',
      residentialAddress: '',
      city: '',
      area: '',
      mobileNumber: '+92',
      emailAddress: '',
      passportSizePicture: [],
      birthCertificate: [],
      b_form: [],
    },
    parents: {
      fatherName: '',
      fatherNICNumber: '',
      fatherOccupation: '',
      fatherOrganization: '',
      fatherEducation: '',
      fatherMobileNumber: '+92',
      fatherEmailAddress: '',
      fatherUploadNIC: [],
      fatherUploadPassportPicture: [],
      motherName: '',
      motherNICNumber: '',
      motherOccupation: '',
      motherOrganization: '',
      motherEducation: '',
      motherMobileNumber: '+92',
      motherEmailAddress: '',
      motherUploadNIC: [],
      motherUploadPassportPicture: [],
      maritalStatus: '',
    },
    emergency: {
      name: '',
      relWithChild: '',
      mobileNumber: '+92',
      email: '',
      homeAddress: '',
    },
    education: {
      previouslyAttended: '',
      schoolName: '',
      schoolAddress: '',
      class: '',
      lastAcademicResults: [],
      reasonForLeaving: '',
      term: '',
      campus: '',
    },
    medical: {
      diabetes: '',
      physicalDisability: '',
      hearingImpairment: '',
      visualImpairment: '',
      anyAllergies: '',
      allergies: '',
    },
    status: {
      status: '',
    },
  });

  // const [createStudentData, setCreateSudentData] = useState({
  //   personal: {
  //     studentId: `std#${(Math.random() * 1000).toFixed()}`,
  //     name: 'John Doe',
  //     dateOfBirth: '2005-06-15',
  //     bForm: '1234567891011',
  //     age: '12',
  //     nationality: 'Pakistani',
  //     gender: 'Male',
  //     permanentAddress: '123 Main Street',
  //     residentialAddress: '456 Elm Street',
  //     city: 'Sample City',
  //     area: 'Sample Area',
  //     mobileNumber: '+923333332333',
  //     emailAddress: 'john.doe@gmail.com',
  //     passportSizePicture: [],
  //     birthCertificate: [],
  //     b_form: [],
  //   },
  //   parents: {
  //     fatherName: 'Michael Doe',
  //     fatherNICNumber: '1234512345671',
  //     fatherOccupation: 'Engineer',
  //     fatherOrganization: 'ABC Engineering Inc.',
  //     fatherEducation: "Bachelor's Degree",
  //     fatherMobileNumber: '+923333332333',
  //     fatherEmailAddress: 'michael.doe@example.com',
  //     fatherUploadNIC: [],
  //     fatherUploadPassportPicture: [],
  //     motherName: 'Emily Doe',
  //     motherNICNumber: '1234576543211',
  //     motherOccupation: 'Doctor',
  //     motherOrganization: 'XYZ Hospital',
  //     motherEducation: 'Doctorate Degree',
  //     motherMobileNumber: '+923333332333',
  //     motherEmailAddress: 'emily.doe@example.com',
  //     motherUploadNIC: [],
  //     motherUploadPassportPicture: [],
  //     martialStatus: 'Married',
  //   },
  //   emergency: {
  //     name: 'Jane Smith',
  //     relWithChild: 'Aunt',
  //     mobileNumber: '+923333332333',
  //     email: 'jane.smith@example.com',
  //     homeAddress: '789 Oak Street',
  //   },
  //   education: {
  //     previouslyAttended: 'Yes',
  //     schoolName: 'Sample School',
  //     schoolAddress: '101 School Avenue',
  //     class: 'Grade 7',
  //     lastAcademicResults: [],
  //     reasonForLeaving: 'Family Relocation',
  //     term: 'Spring',
  //     campus: 'Main',
  //   },
  //   medical: {
  //     diabetes: 'No',
  //     physicalDisability: 'No',
  //     hearingImpairment: 'No',
  //     visualImpairment: 'No',
  //     anyAllergies: 'Yes',
  //     allergies: 'Peanuts',
  //   },
  //   status: {
  //     status: 'Active',
  //     status: '',
  //   },
  // });
  useEffect(() => {
    dispatch(CampusData());
  }, []);

  useEffect(() => {
    if (classes.length == 0) {
      dispatch(ClassesData());
    }
  }, []);

  useEffect(() => {
    if (
      props.selectedStudent &&
      Object.keys(props.selectedStudent).length !== 0
    ) {
      console.log('student coming *', props.selectedStudent);
      setCreateSudentData(props.selectedStudent);
    }
  }, [props.selectedStudent]);

  const handleChangeInfo = (parentObjectName, fieldName, value) => {
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: value,
      },
    }));
  };

  const handleFileChange = (parentObjectName, fieldName, event) => {
    const file = event.target.files[0];
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [...prevState[parentObjectName][fieldName], file],
      },
    }));
  };

  const handleFileRemove = (parentObjectName, fieldName) => {
    console.log('handle file remove', parentObjectName, '@', fieldName);
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [],
      },
    }));
  };
  const handleSubmit = async (e) => {
    console.log('Student Data', createStudentData);
    e.preventDefault();
    try {
      const formData = new FormData();
      // Append personal data
      formData.append('name', createStudentData.personal.name);
      formData.append(
        'ProspectiveStudentInfo.CampusId',
        Number(createStudentData.education.campus)
      );
      formData.append(
        'ProspectiveStudentInfo.ClassId',
        Number(createStudentData.education.class)
      );
      formData.append(
        'age',
        calculateAge(createStudentData.personal.dateOfBirth)
      );

      formData.append('email', createStudentData.personal.emailAddress);
      formData.append(
        'ProspectiveStudentInfo.Status',
        createStudentData.status.status
      );
      formData.append('dateOfBirth', createStudentData.personal.dateOfBirth);
      formData.append('gender', createStudentData.personal.gender);
      formData.append('nic', createStudentData.personal.bForm);
      formData.append('nationality', createStudentData.personal.nationality);
      formData.append(
        'permenantAddress',
        createStudentData.personal.permanentAddress
      );
      formData.append(
        'residentialAddress',
        createStudentData.personal.residentialAddress
      );
      formData.append('city', createStudentData.personal.city);
      formData.append('area', createStudentData.personal.area);
      formData.append('phone', createStudentData.personal.mobileNumber);
      formData.append('currentStatus', createStudentData.status.status);
      formData.append(
        'NicImage',
        createStudentData.personal.b_form[
          createStudentData.personal.b_form.length - 1
        ]
      );
      formData.append(
        'StudentImage',
        createStudentData.personal.passportSizePicture[
          createStudentData.personal.passportSizePicture.length - 1
        ]
      );
      formData.append(
        'BirthCertificateImage',
        createStudentData.personal.birthCertificate[
          createStudentData.personal.birthCertificate.length - 1
        ]
      );
      // Append emergency contact info
      formData.append(
        'studentEmergencyContactInfo.name',
        createStudentData.emergency.name
      );
      formData.append(
        'studentEmergencyContactInfo.email',
        createStudentData.emergency.email
      );
      formData.append(
        'studentEmergencyContactInfo.relationshipType',
        createStudentData.emergency.relWithChild
      );
      formData.append(
        'studentEmergencyContactInfo.phone',
        createStudentData.emergency.mobileNumber
      );
      formData.append(
        'studentEmergencyContactInfo.homeAddress',
        createStudentData.emergency.homeAddress
      );
      // Append education info
      formData.append('ProspectiveStudentInfo.AcademicYearId', 1);
      formData.append(
        'studentEducationInfo.previouslyAttended',
        createStudentData.education.previouslyAttended
      );
      formData.append(
        'studentEducationInfo.schoolName',
        createStudentData.education.schoolName
      );
      formData.append(
        'studentEducationInfo.schoolAddress',
        createStudentData.education.schoolAddress
      );
      formData.append(
        'studentEducationInfo.reasonForLeaving',
        createStudentData.education.reasonForLeaving
      );
      // Append medical info
      formData.append(
        'studentMedicalInfo.hasDiabetes',
        createStudentData.medical.diabetes == 'Yes' ? true : false
      );
      formData.append(
        'studentMedicalInfo.hasPhysicalDisability',
        createStudentData.medical.physicalDisability == 'Yes' ? true : false
      );
      formData.append(
        'studentMedicalInfo.hasHearingImpairment',
        createStudentData.medical.hearingImpairment == 'Yes' ? true : false
      );
      formData.append(
        'studentMedicalInfo.hasVisualImpairment',
        createStudentData.medical.visualImpairment == 'Yes' ? true : false
      );
      formData.append(
        'studentMedicalInfo.hasAllergy',
        createStudentData.medical.anyAllergies == 'Yes' ? true : false
      );
      formData.append(
        'studentMedicalInfo.AllergyReason',
        createStudentData.medical.allergies
      );
      // Append father's data
      formData.append(
        'guardians[0].name',
        createStudentData.parents.fatherName
      );
      formData.append(
        'guardians[0].phone',
        createStudentData.parents.fatherMobileNumber
      );
      formData.append(
        'guardians[0].email',
        createStudentData.parents.fatherEmailAddress
      );
      formData.append(
        'guardians[0].address',
        createStudentData.personal.permanentAddress
      );
      formData.append('guardians[0].gender', 'Male');
      formData.append('guardians[0].type', 'Father');
      formData.append(
        'guardians[0].occupation',
        createStudentData.parents.fatherOccupation
      );
      formData.append(
        'guardians[0].organization',
        createStudentData.parents.fatherOrganization
      );
      formData.append(
        'guardians[0].Nic',
        createStudentData.parents.fatherNICNumber
      );
      formData.append(
        'guardians[0].maritalStatus',
        createStudentData.parents.maritalStatus
      );
      formData.append(
        'guardians[0].education',
        createStudentData.parents.fatherEducation
      );
      formData.append(
        'guardians[0].nicImage',
        createStudentData.parents.fatherUploadNIC[
          createStudentData.parents.fatherUploadNIC.length - 1
        ]
      );
      formData.append(
        'guardians[0].guardianImage',
        createStudentData.parents.fatherUploadPassportPicture[
          createStudentData.parents.fatherUploadPassportPicture.length - 1
        ]
      );
      // Append mother's data
      formData.append(
        'guardians[1].name',
        createStudentData.parents.motherName
      );
      formData.append(
        'guardians[1].phone',
        createStudentData.parents.motherMobileNumber
      );
      formData.append(
        'guardians[1].email',
        createStudentData.parents.motherEmailAddress
      );
      formData.append(
        'guardians[1].address',
        createStudentData.personal.permanentAddress
      );
      formData.append('guardians[1].gender', 'Female');
      formData.append('guardians[1].type', 'Mother');
      formData.append(
        'guardians[1].occupation',
        createStudentData.parents.motherOccupation
      );
      formData.append(
        'guardians[1].organization',
        createStudentData.parents.motherOrganization
      );
      formData.append(
        'guardians[1].Nic',
        createStudentData.parents.motherNICNumber
      );
      formData.append(
        'guardians[1].maritalStatus',
        createStudentData.parents.maritalStatus
      );
      formData.append(
        'guardians[1].education',
        createStudentData.parents.motherEducation
      );
      formData.append(
        'guardians[1].nicImage',
        createStudentData.parents.motherUploadNIC[
          createStudentData.parents.motherUploadNIC.length - 1
        ]
      );
      formData.append(
        'guardians[1].guardianImage',
        createStudentData.parents.motherUploadPassportPicture[
          createStudentData.parents.motherUploadPassportPicture.length - 1
        ]
      );
      if (
        props.selectedStudent &&
        Object.keys(props.selectedStudent).length !== 0
      ) {
        const studentId = props.selectedStudent.Id;
        // console.log('stuid', studentId);
        // for (var pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }
        formData.append('id', studentId);
        // const formData = sendData(studentId);
        dispatch(updateStudent({ id: studentId, formData: formData }));
        // await sendDataApi({ id: studentId, data: formData });
      } else {
        // const formData = sendData();
        dispatch(createdStudent(formData));
      }
    } catch (err) {
      console.log('error', err);
    }
    props.createToggle();
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 6) {
        setactiveTab(tab);
      }
    }
  }
  const updateTabComplete = (boolVal) => {
    const updated_tabs = [...tabs];
    updated_tabs[activeTab - 1].complete = boolVal;
    setTabs(updated_tabs);
    console.log('tabs', tabs);
  };

  return (
    <React.Fragment>
      <Modal size="sm" isOpen={modal}>
        <div className="modal-header">
          <h2 className="modal-title mt-0" id="myModalLabel">
            <IoIosWarning size={32} color="orange" /> Warning
          </h2>
        </div>
        <div className="modal-body">
          <h6> No Class availaible, Please create class first.</h6>
        </div>
        <div className="modal-footer">
          <Link
            to="/academics/Classes"
            onClick={() =>
              setActiveStates({
                main: 'Academics',
                child: 'Classes',
              })
            }
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Create Class
          </Link>
        </div>
      </Modal>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-capitalize mb-2">
                {props.forView === true
                  ? 'STUDENT DETAIL'
                  : props.selectedStudent &&
                    Object.keys(props.selectedStudent).length !== 0
                  ? 'UPDATE STUDENT'
                  : 'CREATE STUDENT'}
              </h4>
              <div className="row">
                {tabs.map((tab, index) => (
                  <div className="col-sm-6 col-md-2 d-grid gap-2" key={index}>
                    <button
                      disabled={
                        !tab.complete &&
                        props?.selectedStudent &&
                        Object.keys(props?.selectedStudent).length === 0
                      }
                      className={`btn ${
                        activeTab === tab.no ? 'btn-primary' : 'btn-secondary'
                      }`}
                      onClick={() => setactiveTab(tab.no)}
                    >
                      {tab.tab}
                    </button>
                  </div>
                ))}
              </div>
              <div className="form-wizard-wrapper wizard clearfix">
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <PersonalInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        personalInfo={createStudentData.personal}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <ParentsInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        parentsInfo={createStudentData.parents}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <EmergencyContactForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        emergencyContact={createStudentData.emergency}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={4}>
                      <EducationInfoForm
                        tabs={tabs}
                        forView={props.forView}
                        forUpdate={
                          props.selectedStudent &&
                          Object.keys(props.selectedStudent).length !== 0
                            ? true
                            : false
                        }
                        updateTabComplete={updateTabComplete}
                        educationInfo={createStudentData.education}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                      />
                    </TabPane>
                    <TabPane tabId={5}>
                      <MedicalInfoForm
                        tabs={tabs}
                        forView={props.forView}
                        updateTabComplete={updateTabComplete}
                        medicalInfo={createStudentData.medical}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                      />
                    </TabPane>
                    <TabPane tabId={6}>
                      <CurrrentStatusForm
                        tabs={tabs}
                        forView={props.forView}
                        updateTabComplete={updateTabComplete}
                        currentStatus={createStudentData.status}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                      />
                    </TabPane>
                    <TabPane tabId={7}>
                      <div className="row justify-content-center">
                        <div className="col-sm-6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Confirm Detail</h5>
                              <p className="text-muted">
                                Are you sure you want to confirm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? 'previous disabled' : 'previous'
                      }
                    >
                      {props.selectedStudent &&
                      Object.keys(props.selectedStudent).length !== 0 ? null : (
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                          }}
                        >
                          Previous
                        </Link>
                      )}
                    </li>
                    {activeTab === 6 ? (
                      <li className={activeTab === 6 ? '' : 'next'}>
                        {props.forView !== true ? (
                          <button
                            // type="submit"
                            className="btn btn-success"
                            disabled={
                              !tabs[activeTab - 1]?.complete &&
                              props.selectedStudent &&
                              Object.keys(props.selectedStudent).length == 0
                            }
                            onClick={handleSubmit}
                          >
                            {props.selectedStudent &&
                            Object.keys(props.selectedStudent).length !== 0
                              ? 'Update'
                              : 'Create'}
                          </button>
                        ) : (
                          ''
                        )}
                      </li>
                    ) : (
                      <li className={activeTab === 6 ? '' : 'next'}>
                        {props.selectedStudent &&
                        Object.keys(props.selectedStudent).length !==
                          0 ? null : (
                          <button
                            to="#"
                            className="btn btn-primary"
                            disabled={!tabs[activeTab - 1]?.complete}
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </button>
                        )}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
