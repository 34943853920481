import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import { IoIosArrowForward } from "react-icons/io";
import "../../App.css";

const Breadcrumb = (props) => {
  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-start align-items-center justify-content-between mb-4">
          {/* <h4 className="page-title mb-0 font-size-18 text-white">
            {props.breadcrumbItem}
          </h4> */}

          <div className="page-title-left z-3">
            <ol className="breadcrumb m-0 mb-4 poppins-medium">
              <BreadcrumbItem>
                <Link to="#" className="text-white">
                  {props.title}
                </Link>
              </BreadcrumbItem>
              <li className="breadcrumb-separator mx-1 text-white">
                <IoIosArrowForward size={15} />
              </li>
              <BreadcrumbItem active>
                <Link to="#" className="text-warning poppins-bold fs-6">
                  {props.breadcrumbItem}
                </Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
};

export default Breadcrumb;
