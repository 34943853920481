import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  courseCreate,
  courseDelete,
  courseUpdate,
  fetchCourseData,
} from "../../../api/Academics/CourseApiHelper";
import Notify from "../../../components/Notify-toast";

const initialState = {
  course: [],
  loading: null,
  courseError: false,
  totalPages: 1,
};

export const createCourse = createAsyncThunk(
  "course/CreateCourse",
  async (_data) => {
    console.log("creating course", _data);
    try {
      const response = await courseCreate(_data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to create course");
    }
  }
);
export const courseData = createAsyncThunk(
  "course/FetchCourseData",
  async (data) => {
    try {
      const response = await fetchCourseData(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch courses data");
    }
  }
);
export const deleteCourse = createAsyncThunk(
  "course/deleteCourse",
  async (id) => {
    try {
      const response = await courseDelete(id);
      if (response.status === 200) {
        Notify("success", "Course has been deleted");
        return response.data;
      } else {
        throw Error("Failed to delete the course");
      }
    } catch (error) {
      throw Error("Failed to delete course data");
    }
  }
);
export const updateSingleCourse = createAsyncThunk(
  "course/UpdateCourse",
  async ({ id, courseData }) => {
    try {
      console.log("updating course", id, courseData);
      const response = await courseUpdate({ id, courseData });
      console.log("response.data", response);
      if (response.status === 200) {
        Notify("success", "Course has been updated");
        return response.data;
      } else {
        throw Error("Failed to update course data");
      }
    } catch (error) {
      throw Error("Failed to update course");
    }
  }
);

export const CoursesSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    addCourse: (state, action) => {
      state.course.push(action.payload);
    },
    emptyCourse: (state, action) => {
      return {
        ...state,
        course: [],
      };
    },
    removeCourse: (state, action) => {
      state.course = state.course.filter((item) => item.id !== action.payload);
    },
    updateCourse: (state, action) => {
      const { id, newData } = action.payload;
      const courseToUpdate = state.course.find((course) => course.id === id);
      if (courseToUpdate) {
        Object.assign(courseToUpdate, newData);
      }
    },
    updateCourseError: (state, action) => {
      state.courseError = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateSingleCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSingleCourse.fulfilled, (state, action) => {
        state.loading = false;
        state.course = state.course.map((cls) =>
          cls.id === action.payload.id ? action.payload : cls
        );
      })
      .addCase(updateSingleCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteCourse.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        console.log("delete course payload", action.payload);
        state.loading = false;
        state.course = state.course.filter((cl) => cl.id !== action.payload);
      })
      .addCase(deleteCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(courseData.pending, (state) => {
        state.loading = true;
      })
      .addCase(courseData.fulfilled, (state, action) => {
        // console.log("course data payload!", action.payload);
        state.loading = false;
        if (action.payload.status === 200) {
          state.course = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(courseData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createCourse.pending, (state) => {
        state.loading = true;
        state.courseError = null; // Reset error state on pending
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        console.log("new course in payload ", action.payload);
        state.loading = false;
        state.status = action.payload.status;
        if (action.payload.status === 200) {
          state.course = [...state.course, action.payload.data];
          state.courseError = action.payload.status;
          Notify("success", "Course has been created");
        } else {
          Notify("error", action.payload.data.message);
        }
      })
      .addCase(createCourse.rejected, (state, action) => {
        state.loading = false;
        state.courseError = action.error.message;
      });
  },
});

export const {
  addCourse,
  removeCourse,
  updateCourse,
  updateCourseError,
  emptyCourse,
} = CoursesSlice.actions;

export const allCourses = (state) => state.allCourses;

export default CoursesSlice.reducer;
