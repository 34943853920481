import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SidebarContent from "./SidebarContent.js";
import avatar2 from "../../assets/images/city_school_profile.png";
import { decodeJWT } from "../../helpers/jwt-token-access/tokenMethods.js";
import UserInfoContext from "../../context/userinfo.context.js";

const Sidebar = () => {
  const { userInfo, setUserInfo } = useContext(UserInfoContext);

  useEffect(() => {
    if (userInfo.Email == undefined) {
      const token = localStorage.getItem("_schoolware_token_userinfo_");
      const decoded_token = decodeJWT(token);
      setUserInfo(decoded_token);
    }
  }, [userInfo]);

  return (
    <React.Fragment>
      <div className="vertical-menu-working vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img
                src={avatar2}
                alt=""
                className="avatar-md mx-auto rounded-circle"
              />
            </div>

            <div className="mt-3">
              <Link
                to="#"
                className="text-muted fw-medium font-size-16 text-text-uppercase"
              >
                {userInfo?.Username?.split("@")[0]?.toUpperCase() || "USER"}
              </Link>
              {/* <p className="text-body fw-medium  mt-1 mb-0 font-size-13">
                m.rizwan@braincrop.io
              </p> */}
            </div>
          </div>
          <div data-simplebar className="h-100">
            {/* {props.type !== "condensed" ? (
              <SidebarContent />
            ) : (
              <SidebarContent />
            )} */}
            <SidebarContent />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
