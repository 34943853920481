import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchClassData,
  classCreate,
  classUpdate,
  classDelete,
  fetchClassDataSingle,
} from "../../api/Academics/ClassApiHelper";
import { toast } from "react-toastify";
import Notify from "../../components/Notify-toast";

export const ClassesData = createAsyncThunk(
  "class/FetchClassData",
  async (data) => {
    try {
      const response = await fetchClassData(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch classes data");
    }
  }
);

export const ClassesDataSingle = createAsyncThunk(
  "class/FetchSingleClassData",
  async (data) => {
    try {
      const response = await fetchClassDataSingle(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch classes data");
    }
  }
);

export const createClass = createAsyncThunk(
  "class/CreateClass",
  async (_data) => {
    try {
      const response = await classCreate(_data);
      if (response?.status === 200) {
        return response.data;
      }
      // return {
      //   id: response.data,
      //   name: _data.name,
      //   code: _data.code,
      //   description: _data.description,
      //   departmentId: _data.departmentId,
      // };
    } catch (error) {
      throw Error("Failed to create class");
    }
  }
);

export const updateSingleClass = createAsyncThunk(
  "class/UpdateClass",
  async ({ id, classData }) => {
    try {
      // console.log("class for update", classData);
      const response = await classUpdate({ id, classData });
      // console.log("response.data", response);
      return response;
    } catch (error) {
      throw Error("Failed to update class");
    }
  }
);

export const deleteClass = createAsyncThunk("class/DeleteClass", async (id) => {
  try {
    const response = await classDelete(id);
    if (response.status === 200) {
      return response.data;
    } else {
      throw Error("Failed to delete class");
    }
  } catch (error) {
    throw Error("Failed to delete class data");
  }
});

const initialState = {
  classes: [],
  singleClasses: [],
  loading: false,
  error: null,
  totalPages: 1,
};

export const classSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    addClass: (state, action) => {},
    updateClass: (state, action) => {
      // console.log("updating class", action.payload);
    },
    removeClass: (state, action) => {
      // console.log(action.payload)
      return {
        ...state,
        classes: state.classes.filter((item) => action.payload !== item.name),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(ClassesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(ClassesData.fulfilled, (state, action) => {
        console.log("Classes Data ->", action.payload);
        state.loading = false;
        if (action.payload.status === 200) {
          state.classes = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(ClassesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteClass.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteClass.fulfilled, (state, action) => {
        // console.log("delete class payload", action.payload);
        state.loading = false;
        if (action.payload) {
          state.classes = state.classes.filter(
            (cl) => cl.id !== action.payload
          );
          Notify("success", "Class has been deleted");
        } else {
          Notify("error", "Error on deleting the class. Something went wrong");
        }
      })
      .addCase(deleteClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createClass.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(createClass.fulfilled, (state, action) => {
        // console.log("new class in payload ", action.payload);
        state.loading = false;
        if (action.payload) {
          state.classes = [...state.classes, action.payload];
          Notify("success", "Class has been added");
        } else {
          Notify("error", "Error on creating the class. Something went wrong");
        }
        state.error = null;
      })
      .addCase(createClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ClassesDataSingle.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.singleClasses = [];
        // Reset error state on pending
      })
      .addCase(ClassesDataSingle.fulfilled, (state, action) => {
        // console.log("new class in payload ", action.payload);
        state.loading = false;
        if (action.payload.status === 200) {
          // console.log("single campus classes", action.payload.data.items)
          state.singleClasses = action.payload.data.items;
        }
        // if (action.payload?.status === 200) {
        //   Notify("success", 'Class Added successfully');
        // } else {
        //   Notify("error", 'Class Not Created. Something Went Wrong');
        // }
        state.error = null;
      })
      .addCase(ClassesDataSingle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateSingleClass.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSingleClass.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.status === 200) {
          // console.log("Updated class", action.payload)
          state.classes = state.classes.map((cls) =>
            cls.id === action.payload?.data?.id ? action.payload?.data : cls
          );
          Notify("success", "Class has been updated");
        } else {
          Notify("error", "Error on updating the class. Something went wrong");
        }
      })
      .addCase(updateSingleClass.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addClass, removeClass, updateClass } = classSlice.actions;
export const allClasses = (state) => state.allClasses;
export default classSlice.reducer;
