import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teachers: [
    {
      id: '24',
      joinDate: 'September 29, 2021',
      name: 'Lionel Messi',
      dept: 'Science',
      rating: 9.5,
      specialize: ['Science', 'Biology'],
    },
    {
      id: '31',
      joinDate: 'April 3, 2022',
      name: 'Babar Azam',
      dept: 'Sports',
      rating: 9.0,
      specialize: ['Cricket'],
    },
  ],
};

export const teacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    addTeacher: (state, action) => {
      // console.log("redux-recevied", action.payload);
      state.teachers.push(action.payload);
    },
    updateTeacher: (state, action) => {
      //   console.log("updating class", action.payload)
    },
    removeTeacher: (state, action) => {
      // console.log(action.payload)
      return {
        ...state,
        teachers: state.teachers.filter((item) => action.payload !== item.id),
      };
    },
  },
});

export const { addTeacher, updateTeacher, removeTeacher } =
  teacherSlice.actions;
export const allTeachers = (state) => state.allTeachers;
export default teacherSlice.reducer;
