import React, { useEffect, useState } from "react";
import { InputField, SelectField } from "../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function MedicalInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  medicalInfo,
  forView,
}) {
  let infoObject = "medical";

  useEffect(() => {
    const keysToExclude = ["allergies"];
    const isAnyKeyEmpty = Object.keys(medicalInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return (
          medicalInfo[key] === "" ||
          medicalInfo[key] === null ||
          medicalInfo[key] === undefined
        );
      });
    // console.log('Medical: ', isAnyKeyEmpty);
    if (!isAnyKeyEmpty) {
      // console.log("tabs", tabs)
      updateTabComplete(true);
    } else {
      if (tabs[4].complete) {
        updateTabComplete(false);
      }
    }
  }, [medicalInfo]);

  return (
    <React.Fragment>
      <form>
        <h6 className="mt-4 poppins-medium">Medical Details</h6>
        <div className="row">
          <SelectField
            disabled={forView}
            label="Diabetes"
            compulsory={true}
            value={medicalInfo.diabetes}
            selectOptions={["Yes", "No"]}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "diabetes",
                e.target.value === "true"
              )
            }
          />
          <SelectField
            disabled={forView}
            label="physical Disability"
            compulsory={true}
            value={medicalInfo.physicalDisability}
            selectOptions={["Yes", "No"]}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "physicalDisability",
                e.target.value === "true"
              )
            }
          />
          <SelectField
            disabled={forView}
            label="Hearing Impairment"
            compulsory={true}
            value={medicalInfo.hearingImpairment}
            selectOptions={["Yes", "No"]}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "hearingImpairment",
                e.target.value === "true"
              )
            }
          />
          <SelectField
            disabled={forView}
            label="Visual Impairment"
            compulsory={true}
            value={medicalInfo.visualImpairment}
            selectOptions={["Yes", "No"]}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "visualImpairment",
                e.target.value === "true"
              )
            }
          />
          <SelectField
            disabled={forView}
            label="Have Allergy"
            compulsory={true}
            value={medicalInfo.anyAllergies}
            selectOptions={["Yes", "No"]}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "anyAllergies",
                e.target.value === "true"
              )
            }
          />
          {medicalInfo.anyAllergies == "Yes" ? (
            <InputField
              label="Allergies"
              disabled={forView}
              type="text"
              value={medicalInfo.allergies}
              placeholder="Allergies.."
              onChange={(e) =>
                handleChangeInfo(infoObject, "allergies", e.target.value)
              }
            />
          ) : (
            <></>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
