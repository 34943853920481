import React, { useState, useEffect, useContext } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link } from "react-router-dom";

// users
import user4 from "../../../assets/images/city_school_profile.png";
import { decodeJWT } from "../../../helpers/jwt-token-access/tokenMethods";
import UserInfoContext from "../../../context/userinfo.context";

const ProfileMenu = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const { userInfo } = useContext(UserInfoContext);

  const [menu, setMenu] = useState(false);

  // useEffect(() => {
  //   if (localStorage.getItem("authUser")) {
  //     if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.displayName);
  //     } else if (
  //       process.env.REACT_APP_DEFAULTAUTH === "fake" ||
  //       process.env.REACT_APP_DEFAULTAUTH === "jwt"
  //     ) {
  //       const obj = JSON.parse(localStorage.getItem("authUser"));
  //       setusername(obj.username);
  //     }
  //   }
  // }, [props.success]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={user4}
            alt="Header Avatar"
          />{" "}
          <span className="d-none d-xl-inline-block ms-1 text-white">
            {userInfo?.Username?.split("@")[0]?.toUpperCase() || "User"}
          </span>{" "}
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block text-white"></i>{" "}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="" disabled>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1"></i> View
            Profile
          </DropdownItem>
          <DropdownItem tag="a" href="/#" disabled>
            <i className="bx bx-wallet font-size-16 align-middle me-1"></i> My
            Wallet
          </DropdownItem>
          <DropdownItem tag="a" href="#" disabled>
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
            Settings
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen" disabled>
            <i className="bx bx-lock-open font-size-16 align-middle me-1"></i>{" "}
            Lock screen
          </DropdownItem>
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-danger">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>{" "}
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
