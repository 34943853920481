import { axiosInstance, instanceAuth } from "../axiosConfig";

export const fetchStudentAttendance = async (data) => {
  try {
    const response = await instanceAuth.get("Attendence", {
      params: {
        ...data,
      },
    });
    console.log("response from fetch attendance", response);
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};

export const fetchSingleStudentAttendance = async (data) => {
  try {
    const response = await instanceAuth.get("Attendence", {
      params: {
        ...data,
      },
    });
    console.log("response to fetch single student attendance", response);
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};

export const deleteStudentAttendanceData = async (id) => {
  try {
    const response = await instanceAuth.delete(`Attendence/${id}`);
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};

export const createStudentAttendanceData = async (data) => {
  try {
    const response = await instanceAuth.post("Attendence", data);
    return response;
  } catch (error) {
    console.log("error-create-student", error);
    throw error;
  }
};

export const updateStudentAttendanceData = async ({ id, data }) => {
  try {
    const response = await instanceAuth.put(`Attendence/${id}`, data);
    return response;
  } catch (error) {
    console.log("error-update-studentData", error);
    throw error;
  }
};
