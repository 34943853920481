import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Row } from "reactstrap";
import AttandanceTable from "./attendance-table";
import AttendanceForm from "./attendance-form";

export default function StudentAttendance() {
  const [createAttendance, setCreateAttendance] = useState(false);
  const [stdAttendanceData, setStdAttendanceData] = useState({});
  const forUpdate = (student_attendance) => {
    console.log("for update attendance index", student_attendance);
    setStdAttendanceData(student_attendance);
    setCreateAttendance(true);
  };

  const toggleTab = () => {
    setStdAttendanceData({});
    setCreateAttendance(!createAttendance);
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Students" breadcrumbItem="Attendance" />
      <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          <button
            style={{
              background: "#37a26a",
              color: "white",
              padding: "12px 30px",
              border: "none",
              borderRadius: "18px",
              marginTop: "10px",
              marginRight: "10px",
            }}
            onClick={toggleTab}
          >
            {createAttendance ? "View" : "Create"}
          </button>
        </Col>
      </Row>
      {createAttendance ? (
        <AttendanceForm
          studentAttendanceData={stdAttendanceData}
          toggleTab={toggleTab}
        />
      ) : (
        <AttandanceTable forUpdate={forUpdate} />
      )}
    </div>
  );
}
