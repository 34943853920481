import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Modal } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";

import {
  allApplicant,
  ApplicantsData,
} from "../../redux/slice/admission/applicantSlice";
import StartupForm from "../StartupForm";
import SalesAnalytics from "./sales-analytics";
import RevenueChart from "./revenue-chart";
import Inbox from "./Inbox";
import LatestTransaction from "./latest-transaction";
import { allCampus } from "../../redux/slice/academics/CampusSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    applicants = [],
    loading,
    error,
    totalPages,
  } = useSelector(allApplicant);
  const { campus, currentCampus } = useSelector(allCampus);

  const [campusModal, setCampusModal] = useState(false);
  const interviewCount = applicants.filter(
    (v) => v?.studentAdmissionStatus?.status === "Interview"
  ).length;
  const pendingCount = applicants.filter(
    (v) => v?.studentAdmissionStatus?.status === "Pending"
  ).length;
  const Shortlist = applicants.filter(
    (v) => v?.studentAdmissionStatus?.status === "InterviewDone"
  ).length;
  const OnHold = applicants.filter(
    (v) => v?.studentAdmissionStatus?.status === "OnHold"
  ).length;

  const commonRadialOptions = {
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "80%",
          background: "#fff",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          strokeWidth: "67%",
          margin: 0,
        },
        dataLabels: {
          showOn: "always",
          value: {
            formatter: (val) => `${val}%`,
            color: "#111",
            fontSize: "22px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 0],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Percent"],
  };

  const chartConfigs = [
    {
      label: "Pending",
      count: pendingCount,
      series: [
        applicants.length === 0
          ? 0
          : ((pendingCount / applicants.length) * 100).toFixed(0),
      ],
      options: {
        ...commonRadialOptions,
        fill: {
          ...commonRadialOptions.fill,
          gradient: {
            ...commonRadialOptions.fill.gradient,
            gradientToColors: ["#b39b04"],
          },
        },
      },
    },
    {
      label: "Shortlisted",
      count: Shortlist,
      series: [
        applicants.length === 0
          ? 0
          : ((Shortlist / applicants.length) * 100).toFixed(0),
      ],
      options: {
        ...commonRadialOptions,
        fill: {
          ...commonRadialOptions.fill,
          gradient: {
            ...commonRadialOptions.fill.gradient,
            gradientToColors: ["#38c465"],
          },
        },
      },
    },
    {
      label: "On Hold",
      count: OnHold,
      series: [
        applicants.length === 0
          ? 0
          : ((OnHold / applicants.length) * 100).toFixed(0),
      ],
      options: {
        ...commonRadialOptions,
        fill: {
          ...commonRadialOptions.fill,
          gradient: {
            ...commonRadialOptions.fill.gradient,
            gradientToColors: ["#8f929b"],
          },
        },
      },
    },
    {
      label: "Interview",
      count: interviewCount,
      series: [
        applicants.length === 0
          ? 0
          : ((interviewCount / applicants.length) * 100).toFixed(0),
      ],
      options: {
        ...commonRadialOptions,
        fill: {
          ...commonRadialOptions.fill,
          gradient: {
            ...commonRadialOptions.fill.gradient,
            gradientToColors: ["#2f5eec"],
          },
        },
      },
    },
  ];

  useEffect(() => {
    if (currentCampus == 0) {
      const data = {
        // CampusId: Number(currentCampus),
      };
      dispatch(ApplicantsData(data));
    } else if (currentCampus) {
      const data = {
        CampusId: Number(currentCampus),
      };
      dispatch(ApplicantsData(data));
    }
  }, [currentCampus]);

  const handleModalSubmit = () => {
    setCampusModal(false);
  };

  return (
    <div className="page-content">
      <Modal size="md" isOpen={campusModal} centered>
        <Card>
          <CardBody>
            <h3 className="card-title fs-5 text-center mb-4">Select campus</h3>
            <StartupForm
              style={{ background: "transparent", color: "black" }}
            />
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary mt-4 w-100"
                onClick={handleModalSubmit}
              >
                Select
              </button>
            </div>
          </CardBody>
        </Card>
      </Modal>
      <Row>
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="page-title mb-0 font-size-18 text-white">
              Dashboard
            </h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active text-white">
                  Welcome to School Management System
                </li>
              </ol>
            </div>
          </div>
        </div>
      </Row>
      <Row>
        {chartConfigs.map((config, index) => (
          <Col lg={3} key={index}>
            <Card className="rounded-4">
              <CardBody>
                <Row>
                  <div className="col-xxl-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-6">
                    <span className="font-size-14 mt-2 text-grey poppins-medium mx-1 text-nowrap">
                      {config.label}
                    </span>
                    <h4 className="font-size-30 mt-2">{config.count}</h4>
                  </div>
                  <div className="col-xxl-12 col-sm-6 col-md-12 col-lg-12 col-xl-12 col-6 d-flex justify-content-center">
                    <ReactApexChart
                      options={config.options}
                      series={config.series}
                      type="radialBar"
                      height={190}
                      width={190}
                    />
                  </div>
                </Row>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <Row>
        <Col lg={6}>
          <SalesAnalytics />
        </Col>
        <Col lg={6}>
          <RevenueChart />
        </Col>
      </Row>
      <Row>
        <Inbox />
        <LatestTransaction />
      </Row>
    </div>
  );
};

export default Dashboard;
