import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../assets/images/city_school_profile.png";
import Loader from "../../components/loader/Loader";
import {
  LoginUser,
  SetResponseLogin,
  allLogin,
} from "../../redux/slice/Authentication/loginSlice";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { response, error, loading } = useSelector(allLogin);

  // console.log(
  //   'jwt--',
  //   response?.data?.jwtToken,
  //   'status',
  //   response?.status,
  //   'all-data',
  //   response,
  //   'error',
  //   error
  // );

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    if (
      localStorage.getItem("_schoolware_token_userinfo_") != null ||
      localStorage.getItem("_schoolware_token_userinfo_") != undefined
    ) {
      navigate("/dashboard");
    }
  }, [response]);
  const disabled = !loginData.email || !loginData.password;
  const handleSubmit = () => {
    dispatch(SetResponseLogin());
    dispatch(
      LoginUser({
        username: loginData.email,
        password: loginData.password,
      })
    );
  };
  // console.log("response",response)
  // console.log("user-data", loginData);
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <h2>
            <i className="fas fa-home" />
          </h2>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Welcome Back !</h5>
                    <p className="text-white-50 mb-0">
                      Sign in to continue to City School.
                    </p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="71%" width={"71%"} />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    {/* {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null} */}
                    <div className="mb-3">
                      <label>Enter Email</label>
                      <input
                        value={loginData.email}
                        className="form-control"
                        type="email"
                        placeholder="Enter email"
                        onChange={(e) =>
                          setLoginData((prev) => ({
                            ...prev,
                            email: e.target.value,
                          }))
                        }
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label>Enter Password</label>
                      <input
                        className="form-control"
                        onChange={(e) =>
                          setLoginData((prev) => ({
                            ...prev,
                            password: e.target.value,
                          }))
                        }
                        value={loginData.password}
                        type="password"
                        required
                        placeholder="Enter Password"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSubmit();
                          }
                        }}
                      />
                    </div>
                    {response?.status === 400 ? (
                      <p className="text-danger mt-2">{response?.data}</p>
                    ) : (
                      ""
                    )}
                    {response[response.length - 1]?.status === 201 ? (
                      <p className="text-danger mt-2">
                        {response[response.length - 1]?.data?.message}
                      </p>
                    ) : (
                      ""
                    )}
                    {response.code === "ERR_NETWORK" ? (
                      <p className="text-danger mt-2 ">
                        Something went wrong Check Network Connectivity!!
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="mt-3">
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="submit"
                        disabled={disabled}
                        onClick={() => handleSubmit()}
                      >
                        {loading ? (
                          <div
                            className="spinner-border text-light"
                            style={{ height: "16px", width: "16px" }}
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Log In"
                        )}
                      </button>
                    </div>

                    {/* <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <i className="mdi mdi-lock me-1"></i> Forgot your
                        password?
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>
                  Don't have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>© {new Date().getFullYear()} HappyDigital.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
