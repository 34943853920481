import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';
// layouts Format
// import VerticalLayout from "./components/VerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from './components/NonAuthLayout';
// Import scss
import './assets/scss/theme.scss';
import fakeBackend from './helpers/AuthType/fakeBackend';
import {
  authRoutes,
  roleBase,
  AdminRoutes,
  userRoutes,
} from './routes/allRoutes';
import AppRoute from './routes/middleware/Authmiddleware';
import Layout from './components/workingLayout';
import ErrorPage from './pages/ErrorPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';
import {
  loginUserRole,
  allLogin,
} from './redux/slice/Authentication/loginSlice';

// Activating fake backend
fakeBackend();

const App = (props) => {
  const { userRole } = useSelector(allLogin);
  const route = localStorage.getItem('userRole');
  const change = JSON.parse(route);
  console.log('userRole', change?.role);
  console.log('domain', window.location.hostname);

  return (
    <React.Fragment>
      {/* <Router>

        <Switch>
        {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
          
          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>

      </Router> */}

      <div>
        <ToastContainer />
      </div>

      <Routes>
        {authRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
        {change?.role === 'Admin' ? (
          <>
            {AdminRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AppRoute>
                    <Layout>{route.component}</Layout>
                    {/* <Layout>{route.component}</Layout> */}
                  </AppRoute>
                }
                key={idx}
                exact={true}
              />
            ))}
          </>
        ) : (
          <>
            {userRoutes.map((route, idx) => (
              <Route
                path={route.path}
                element={
                  <AppRoute>
                    <Layout>{route.component}</Layout>
                    {/* <Layout>{route.component}</Layout> */}
                  </AppRoute>
                }
                key={idx}
                exact={true}
              />
            ))}
          </>
        )}
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </React.Fragment>
  );
};

// App.propTypes = {
//   layout: PropTypes.any
// }

// const mapStateToProps = (state) => {
//   return {
//     layout: state.Layout,
//   };
// };

// export default connect(mapStateToProps, null)(App);
export default App;
