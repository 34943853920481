import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  classRoomFetchAPI,
  classRoomCreateAPI,
  classRoomDeleteAPI,
  classRoomUpdateAPI,
} from "../../../api/Academics/ClassRoomapiHelper";
import Notify from "../../../components/Notify-toast";

export const ClassRoomFetch = createAsyncThunk(
  "Classroom/ClassRoomFetch",
  async (data) => {
    try {
      const response = await classRoomFetchAPI(data);
      const _response = {
        data: response?.data,
        status: response?.status,
      };
      return _response;
    } catch (error) {
      Notify("error", "Failed to fetch Classroom");
      throw Error("Failed to fetch the Classroom");
    }
  }
);
export const ClassRoomCreate = createAsyncThunk(
  "Classroom/ClassRoomCreate",
  async (data) => {
    try {
      const response = await classRoomCreateAPI(data);
      if (response?.status === 200) {
        Notify("success", "Classroom added successfully.");
        return response.data;
      } else {
        Notify("error", "Failed to create Classroom");
        throw Error("Failed to add Classroom");
      }
    } catch (error) {
      Notify("error", "Failed to create Classroom");
      throw Error("Failed to add Classroom");
    }
  }
);
export const ClassRoomUpdate = createAsyncThunk(
  "Classroom/ClassRoomUpdate",
  async ({ id: id, bodyData: bodyData }) => {
    try {
      const response = await classRoomUpdateAPI({ id: id, bodyData: bodyData });
      if (response?.status === 200) {
        Notify("success", "Classroom updated.");
        return response.data;
      } else {
        Notify("error", "Failed to update Classroom");
        throw Error("Failed to update Classroom");
      }
    } catch (error) {
      Notify("error", "Failed to update Classroom");
      throw Error("Failed to update Classroom");
    }
  }
);
export const ClassRoomDelete = createAsyncThunk(
  "Classroom/ClassRoomDelete",
  async (id) => {
    try {
      const response = await classRoomDeleteAPI(id);
      if (response?.status === 200) {
        Notify("success", "Classroom deleted.");
        return response.data;
      } else {
        Notify("error", "Failed to delete Classroom");
        throw Error("Failed to delete Classroom");
      }
    } catch (error) {
      Notify("error", "Failed to delete Classroom");
      throw Error("Failed to delete Classroom");
    }
  }
);

const initialState = {
  classroomsData: [],
  totalPages: 1,
  loading: false,
  error: null,
};

export const Classroom = createSlice({
  name: "Classroom",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(ClassRoomFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ClassRoomFetch.fulfilled, (state, action) => {
        console.log("Classroom fetch payload", action.payload.data?.items);
        state.loading = false;
        state.totalPages = action.payload.data?.totalPages;
        state.classroomsData = action.payload.data?.items;
      })
      .addCase(ClassRoomFetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ClassRoomCreate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ClassRoomCreate.fulfilled, (state, action) => {
        console.log("Classroom create payload", action.payload);
        state.loading = false;
        state.classroomsData = [...state.classroomsData, action.payload];
      })
      .addCase(ClassRoomCreate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ClassRoomDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ClassRoomDelete.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Classroom delete payload", action.payload);
        state.classroomsData = state.classroomsData.filter(
          (i) => i.id !== action.payload
        );
      })
      .addCase(ClassRoomDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(ClassRoomUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(ClassRoomUpdate.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Classroom update payload", action.payload);
        if (action.payload) {
          state.classroomsData = state.classroomsData.map((class_time) =>
            class_time.id === action.payload.id ? action.payload : class_time
          );
        }
      })
      .addCase(ClassRoomUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {} = Classroom.actions;

export const allClassroom = (state) => state.allClassroom;

export default Classroom.reducer;
