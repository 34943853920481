import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap';
import { CiExport } from 'react-icons/ci';

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (apiData, fileName) => {
    try {
      // Validate that apiData is an array
      //   if (!Array.isArray(apiData)) {
      //     throw new Error('Provided data is not an array');
      //   }

      // Create worksheet from JSON data
      const ws = XLSX.utils.json_to_sheet(apiData);

      // Create workbook and add worksheet
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

      // Generate Excel file buffer
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

      // Create Blob from buffer
      const data = new Blob([excelBuffer], { type: fileType });
    
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

  return (
    <>
      <button
        className='switch-btn mx-1'
        style={{
          background: '#3b5de7',
          color: 'white',
        }}
        // className={`${activeTab == "View" ? "active" : null}`}
        onClick={() => exportToCSV(apiData, fileName)}
      >
        <CiExport style={{ marginRight: 10, fontSize: 20 }} />
        Export CSV
      </button>
    </>
  );
};
