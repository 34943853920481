import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFormOptionsData } from '../../../api/satff/catalog/apiHelpers';

const initialState = {
  optionState: {
    staffFormOptions: [],
    loading: false,
    error: null,
  },
  // staffView:true,
  // staffUpdate:false,
  // staffCreate:false,
};

export const fetchDataAsync = createAsyncThunk(
  'data/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetchFormOptionsData();
      return data;
    } catch (error) {
      // console.log("error : in optionSlice....",error);
      return rejectWithValue(error.message);
    }
  }
);

export const formOptionsSlice = createSlice({
  name: 'staffFormOptions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataAsync.pending, (state) => {
        state.optionState.loading = true;
      })
      .addCase(fetchDataAsync.fulfilled, (state, action) => {
        state.optionState.loading = false;
        state.optionState.staffFormOptions = action.payload;
        state.optionState.error = null; // Reset error when request succeeds
      })
      .addCase(fetchDataAsync.rejected, (state, action) => {
        state.optionState.loading = false;
        state.optionState.error = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
// export const { addStaff , setSelectedStaff, setStaffState, setSelectedStaffUpdate, deleteSelectedStaff, updateStaff  } = formOptionsSlice.actions;

export const FormOptions = (state) => state.staffFormOptions.optionState;

export default formOptionsSlice.reducer;
