const reactSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? "#7b8ed2" : "#f6f6f6",
    boxShadow: state.isFocused ? "0 0 0 0.25rem rgba(106, 79, 204, 0.25)" : "",
    fontWeight: 400,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: ".10rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#2170e1" : "white",
    color: state.isFocused ? "white" : "black",
    fontWeight: 400,
    padding: "0.100rem 0.40rem",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#212529",
    fontWeight: 400,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#212529",
    fontWeight: 400,
  }),
};

export { reactSelectStyle };
