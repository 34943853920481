import { axiosInstance } from '../axiosConfig';

export const LoginData = async (data) => {
  try {
    const response = await axiosInstance.post('/Auth/Login', data);
    return response;
  } catch (error) {
    console.log('error-for-login', error);
    throw error;
  }
};
