const MapExportData = (student,name) => {
    let reactStudentObj
    // console.log("name",name)
    if(name === "applicants"){
        reactStudentObj = {
            // RollNumber: student?.barcode,
            Name: student?.name,
            DateOfBirth: student?.dateOfBirth,
            BForm: student?.nic,
            Nationality: student?.nationality,
            InterViewStatus:student?.studentAdmissionStatus?.status,
            Gender: student?.gender,
            PermanentAddress: student?.permenantAddress,
            ResidentialAddress: student?.residentialAddress,
            City: student?.city,
            Area: student?.area,
            MobileNumber: student?.phone,
            EmailAddress: student?.email,
            PassportSizePicture: student?.studentImageUrl,
            BirthCertificate: student?.birthCertificateImageUrl,
            BForm: student?.nicImageUrl,
            FatherName: student?.guardians[0]?.name,
            FatherNICNumber: student?.guardians[0]?.nic,
            FatherOccupation: student?.guardians[0]?.occupation,
            FatherOrganization: student?.guardians[0]?.organization,
            FatherEducation: student?.guardians[0]?.education,
            FatherMobileNumber: student?.guardians[0]?.phone,
            FatherEmailAddress: student?.guardians[0]?.email,
            FatherUploadNIC: student?.guardians[0]?.nicImageUrl,
            FatherUploadPassportPicture: student?.guardians[0]?.guardianImageUrl,
            MotherName: student?.guardians[1]?.name,
            MotherNICNumber: student?.guardians[1]?.nic,
            MotherOccupation: student?.guardians[1]?.occupation,
            MotherOrganization: student?.guardians[1]?.organization,
            MotherEducation: student?.guardians[1]?.education,
            MotherMobileNumber: student?.guardians[1]?.phone,
            MotherEmailAddress: student?.guardians[1]?.email,
            MotherUploadNIC: student?.guardians[1]?.nicImageUrl,
            MotherUploadPassportPicture: student?.guardians[1]?.guardianImageUrl,
            MaritalStatus: student?.guardians[1]?.maritalStatus,
            IsCommunityRef: student?.studentComunityReferences.length > 0 ? 'Yes' : 'No',
            ReferenceDetails: student?.studentComunityReferences
            .map(r => `Campus: ${r.campus} Name: ${r.name} Position: ${r.position} Relation: ${r.relation} Status: ${r.status}`)
            .join(', '),
            EmergencyContactName: student?.studentEmergencyContactInfo?.name,
            RelationWithChild: student?.studentEmergencyContactInfo?.relationShipType,
            EmergencyContactMobileNumber: student?.studentEmergencyContactInfo?.phone,
            EmergencyContactEmail: student?.studentEmergencyContactInfo?.email,
            EmergencyContactHomeAddress: student?.studentEmergencyContactInfo?.homeAddress,
            // PreviouslyAttended: student?.studentEducationInfo?.previouslyAttended,
            SchoolName: student?.studentEducationInfo?.schoolName,
            SchoolAddress: student?.studentEducationInfo?.schoolAddress,
            TotalNumberOfStudents: student?.studentEducationInfo?.noOfStudents,
            LastAcademicResults: student?.studentEducationInfo?.lastAcademicResult || 'not given',
            ReasonForLeaving:student?.studentEducationInfo?.reasonForLeaving,
            ClassId: student?.studentAdmissionStatus?.class?.id,
            ReasonForLeaving: student?.studentEducationInfo?.reasonForLeaving,
            TermId: student?.studentAdmissionStatus?.academicYear?.id,
            CampusId: student?.studentAdmissionStatus?.campus?.id,
            SubjectsAppeared: student?.studentEducationInfo?.studentAcademicProfiles.map((v) => `${v?.subject} ${v.grade}`).join(', '),
            ALevelSubject:student?.studentAdmissionStatus?.stdAdmSubInfos.map((s) => s.subject).join(', '),
            HasDiabetes: student?.studentMedicalInfo?.hasDiabetes == false ? 'No' : 'Yes',
            HasPhysicalDisability: student?.studentMedicalInfo?.hasPhysicalDisability == false ? 'No' : 'Yes',
            HasHearingImpairment: student?.studentMedicalInfo?.hasHearingImpairment == false ? 'No' : 'Yes',
            HasVisualImpairment: student?.studentMedicalInfo?.hasVisualImpairment == false ? 'No' : 'Yes',
            HasAllergy: student?.studentMedicalInfo?.hasAllergy == false ? 'No' : 'Yes',
            AllergyReason: student?.studentMedicalInfo?.allergyReason,
            // CurrentStatus: student?.currentStatus,
          };
    }else{
        reactStudentObj = {
        RollNumber: student?.barcode,
        Name: student?.prospectiveStudent?.name,
        DateOfBirth: student?.prospectiveStudent?.dateOfBirth,
        BForm: student?.prospectiveStudent?.nic,
        Nationality: student?.prospectiveStudent?.nationality,
        Gender: student?.prospectiveStudent?.gender,
        PermanentAddress: student?.prospectiveStudent?.permenantAddress,
        ResidentialAddress: student?.prospectiveStudent?.residentialAddress,
        City: student?.prospectiveStudent?.city,
        Area: student?.prospectiveStudent?.area,
        MobileNumber: student?.prospectiveStudent?.phone,
        EmailAddress: student?.prospectiveStudent?.email,
        PassportSizePicture: student?.prospectiveStudent?.studentImageUrl,
        BirthCertificate: student?.prospectiveStudent?.birthCertificateImageUrl,
        BForm: student?.prospectiveStudent?.nicImageUrl,
        FatherName: student?.prospectiveStudent?.guardians[0]?.name,
        FatherNICNumber: student?.prospectiveStudent?.guardians[0]?.nic,
        FatherOccupation: student?.prospectiveStudent?.guardians[0]?.occupation,
        FatherOrganization: student?.prospectiveStudent?.guardians[0]?.organization,
        FatherEducation: student?.prospectiveStudent?.guardians[0]?.education,
        FatherMobileNumber: student?.prospectiveStudent?.guardians[0]?.phone,
        FatherEmailAddress: student?.prospectiveStudent?.guardians[0]?.email,
        FatherUploadNIC: student?.prospectiveStudent?.guardians[0]?.nicImageUrl,
        FatherUploadPassportPicture: student?.prospectiveStudent?.guardians[0]?.guardianImageUrl,
        MotherName: student?.prospectiveStudent?.guardians[1]?.name,
        MotherNICNumber: student?.prospectiveStudent?.guardians[1]?.nic,
        MotherOccupation: student?.prospectiveStudent?.guardians[1]?.occupation,
        MotherOrganization: student?.prospectiveStudent?.guardians[1]?.organization,
        MotherEducation: student?.prospectiveStudent?.guardians[1]?.education,
        MotherMobileNumber: student?.prospectiveStudent?.guardians[1]?.phone,
        MotherEmailAddress: student?.prospectiveStudent?.guardians[1]?.email,
        MotherUploadNIC: student?.prospectiveStudent?.guardians[1]?.nicImageUrl,
        MotherUploadPassportPicture: student?.prospectiveStudent?.guardians[1]?.guardianImageUrl,
        MaritalStatus: student?.prospectiveStudent?.guardians[1]?.maritalStatus,
        IsCommunityRef: student?.prospectiveStudent?.studentComunityReferences.length > 0 ? 'Yes' : 'No',
        ReferenceDetails: student?.prospectiveStudent?.studentComunityReferences
  .map(r => `Campus: ${r.campus} Name: ${r.name} Position: ${r.position} Relation: ${r.relation} Status: ${r.status}`)
  .join(', '),
        EmergencyContactName: student?.prospectiveStudent?.studentEmergencyContactInfo?.name,
        RelationWithChild: student?.prospectiveStudent?.studentEmergencyContactInfo?.relationShipType,
        EmergencyContactMobileNumber: student?.prospectiveStudent?.studentEmergencyContactInfo?.phone,
        EmergencyContactEmail: student?.prospectiveStudent?.studentEmergencyContactInfo?.email,
        EmergencyContactHomeAddress: student?.prospectiveStudent?.studentEmergencyContactInfo?.homeAddress,
        PreviouslyAttended: student?.prospectiveStudent?.studentEducationInfo?.previouslyAttended,
        SchoolName: student?.prospectiveStudent?.studentEducationInfo?.schoolName,
        SchoolAddress: student?.prospectiveStudent?.studentEducationInfo?.schoolAddress,
        TotalNumberOfStudents: student?.prospectiveStudent?.studentEducationInfo?.noOfStudents,
        LastAcademicResults: student?.prospectiveStudent?.studentEducationInfo?.lastAcademicResult || 'not given',
        ReasonForLeaving:student?.prospectiveStudent?.studentEducationInfo?.reasonForLeaving,
        ClassId: student?.prospectiveStudent?.studentAdmissionStatus?.class?.id,
        ReasonForLeaving: student?.prospectiveStudent?.studentEducationInfo?.reasonForLeaving,
        TermId: student?.prospectiveStudent?.studentAdmissionStatus?.academicYear?.id,
        CampusId: student?.prospectiveStudent?.studentAdmissionStatus?.campus?.id,
        SubjectsAppeared: student?.prospectiveStudent?.studentEducationInfo?.studentAcademicProfiles.map((v) => `${v?.subject} ${v.grade}`).join(', '),
        ALevelSubject:student?.prospectiveStudent?.studentAdmissionStatus?.stdAdmSubInfos.map((s) => s.subject).join(', '),
        HasDiabetes: student?.prospectiveStudent?.studentMedicalInfo?.hasDiabetes == false ? 'No' : 'Yes',
        HasPhysicalDisability: student?.prospectiveStudent?.studentMedicalInfo?.hasPhysicalDisability == false ? 'No' : 'Yes',
        HasHearingImpairment: student?.prospectiveStudent?.studentMedicalInfo?.hasHearingImpairment == false ? 'No' : 'Yes',
        HasVisualImpairment: student?.prospectiveStudent?.studentMedicalInfo?.hasVisualImpairment == false ? 'No' : 'Yes',
        HasAllergy: student?.prospectiveStudent?.studentMedicalInfo?.hasAllergy == false ? 'No' : 'Yes',
        AllergyReason: student?.prospectiveStudent?.studentMedicalInfo?.allergyReason,
        // CurrentStatus: student?.prospectiveStudent?.currentStatus,
      };
    }
    return reactStudentObj;
  };
  
  export { MapExportData };
  