import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
// import images
import Companylogo from "../../assets/images/logo/The_City_School_(Pakistan)_Logo_2.png";
import ProfileMenu from "../commonWorkingComponents/TopbarDropdown/ProfileMenu";
import "../../App.css";
import { useSelector, useDispatch } from "react-redux";
import {
  CampusData,
  allCampus,
  currentCampusSelect,
} from "../../redux/slice/academics/CampusSlice";
import StartupForm from "../../pages/StartupForm";
import CampusDropdown from "./campusDropdown";

const Header = (props) => {
  const dispatch = useDispatch();
  const [search, setsearch] = useState(false);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.bkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 768) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  useEffect(() => {
    console.log("Loading campus data header..");
    dispatch(CampusData());
    // let getCampusFromLocal = localStorage.getItem(
    //   "_schoolware_selected_campus_"
    // );
    // if (getCampusFromLocal) {
    //   dispatch(currentCampusSelect(getCampusFromLocal));
    // }
  }, []);

  let campusInputstyle = {
    background: "transparent",
    color: "white",
    // borderColor: "#FFF200",
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <Container
            fluid
            className="d-flex flex-row-reverse justify-content-lg-between align-items-center"
          >
            <div className="float-end d-flex align-items-center">
              {/* <Dropdown
                className="d-inline-block d-lg-none ms-2"
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
              >
                <DropdownToggle
                  className="btn header-item noti-icon waves-effect"
                  id="page-header-search-dropdown"
                  tag="button"
                >
                  {" "}
                  <i className="mdi mdi-magnify"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                  <Form className="p-3">
                    <div className="m-0">
                      <div className="input-group">
                        <Input
                          type="text"
                          className="form-control text-white"
                          placeholder="Search ..."
                          aria-label="Recipient's username"
                        />
                        <div className="input-group-append">
                          <Button className="btn btn-primary" type="submit">
                            <i className="mdi mdi-magnify"></i>
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </DropdownMenu>
              </Dropdown> */}
              <Dropdown
                className="d-inline-block ms-1"
                style={{ width: "45%", color: "blue" }}
              >
                <StartupForm style={campusInputstyle} />
              </Dropdown>
              <Dropdown className="d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  onClick={() => {
                    toggleFullscreen();
                  }}
                  className="btn header-item noti-icon waves-effect"
                  data-toggle="fullscreen"
                >
                  <i className="mdi mdi-fullscreen text-white"></i>
                </button>
              </Dropdown>{" "}
              <ProfileMenu />
            </div>
            <div
              style={{ display: "flex", width: "60%", alignItems: "baseline" }}
            >
              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
                data-toggle="collapse"
                onClick={() => {
                  tToggle();
                }}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars text-blue"></i>
              </button>
              <div className="navbar-brand-box logo-container">
                <Link to="/" className="logo logo-dark">
                  <img
                    src={Companylogo}
                    alt="city school-logo"
                    className="logo-container-image"
                    // style={{height: "80px", width: "220px", marginTop: "0px"}}
                  />
                </Link>

                <Link to="/" className="logo logo-light">
                  <img
                    src={Companylogo}
                    alt="city-school-logo"
                    width={180}
                    height={60}
                  />
                </Link>
                <div className="logo-campus-dropdown" style={{}}>
                  <CampusDropdown />
                </div>
              </div>

              {/* <Form className="app-search d-none d-lg-inline-block w-100 mt-3">
                <div className="position-relative">
                  <input
                    type="text"
                    style={{ outline: "none" }}
                    className="w-100 rounded-2 header-search bg-transparent py-2 px-5 text-white"
                    placeholder="Search..."
                  />
                  <span className="bx bx-search-alt text-white"></span>
                </div>
              </Form>{" "} */}
            </div>
          </Container>
        </div>
      </header>
    </>
  );
};

export default Header;
