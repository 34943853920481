import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardTitle,
  Table,
  Modal,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  allApplicant,
  ApplicantsData,
  moveStudent,
  postApplicantsData,
  UpdateApplicantStatus,
  UpdateOfferStatus,
} from "../../../redux/slice/admission/applicantSlice";
import ApplicantDetails from "../Applicants/applicant-details";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import Notify from "../../../components/Notify-toast";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import FilterInput from "../../../components/FilterInputComponent";
import { SelectField } from "../../../components/input-fields";
import { MaptoStudentStateObj } from "../../../helpers/map_student_data";

const Applicants = () => {
  const dispatch = useDispatch();
  const { currentCampus } = useSelector(allCampus);
  const { applicants, loading, error, totalPages } = useSelector(allApplicant);
  const { singleClasses } = useSelector(allClasses);

  const [selectedStudent, setSelectedStudent] = useState(false);
  const [modal, setModal] = useState(false);
  const [create, setCreate] = useState(false);
  const [storeId, setStoreId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [emailSend, setEmailSend] = useState("No");
  const [timeValidationError, setTimeValidationError] = useState("");
  const [filterApplicantsInterviews, setFilterApplicantsInterviews] = useState(
    []
  );
  const [actionOnApplicant, setActionOnApplicant] = useState({
    id: "",
    action: 0,
    // interviewTime: '',
    // rescheduleReason: '',
  });
  const subjectNames = [
    "Mathematics",
    "Additional Mathematics",
    "Biology",
    "Physics",
    "Chemistry",
  ];
  const grades = ["A*", "A", "B", "C"];
  const [subjects, setSubjects] = useState({
    subjectOne: {
      subjectName: "",
      grade: "",
    },
    subjectTwo: {
      subjectName: "",
      grade: "",
    },
    subjectThree: {
      subjectName: "",
      grade: "",
    },
  });
  const [scholarshipDetails, setScholarshipDetails] = useState({
    granted: "No",
    type: "",
    percentage: 0,
  });

  const [currentData, setCurrentData] = useState({
    currentClass: "0",
  });

  useEffect(() => {
    // console.log("applicants");
    let filter_applicants_interviews = applicants
      .filter((a) => a?.studentAdmissionStatus?.status == "InterviewDone")
      .filter((a) => a?.studentAdmissionOffer == null);
    // console.log("filter_applicants_interviews", filter_applicants_interviews);
    setFilterApplicantsInterviews(filter_applicants_interviews);
  }, [applicants]);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: pageNumber,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: false,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        // CampusId: Number(currentCampus),
      };
      dispatch(ApplicantsData(data));
    } else {
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: pageNumber,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: false,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
      };
      dispatch(ApplicantsData(data));
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    if (currentCampus == 0) {
      dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: 1,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: false,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        // CampusId: Number(currentCampus),
      };
      dispatch(ApplicantsData(data));
    } else if (currentCampus) {
      dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: 1,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: false,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
      };
      dispatch(ApplicantsData(data));
    }
  }, [itemsPerPage, currentCampus, currentData]);

  const registerstudent = async (id) => {
    console.log("id----", id);
    dispatch(postApplicantsData(id));
    setModal(false);
    // dispatch(moveStudent(id));
  };
  const createToggle = async (id) => {
    setCreate(!create);
  };

  const handleChangeInfo = (parentObjectName, fieldName, value) => {
    setSubjects((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: value,
      },
    }));
  };

  useEffect(() => {
    console.log("subjects conditions & scholarships", subjects, scholarshipDetails);
  }, [subjects, scholarshipDetails]);

  const handleClick = (applicant_action_value, id) => {
    console.log("action details", applicant_action_value, id);
    setModal(true);
    setActionOnApplicant((prevState) => ({
      ...prevState,
      id: id,
      action: applicant_action_value,
    }));
  };

  const filterNonEmptySubjects = (subs) => {
    return Object.values(subs).filter(
      (subject) =>
        subject.subjectName.trim() !== "" || subject.grade.trim() !== ""
    );
  };

  const applicantAction = () => {
    console.log("taking action confirmed on", subjects, scholarshipDetails);
    console.log("send email?", emailSend);
    let data;
    const filteredSubjects = filterNonEmptySubjects(subjects);
    console.log("Filtered Subjects:", filteredSubjects);
    const allValuesPresent = filteredSubjects.every(
      (subject) => subject.subjectName && subject.grade
    );
    console.log("is all the values", allValuesPresent);
    if (actionOnApplicant.action == 0) {
      if (!allValuesPresent) {
        Notify("error", "Please provide both subject name and grade.");
      } else {
        const data = {
          studentId: actionOnApplicant.id,
          status: Number(actionOnApplicant.action),
          conditionalOffer: {
            isScholarshipApplied: scholarshipDetails.granted == "Yes",
            stdAdmOfrSubDetails: filteredSubjects,
            stdAdmOffrScholarshipDetail: {
              scholarShipType:
                scholarshipDetails.type && scholarshipDetails.percentage
                  ? scholarshipDetails.type
                  : "",
              percentage:
                scholarshipDetails.type && scholarshipDetails.percentage
                  ? Number(scholarshipDetails.percentage)
                  : 0,
            },
          },
        };
        console.log("it is conditional", data);
        dispatch(UpdateOfferStatus(data));
        setModal(false);
      }
    }
    if (actionOnApplicant.action == 1) {
      data = {
        studentId: actionOnApplicant.id,
        status: Number(actionOnApplicant.action),
      };
      console.log("it is unconditional", data);
      if (data.studentId) {
        dispatch(UpdateOfferStatus(data));
        setModal(false);
      }
    }
    // if (actionOnApplicant.action == "9") {
    //   dispatch(postApplicantsData(actionOnApplicant.id));
    //   setModal(false);
    // } else {
    //   const date = new Date(actionOnApplicant.interviewTime);
    //   const hours = date.getHours();
    //   if (!actionOnApplicant?.interviewTime || hours < 9 || hours >= 17) {
    //     // setTimeValidationError("Interview time must be between 9 AM and 5 PM.");
    //     Notify("error", "Please provide interview time between 9 AM and 5 PM");
    //     // setModal(false);
    //   } else if (
    //     actionOnApplicant.action == "1" &&
    //     !actionOnApplicant.rescheduleReason
    //   ) {
    //     Notify("error", "Please provide reason for rescheduling interview.");
    //   } else {
    //     let data;
    //     if (actionOnApplicant.action == "1") {
    //       data = {
    //         studentId: actionOnApplicant.id,
    //         isMailSent: emailSend == "No" ? false : true,
    //         statusId: Number(actionOnApplicant.action),
    //         InterviewScheduleTime: actionOnApplicant.interviewTime,
    //         ReasonForReschedule: actionOnApplicant.rescheduleReason,
    //       };
    //     } else {
    //       data = {
    //         studentId: actionOnApplicant.id,
    //         isMailSent: emailSend == "No" ? false : true,
    //         statusId: Number(actionOnApplicant.action),
    //       };
    //     }
    //     console.log("data to update status", data);
    //     dispatch(UpdateApplicantStatus(data));
    //     setModal(false);
    //   }
    // }
  };

  const toggleDropdown = (index) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const formatDateCustom = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date).replace(",", "");
  };

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Admission" breadcrumbItem="Post Interview" />
        {create ? (
          <Row className="mb-2">
            <Col className="justify-content-end d-flex">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light mx-1"
                onClick={createToggle}
              >
                Show List
              </Button>
            </Col>
          </Row>
        ) : null}
        {!create ? (
          <div>
            <div className="container-fluid mt-2">
              <Card style={{ minHeight: "540px" }}>
                <CardBody
                  className="position-relative"
                  style={{ overflowX: "auto" }}
                >
                  <div className="">
                    <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold">
                      Post Interview
                    </CardTitle>
                    <div className="my-2 w-100 d-flex">
                      <div className="w-75 d-flex">
                        <FilterInput
                          options={singleClasses}
                          value={currentData.currentClass}
                          label="Filter by Class ---"
                          onChange={(e) => {
                            setCurrentData((prev) => ({
                              ...prev,
                              currentClass: e.target.value,
                            }));
                          }}
                        />
                      </div>
                      <select
                        className="form-control w-25"
                        style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                        value={itemsPerPage}
                        onChange={(e) => {
                          setCurrentPage(1);
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Items per page
                        </option>
                        <option value={""}>All</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Applicants's Name</th>
                            <th className="text-nowrap">Father's Name</th>
                            <th className="text-nowrap">Campus</th>
                            <th className="text-nowrap">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterApplicantsInterviews?.map((std, ind) => (
                            <tr key={ind}>
                              <td className="text-nowrap">{std?.name}</td>
                              <td className="text-nowrap">
                                {std?.guardians[0]?.name}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentAdmissionStatus?.campus?.name}
                              </td>
                              <td className="d-flex justify-content-start">
                                <button
                                  onClick={() => {
                                    setSelectedStudent(
                                      MaptoStudentStateObj(std)
                                    );
                                    setCreate(!create);
                                  }}
                                  className="btn btn-primary me-2"
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                >
                                  View
                                </button>
                                <Dropdown
                                  isOpen={dropdownsOpen[ind] || false}
                                  toggle={() => toggleDropdown(ind)}
                                  direction="down"
                                >
                                  <DropdownToggle caret>
                                    Generate Offer
                                    <IoMdArrowDropdown size={20} />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="text-info text-nowrap"
                                      onClick={() => {
                                        handleClick("1", std.id);
                                      }}
                                    >
                                      {" "}
                                      Unconditional Offer
                                    </DropdownItem>
                                    <DropdownItem
                                      className="text-success text-nowrap"
                                      onClick={() => handleClick("0", std.id)}
                                    >
                                      Conditional Offer
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="mt-5">
                    <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                      <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink
                          previous
                          onClick={() => paginate(currentPage - 1)}
                        >
                          <IoArrowBackCircle size={25} />
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPages).keys()].map((number) => (
                        <PaginationItem
                          key={number}
                          active={number + 1 === currentPage}
                        >
                          <PaginationLink onClick={() => paginate(number + 1)}>
                            {number + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink
                          next
                          onClick={() => paginate(currentPage + 1)}
                        >
                          <IoArrowForwardCircle size={25} />
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </CardFooter>
              </Card>
            </div>
            <Modal isOpen={modal} centered>
              <Card>
                <CardBody className="mt-2">
                  <h5>Are You Sure?</h5>
                  {actionOnApplicant.action == 0 ? (
                    <>
                      <label>Select Condition for Subjects</label>
                      {Object.keys(subjects).map((subjectKey, index) => (
                        <div className="row mb-2" key={index}>
                          <label className="text-blue">
                            Subject {index + 1}
                          </label>
                          <div className="col-sm-6">
                            <label>Name:</label>
                            <select
                              className="form-select text-black"
                              value={subjects[subjectKey].subjectName}
                              onChange={(e) =>
                                handleChangeInfo(
                                  subjectKey,
                                  "subjectName",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Subject</option>
                              {subjectNames.map((name) => (
                                <option key={name} value={name}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Grade:</label>
                            <select
                              className="form-select"
                              value={subjects[subjectKey].grade}
                              onChange={(e) =>
                                handleChangeInfo(
                                  subjectKey,
                                  "grade",
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Grade</option>
                              {grades.map((grade) => (
                                <option key={grade} value={grade}>
                                  {grade}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                      <label>Scholarship Details</label>
                      <select
                        className="form-select mb-2"
                        value={scholarshipDetails.granted}
                        onChange={(e) =>
                          setScholarshipDetails((prevState) => ({
                            ...prevState,
                            granted: e.target.value,
                          }))
                        }
                      >
                        <option option="No">No</option>
                        <option option="Yes">Yes</option>
                      </select>
                      {scholarshipDetails.granted === "Yes" ? (
                        <div className="row">
                          <div className="col-sm-6 mb-2">
                            <label>Scholarship Type:</label>
                            <select
                              className="form-select text-black"
                              value={scholarshipDetails.type}
                              onChange={(e) =>
                                setScholarshipDetails((prevState) => ({
                                  ...prevState,
                                  type: e.target.value,
                                }))
                              }
                            >
                              <option value="" disabled>
                                Select Scholarship Type
                              </option>
                              {[
                                "Academic",
                                "Sports",
                                "Extracurricular",
                                "Leadership",
                              ].map((option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-sm-6">
                            <label>Scholarship Percentage:</label>
                            <select
                              className="form-select text-black"
                              value={scholarshipDetails.percentage}
                              onChange={(e) =>
                                setScholarshipDetails((prevState) => ({
                                  ...prevState,
                                  percentage: e.target.value,
                                }))
                              }
                            >
                              <option value={0}>select Percentage</option>
                              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                                (option, index) => (
                                  <option key={index} value={option}>
                                    {option}%
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {/* <label>Do you want to send email for the action?</label>
                  <select
                    className="form-select"
                    value={emailSend}
                    onChange={(e) => setEmailSend(e.target.value)}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select> */}
                </CardBody>
                <CardFooter>
                  <button
                    className="btn btn-success me-2"
                    onClick={() => applicantAction()}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setModal(false);
                      setActionOnApplicant((prevState) => ({
                        ...prevState,
                        id: "",
                        action: "",
                      }));
                    }}
                  >
                    No
                  </button>
                </CardFooter>
              </Card>
            </Modal>
          </div>
        ) : (
          <div>
            <ApplicantDetails
              forView={true}
              selectedStudent={selectedStudent}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Applicants;
