import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './sideNav.scss';
import { FaLock } from 'react-icons/fa';
import { FiPlus, FiMinus } from 'react-icons/fi';

export default function NavLinks({
  linkName,
  link,
  icon,
  subNavs,
  activeStates,
  setActiveStates,
}) {
  const [show, setShow] = useState(false);
  return (
    <li className="py-2">
      <Link
        className={`d-flex align-items-center justify-content-between poppins-semibold bg-transparent border-0 linkMainLi ${
          activeStates.main === linkName ? 'text-primary' : 'text-secondary'
        }`}
        to={subNavs === undefined && link}
        onClick={
          subNavs !== undefined
            ? () => setShow(!show)
            : () =>
                setActiveStates({
                  main: linkName,
                  child: '',
                })
        }
      >
        <div>
          <span className="px-2">{icon}</span>
          <span>{linkName}</span>
        </div>
        {subNavs ? (
          <span className="">
            {show ? <FiMinus size={15} /> : <FiPlus size={15} />}
          </span>
        ) : (
          ''
        )}
      </Link>
      <ul
        className={`w-100 flex-column justify-content-start align-items-start linkUl ${
          show ? 'show' : ''
        }`}
      >
        {subNavs?.map((nav, i) => (
          <li className="p-1 list-item" key={i}>
            <Link
              className={`poppins-medium font-size-13 d-flex font-weight-light w-100 ${
                activeStates.child === nav.linkName
                  ? 'text-primary'
                  : 'text-secondary'
              }`}
              to={nav.link}
              onClick={() =>
                setActiveStates({
                  main: linkName,
                  child: nav.linkName,
                })
              }
            >
              {nav.link ? (
                <>{nav.linkName}</>
              ) : (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  <span
                    style={{
                      pointerEvents: 'none',
                      color: 'grey',
                    }}
                  >
                    {nav.linkName}
                  </span>
                  <FaLock style={{ pointerEvents: 'none', color: 'grey' }} />
                </div>
              )}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
}
