// axiosConfig.js
import axios from "axios";

const baseURL =
  window.location.hostname === "tcs.schoolware.net" ||
  window.location.hostname === "cs-school.netlify.app"
    ? process.env.REACT_APP_API_URL_STAGING
    : process.env.REACT_APP_API_URL_DEVELOPMENT;

const staffBaseUrl =
  window.location.hostname === "tcs.schoolware.net" ||
  window.location.hostname === "cs-school.netlify.app"
    ? process.env.REACT_APP_API_URL_STAGING
    : process.env.REACT_APP_STAFF_API_URL_DEVELOPMENT;

// Set up Axios instance
const axiosInstance = axios.create({
  baseURL: baseURL,
});

const axiosStaffInstance = axios.create({
  baseURL: staffBaseUrl,
});

const token = localStorage.getItem("_schoolware_token_userinfo_");

const instanceAuth = axios.create({
  baseURL: baseURL,
  headers: {
    authorization: "Bearer " + token,
  },
});
export { axiosInstance, instanceAuth, axiosStaffInstance };
