import React, { useEffect, useState } from "react";
import { InputField, SelectField } from "../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function CurrrentStatusForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  currentStatus,
  forView,
}) {
  let infoObject = "status";

  useEffect(() => {
    const isAnyKeyEmpty = Object.values(currentStatus).some((value) => {
      return value === "" || value === null;
    });
    if (!isAnyKeyEmpty) {
      updateTabComplete(true);
    } else {
      if (tabs[5].complete) {
        updateTabComplete(false);
      }
    }
  }, [currentStatus]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          {!forView ? (
            <SelectField
              label="Current Status"
              disabled={forView}
              compulsory={true}
              value={currentStatus.status}
              selectOptions={["Graduated", "Enrolled", "Left", "Suspended"]}
              onChange={(e) =>
                handleChangeInfo(infoObject, "status", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Current Status</label>
              <p className="">{currentStatus.status}</p>
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
