import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { teachingDepartments, nonTeachingDepartments, teachingDesignations, nonTeachingDesignations } from "../../../../common/data/optionsData";



function DynamicForm({
  fields,
  formData,
  teachingDepartments,
  nonTeachingDepartments,
  handleChangeInput,
  handleFileChange,
}) {

  console.log(formData, "formData DynamicFormCreateStaff");

 
  console.log("teachingDepartments------",teachingDepartments?.map(d => d.opt));
  // console.log(
  //   fields.map((f) => formData[f.name]),
  //   "fieldss"
  // );
  console.log(fields,"fields");
  let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/
  // let isValidEmail = regex.test(formData[field.name]) 

  function isValidEmail(email) {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidMobile(mobile) {
    // Regular expression for mobile number validation
    const mobileRegex = /^([+]\d{2})?\d{11}$/;
    return mobileRegex.test(mobile);
  }
  function isValidCNIC(mobile) {
    // Regular expression for mobile number validation
    const cnicRegex = /^([+]\d{2})?\d{13}$/;
    return cnicRegex.test(mobile);
  }

  return (
    <>
      <Row>
        {fields?.map((field, fieldIndex) => 
          {
           
            return(
              <Col md="6" key={fieldIndex}>
                <div className="mb-3">
                  <label htmlFor={`validationCustom${fieldIndex}`}>
                    {field.label}
                  </label>
                  {field.type === "select" ? (
                    <select
                      name={field.name}
                      value={ formData[field.name] }
                      onChange={handleChangeInput}
                      className={`form-control `}
                      id={`validationCustom${fieldIndex}`}
                    >
                      <option value="">{field.label}</option>
                      //! THIS IS FOR TEACHING.....
                      {field.name === "department" &&
                      formData.departmentCategory === 1
                      
                        ? teachingDepartments?.map((option, index) => (
                          
                            <option key={option.id} value={option.id}>
                              {option.opt}
                            </option>
                          ))
                        //! THIS IS FOR NON - TEACHING.....
                        : field.name === "department" &&
                        formData.departmentCategory === 2
                        ? nonTeachingDepartments?.map((option, index) => (
                            <option key={option.id} value={option.id}>
                              {option.opt}
                            </option>
                          ))
                        // : field.name === "designation" &&
                        // formData.departmentCategory === 1
                        // ? teachingDesignations.map((option) => (
                        //     <option key={option.value} value={option.value}>
                        //       {option.label}
                        //     </option>
                        //   ))
                        // : field.name === "designation" &&
                        // formData.departmentCategory === 2
                        // ? nonTeachingDesignations.map((option) => (
                        //     <option key={option.value} value={option.value}>
                        //       {option.label}
                        //     </option>
                        //   ))
                        : ((
                        //! THIS IS FOR OTHER FIELDS .....
                            field.options.map((option, index) => (
                              <option key={option.id || index} value={option.id || option.value}>
                              {option.opt || option.value}
                            </option>
                          ))
                      ))
                    }
                    </select>
                  ) : field.type === "file" ? (
                    <>
                      <input
                        name={field.name}
                        type={field.type}
                        placeholder={field.placeholder}
                        className={`form-control `}
                        id={`validationCustom${fieldIndex}`}
                        // onBlur={() => handleBlur(field.name, field.required)}
                        onChange={handleFileChange}
                        // value={formData[field.name]}
                        required={field?.required}
                      />
                        {/* {formData[field.name] && (
                          <>
                          <span>{formData[field.name]}</span>
                          
                          </>

                        )} */}
                    </>
                  ) : (
                  <>
                    <input
                      name={field.name}
                      type={field.type}
                      placeholder={field.placeholder}
                      className={`form-control `}
                      id={`validationCustom${fieldIndex}`}
                      onChange={handleChangeInput}

                      value={formData[field.name]}
                      required={field.required}
                    
                    />

                    {!isValidEmail(formData[field.name]) && field.name === 'email' && formData[field.name] !== "" && (
                      <span style={{ color: 'red' }}>Invalid email</span>
                    )}

                    {!isValidMobile(formData[field.name]) && field.name === 'mobileNumber' && formData[field.name] !== "+92" && (
                          <span style={{ color: 'red' }}>Invalid mobile number</span>
                        )}
                    {!isValidCNIC(formData[field.name]) && field.name === 'nic' && formData[field.name] !== "" && (
                          <span style={{ color: 'red' }}>Invalid cnic number</span>
                        )}

                  </>
                    
                  
                  )}
              
                </div>
              </Col>
            )
          }
        )}
      </Row>
    </>
  );
}

export default DynamicForm;
