import React from 'react'
import { CiImport } from "react-icons/ci";
import { Button } from 'reactstrap';

export default function ImportButton () {
  return (
    <Button
        style={{
        background: "#3b5de7",
        color: "white",
        padding: "12px 30px",
        border: "none",
        borderRadius: "18px",
        marginTop: "10px",
        }}
        disabled={true}
        // className={`${activeTab == "View" ? "active" : null}`}
        onClick={() => { }}
    >
        <CiImport style={{marginRight:10, fontSize:20} }/>
        Import
  </Button>
  )
}
