import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNewAssignmentHelper,
  deleteAssignmentHelper,
  getAllAssignmentHelper,
  updateAssignmentHelper,
} from "../../../api/Academics/AssignmentApiHelper";
import { toast } from "react-toastify";
import Notify from "../../../components/Notify-toast";

const initialState = {
  assignments: [],
  loading: false,
  view: false,
  error: null,
  totalPages: 1,
};

export const getAllAssignment = createAsyncThunk(
  "assignment/getAllAssignment",
  async (data) => {
    try {
      console.log("get all", data);
      const response = await getAllAssignmentHelper(data);
      const _resp = {
        data: response.data,
        status: response.status,
      };
      return _resp;
    } catch (error) {
      console.log("ERR", error);
      throw error;
    }
  }
);
export const deleteAssignment = createAsyncThunk(
  "assignment/deleteAssignment",
  async (data) => {
    try {
      const response = await deleteAssignmentHelper(data);
      const _resp = {
        data: response.data,
        status: response.status,
      };
      return _resp;
    } catch (error) {
      console.log("deleteAssignment ERROR", error);
      return error;
    }
  }
);
export const updateAssignment = createAsyncThunk(
  "assignment/updateAssignment",
  async (data) => {
    try {
      const response = await updateAssignmentHelper(data);
      console.log("update,", response);

      const _resp = {
        data: response.data,
        status: response.status,
      };
      return _resp;
    } catch (error) {
      console.log("updateAssignment ERROR", error);
      return error;
    }
  }
);
export const createAssignment = createAsyncThunk(
  "assignment/createAssignment",
  async (data) => {
    try {
      const response = await createNewAssignmentHelper(data);
      const _resp = {
        data: response.data,
        status: response.status,
      };
      return _resp;
    } catch (error) {
      console.log("createAssignment ERROR", error);
      return error;
    }
  }
);

export const assignmentSlice = createSlice({
  name: "assignment",
  initialState,
  reducers: {
    addAssignment: (state, action) => {
      const _progress = state.assignments.find((e) => e.id === 1);
      _progress.tasks.push(action.payload);
    },
    setViewAssignment: (state, action) => {
      return { ...state, view: action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(updateAssignment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAssignment.fulfilled, (state, action) => {
        state.loading = false;
        console.log("payload", action.payload);
        if (action.payload.status === 200) {
          Notify("success", "Assingment has been updated");
          state.assignments = state.assignments.map((cls) =>
            cls.id === action.payload.data.id ? action.payload.data : cls
          );
        } else {
          Notify("error", "Error on updating the Assignment");
        }
      })
      .addCase(updateAssignment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteAssignment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAssignment.fulfilled, (state, action) => {
        state.loading = false;
        console.log("payload", action.payload);
        if (action.payload.status === 200) {
          Notify("success", "Assingment has been deleted");
          state.assignments = state.assignments.filter(
            (e) => e.id !== action.payload.data[0]
          );
        } else {
          Notify("error", "Error on deleting the Assignment");
        }
      })
      .addCase(deleteAssignment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getAllAssignment.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAssignment.fulfilled, (state, action) => {
        state.loading = false;
        console.log("payload", action.payload);
        if (action.payload.status === 200) {
          state.assignments = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        } else {
          Notify("error", "Error on fetching Assignments");
        }
      })
      .addCase(getAllAssignment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        Notify("error", action.error.message);
      });
    builder
      .addCase(createAssignment.pending, (state) => {
        state.loading = true;
        state.view = false;
        state.error = null;
      })
      .addCase(createAssignment.fulfilled, (state, action) => {
        state.loading = false;
        console.log("payload", action.payload);
        if (action.payload.status === 200) {
          Notify("success", "Assignment has been created");
          state.assignments = [...state.assignments, action.payload.data];
          state.view = true;
        } else {
          Notify("error", "Error creating Assignment.");
        }
      })
      .addCase(createAssignment.rejected, (state, action) => {
        console.log("rejected", state);

        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { addAssignment, setViewAssignment } = assignmentSlice.actions;

export const allAssignments = (state) => state.allAssignments;

export default assignmentSlice.reducer;
