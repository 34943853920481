import React, { useContext, useState } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardImg,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SideBarContext from "../../../context/sidebar.context";
import ClassesCards from "./classes-cards";
import AddClass from "./add-class";
import ImportButton from "../../../components/ImportButton";

export default function Classes(props) {
  const { setActiveStates } = useContext(SideBarContext);
  const [activeTab, setActiveTab] = useState("View");
  // const [classes, setClasses] = useState([
  //   { name: "1A", teacher: "Farhan Khan", strength: 30 },
  //   { name: "2A", teacher: "Taha Khan", strength: 20 },
  //   { name: "2B", teacher: "Hina Jamal", strength: 25 },
  //   { name: "3A", teacher: "Usman Khan", strength: 30 },
  // ]);

  const componentTab = () => {
    const switchTab = (data) => {
      setActiveTab(data);
    };

    if (activeTab == "View") {
      return <ClassesCards switchTab={switchTab} header="Classes" />;
    } else if (activeTab == "Add") {
      return <AddClass switchTab={switchTab} header="Add Class" />;
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Academics" breadcrumbItem="Classes" />
        <Row className="">
          <Col className="justify-content-end d-flex">
            {/* <Link
              style={{
                background: '#3D94D5',
                color: 'white',
                padding: '12px 30px',
                border: 'none',
                borderRadius: '18px',
                marginTop: '10px',
                marginRight: '10px',
              }}
              className={`${activeTab == 'View' ? 'active' : null}`}
              to="/academics/Sections"
              onClick={() =>
                setActiveStates({
                  main: 'Academics',
                  child: 'Sections',
                })
              }
            >
              Create Section
            </Link> */}
            {activeTab == "Add" ? (
              <Button
                // color="success"
                style={{
                  background: "#37a26a",
                  color: "white",
                  padding: "12px 30px",
                  border: "none",
                  borderRadius: "18px",
                  marginTop: "10px",
                }}
                className={`${activeTab == "View" ? "active" : null}`}
                onClick={() => setActiveTab("View")}
              >
                View Classes
              </Button>
            ) : (
              <>
                <Button
                  style={{
                    background: "#37a26a",
                    color: "white",
                    padding: "12px 30px",
                    border: "none",
                    borderRadius: "18px",
                    marginTop: "10px",
                    marginRight: "10px",
                    
                  }}
                  className={`${activeTab == "View" ? "active" : null}`}
                  onClick={() => setActiveTab("Add")}
                >
                  Create New
                </Button>
                <ImportButton/>
              </>
            )}
          </Col>
        </Row>
        {componentTab()}
      </div>
    </React.Fragment>
  );
}
