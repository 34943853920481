import { axiosInstance, instanceAuth } from "../axiosConfig";

export const classRoomFetchAPI = async (data) => {
  try {
    console.log("Fetch classroom in helper", data);
    const response = await instanceAuth.get("CampusRoom", {
      params: {
        ...data,
      },
    });
    return response;
  } catch (error) {
    console.log("error fetching classroom data...", error);
    throw error;
  }
};

export const classRoomCreateAPI = async (data) => {
  console.log("Create classroom in helper", data);
  try {
    const response = await instanceAuth.post("CampusRoom", data);
    return response;
  } catch (error) {
    console.log("error creating classroom...", error);
    throw error;
  }
};

export const classRoomUpdateAPI = async ({ id: id, bodyData: bodyData }) => {
  try {
    console.log(`Update classroom in helper with id: ${id}`, bodyData);
    const response = await instanceAuth.put(`CampusRoom/${id}`, bodyData);
    return response;
  } catch (error) {
    console.log("error updating classroom...", error);
    throw error;
  }
};

export const classRoomDeleteAPI = async (id) => {
  try {
    console.log(`Delete classroom in helper with id: ${id}`);
    const response = await instanceAuth.delete(`CampusRoom/${id}`);
    return response;
  } catch (error) {
    console.log("error deleting classroom...", error);
    throw error;
  }
};
