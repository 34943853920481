import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  UpdateDepartment,
  deleteDepartmentData,
  fetchDepartmentData,
  postDepartmentData,
} from "../../../api/Department/DepartmentHelperApi";
import Notify from "../../../components/Notify-toast";

export const DepartmentData = createAsyncThunk(
  "department/FetchdepartmentData",
  async (data) => {
    try {
      const response = await fetchDepartmentData(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch sections data");
    }
  }
);
export const PostDepartmentData = createAsyncThunk(
  "department/PostdepartmentData",
  async (data) => {
    try {
      const response = await postDepartmentData(data);
      if (response?.status === 200) {
        return response.data; // Assuming response is an array of students
      }
    } catch (error) {
      throw Error("Failed to fetch sections data");
    }
  }
);
export const DeleteDepartmentData = createAsyncThunk(
  "department/DeletedepartmentData",
  async (id) => {
    try {
      const response = await deleteDepartmentData(id);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to fetch sections data");
    }
  }
);
export const UpdateDepartmentData = createAsyncThunk(
  "department/UpdatedepartmentData",
  async ({ id, departmentData }) => {
    try {
      const response = await UpdateDepartment({
        id: id,
        departmentData: departmentData,
      });
      if (response?.status === 200) {
        return response.data; // Assuming response is an array of students
      } else {
        console.log("failed to update department");
      }
    } catch (error) {
      throw Error("Failed to update department data");
    }
  }
);

const initialState = {
  departments: [],
  loading: false,
  error: null,
  totalPages: 1,
};

export const Department = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(DepartmentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(DepartmentData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.departments = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(DepartmentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(PostDepartmentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(PostDepartmentData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.departments.push(action.payload);
          Notify("success", "Department has been created");
        } else {
          Notify(
            "error",
            "Error on creating the department. Something went wrong"
          );
        }
      })
      .addCase(PostDepartmentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(DeleteDepartmentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteDepartmentData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.departments = state.departments.filter(
            (i) => i.id !== action.payload
          );
          Notify("success", "Department has been deleted");
        } else {
          Notify(
            "error",
            "Error on deleting the department. Something went wrong"
          );
        }
      })
      .addCase(DeleteDepartmentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateDepartmentData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateDepartmentData.fulfilled, (state, action) => {
        console.log("update department", action.payload);
        state.loading = false;
        if (action.payload) {
          state.departments = state.departments.map((cls) =>
            cls.id === action.payload.id ? action.payload : cls
          );
          Notify("success", "Department has been updated");
        } else {
          Notify(
            "error",
            "Error on updating the department. Something went wrong"
          );
        }
      })
      .addCase(UpdateDepartmentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {} = Department.actions;

export const allDepartment = (state) => state.allDepartment;

export default Department.reducer;
