import { axiosInstance, instanceAuth } from "../axiosConfig";

export const fetchApplicantsData = async (data) => {
  try {
    const response = await instanceAuth.get("Admission/GetAllApplicants", {
      params: {
        ...data,
        includeAll: true,
      },
    });
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};

export const fetchApplicantExportData = async () => {
  try {
    const response = await instanceAuth.get("Admission/GetAllApplicants", {
      params: {
        includeAll: true,
      },
    });
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};


export const postApplicantData = async (id) => {
  try {
    console.log("id in post applicant", id)
    const response = await instanceAuth.post(
      `Admission/RegisterApplication/${id}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const updateApplicantStatusMethod = async (data) => {
  try {
    const { studentId, statusId, isMailSent, InterviewScheduleTime, ReasonForReschedule, ...rest } = data;
    console.log("data values", data);
    // const response = await instanceAuth.post(
    //   `Admission/UpdateApplicationStatus?studentId=${data.studentId}&statusId=${data.statusId}&isMailSent=${data.isMailSent}&InterviewScheduleTime=${data.InterviewScheduleTime}`
    // );
    const params = {
      studentId,
      statusId,
      isMailSent,
      InterviewScheduleTime,
      ReasonForReschedule,
      // ...rest,
    };
    
    if (InterviewScheduleTime !== undefined) {
      params.InterviewScheduleTime = InterviewScheduleTime;
      console.log("params with studentId")
    }
    if (ReasonForReschedule !== undefined) {
      params.ReasonForReschedule = ReasonForReschedule;
      console.log("params with ReasonForReschedule")
    }
    else{
      console.log("params with not ReasonForReschedule")
    }
    const response = await instanceAuth.post(
      'Admission/UpdateApplicationStatus',
      null,
      {
        params
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.error("Error updating applicant status:", error);
    throw error;
  }
};

export const updateOfferStatusMethod = async (data) => {
  try {
    console.log("Data in API method before request:", data);
    const response = await axiosInstance.post('AdmissionOffer', data);
    console.log("Response from API:", response);
    return response;
  } catch (error) {
    console.error("Error updating applicant status:", error);
    throw error;
  }
};

export const postStudentDocument = async (formData) => {
  try {
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    const response = await axiosInstance.post('StudentDocument', formData);
    console.log("Response from API:", response);
    return response;
  } catch (error) {
    console.error("Error updating applicant status:", error);
    throw error;
  }
};