import React, { useEffect, useState } from 'react';
import {
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
} from 'reactstrap';
import { MdDelete } from 'react-icons/md';
import { MdModeEdit } from 'react-icons/md';
import { IoArrowBackCircle } from 'react-icons/io5';
import { IoArrowForwardCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import img2 from '../../../assets/images/small/img-2.jpg';
import {
  removeClass,
  allClasses,
  ClassesData,
  deleteClass,
} from '../../../redux/slice/classesSlice';
import { allCampus } from '../../../redux/slice/academics/CampusSlice';
import { useSelector, useDispatch } from 'react-redux';
import AddClass from './add-class';
import { IoIosClose } from 'react-icons/io';
import Loader from '../../../components/loader/Loader';

export default function ClassesCards(props) {
  const { currentCampus } = useSelector(allCampus);
  const { classes, loading, totalPages } = useSelector(allClasses);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const [Deletemodal, setDeleteModal] = useState(false);
  const [stdid, setStdId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedClassId, setSelectedClassId] = useState(null);
  const [selectedClass, setSelectedClass] = useState({
    name: '',
    code: '',
    description: '',
    section: [],
    department: '',
  });
  console.log('Current campus id in classes table: ', currentCampus);
  // Change page
  const paginate = (pageNumber) => {
    if (currentCampus == 0) {
      setCurrentPage(pageNumber);
      const data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        //  CampusId: Number(currentCampus),
      };
      dispatch(ClassesData(data));
    } else {
      setCurrentPage(pageNumber);
      const data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        CampusId: Number(currentCampus),
      };
      dispatch(ClassesData(data));
    }
  };

  useEffect(() => {
    setCurrentPage(1)
    if (currentCampus == 0) {
      const data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
        //  CampusId: Number(currentCampus),
      };
      dispatch(ClassesData(data));
    } else if (currentCampus) {
      const data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
        CampusId: Number(currentCampus),
      };
      dispatch(ClassesData(data));
    }
  }, [itemsPerPage, currentCampus]);

  function handleDelete(_class) {
    // props.deleteExam(stdclass);
    console.log('class name', _class);
    dispatch(deleteClass(_class.id));
  }
  const deleteClassesData = (id) => {
    console.log('receivd-id', id);
    dispatch(deleteClass(id));
    setDeleteModal(false);
  };
  const handleClick = (id) => {
    setStdId(id);
    setDeleteModal(true);
  };
  function ModalToggle() {
    setModal(!modal);
  }
  function classSelectforEdit(stdclass) {
    setSelectedClass({
      name: stdclass.name,
      code: stdclass.code,
      description: stdclass.description,
      department: stdclass.department,
    });
    setSelectedClassId(stdclass.id);
    ModalToggle();
    console.log('class selected', stdclass);
  }
  return (
    <div className="mt-4">
      <div className="row">
        <Modal size="lg" isOpen={modal} toggle={() => ModalToggle()} centered>
          <AddClass
            selectedClass={selectedClass}
            switchTab={props.switchTab}
            ModalToggle={ModalToggle}
            id={selectedClassId}
            header="Update Class"
          />
        </Modal>
        <Col lg={12} md={12}>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                {/* <CardTitle className="fs-5 mb-4 bold text-uppercase poppins-medium-italic">{props.header}</CardTitle> */}
                <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold card-title">
                  {props.header}
                </CardTitle>
                <select
                  className="form-control w-25"
                  style={{ borderColor: 'rgb(59 93 231 / 47%)' }}
                  value={itemsPerPage}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setItemsPerPage(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Items per page
                  </option>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
              <div className="table-responsive">
                {loading ? (
                  <span className="m-4">
                    <Loader />
                  </span>
                ) : (
                  <Table className="table-centered table-striped">
                    <thead>
                      <tr>
                        <th scope="col fw-bold">Class</th>
                        <th scope="col fw-bold">Code</th>
                        <th scope="col fw-bold">Description</th>
                        <th scope="col fw-bold">Department</th>
                        <th scope="col fw-bold">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classes?.map((stdclass, index) => (
                        <tr key={index}>
                          <td>{stdclass.name}</td>
                          <td>{stdclass.code}</td>
                          <td>{stdclass.description}</td>
                          <td>{stdclass?.department?.name}</td>
                          <td>
                            <div className="d-flex justify-content-start">
                              <Link
                                className="btn btn-primary btn-sm me-2"
                                onClick={() => classSelectforEdit(stdclass)}
                              >
                                <MdModeEdit size={15} />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm"
                                onClick={() => handleClick(stdclass.id)}
                              >
                                <MdDelete size={15} />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
              <div className="mt-3">
                <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => paginate(currentPage - 1)}
                    >
                      <IoArrowBackCircle size={25} />
                    </PaginationLink>
                  </PaginationItem>
                  {[...Array(totalPages).keys()].map((number) => (
                    <PaginationItem
                      key={number}
                      active={number + 1 === currentPage}
                    >
                      <PaginationLink onClick={() => paginate(number + 1)}>
                        {number + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => paginate(currentPage + 1)}
                    >
                      <IoArrowForwardCircle size={25} />
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
      <Modal isOpen={Deletemodal} centered>
        <Card>
          <CardBody className="mt-2">
            <h5 style={{ fontWeight: 'normal' }}>
              Deleting this class will also delete all associated students,
              sections, assignments etc. Are you sure you want to delete this
              Class?
            </h5>
          </CardBody>
          <CardFooter>
            <button
              className="btn btn-danger me-2"
              onClick={() => deleteClassesData(stdid)}
            >
              Delete
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  );
}
