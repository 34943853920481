import React, { createContext, useState } from "react";
const SideBarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [activeStates, setActiveStates] = useState({
    main: "Dashboard",
    child: "",
  });

  return (
    <SideBarContext.Provider value={{ activeStates, setActiveStates }}>
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContext;
