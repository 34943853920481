import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchApplicantExportData,
  fetchApplicantsData,
  postApplicantData,
  postStudentDocument,
  updateApplicantStatusMethod,
  updateOfferStatusMethod,
} from "../../../api/admission/admissionHelperApi";
import { axiosInstance } from "../../../api/axiosConfig";
import Notify from "../../../components/Notify-toast";

export const ApplicantsData = createAsyncThunk(
  "Applicants/FetchApplicantsData",
  async (data) => {
    try {
      const response = await fetchApplicantsData(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch applicants data");
    }
  }
);

export const ExportApplicantsData = createAsyncThunk(
  "Applicants/FetchApplicantsExportData",
  async () => {
    try {
      const response = await fetchApplicantExportData();
      return response;
    } catch (error) {
      throw Error("Failed to fetch applicants data");
    }
  }
);

export const postApplicantsData = createAsyncThunk(
  "Applicants/postApplicantsData",
  async (id) => {
    try {
      const response = await postApplicantData(id);
      if (response.status === 200) {
        return response?.data?.id; // Return studentId if deletion is successful
      } else {
        throw Error("Failed to delete student");
      }
    } catch (err) {
      throw Error("Failed to Post applicant data");
    }
  }
);

export const UpdateApplicantStatus = createAsyncThunk(
  "Applicants/updateApplicantStatus",
  async (data) => {
    try {
      const response = await updateApplicantStatusMethod(data);
      if (response.status === 200) {
        Notify("success", "Applicant status updated.");
        return response?.data;
        // return {response: response?.data, data: data.statusId};
      } else {
        Notify("error", "Failed to Update Status");
        throw Error("Failed to update status");
      }
    } catch (err) {
      Notify("error", "Failed to Update Status");
      throw Error("Failed to Post applicant data");
    }
  }
);

export const UpdateOfferStatus = createAsyncThunk(
  "Applicants/updateOfferStatus",
  async (data) => {
    try {
      console.log("data in slice", data)
      const response = await updateOfferStatusMethod(data);
      if (response.status === 200) {
        Notify("success", "Applicant status updated.");
        return response?.data;
      } else {
        Notify("error", "Failed to Update Status");
        throw Error("Failed to update status");
      }
    } catch (err) {
      Notify("error", "Failed to Update Status");
      throw Error("Failed to Update offer status");
    }
  }
);

export const StudentDocument = createAsyncThunk(
  "Applicants/StudentDocument",
  async (formData) => {
    try {
      const response = await postStudentDocument(formData);
      if (response.status === 200) {
        Notify("success", "Documents Added.");
        return response;
      } else {
        Notify("error", "Failed to post documents");
        throw Error("Failed to post documents");
      }
    } catch (err) {
      Notify("error", "Failed to post documents");
      throw Error("Failed to post documents");
    }
  }
);

const initialState = {
  applicants: [],
  AllApplicantsData:[],
  offerStatus: {},
  loading: false,
  error: null,
  totalPages: 1,
};

export const applicantSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    moveStudent: (state, action) => {
      return {
        ...state,
        applicants: state.applicants.filter((i) => i.id !== action.payload),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(ApplicantsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(ApplicantsData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          console.log("payload applicants data", action.payload.data.items);
          state.applicants = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(ApplicantsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

      builder
      .addCase(ExportApplicantsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(ExportApplicantsData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          // console.log("payload applicants data", action.payload.data.items);
          state.AllApplicantsData = action.payload?.data?.items;
          // state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(ExportApplicantsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });


    builder
      .addCase(postApplicantsData.fulfilled, (state, action) => {
        state.loading = false;
        state.applicants = state.applicants.filter(
          (i) => i.id !== action.payload
        );
      })
      .addCase(postApplicantsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateApplicantStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(UpdateApplicantStatus.fulfilled, (state, action) => {
        state.loading = false;
        console.log(action.payload.response, action.payload.data);
        state.applicants = state.applicants.map((student) =>
          student.id === action.payload.id
            ? { ...student, ...action.payload }
            : student
        );
      })
      .addCase(UpdateApplicantStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateOfferStatus.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(UpdateOfferStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.offerStatus = action.payload
        console.log("action action%",action.payload, state.applicants)
        state.applicants = state.applicants.map((student) =>
          student.id === action.payload.studentId
            ? { ...student, studentAdmissionOffer: action.payload.conditionalOffer }
            : student
        );
      })
      .addCase(UpdateOfferStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(StudentDocument.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(StudentDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.offerStatus = action.payload
        console.log("student documant payload%", action.payload?.data)
        console.log("student documant id%", action.payload?.data?.studentId)
        state.applicants = state.applicants.map((student) =>
          student.id === action.payload?.data?.studentId
            ? { ...student, studentDocuments: action.payload.data }
            : student
        );
      })
      .addCase(StudentDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { addStudent, updateStudent, moveStudent } =
  applicantSlice.actions;

export const allApplicant = (state) => state.allApplicant;

export default applicantSlice.reducer;
