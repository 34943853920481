import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  fetchCampusData,
  postCampusData,
  UpdateCampusData,
  deleteCampusData,
} from '../../../api/Campus/CampusHelperApi';
import { toast } from 'react-toastify';
import Notify from '../../../components/Notify-toast.jsx';

export const CampusData = createAsyncThunk(
  'Campus/FetchCampusData',
  async (_data) => {
    try {
      const response = await fetchCampusData(_data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error('Failed to fetch Campus data');
    }
  }
);
export const PostCampusData = createAsyncThunk(
  'Campus/PostCampusData',
  async (data) => {
    try {
      const response = await postCampusData(data);
      if (response?.status === 200) {
        return response.data; // Assuming response is an array of students
      }
    } catch (error) {
      throw Error('Failed to post Campus data');
    }
  }
);
export const DeleteCampusData = createAsyncThunk(
  'Campus/DeleteCampusData',
  async (id) => {
    try {
      const response = await deleteCampusData(id);
      if (response?.status === 200) {
        return response.data; // Assuming response is an array of students
      }
    } catch (error) {
      throw Error('Failed to delete campus data');
    }
  }
);
export const UpdateCampus = createAsyncThunk(
  'department/UpdateClassData',

  async ({ id, campusData }) => {
    try {
      const response = await UpdateCampusData({
        id: id,
        campusData: campusData,
      });
      if (response?.status === 200) {
        return response.data; // Assuming response is an array of students
      } else {
        console.log('failed to updated');
      }
    } catch (error) {
      throw Error('Failed to update campus data');
    }
  }
);

const initialState = {
  campus: [],
  currentCampus: parseInt(localStorage.getItem('_schoolware_selected_campus_')) || 0,
  loading: false,
  error: null,
  totalPages: 1,
};

export const Campus = createSlice({
  name: 'Campus',
  initialState,
  reducers: {
    currentCampusSelect: (state, action) => {
      // console.log(action);
      localStorage.setItem('_schoolware_selected_campus_', action.payload);
      state.currentCampus = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(CampusData.pending, (state) => {
        state.loading = true;
      })
      .addCase(CampusData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.campus = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(CampusData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(PostCampusData.pending, (state) => {
        state.loading = true;
      })
      .addCase(PostCampusData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          // state.campus.push(action.payload);
          state.campus = [...state.campus, action.payload];
          Notify('success', 'Campus has been added');
        } else {
          // Notify("error", 'Campus Not Created. Something Went Wrong');
          Notify('error', 'Error on adding the campus. Something went wrong');
        }
      })
      .addCase(PostCampusData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(DeleteCampusData.pending, (state) => {
        state.loading = true;
      })
      .addCase(DeleteCampusData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.campus = state.campus.filter((i) => i.id !== action.payload);
          Notify('success', 'Campus has been deleted');
        } else {
          Notify('error', 'Error on deleting the campus. Something went wrong');
        }
      })
      .addCase(DeleteCampusData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateCampus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateCampus.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.campus = state.campus.map((cls) =>
            cls.id === action.payload.id ? action.payload : cls
          );
          Notify('success', 'Campus has been updated');
        } else {
          Notify('error', 'Error on updating the campus. Something went wrong');
        }
      })
      .addCase(UpdateCampus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { currentCampusSelect } = Campus.actions;

export const allCampus = (state) => state.allCampus;

export default Campus.reducer;
