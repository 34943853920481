import React, { useContext, useEffect, useState } from "react";
import { CardTitle, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import {
  addClass,
  createClass,
  updateSingleClass,
} from "../../../redux/slice/classesSlice";
import {
  SectionsData,
  allSections,
} from "../../../redux/slice/academics/sectionsSlice";
import { useSelector, useDispatch } from "react-redux";
import { IoIosWarning } from "react-icons/io";
import Select from "react-select";
import {
  InputField,
  SelectField,
  SelectFieldTeacher,
} from "../../../components/input-fields";
import {
  DepartmentData,
  allDepartment,
} from "../../../redux/slice/academics/DepartmentSlice";
import { allCourses } from "../../../redux/slice/academics/CoursesSlice";
import SideBarContext from "../../../context/sidebar.context";
import { allStaffs } from "../../../redux/slice/staff/catalogSlice";
import { ClassFormClass } from "../../../classes/classFormClasses";
import { ToastContainer, toast } from "react-toastify";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import Notify from "../../../components/Notify-toast";

const AddClass = (props) => {
  const { sections } = useSelector(allSections);
  const { currentCampus } = useSelector(allCampus);
  const { staff } = useSelector(allStaffs);
  const { departments } = useSelector(allDepartment);
  const { setActiveStates } = useContext(SideBarContext);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState("");
  const [classData, setClassData] = useState({
    name: "",
    code: "",
    departmentId: 0,
    description: "",
  });
  console.log("class-data", classData);

  useEffect(() => {
    const data = {
      CampusId: currentCampus != "0" ? Number(currentCampus) : null,
    };
    dispatch(DepartmentData(data));
  }, [currentCampus]);

  // const courseName = courses.map((n) => n.name);
  // const handleCourseChange = (selectedOption) => {
  //   setClassData({
  //     ...classData,
  //     section: selectedOption,
  //   });
  // };
  // const _filteredTeahers = staff.filter(
  //   (e) => e.employmentStatusFormData.departmentCategory === 'Teaching'
  // );

  // useEffect(() => {
  //   if (sections.length == 0) {
  //     dispatch(SectionsData());
  //   }
  //   // let secNamesMap = sections.map((obj) => obj.name);
  //   // setSectionsName(secNamesMap);
  // }, []);

  useEffect(() => {
    if (props.selectedClass && Object.keys(props.selectedClass).length !== 0) {
      // const convertedArray = props?.selectedClass?.department.map((obj) => {
      //   console.log('converting this ', obj);
      //   return {
      //     value: obj?.id,
      //     label: obj?.name,
      //   };
      // });
      console.log("props-all", props.selectedClass);
      setClassData({
        name: props.selectedClass.name,
        code: props.selectedClass.code,
        // section: convertedArray,
        departmentId: props.selectedClass.department.id,
        // department: convertedArray,
        description: props.selectedClass.description,
      });
      // setClassData(props.selectedClass);
      console.log("selectedClass**", props.selectedClass);
    }
  }, [props.selectedClass]);

  // useEffect(() => {
  //   if (!_filteredTeahers.length) {
  //     setModal(true);
  //   }
  // }, [staff]);

  const handleChangeInfo = (fieldName, value) => {
    // console.log('classData', value);
    setClassData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  function ModalToggle() {
    setModal(!modal);
  }
  const handleSave = () => {
    console.log("classData", classData);
    console.log("id for update", props.id);
    if (
      classData.name &&
      classData.code &&
      classData.departmentId &&
      classData.description
    ) {
      setError("");
      if (
        props.selectedClass &&
        Object.keys(props.selectedClass).length !== 0
      ) {
        dispatch(
          updateSingleClass({
            id: props?.id,
            classData: {
              name: classData?.name,
              code: classData?.code,
              description: classData?.description,
              departmentId: +classData?.departmentId,
            },
          })
        );
        props.ModalToggle();
      } else {
        dispatch(
          createClass({
            name: classData?.name,
            code: classData?.code,
            description: classData?.description,
            departmentId: +classData?.departmentId,
          })
        );
        cancel();
      }
    } else {
      setError("Please fill all the fields");
    }
  };

  const cancel = () => {
    setClassData({
      name: "",
      code: "",
      description: "",
      departmentId: 1,
    });
    props.switchTab("View");
  };
  const sectionData = sections?.map((d) => d);
  console.log("section-data", sectionData);

  return (
    <div className="col mt-4">
      <Modal size="sm" isOpen={modal}>
        <div className="modal-header">
          <h2 className="modal-title mt-0" id="myModalLabel">
            <IoIosWarning size={32} color="orange" /> Warning
          </h2>
        </div>
        <div className="modal-body">
          <h6> No teacher availaible, Please create teacher first.</h6>
        </div>
        <div className="modal-footer">
          <Link
            to="/staff/catalog"
            onClick={() =>
              setActiveStates({
                main: "Staff",
                child: "Catalog",
              })
            }
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Create Teacher
          </Link>
        </div>
      </Modal>
      <div className="card">
        {/* <button onClick={notify}>click</button */}
        <div className="card-body">
          {error && (
            <p className="poppins-medium fs-5 text-white text-center bg-danger rounded bold">
              {error}
            </p>
          )}
          <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold card-title">
            {props.header}
          </CardTitle>
          <form>
            <div className="row my-4">
              <InputField
                label="Class Name:"
                type="text"
                compulsory={true}
                disabled={false}
                placeholder="Enter Class Name"
                value={classData.name}
                onChange={(e) => handleChangeInfo("name", e.target.value)}
              />
              <InputField
                label="Class Code:"
                type="text"
                compulsory={true}
                disabled={false}
                placeholder="Enter Class Code"
                value={classData.code}
                onChange={(e) => handleChangeInfo("code", e.target.value)}
              />
              {/* <label>Select section</label>
              <Select
                options={sections?.map((option) => ({
                  value: option?.id,
                  label: option?.name,
                }))}
                value={classData.section}
                disabled={false}
                onChange={(e) => handleCourseChange(e)}
                isMulti={true}
              /> */}
              <InputField
                label="Description:"
                type="text"
                compulsory={true}
                disabled={false}
                placeholder="Enter Description"
                value={classData.description}
                onChange={(e) =>
                  handleChangeInfo("description", e.target.value)
                }
              />
              {/* <InputField
                label="Location:"
                type="text"
                placeholder="Enter Location"
                disabled={false}
                value={classData.location}
                onChange={(e) => handleChangeInfo('location', e.target.value)}
              /> */}
              {/* <SelectFieldTeacher
                label="Class Teacher:"
                value={classData.teacher}
                selectOptions={_filteredTeahers}
                onChange={(e) => handleChangeInfo('teacher', e.target.value)}
              /> */}
              <div className="col-md-4 my-2">
                <label>Select Department</label>
                <select
                  className="form-control text-black"
                  value={classData.departmentId}
                  onChange={(e) =>
                    handleChangeInfo("departmentId", e.target.value)
                  }
                >
                  <option value="" selected>
                    Select Department
                  </option>
                  {departments.map((v, i) => (
                    <option value={v.id} key={i}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {props.selectedClass &&
            Object.keys(props.selectedClass).length !== 0 ? null : (
              <Link className="btn btn-danger" onClick={cancel}>
                Cancel
              </Link>
            )}
            <Link className="btn btn-primary mx-1" onClick={handleSave}>
              {props.selectedClass &&
              Object.keys(props.selectedClass).length !== 0
                ? "Update"
                : "Add"}
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddClass;
