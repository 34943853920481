import React from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import {
  allApplicant,
  ApplicantsData,
} from "../../redux/slice/admission/applicantSlice";
import { useSelector } from "react-redux";

const LatestTransaction = () => {
  const { applicants, loading, error, totalPages } = useSelector(allApplicant);
  return (
    <Col lg={8}>
      <Card className="rounded-4">
        <CardBody>
          <CardTitle className="h4 mb-4">Application Status</CardTitle>
          <div className="table-responsive">
            <Table className="table-centered">
              <thead>
                <tr>
                  <th scope="col">Id no.</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Candidate Name</th>
                  {/* <th scope="col">Amount</th> */}
                  <th scope="col" colSpan="2">
                    Interview Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {applicants.length > 0 ? applicants.slice(0, 5).map((std, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Link to="#" className="text-body fw-medium">
                          {std.id.slice(0, 5)}
                        </Link>
                      </td>
                      <td>{std.phone}</td>
                      <td>{std.name}</td>
                      {/* <td>$ 125</td> */}
                      <td
                        className={`text-nowrap ${
                          std?.studentAdmissionStatus?.status == "Pending"
                            ? "text-warning"
                            : std?.studentAdmissionStatus?.status == "Interview"
                            ? "text-info"
                            : std?.studentAdmissionStatus?.status == "NoShow"
                            ? "text-danger"
                            : std?.studentAdmissionStatus?.status == "OnHold"
                            ? "text-secondary"
                            : "text-success"
                        }`}
                      >
                        {std?.studentAdmissionStatus?.status}
                      </td>
                      <td></td>
                    </tr>
                  );
                }) : null}
                <Link
                  to="/Admission/Applicants"
                  className="btn btn-primary btn-sm mt-4"
                >
                  Load More
                </Link>
              </tbody>
            </Table>
          </div>
          {/* <div className="mt-3">
            <Pagination className="pagination pagination-rounded justify-content-center mb-0">
              <PaginationItem>
                <PaginationLink to="#">
                  <IoArrowBackCircle size={20} />
                </PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink to="#">1</PaginationLink>
              </PaginationItem>
              <PaginationItem className="active">
                <PaginationLink to="#">2</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink to="#">3</PaginationLink>
              </PaginationItem>
              <PaginationItem>
                <PaginationLink to="#">
                  <IoArrowForwardCircle size={20} />
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div> */}
        </CardBody>
      </Card>
    </Col>
  );
};

export default LatestTransaction;
