import React, { useEffect, useState } from "react";
import { Row, Col, Card, Alert, Container } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/city_school_profile.png";
import {
  Registration,
  sendEmail,
  sendOTP,
  allRegistration,
  resetStage,
} from "../../redux/slice/Authentication/RegisterSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Notify from "../../components/Notify-toast";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { response, loading, stage, error } = useSelector(allRegistration);
  const [registerdata, setRegisterData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    roles: ["Admin"],
  });
  const [otp, setOtp] = useState("");
  const [count, setCount] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [errorV, setError] = useState({
    emailError: "",
    otpError: "",
    passwordError: "",
    confirmPasswordError: "",
    generalError: "",
  });
  //  console.log(otp)
  const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    console.log("API response: ", response, response?.status);
    console.log("error useeffect", error?.message);
    if (error && error?.message) {
      Notify("error", error.message);
    }
    // if (stage == 2) {
    //   startTimer();
    // }
    // check for response
    if (response?.data?.message && response?.status) {
      console.log("message", response?.data);
      if (response?.status !== 200) {
        Notify("error", response?.data?.message);
      } else {
        Notify("success", response?.data?.message);
      }
    }
    if ((response?.status === 200, stage == 4)) {
      dispatch(resetStage());
      navigate("/login");
    }
  }, [response, navigate, error]);

  useEffect(() => {
    if (stage === 2 && !isTimerActive) {
      startTimer();
    }
  }, [stage, isTimerActive]);

  useEffect(() => {
    let interval;
    if (isTimerActive && count > 0) {
      interval = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (count === 0) {
      setIsTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, count]);

  const startTimer = () => {
    setCount(60); // Reset the count whenever timer starts
    setIsTimerActive(true);
  };

  const handleEmailVerification = () => {
    try {
      console.log("verification email handler", registerdata.email);
      if (!emailRegex.test(registerdata.email || registerdata.email == "")) {
        // setError((preError) => ({
        //   ...preError,
        //   emailError: "Invalid Email format",
        // }));
        Notify("error", "Invalid Email");
      } else {
        setError((preError) => ({
          ...preError,
          emailError: "",
        }));
        if (stage === 2 && !isTimerActive) {
          startTimer();
        }
        dispatch(
          sendEmail({
            username: registerdata.email,
          })
        );
      }
    } catch (error) {
      console.log("error stage 1", error);
    }
  };

  const handleOtpVerification = () => {
    console.log(registerdata.email);
    if (otp.length < 6 || otp.length > 6) {
      // setError((preError) => ({
      //   ...preError,
      //   otpError: "Invalid OTP Code",
      // }));
      Notify("error", "Invalid OTP Code");
    } else {
      setError((preError) => ({
        ...preError,
        otpError: "",
      }));
      dispatch(sendOTP({ email: registerdata.email, otp: otp }));
    }
  };

  const handleSubmit = async () => {
    try {
      // dispatch(SetResponseRegister());
      if (!emailRegex.test(registerdata.email)) {
        console.log("email");
        Notify("error", "Invalid Email");
      } else if (
        registerdata.password.length < 6 ||
        !specialCharacterRegex.test(registerdata.password)
      ) {
        Notify(
          "error",
          "Password must be at least 6 characters long and contain at least one special character"
        );
      } else if (!/\d/.test(registerdata.password)) {
        Notify("error", "Passwords should contain atleast one digit.");
      } else if (registerdata.password !== registerdata.confirmPassword) {
        console.log("same pass");
        Notify("error", "Passwords do not match");
      } else if (
        registerdata.email &&
        registerdata.confirmPassword &&
        registerdata.password &&
        registerdata.roles
      ) {
        console.log("dispatch value", response);
        dispatch(
          Registration({
            username: registerdata.email,
            password: registerdata.password,
            confirmPassword: registerdata.confirmPassword,
            roles: registerdata.roles,
          })
        );
      } else {
        Notify("error", "Please fill Data");
      }
    } catch (error) {
      console.log("err", error.message);
      console.log("err", error.message);
    }
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <h2>
            <i className="fas fa-home"></i>
          </h2>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Register</h5>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="50" />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    {stage == 1 || stage == 3 ? (
                      <div className="mb-3">
                        <label>Enter Email</label>
                        <input
                          value={registerdata.email}
                          autoComplete="off"
                          className="form-control"
                          placeholder="Enter Email Address"
                          type="email"
                          required
                          onChange={(v) =>
                            setRegisterData({
                              ...registerdata,
                              email: v.target.value,
                            })
                          }
                        />
                        {!emailRegex.test(registerdata.email) ? (
                          <p className="text-danger mt-2">
                            {errorV?.emailError}
                          </p>
                        ) : (
                          ""
                        )}
                        {response?.status === 400 ? (
                          <p className="text-danger mt-2 ">
                            {response?.data?.errors[0]?.description}
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                    {stage == 2 ? (
                      <div className="mb-3">
                        <label>Enter OTP (Send to Your Email)</label>
                        <input
                          value={otp}
                          autoComplete="off"
                          className="form-control"
                          placeholder="Enter OTP verification Code"
                          type="text"
                          required
                          onChange={(v) => setOtp(v.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleOtpVerification();
                            }
                          }}
                        />
                        {errorV.otpError ? (
                          <p className="text-danger mt-2">{errorV.otpError}</p>
                        ) : (
                          ""
                        )}
                        {response?.status === 400 ? (
                          <p className="text-danger mt-2 ">
                            {response?.data?.errors[0]?.description}
                          </p>
                        ) : null}
                      </div>
                    ) : null}
                    {stage == 3 ? (
                      <>
                        <div className="mb-3">
                          <label>Enter Password</label>
                          <input
                            autoComplete="off"
                            className="form-control"
                            onChange={(v) =>
                              setRegisterData({
                                ...registerdata,
                                password: v.target.value,
                              })
                            }
                            type="password"
                            value={registerdata.password}
                            required
                            placeholder="Enter Password"
                          />
                          {registerdata.password.length < 6 ||
                          !specialCharacterRegex.test(registerdata.password) ? (
                            <p className="text-danger mt-2">
                              {errorV.passwordError}
                            </p>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <label>Password Confirm</label>
                          <input
                            autoComplete="off"
                            className="form-control"
                            onChange={(v) =>
                              setRegisterData({
                                ...registerdata,
                                confirmPassword: v.target.value,
                              })
                            }
                            type="password"
                            placeholder="Confirm Password"
                            value={registerdata.confirmPassword}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleSubmit();
                              }
                            }}
                            required
                          />
                          {registerdata.password !==
                          registerdata.confirmPassword ? (
                            <p className="text-danger mt-2">
                              {errorV.confirmPasswordError}
                            </p>
                          ) : (
                            " "
                          )}
                        </div>
                      </>
                    ) : null}
                    {stage == 2 ? (
                      <div className="d-flex justify-content-around">
                        <p className="m-2">
                          Didn't receive a code?{" "}
                          <span className="text-danger">
                            {isTimerActive ? count : null}
                          </span>
                        </p>
                        <button
                          className="btn btn-link float-end text-nowrap"
                          disabled={isTimerActive && stage == 2}
                          onClick={() => handleEmailVerification()}
                        >
                          Resend OTP
                        </button>
                      </div>
                    ) : null}
                    <p className="text-danger mt-2">{errorV.generalError}</p>
                    <div className="mt-4">
                      {stage == 1 ? (
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          disabled={registerdata.email == ""}
                          onClick={() => handleEmailVerification()}
                        >
                          {loading ? (
                            <div
                              className="spinner-border text-light"
                              style={{ height: "16px", width: "16px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Verify Email"
                          )}
                        </button>
                      ) : null}
                      {stage == 2 ? (
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          disabled={otp == ""}
                          onClick={() => handleOtpVerification()}
                        >
                          {loading ? (
                            <div
                              className="spinner-border text-light"
                              style={{ height: "16px", width: "16px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Verify OTP"
                          )}
                        </button>
                      ) : null}
                      {stage == 3 ? (
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          onClick={() => handleSubmit()}
                        >
                          {loading ? (
                            <div
                              className="spinner-border text-light"
                              style={{ height: "16px", width: "16px" }}
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          ) : (
                            "Register"
                          )}
                        </button>
                      ) : null}
                    </div>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        By registering you agree to the HappyDigital By registering
                        you agree to the HappyDigital{" "}
                        <Link to="#" className="text-primary">
                          Terms of Use
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ? Already have an account ?{" "}
                  <a href="/login" className="fw-medium text-primary">
                    Login
                  </a>{" "}
                </p>
                <p>© {new Date().getFullYear()} HappyDigital.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
