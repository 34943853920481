import React, { useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";
import {
  allCourses,
  courseData,
  deleteCourse,
  updateCourseError,
} from "../../../redux/slice/academics/CoursesSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import AddCourse from "./add-course";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import {
  ClassesData,
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import Loader from "../../../components/loader/Loader";
import FilterInput from "../../../components/FilterInputComponent";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";

function CourseCard(props) {
  const { course, courseError, loading, totalPages } = useSelector(allCourses);
  const { currentCampus } = useSelector(allCampus);
  const [Deletemodal, setDeleteModal] = useState(false);
  const [_dltID, set_dltID] = useState();
  const { classes, singleClasses } = useSelector(allClasses);
  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [currentData, setCurrentData] = useState({
    currentClass: "0",
  });

  const [selectedCourse, setSelectedCourse] = useState({
    name: "",
    code: "",
    description: "",
    section: [],
    department: "",
  });

  const dispatch = useDispatch();

  // console.log("_course", currentData);

  useEffect(() => {
    setCurrentData((prev) => ({
      ...prev,
      currentClass: "0",
    }));
  }, [currentCampus]);

  useEffect(() => {
    setCurrentPage(1);
    if (currentCampus == "0") {
      dispatch(ClassesData());
      const data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
      };
      dispatch(courseData(data));
    } else if (currentCampus) {
      dispatch(ClassesDataSingle(currentCampus));
      dispatch(ClassesData());
      const data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
        CampusId: Number(currentCampus),
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
      };
      dispatch(courseData(data));
    }
  }, [itemsPerPage, currentData, currentCampus]);

  useEffect(() => {
    if (courseError === 200) {
      ModalToggle();
      dispatch(updateCourseError());
    }
  }, [courseError, dispatch, props]);

  function ModalToggle() {
    setModal(!modal);
  }
  function handleDeleteClick(i) {
    dispatch(deleteCourse(i));
    set_dltID("");
    setDeleteModal(!Deletemodal);
  }

  function courseForEdit(_course) {
    // console.log("updated-data", _course);
    setSelectedCourse({
      name: _course.name,
      code: _course.code,
      description: _course.description,
      classId: _course.classId,
    });
    setSelectedCourseId(_course.id);
    ModalToggle();
  }

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const data = {
      pageSize: itemsPerPage,
      pageNumber: pageNumber,
      ClassId:
        currentData.currentClass !== "0" ? +currentData.currentClass : null,
      CampusId: currentCampus != "0" ? Number(currentCampus) : null,
    };
    dispatch(courseData(data));
  };
  //calculate number of pages

  return (
    <>
      <div className="row mt-4">
        <Card>
          <CardBody>
            <div className="">
              <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold">
                Courses
              </CardTitle>
              <div className="my-2 w-100 d-flex">
                <div className="w-75 d-flex">
                  <FilterInput
                    options={singleClasses}
                    value={currentData.currentClass}
                    label="Filter by Class ---"
                    onChange={(e) => {
                      setCurrentData({
                        currentClass: e.target.value,
                      });
                    }}
                  />
                </div>
                <select
                  className="form-control w-25"
                  style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                  value={itemsPerPage}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setItemsPerPage(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Items per page
                  </option>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select>
              </div>
            </div>
            <Modal
              size="lg"
              centered
              isOpen={modal}
              toggle={() => ModalToggle()}
            >
              <AddCourse
                selectedCourse={selectedCourse}
                switchTab={props.switchTab}
                ModalToggle={ModalToggle}
                id={selectedCourseId}
                header="Update Course"
              />
            </Modal>
            {/* {reduxStoreData.length !== 0 ? ( */}
            <div className="table-responsive">
              {loading ? (
                <span className="m-4">
                  <Loader />
                </span>
              ) : course.length > 0 ? (
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Code</th>
                      <th className="text-nowrap">Class</th>
                      <th className="text-nowrap">Description</th>
                      <th className="text-nowrap">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {course?.map((c, index) => {
                      let _className =
                        classes?.find((x) => x.id === c.classId) || "-";
                      return (
                        <tr key={index} className="p-2">
                          <td>{c.name}</td>
                          <td>{c.code}</td>
                          <td>{_className.name}</td>
                          <td>{c.description}</td>
                          <td>
                            <div className="d-flex justify-content-start">
                              <Link
                                className="btn btn-primary btn-sm me-2"
                                onClick={() => courseForEdit(c)}
                              >
                                <MdModeEdit size={15} />
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  set_dltID(c.id);
                                  setDeleteModal(true);
                                }}
                              >
                                <MdDelete size={15} />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <h5 className="text-danger text-center w-100" style={{}}>
                  No record found!
                </h5>
              )}

              {/* ) : (
              <h5 className="text-danger text-center w-100" style={{}}>
                No record found!
              </h5>
            )} */}
            </div>
            {course.length > 0 && (
              <div className="mt-3">
                <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => paginate(currentPage - 1)}
                    >
                      <IoArrowBackCircle size={25} />
                    </PaginationLink>
                  </PaginationItem>
                  {[...Array(totalPages).keys()].map((number) => (
                    <PaginationItem
                      key={number}
                      active={number + 1 === currentPage}
                    >
                      <PaginationLink onClick={() => paginate(number + 1)}>
                        {number + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => paginate(currentPage + 1)}
                    >
                      <IoArrowForwardCircle size={25} />
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={Deletemodal} centered>
        <Card>
          <CardBody className="mt-2">
            <h5 style={{ fontWeight: "normal" }}>
              Deleting this course will also delete all associated Assignments,
              Do you still want to delete this Course?
            </h5>
          </CardBody>
          <CardFooter>
            <button
              className="btn btn-danger me-2"
              onClick={() => handleDeleteClick(_dltID)}
            >
              Delete
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
          </CardFooter>
        </Card>
      </Modal>
    </>
  );
}

export default CourseCard;
