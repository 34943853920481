import React, { useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Row, Table } from "reactstrap";
import { statusClasses } from "../../../common/data/tasks";
import classNames from "classnames";

const academicYear = [
  {
    year: 2024,
    startDate: "September 29, 2024",
    endDate: "March 5, 2025",
    term: 2,
    status: "inactive",
  },
  {
    year: 2024,
    startDate: "April 3, 2024",
    endDate: "September 7, 2024",
    term: 1,
    status: "inactive",
  },
  {
    year: 2023,
    startDate: "Octuber 1, 2023",
    endDate: "March 2, 2024",
    term: 2,
    status: "active",
  },

  {
    year: 2023,
    startDate: "April 10, 2023",
    endDate: "September 10, 2023",
    term: 1,
    status: "inactive",
  },
  {
    year: 2022,
    startDate: "Octuber 6, 2022",
    endDate: "March 1, 2023",
    term: 2,
    status: "inactive",
  },

  {
    year: 2022,
    startDate: "April 6, 2022",
    endDate: "September 20, 2022",
    term: 1,
    status: "inactive",
  },
];

function AcademicYear() {
  const [createNew, setCreateNew] = useState(false);

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Academics" breadcrumbItem="Academic Year" />
        <Row lg={3} className="justify-content-end mb-2">
          <Col className="justify-content-end d-flex">
            <Button
              color="success"
              className="btn btn-success waves-effect waves-light"
              onClick={() => setCreateNew(true)}
            >
              Create New
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <h4 className="mb-4 font-weight-bold">Academic Years</h4>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Academic Year</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Term</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {academicYear.map((item, ind) => (
                        <tr key={ind}>
                          <th scope="row">{item.year}</th>
                          <td>{item.startDate}</td>
                          <td>{item.endDate}</td>
                          <td>{item.term}</td>
                          <td>
                            <div className="">
                              <span
                                className={classNames(
                                  "badge badge-pill font-size-11",
                                  statusClasses[item.status.toLowerCase()]
                                )}
                              >
                                {item.status}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default AcademicYear;
