import React, { useEffect, useState } from "react";
import { InputField, SelectField } from "../../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function EmergencyContactForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  forView,
  emergencyContact,
}) {
  let infoObject = "emergency";
  let mobileRegex = /^\+92\d{10}$/;
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  let nicRegex = /^[0-9]{5}[0-9]{7}[0-9]$/;

  useEffect(() => {
    const isAnyKeyEmpty = Object.values(emergencyContact).some((value) => {
      return value === "" || value === null;
    });
    if (!isAnyKeyEmpty) {
      const isValidMobile = mobileRegex.test(emergencyContact.mobileNumber);
      const isValidEmail = emailRegex.test(emergencyContact.email);
      if (isValidMobile && isValidEmail) {
        updateTabComplete(true);
      } else {
        updateTabComplete(false);
      }
    } else {
      if (tabs[2].complete) {
        updateTabComplete(false);
      }
    }
  }, [emergencyContact]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          {!forView ? (
            <InputField
              disabled={forView}
              label="Name"
              type="text"
              compulsory={true}
              value={emergencyContact.name}
              placeholder="Name"
              onChange={(e) =>
                handleChangeInfo(infoObject, "name", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Name</label>
              <p className="">{emergencyContact.name}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              label="Relationship with Child"
              type="text"
              disabled={forView}
              compulsory={true}
              value={emergencyContact.relWithChild}
              selectOptions={["Father", "Mother", "Guardian"]}
              placeholder="Enter Relation With Child"
              onChange={(e) =>
                handleChangeInfo(infoObject, "relWithChild", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Relationship with Child</label>
              <p className="">{emergencyContact.relWithChild}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mobile Number"
              type="tel"
              compulsory={true}
              value={emergencyContact.mobileNumber}
              placeholder="Mobile Number"
              onChange={(e) =>
                handleChangeInfo(infoObject, "mobileNumber", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mobile Number</label>
              <p className="">{emergencyContact.mobileNumber}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Email"
              type="email"
              compulsory={true}
              value={emergencyContact.email}
              placeholder="Email"
              onChange={(e) =>
                handleChangeInfo(infoObject, "email", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Email</label>
              <p className="">{emergencyContact.email}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Home Address"
              type="text"
              compulsory={true}
              value={emergencyContact.homeAddress}
              placeholder="Home Address"
              onChange={(e) =>
                handleChangeInfo(infoObject, "homeAddress", e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Home Address</label>
              <p className="">{emergencyContact.homeAddress}</p>
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
