import { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  SelectFieldForId,
} from "../../../components/input-fields";
import Notify from "../../../components/Notify-toast";
import { useSelector, useDispatch } from "react-redux";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  CreateTimetableData,
  UpdateTimetableData,
} from "../../../redux/slice/academics/timetableSlice";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import {
  allCourses,
  courseData,
} from "../../../redux/slice/academics/CoursesSlice";
import Select from "react-select";
import { reactSelectStyle } from "../../../helpers/react-select-style";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { weekDaysWithId } from "../../../db/fields-data";
import {
  ClassRoomCreate,
  ClassRoomUpdate,
} from "../../../redux/slice/academics/classroomSlice";

export default function ClassRoomForm(props) {
  const dispatch = useDispatch();
  const { campus, currentCampus } = useSelector(allCampus);
  const { singleClasses } = useSelector(allClasses);
  const { course, loading, courseError } = useSelector(allCourses);
  const [allCourseData, setAllCourseData] = useState([]);
  const [createData, setCreateData] = useState({
    name: "",
    campusId: "",
    macAddress: "",
    description: "",
  });

  useEffect(() => {
    if (props.classRoomData && Object.keys(props.classRoomData).length !== 0) {
      console.log("classRoomData update", props.classRoomData);
      setCreateData(props.classRoomData);
    }
  }, []);

  useEffect(() => {
    console.log("dispatching classes data");
    if (currentCampus == 0) {
      let data = null;
      // dispatch(ClassesDataSingle(data));
    } else {
      let data = Number(currentCampus);
      // dispatch(ClassesDataSingle(data));
    }
  }, [currentCampus]);

  useEffect(() => {
    if (currentCampus == 0) {
      let data = {
        ClassId: createData.class ? createData.class : null,
      };
      // dispatch(courseData(data));
    } else {
      let data = {
        CampusId: Number(currentCampus),
        ClassId: createData.class ? createData.class : null,
      };
      dispatch(courseData(data));
    }
  }, [createData.class]);

  useEffect(() => {
    console.log("courses", course);
    const transformedArray = course.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    setAllCourseData(transformedArray);
  }, [course]);

  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value);
    setCreateData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("createData", createData);
    if (createData.campusId == "") {
      Notify("error", "Please Select Campus.");
    } else if (!createData.name) {
      Notify("error", "Please Enter Name.");
    } else if (!createData.description) {
      Notify("error", "Please Enter Description.");
    } else {
      if (
        props.classRoomData &&
        Object.keys(props.classRoomData).length !== 0
      ) {
        console.log("updating...");
        let id = Number(props?.updateId);
        dispatch(ClassRoomUpdate({ id, bodyData: createData }));
      } else {
        console.log("creating...");
        dispatch(ClassRoomCreate(createData));
      }
      props.toggleTab();
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Add Classroom</h5>
          <div className="row mt-4">
            <InputField
              label="Name"
              placeholder="Enter Name"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              type="text"
              compulsory={true}
              disabled={false}
              value={createData.name}
              onChange={(e) => handleChangeInfo("name", e.target.value)}
            />
            <SelectFieldForId
              label="Select Campus"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              disabled={false}
              value={createData.campusId}
              selectOptions={campus}
              onChange={(e) => handleChangeInfo("campusId", e.target.value)}
            />
            <InputField
              label="MAC Address"
              placeholder="Enter MAC Address"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={false}
              disabled={false}
              type="text"
              value={createData.macAddress}
              onChange={(e) => handleChangeInfo("macAddress", e.target.value)}
            />
            <InputField
              label="Description"
              placeholder="Enter Description"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={false}
              disabled={false}
              type="text"
              value={createData.description}
              onChange={(e) => handleChangeInfo("description", e.target.value)}
            />
            {/* <InputField
              label="End Time"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={false}
              disabled={false}
              type="time"
              value={createData.endTime}
              placeholder="Select End Time"
              onChange={(e) => handleChangeInfo("endTime", e.target.value)}
            /> */}
          </div>
          <button
            className="btn btn-success float-end mt-4"
            onClick={handleSubmit}
          >
            {props.classRoomData &&
            Object.keys(props.classRoomData).length !== 0
              ? "Update"
              : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}
