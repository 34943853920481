import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  CardFooter,
  Modal,
} from "reactstrap";
import FilterInput from "../../../components/FilterInputComponent";
import Select from "react-select";
import Loader from "../../../components/loader/Loader";
import { FaRegEye } from "react-icons/fa";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  AssignCourseData,
  PostAssignCourse,
  PostAssignCourseData,
  UpdateAssignCourse,
  allAssignCourse,
} from "../../../redux/slice/academics/AssignCourses";
import {
  allClasses,
  ClassesDataSingle,
} from "../../../redux/slice/classesSlice";
import { useDispatch } from "react-redux";
import {
  allCourses,
  courseData,
} from "../../../redux/slice/academics/CoursesSlice";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";

const AssignCourses = () => {
  // const loading = false;
  const dispatch = useDispatch();
  const { course } = useSelector(allCourses);
  const { singleClasses } = useSelector(allClasses);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState({
    id: "",
    updateCourse: [],
  });
  const [courseId, setCourseId] = useState("");
  const [checkDataModal, setcheckDataModal] = useState(false);
  const { currentCampus } = useSelector(allCampus);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentData, setCurrentData] = useState({
    currentClass: "0",
  });
  const { AssignCourse, totalPages, loading } = useSelector(allAssignCourse);
  const [courses, setCourse] = useState({
    course: [],
  });
  console.log("campus-id", currentCampus);
  console.log("classes", singleClasses);

  useEffect(() => {
    setCurrentData((prev) => ({
      ...prev,
      currentClass: "0",
    }));
  }, [currentCampus]);

  useEffect(() => {
    setCurrentPage(1);
    if (currentCampus == "0") {
      dispatch(courseData());
      const data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
      };
      dispatch(AssignCourseData(data));
    } else if (currentCampus) {
      dispatch(ClassesDataSingle(currentCampus));
      dispatch(courseData());
      const data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
        CampusId: Number(currentCampus),
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
      };
      //  dispatch(getAllAssignment(data));
      dispatch(AssignCourseData(data));
    }
  }, [itemsPerPage, currentCampus, currentData]);

  const handleClick = (data) => {
    if (data.studentClassDetail === null) {
      setcheckDataModal(true);
    } else {
      setModal(true);
      setCourseId(data.id);
    }
  };
  const handleCoursesChange = (selectedOption) => {
    setCourse({
      ...courses,
      course: selectedOption,
    });
  };
  const handleCoursesUpdateChange = (selectedOption) => {
    console.log("received-val", selectedOption);
    setUpdateData({
      ...updateData,
      updateCourse: selectedOption,
    });
  };
  const HandleAssignCourse = () => {
    console.log("course-iddd", courseId);
    dispatch(
      PostAssignCourse({
        id: courseId,
        data: [...courses.course?.map((v) => v?.value)],
      })
    );
    setCourse("");
    setModal(false);
  };

  const HandleUpdateAssignCourse = () => {
    if (!updateData || !updateData.id || !updateData.updateCourse) {
      console.error("Invalid updateData");
      return;
    }
    const id = updateData.id;
    console.log("course-iddd", id);
    const updatedCourses = updateData.updateCourse?.map((v) => v?.value) || [];
    dispatch(
      UpdateAssignCourse({
        id: id,
        data: updatedCourses,
      })
    );
    setUpdateModal(false);
  };
  const handleUpdate = (data) => {
    console.log("updated-data", data);
    const convertedArray = data.courses.map((v) => {
      console.log("Data", v);
      return {
        label: v.name,
        value: v.id,
      };
    });
    setUpdateData({
      id: data.id,
      updateCourse: convertedArray,
    });
    setUpdateModal(true);
  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const data = {
      pageSize: itemsPerPage,
      pageNumber: pageNumber,
      ClassId:
        currentData.currentClass !== "0" ? +currentData.currentClass : null,
      CampusId: currentCampus != "0" ? Number(currentCampus) : null,
    };
    // dispatch(getAllAssignment(data));
    dispatch(AssignCourseData(data));
  };
  // console.log('updateDAta', updateData.updateCourse);
  // console.log('course', courses);
  console.log(
    "Assign-course-data",
    AssignCourse.map((v) => v.courses.length > 0)
  );
  // console.log('course-data', course);
  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Academics" breadcrumbItem="Assign-Courses" />
        <Row>
          <Card>
            <CardBody>
              <div className="">
                <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold">
                  Assign Courses
                </CardTitle>
                <div className="my-2 w-100 d-flex">
                  <div className="w-75 d-flex">
                    <FilterInput
                      options={singleClasses}
                      value={currentData.currentClass}
                      label="Filter by Class ---"
                      onChange={(e) => {
                        setCurrentData({
                          currentClass: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <select
                    className="form-control w-25"
                    style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                    value={itemsPerPage}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setItemsPerPage(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Items per page
                    </option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
              </div>
              {/* <select
                  className="form-control w-25"
                  style={{ borderColor: 'rgb(59 93 231 / 47%)' }}
                  value={itemsPerPage}
                  onChange={(e) => {
                    setCurrentPage(1);
                    setItemsPerPage(e.target.value);
                  }}
                >
                  <option value="" disabled>
                    Items per page
                  </option>
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={15}>15</option>
                  <option value={20}>20</option>
                </select> */}

              <div className="table-responsive">
                <Table className="table table-striped mb-0">
                  {loading ? (
                    <Loader />
                  ) : (
                    <>
                      <thead>
                        <tr>
                          <th>Roll Number</th>
                          <th>Name</th>
                          <th>Campus</th>
                          <th>Class</th>
                          <th>Section</th>
                          <th>Courses</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {AssignCourse?.map((std, ind) => (
                          <tr key={ind}>
                            <td>{std.registerationNo}</td>
                            <td>{std.studentName}</td>
                            <td>{std.studentClassDetail?.campus?.name}</td>
                            <td>{std.studentClassDetail?.class?.name}</td>
                            <td>{std.studentClassDetail?.section?.name}</td>
                            <td>
                              {std.courses?.map((v, i) => (
                                <span
                                  key={i}
                                  className="badge badge-pill font-size-14 badge-soft-success me-2"
                                >
                                  {v.name}
                                </span>
                              ))}
                            </td>
                            <td>
                              {/* <FaRegEye
                                className="me-2 text-success"
                                size={15}
                                style={{ cursor: 'pointer' }}
                              /> */}
                              {std.courses.length > 0 ? (
                                <MdModeEdit
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                  className="me-2 text-primary"
                                  onClick={() => handleUpdate(std)}
                                  title="Edit Student"
                                />
                              ) : (
                                <MdOutlineLibraryBooks
                                  size={15}
                                  onClick={() => handleClick(std)}
                                  style={{ cursor: "pointer" }}
                                  className="me-2 text-primary"
                                />
                              )}
                              {/* <MdDelete
                                size={15}
                                style={{ cursor: 'pointer' }}
                                className="me-2 text-danger"
                              /> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                </Table>
                <Modal isOpen={modal} centered>
                  <Card>
                    <CardBody className="mt-2">
                      <label>Select Courses</label>
                      <Select
                        className=""
                        options={course?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        value={courses.course}
                        onChange={(e) => handleCoursesChange(e)}
                        isMulti={true}
                      />
                    </CardBody>
                    <CardFooter>
                      <button
                        className="btn btn-danger me-2"
                        onClick={() => HandleAssignCourse()}
                        // disabled={courses.course}
                      >
                        Assign
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => setModal(false)}
                      >
                        Cancel
                      </button>
                    </CardFooter>
                  </Card>
                </Modal>
                <Modal isOpen={updateModal} centered>
                  <Card>
                    <CardBody className="mt-2">
                      <label>Update Courses</label>
                      <Select
                        className=""
                        options={course?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        value={updateData?.updateCourse}
                        onChange={(e) => handleCoursesUpdateChange(e)}
                        isMulti={true}
                      />
                    </CardBody>
                    <CardFooter>
                      <button
                        className="btn btn-danger me-2"
                        onClick={() => HandleUpdateAssignCourse()}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => setUpdateModal(false)}
                      >
                        Cancel
                      </button>
                    </CardFooter>
                  </Card>
                </Modal>
                <Modal isOpen={checkDataModal} centered>
                  <Card>
                    <CardBody className="mt-2">
                      <p>
                        Please note that if you have not yet been assigned a
                        campus, class, or section, you cannot be assigned
                        courses. Please ensure you get your campus, class, or
                        section assigned first.
                      </p>
                    </CardBody>
                    <CardFooter>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => setcheckDataModal(false)}
                      >
                        OK
                      </button>
                    </CardFooter>
                  </Card>
                </Modal>
              </div>
              {AssignCourse?.length > 0 && (
                <div className="mt-3">
                  <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <IoArrowBackCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages).keys()].map((number) => (
                      <PaginationItem
                        key={number}
                        active={number + 1 === currentPage}
                      >
                        <PaginationLink onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink
                        next
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <IoArrowForwardCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              )}
            </CardBody>
          </Card>
        </Row>
      </div>
    </>
  );
};

export default AssignCourses;
