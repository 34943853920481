import { axiosInstance, instanceAuth } from '../axiosConfig';

export const fetchCampusData = async (data) => {
  try {
    const response = await instanceAuth.get('Campus', {
      params: {
        ...data,
      },
    });
    return response;
  } catch (error) {
    console.log('error-fetching-data', error);
    throw error;
  }
};

export const postCampusData = async (data) => {
  try {
    const response = await instanceAuth.post('Campus', data);
    return response;
  } catch (error) {
    console.error('Error post data:', error);
    throw error;
  }
};
export const deleteCampusData = async (id) => {
  try {
    const response = await instanceAuth.delete(`Campus/${id}`);
    return response;
  } catch (error) {
    console.log('error-deleting-data', error);
    throw error;
  }
};
export const UpdateCampusData = async ({ id, campusData }) => {
  try {
    const response = await instanceAuth.put(`Campus/${id}`, campusData);
    return response;
  } catch (error) {
    console.log('error updating campus...', error);
    throw error;
  }
};
