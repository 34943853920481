import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteStudentAttendanceData,
  fetchStudentAttendance,
  fetchSingleStudentAttendance,
  createStudentAttendanceData,
  updateStudentAttendanceData,
} from "../../../api/Student/studentAttandanceApiHelper";
import { toast } from "react-toastify";
import {
  UpdateClassSectionData,
  postClassSectionData,
} from "../../../api/Academics/SectionApiHelper";
import Notify from "../../../components/Notify-toast";

export const studentAttendanceData = createAsyncThunk(
  "student/studentAttendanceData",
  async (data) => {
    try {
      const response = await fetchStudentAttendance(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch student data");
    }
  }
);

export const singleStudentAttendanceData = createAsyncThunk(
  "student/singleStudentAttendanceData",
  async (data) => {
    try {
      const response = await fetchSingleStudentAttendance(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch this student data");
    }
  }
);

export const deleteStudentAttendance = createAsyncThunk(
  "student/deleteStudentAttendance",
  async (id) => {
    try {
      const response = await deleteStudentAttendanceData(id);
      if (response.status === 200) {
        return response.data.studentid; // Return studentId if deletion is successful
      } else {
        throw Error("Failed to delete student");
      }
    } catch (error) {
      throw Error("Failed to delete student data");
    }
  }
);

export const createStudentAttendance = createAsyncThunk(
  "student/createStudentAttendance",
  async (data) => {
    try {
      const response = await createStudentAttendanceData(data);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to create student data");
    }
  }
);

export const updateStudentAttandance = createAsyncThunk(
  "student/updateStudentAttandance",
  async ({ id, data }) => {
    try {
      const response = await updateStudentAttendanceData({
        id: id,
        data: data,
      });
      console.log("response.data", response);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to update student data");
    }
  }
);

const initialState = {
  studentsAttendanceList: [],
  loading: false,
  error: null,
  totalPages: 1,
};

export const studentAttendenceSlice = createSlice({
  name: "student_attendance",
  initialState,
  reducers: {
    addStudentAttendance: (state, action) => {
      // add working
      state.studentsAttendanceList.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(studentAttendanceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(studentAttendanceData.fulfilled, (state, action) => {
        console.log("student attendance fetch payload!", action.payload.data?.items);
        state.loading = false;
        if (action.payload.status === 200) {
          state.studentsAttendanceList = action.payload.data?.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(studentAttendanceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(singleStudentAttendanceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(singleStudentAttendanceData.fulfilled, (state, action) => {
        console.log("single student attendance fetch payload!", action.payload);
        state.loading = false;
        if (
          action.payload.status === 200 &&
          action.payload.data.items.length > 0
        ) {
          const updatedStudent = action.payload.data.items[0];
          state.studentsAttendanceList = state.studentsAttendanceList.map(
            (student) =>
              student.id === updatedStudent.id ? updatedStudent : student
          );
        }
        // else if(action.payload.data.items < 1){
        //   const updatedStudent = action.payload.data.items;
        //   state.studentsAttendanceList = state.studentsAttendanceList.map((student) =>
        //     student.id === updatedStudent.id ? updatedStudent : student
        //   );
        // }
      })
      .addCase(singleStudentAttendanceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createStudentAttendance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createStudentAttendance.fulfilled, (state, action) => {
        console.log("create attendance payload", action.payload);
        state.loading = false;
        if (action.payload) {
          // state.studentsAttendanceList = [...state.studentsAttendanceList, action.payload];
          Notify("success", "Attendance has been added");
        } else {
          Notify(
            "error",
            "Error on creating the Attendance. Something went wrong"
          );
        }
        state.error = null;
      })
      .addCase(createStudentAttendance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { addStudentAttendance } = studentAttendenceSlice.actions;

export const allstudentAttendance = (state) => state.allstudentAttendance;

export default studentAttendenceSlice.reducer;
