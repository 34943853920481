import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteStudentData,
  fetchStudentData,
  createStudent,
  ExportStudentData,
  updateStudentData,
  addStudentsFileAPI,
} from '../../../api/Student/StudentApiHelper';
import { toast } from 'react-toastify';
import {
  UpdateClassSectionData,
  postClassSectionData,
} from '../../../api/Academics/SectionApiHelper';
import Notify from '../../../components/Notify-toast';

export const StudentData = createAsyncThunk(
  'student/FetchStudentData',
  async (data) => {
    try {
      const response = await fetchStudentData(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error('Failed to fetch student data');
    }
  }
);

export const FetchExportStudentData = createAsyncThunk(
  'student/FetchExportStudentData',
  async () => {
    try {
      const response = await ExportStudentData();
      return response;
    } catch (error) {
      throw Error('Failed to fetch student Export data');
    }
  }
);


export const postSingleClassSection = createAsyncThunk(
  'section/postSectionClassData',
  async ({ id, sectionData }) => {
    try {
      const response = await postClassSectionData({ id, sectionData });
      console.log('response.data', response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error('Failed to update class section');
    }
  }
);

export const UpdateSingleClassSection = createAsyncThunk(
  'section/UpdateSectionClassData',
  async ({ id, sectionData }) => {
    try {
      const response = await UpdateClassSectionData({ id, sectionData });
      console.log('response.data', response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error('Failed to update class section');
    }
  }
);
export const deleteStudent = createAsyncThunk(
  'student/deleteStudentData',
  async (id) => {
    try {
      const response = await deleteStudentData(id);
      if (response.status === 200) {
        return response.data.studentid; // Return studentId if deletion is successful
      } else {
        throw Error('Failed to delete student');
      }
    } catch (error) {
      throw Error('Failed to delete student data');
    }
  }
);

export const createdStudent = createAsyncThunk(
  'student/createStudentData',
  async (formData) => {
    try {
      const response = await createStudent(formData);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error('Failed to create student data');
    }
  }
);
export const updateStudent = createAsyncThunk(
  'student/updateStudentData',
  async ({ id, formData }) => {
    try {
      const response = await updateStudentData({ id: id, formData: formData });
      console.log('response.data', response);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error('Failed to update student data');
    }
  }
);

export const AddStudentsFile = createAsyncThunk(
  'student/AddStudentsFile',
  async (formData) => {
    try {
      const response = await addStudentsFileAPI(formData);
      console.log('add student file response data', response);
      if (response?.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error('Failed to update student data');
    }
  }
);

const initialState = {
  students: [],
  singleSection: [],
  AllDataforExport:[],
  updateClassSection: [],
  loading: false,
  error: null,
  studentResponse: "",
  totalPages: 1,
};

export const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    addStudent: (state, action) => {
      // add working
      console.log(action);
      state.students.push(action.payload);
    },
    updateStudentdata: (state, action) => {
      // Update an existing student based on studentId
      console.log('update data payload', action.payload);
      const updatedStudent = action.payload;
      const index = state.students.findIndex(
        (student) =>
          student.personal.studentId === updatedStudent.personal.studentId
      );
      if (index !== -1) {
        state.students[index] = updatedStudent;
      } else {
        console.error('Student not found for update');
      }
    },
    removeStudent: (state, action) => {
      return {
        ...state,
        students: state.students.filter((i) => i.id !== action.payload),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(StudentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(StudentData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          console.log("Fetch Student Payload->", action.payload.data?.items)
          state.students = action.payload.data?.items;
          state.totalPages = action.payload.data?.totalPages;
        }
      })
      .addCase(StudentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
      
      builder
      .addCase(FetchExportStudentData.pending, (state) => {
        state.loading = true;
      })
      .addCase(FetchExportStudentData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          // console.log("Fetch Student Payload->", action.payload.data?.items)
          state.AllDataforExport = action.payload.data?.items || [];
        }
      })
      .addCase(FetchExportStudentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });


    builder
      .addCase(postSingleClassSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(postSingleClassSection.fulfilled, (state, action) => {
        console.log('section data payload!', action.payload);
        state.loading = false;
        if (action.payload) {
          state.updateClassSection = action.payload;
          state.students = state.students.filter(
            (v) => v.id !== action.payload.id
          );
          Notify('success', "Student's data has been updated");
        } else {
          Notify(
            'error',
            'Error on updating the Class Section Data. Something went wrong'
          );
        }
      })
      .addCase(postSingleClassSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateSingleClassSection.pending, (state) => {
        state.loading = true;
      })
      .addCase(UpdateSingleClassSection.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          console.log('state.updateClassSection0', state);
          state.updateClassSection = state?.updateClassSection?.map((cls) =>
            cls?.id === action.payload?.id ? action.payload : cls
          );
          Notify('success', "Student's data has been updated");
        } else {
          Notify(
            'error',
            'Error on updating the Class Section Data. Something went wrong'
          );
        }
      })
      .addCase(UpdateSingleClassSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(deleteStudent.fulfilled, (state, action) => {
        console.log('update data payload', action.payload);
        state.loading = false;
        if (action.payload) {
          state.students = state.students.filter(
            (i) => i.id !== action.payload
          );
          Notify('success', 'Student has been deleted');
        } else {
          Notify('error', 'Something went wrong');
        }
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createdStudent.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state on pending
      })
      .addCase(createdStudent.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.students = [...state.students, action.payload];
          Notify('success', 'Student has been added');
        } else {
          Notify('error', 'Error on adding the Student. Something went wrong');
        }
        state.error = null;
      })
      .addCase(createdStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateStudent.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        state.loading = false;
        // state.students = state.students.map((student) => {
        //   if (student.prospectiveStudent.id === action.payload.id) {
        //     let student = {
        //       prospectiveStudent: action.payload,
        //     };
        //     return student;
        //   } else {
        //     return student;
        //   }
        // : student
        if (action.payload) {
          state.students = state.students.map((cls) =>
            cls.id === action.payload.id ? action.payload : cls
          );
          Notify('success', "Student's data has been updated");
        } else {
          Notify(
            'error',
            "Error on updating the Student's Data. Something went wrong"
          );
        }
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(AddStudentsFile.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.studentResponse = ""
      })
      .addCase(AddStudentsFile.fulfilled, (state, action) => {
        state.loading = false;
        Notify("success", "Students Data Uploaded");
        state.studentResponse = "OK"
      })
      .addCase(AddStudentsFile.rejected, (state, action) => {
        state.loading = false;
        Notify("error", "Failed File Uploading");
        state.error = action.error.message;
        state.studentResponse = ""
      });
  },
});

// Action creators are generated for each case reducer function
export const { addStudent, updateStudentdata, removeStudent } =
  studentSlice.actions;

export const allStudents = (state) => state.allStudents;

export default studentSlice.reducer;
