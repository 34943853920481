import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import {
  allTimetable,
  DeleteTimetableData,
  TimetableData,
} from "../../../redux/slice/academics/timetableSlice";
import { MdDelete, MdModeEdit, MdOutlineViewAgenda } from "react-icons/md";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import {
  allCourses,
  courseData,
} from "../../../redux/slice/academics/CoursesSlice";
import FilterInput from "../../../components/FilterInputComponent";
import Loader from "../../../components/loader/Loader";
import { weekDaysWithId } from "../../../db/fields-data";
import {
  allClassroom,
  ClassRoomFetch,
} from "../../../redux/slice/academics/classroomSlice";

export default function TimeTableView(props) {
  const dispatch = useDispatch();
  const { currentCampus } = useSelector(allCampus);
  const { singleClasses } = useSelector(allClasses);
  const { course } = useSelector(allCourses);
  const { classroomsData } = useSelector(allClassroom);
  const { timetable, loading, totalPages } = useSelector(allTimetable);
  const [appliedFilters, setAppliedFilters] = useState({
    currentClass: "0",
    currentRoom: "0",
    course: "0",
    day: "1",
  });
  const [coursesArray, setCoursesArray] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();

  //pagination working
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  // function for paginate buttons
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        ClassId:
          appliedFilters.currentClass != "0"
            ? Number(appliedFilters.currentClass)
            : null,
        // RoomId:
        //   appliedFilters.currentRoom != "0"
        //     ? Number(appliedFilters.currentRoom)
        //     : null,
        CourseId:
          appliedFilters.course != "0" && appliedFilters.currentClass != "0"
            ? Number(appliedFilters.course)
            : null,
        Day: appliedFilters.day != "" ? Number(appliedFilters.day) : null,
      };
      dispatch(TimetableData(data));
    } else {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        CampusId: Number(currentCampus),
        ClassId:
          appliedFilters.currentClass != "0"
            ? Number(appliedFilters.currentClass)
            : null,
        RoomId:
          appliedFilters.currentRoom != "0"
            ? Number(appliedFilters.currentRoom)
            : null,
        CourseId:
          appliedFilters.course != "0" && appliedFilters.currentClass != "0"
            ? Number(appliedFilters.course)
            : null,
        Day: appliedFilters.day != "" ? Number(appliedFilters.day) : null,
      };
      dispatch(TimetableData(data));
    }
  };
  // End of Pagination Working

  useEffect(() => {
    if (currentCampus) {
      let data = {
        CampusId: Number(currentCampus),
      };
      dispatch(ClassRoomFetch(data));
      dispatch(ClassesDataSingle(currentCampus));
    }
    if (
      appliedFilters.course != "0" ||
      appliedFilters.currentClass != "0" ||
      appliedFilters.currentRoom != "0"
    )
      setAppliedFilters((prev) => ({
        ...prev,
        course: "0",
        currentClass: "0",
        currentRoom: "0",
      }));
  }, [currentCampus]);

  // useEffect(() => {
  //   if (currentCampus == 0) {
  //     let data = {};
  //     dispatch(ClassRoomFetch(data));
  //   } else {
  //     let data = {
  //       CampusId: Number(currentCampus),
  //     };
  //     dispatch(ClassRoomFetch(data));
  //   }
  // }, [currentCampus]);

  useEffect(() => {
    if (appliedFilters.course != "0") {
      setAppliedFilters((prev) => ({
        ...prev,
        course: "0",
      }));
    }
    let data = {
      CampusId: !currentCampus == "0" ? Number(currentCampus) : null,
      ClassId:
        appliedFilters.currentClass != "0"
          ? Number(appliedFilters.currentClass)
          : null,
    };
    if (appliedFilters?.currentClass) {
      dispatch(courseData(data));
    }
  }, [appliedFilters.currentClass]);

  useEffect(() => {
    setCurrentPage(1);
    let data = {};
    if (currentCampus == 0) {
      let data = {
        pageSize: itemsPerPage,
        // pageNumber: currentPage,
        pageNumber: 1,
        ClassId:
          appliedFilters.currentClass != "0"
            ? Number(appliedFilters.currentClass)
            : null,
        // RoomId:
        //   appliedFilters.currentRoom != "0"
        //     ? Number(appliedFilters.currentRoom)
        //     : null,
        CourseId:
          appliedFilters.course != "0" && appliedFilters.currentClass != "0"
            ? Number(appliedFilters.course)
            : null,
        Day: appliedFilters.day != "" ? Number(appliedFilters.day) : null,
      };
      dispatch(TimetableData(data));
    } else {
      data = {
        pageSize: itemsPerPage,
        // pageNumber: currentPage,
        pageNumber: 1,
        CampusId: Number(currentCampus),
        ClassId:
          appliedFilters.currentClass != "0"
            ? Number(appliedFilters.currentClass)
            : null,
        RoomId:
          appliedFilters.currentRoom != "0"
            ? Number(appliedFilters.currentRoom)
            : null,
        CourseId:
          appliedFilters.course != "0" && appliedFilters.currentClass != "0"
            ? Number(appliedFilters.course)
            : null,
        Day: appliedFilters.day != "" ? Number(appliedFilters.day) : null,
      };
      dispatch(TimetableData(data));
    }
  }, [currentCampus, itemsPerPage, appliedFilters]);

  // useEffect(() => {
  //   const transformedArray = course.map((item) => ({
  //     value: item?.id,
  //     label: item?.name,
  //   }));
  //   setCoursesArray(transformedArray);
  // }, [course]);

  useEffect(() => {
    console.log("course", course);
    console.log("singleClasses", singleClasses);
  }, [course, singleClasses]);

  const handleView = (time) => {
    console.log("all view", time);
  };

  const handleUpdate = (time) => {
    props.forUpdate(time);
  };

  const handleDelete = () => {
    console.log("delete time id", deleteId);
    dispatch(DeleteTimetableData(Number(deleteId)));
    setDeleteModal(false);
  };

  return (
    <React.Fragment>
      <div>
        <div className="row">
          <div className="col col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title fs-5 mb-4 bold text-uppercase font-weight-bold">
                  Timetable
                </h5>
                <div className="my-2 w-100 d-flex">
                  <div className="w-75 d-flex">
                    <FilterInput
                      options={singleClasses}
                      value={appliedFilters.currentClass}
                      label="All Classes"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          currentClass: e.target.value,
                        }));
                      }}
                    />
                    <FilterInput
                      options={course}
                      value={appliedFilters.course}
                      label="All Courses"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          course: e.target.value,
                        }));
                      }}
                    />
                    <FilterInput
                      options={classroomsData}
                      value={appliedFilters.currentRoom}
                      label="All Rooms"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          currentRoom: e.target.value,
                        }));
                      }}
                    />
                    <FilterInput
                      options={weekDaysWithId}
                      value={appliedFilters.day}
                      label="All Days"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          day: e.target.value,
                        }));
                      }}
                    />
                    {/* <div className="col-sm-6">
                      <Select
                        options={coursesArray}
                        value={coursesArray.find(
                          (option) => option.value === appliedFilters.course
                        )}
                        onChange={(selectedOption) => {
                          setAppliedFilters((prev) => ({
                            ...prev,
                            course: selectedOption ? selectedOption.value : "0",
                          }));
                        }}
                        placeholder="Select Course"
                        isClearable
                        className="w-50 ml-2 border-filter rounded mx-1"
                        styles={reactSelectStyle}
                      />
                    </div> */}
                  </div>
                  <select
                    className="form-control w-25"
                    style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                    value={itemsPerPage}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setItemsPerPage(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Items per page
                    </option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
                <div className="table-responsive mt-4">
                  {loading ? (
                    <span className="m-4">
                      <Loader />
                    </span>
                  ) : (
                    <Table className="table-centered">
                      <thead>
                        <tr>
                          <th scope="col">Campus</th>
                          <th scope="col">Class</th>
                          <th scope="col">Room</th>
                          <th scope="col">Course</th>
                          <th scope="col">Day</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">End Time</th>
                          <th scope="Action">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timetable.map((row, index) => (
                          <tr key={index}>
                            <td>{row.campusName}</td>
                            <td>{row.className}</td>
                            <td>{row.roomName}</td>
                            <td>{row.courseName}</td>
                            <td>{row.day}</td>
                            <td>{row.startTime}</td>
                            <td>{row.endTime}</td>
                            <td className="d-flex">
                              {/* <MdOutlineViewAgenda
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleView(row)}
                                size={18}
                              /> */}
                              <MdModeEdit
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleUpdate(row)}
                                size={18}
                              />
                              <MdDelete
                                style={{ cursor: "pointer" }}
                                className="text-danger"
                                onClick={() => {
                                  setDeleteModal(true);
                                  setDeleteId(row.id);
                                }}
                                size={18}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
                <div className="mt-3">
                  <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <IoArrowBackCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages).keys()].map((number) => (
                      <PaginationItem
                        key={number}
                        active={number + 1 === currentPage}
                      >
                        <PaginationLink onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink
                        next
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <IoArrowForwardCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={deleteModal} centered>
          <Card>
            <CardBody className="mt-2">
              <h5 style={{ fontWeight: "normal" }}>
                Are You Sure. You want to Delete ?
              </h5>
            </CardBody>
            <CardFooter>
              <button
                className="btn btn-danger me-2"
                onClick={() => handleDelete()}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={() => setDeleteModal(false)}
              >
                No
              </button>
            </CardFooter>
          </Card>
        </Modal>
      </div>
    </React.Fragment>
  );
}
