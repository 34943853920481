import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Modal,
  CardFooter,
  CardHeader,
} from "reactstrap";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import {
  allstudentAttendance,
  singleStudentAttendanceData,
  studentAttendanceData,
} from "../../../redux/slice/students/studentAttendanceSlice";
import { MdDelete, MdModeEdit, MdOutlineViewAgenda } from "react-icons/md";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import {
  allCourses,
  courseData,
} from "../../../redux/slice/academics/CoursesSlice";
import FilterInput from "../../../components/FilterInputComponent";
import { InputField } from "../../../components/input-fields";
import Notify from "../../../components/Notify-toast";
import Loader from "../../../components/loader/Loader";

export default function AttandanceTable(props) {
  const dispatch = useDispatch();
  const { currentCampus } = useSelector(allCampus);
  const { singleClasses } = useSelector(allClasses);
  const { course } = useSelector(allCourses);
  const [appliedFilters, setAppliedFilters] = useState({
    currentClass: "0",
    course: "0",
  });
  const [timeFilter, setTimeFilter] = useState({
    FromDate: "",
    ToDate: "",
  });
  const [coursesArray, setCoursesArray] = useState([]);
  const [stdAttView, setStdAttView] = useState(false);
  const [stdAttViewData, setStdAttViewData] = useState({});
  const { studentsAttendanceList, loading, totalPages } =
    useSelector(allstudentAttendance);

  //pagination working
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  //calculate number of pages and items on page
  // const totalPages = Math.ceil(studentsAttendanceList?.length / itemsPerPage || 0);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const reversedList = [...(studentsAttendanceList || [])].reverse();
  const currentList =
    reversedList?.slice(indexOfFirstItem, indexOfLastItem) || [];
  // function for paginate buttons
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
      };
      dispatch(studentAttendanceData(data));
    } else {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        CampusId: Number(currentCampus),
        ClassId:
          appliedFilters.currentClass != "0"
            ? Number(appliedFilters.currentClass)
            : null,
        // CourseId:
        //   !appliedFilters.course == "0" ? Number(appliedFilters.course) : null,
      };
      dispatch(studentAttendanceData(data));
    }
  };
  // End of Pagination Working

  const options = { day: "2-digit", month: "short", year: "numeric" };
  const currentDate = new Date().toLocaleDateString("en-US", options);
  const formatDateCustom = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date).replace(",", "");
  };

  useEffect(() => {
    if (studentsAttendanceList.length > 0 && stdAttView) {
      let filterStudentAllAttendence = studentsAttendanceList.find(
        (student) => student.id === stdAttViewData.id
      );
      setStdAttViewData(filterStudentAllAttendence);
      console.log("filter", filterStudentAllAttendence);
    }
  }, [studentsAttendanceList]);

  useEffect(() => {
    let data = {};
    setCurrentPage(1);
    if (currentCampus == 0) {
      let data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
      };
      dispatch(studentAttendanceData(data));
    } else {
      data = {
        pageSize: itemsPerPage,
        pageNumber: 1,
        CampusId: Number(currentCampus),
        ClassId:
          appliedFilters.currentClass != "0"
            ? Number(appliedFilters.currentClass)
            : null,
        // CourseId:
        //   !appliedFilters.course == "0" ? Number(appliedFilters.course) : null,
      };
      dispatch(studentAttendanceData(data));
    }
    dispatch(ClassesDataSingle(currentCampus));
  }, [currentCampus, itemsPerPage, appliedFilters.currentClass]);

  useEffect(() => {
    let data = {
      CampusId: !currentCampus == "0" ? Number(currentCampus) : null,
      ClassId:
        !appliedFilters.currentClass == "0"
          ? Number(appliedFilters.currentClass)
          : null,
    };
    dispatch(courseData(data));
  }, [appliedFilters.currentClass]);

  useEffect(() => {
    const transformedArray = course.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    setCoursesArray(transformedArray);
  }, [course]);

  // useEffect(() => {
  //   console.log("course", course);
  //   console.log("singleClasses", singleClasses);
  // }, [course, singleClasses]);

  const handleView = (att) => {
    setStdAttView(true);
    setStdAttViewData(att);
    console.log("all view", att);
  };

  const handleUpdate = (att) => {
    props.forUpdate(att);
  };

  const handleDelete = (id) => {
    console.log("delete attendance id", id);
  };

  const handleChangeTimeFilter = (fieldName, value) => {
    setTimeFilter((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleStudentTimeFilter = () => {
    if (timeFilter.FromDate && timeFilter.ToDate) {
      let timeFilterData = {
        StudentId: stdAttViewData.id,
        FromDate: timeFilter.FromDate,
        ToDate: timeFilter.ToDate,
      };
      dispatch(singleStudentAttendanceData(timeFilterData));
    } else {
      Notify("error", "Please Select Date filters");
    }
  };

  return (
    <React.Fragment>
      <div>
        <div className="row">
          <div className="col col-lg-12">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title fs-5 mb-4 bold text-uppercase font-weight-bold">
                  Student Attendance
                </h5>
                <div className="my-2 w-100 d-flex">
                  <div className="w-75 d-flex">
                    <FilterInput
                      options={singleClasses}
                      value={appliedFilters.currentClass}
                      label="All Classes"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          currentClass: e.target.value,
                        }));
                      }}
                    />
                    {/* <FilterInput
                      options={course}
                      value={appliedFilters.course}
                      label="All Courses"
                      onChange={(e) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          status: e.target.value,
                        }));
                      }}
                    /> */}
                    {/* <Select
                      options={coursesArray}
                      value={coursesArray.find(
                        (option) => option.value === appliedFilters.course
                      )}
                      onChange={(selectedOption) => {
                        setAppliedFilters((prev) => ({
                          ...prev,
                          course: selectedOption ? selectedOption.value : "0",
                        }));
                      }}
                      placeholder="Select Course"
                      isClearable
                      className="w-50 ml-2 border-filter rounded mx-1"
                    /> */}
                  </div>
                  <select
                    className="form-control w-25"
                    style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                    value={itemsPerPage}
                    onChange={(e) => {
                      setCurrentPage(1);
                      setItemsPerPage(e.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Items per page
                    </option>
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={15}>15</option>
                    <option value={20}>20</option>
                  </select>
                </div>
                <div className="table-responsive mt-4">
                  {loading ? (
                    <span className="m-4">
                      <Loader />
                    </span>
                  ) : (
                    <Table className="table-centered">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Room</th>
                          <th scope="col">Last Time In</th>
                          <th scope="col">Last Time Out</th>
                          <th scope="Action">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {studentsAttendanceList.map((atd, index) => (
                          <tr key={index}>
                            <td>{atd.name}</td>
                            <td>
                              {
                                atd?.attendences[atd?.attendences.length - 1]
                                  .roomName
                              }
                            </td>
                            <td>
                              {formatDateCustom(
                                atd?.attendences[atd?.attendences.length - 1]
                                  .timeIn
                              )}
                            </td>
                            <td>
                              {formatDateCustom(
                                atd?.attendences[atd?.attendences.length - 1]
                                  .timeOut
                              )}
                            </td>
                            <td className="d-flex">
                              <MdOutlineViewAgenda
                                style={{ cursor: "pointer" }}
                                className="me-2 text-primary"
                                onClick={() => handleView(atd)}
                                size={18}
                              />
                              {/* <MdModeEdit
                              style={{ cursor: "pointer" }}
                              className="me-2 text-primary"
                              onClick={() => handleUpdate(atd)}
                              size={18}
                            />
                            <MdDelete
                              style={{ cursor: "pointer" }}
                              className="text-danger"
                              onClick={() => handleDelete(atd.id)}
                              size={18}
                            /> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
                <div className="mt-3">
                  <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => paginate(currentPage - 1)}
                      >
                        <IoArrowBackCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                    {[...Array(totalPages).keys()].map((number) => (
                      <PaginationItem
                        key={number}
                        active={number + 1 === currentPage}
                      >
                        <PaginationLink onClick={() => paginate(number + 1)}>
                          {number + 1}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink
                        next
                        onClick={() => paginate(currentPage + 1)}
                      >
                        <IoArrowForwardCircle size={25} />
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={stdAttView} centered size="lg">
          <Card>
            <CardHeader className="mt-2">
              <h5 style={{ fontWeight: "normal" }} className="mb-4">
                All Attendances of{" "}
                <span className="poppins-bold">{stdAttViewData.name}</span>
              </h5>
              <div className="row mb-4 d-flex align-items-end">
                <InputField
                  className="col-sm-12 col-md-5 col-lg-5 my-2"
                  label="From Date"
                  compulsory={false}
                  disabled={false}
                  type="datetime-local"
                  value={timeFilter.FromDate}
                  placeholder="Attendance From"
                  onChange={(e) =>
                    handleChangeTimeFilter("FromDate", e.target.value)
                  }
                />
                <InputField
                  className="col-sm-12 col-md-5 col-lg-5 my-2"
                  label="To Date"
                  compulsory={false}
                  disabled={false}
                  type="datetime-local"
                  value={timeFilter.ToDate}
                  placeholder="Attendance to Date"
                  onChange={(e) =>
                    handleChangeTimeFilter("ToDate", e.target.value)
                  }
                />
                <button
                  className=" btn btn-primary col-sm-12 col-md-2 col-lg-2 my-2"
                  style={{ height: "40px" }}
                  onClick={handleStudentTimeFilter}
                >
                  Filter
                </button>
              </div>
            </CardHeader>
            <CardBody
              className="mt-2"
              style={{ height: "300px", overflowY: "auto" }}
            >
              {
                <div className="table-responsive">
                  <Table className="table-centered">
                    <thead>
                      <tr>
                        <th scope="col" className="text-nowrap">
                          #
                        </th>
                        <th scope="col" className="text-nowrap">
                          Room
                        </th>
                        <th scope="col" className="text-nowrap">
                          Time In
                        </th>
                        <th scope="col" className="text-nowrap">
                          Time Out
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {stdAttViewData?.attendences?.length > 0
                        ? stdAttViewData?.attendences.map((attend, index) => (
                            <tr key={index}>
                              <td className="text-nowrap">{index}</td>
                              <td className="text-nowrap">
                                {attend?.roomName ? attend?.roomName : "---"}
                              </td>
                              <td className="text-nowrap">
                                {attend?.timeIn
                                  ? formatDateCustom(attend.timeIn)
                                  : "---"}
                              </td>
                              <td className="text-nowrap">
                                {attend?.timeOut
                                  ? formatDateCustom(attend.timeOut)
                                  : "---"}
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              }
            </CardBody>
            <CardFooter>
              {/* <button
              className="btn btn-danger me-2"
              onClick={() => deleteClassesData(stdid)}
            >
              Delete
            </button> */}
              <button
                className="btn btn-danger"
                onClick={() => setStdAttView(false)}
              >
                Cancel
              </button>
            </CardFooter>
          </Card>
        </Modal>
      </div>
    </React.Fragment>
  );
}
