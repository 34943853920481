import { createSlice } from "@reduxjs/toolkit";


// const staffLeave = [
//   {
//     staffId: 3224,
//     name: "John",
//     reason: "Personal Health",
//     fromDate: "08-04-2023",
//     toDate: "15-04-2023",
//     positionLevel: "Developer",
//     status: "Rejected",
//   },
//   {
//     staffId: 3574,
//     name: "Michael",
//     reason: "Childcare Needs",
//     fromDate: "08-05-2023",
//     toDate: "20-05-2023",
//     positionLevel: "Designer",
//     status: "Approved",
//   },
//   {
//     staffId: 8624,
//     name: "Jennifer",
//     reason: "Mental Health",
//     fromDate: "02-08-2023",
//     toDate: "08-08-2023",
//     positionLevel: "Analyst",
//     status: "No Decision",
//   },
//   {
//     staffId: 5467,
//     name: "Sarah",
//     reason: "Travel Plans",
//     fromDate: "02-06-2023",
//     toDate: "05-06-2023",
//     positionLevel: "Manager",
//     status: "Rejected",
//   },
//   {
//     staffId: 8355,
//     name: "David",
//     reason: "Car Troubles",
//     fromDate: "08-03-2023",
//     toDate: "11-03-2023",
//     positionLevel: "Designer",
//     status: "Approved",
//   },
// ]

const initialState = {
  staffLeave:[
    {
      staffId: 3224,
      name: "John",
      reason: "Personal Health",
      fromDate: "08-04-2023",
      toDate: "15-04-2023",
      positionLevel: "Developer",
      status: "Rejected",
    },
    {
      staffId: 3574,
      name: "Michael",
      reason: "Childcare Needs",
      fromDate: "08-05-2023",
      toDate: "20-05-2023",
      positionLevel: "Designer",
      status: "Approved",
    },
    {
      staffId: 8624,
      name: "Jennifer",
      reason: "Mental Health",
      fromDate: "02-08-2023",
      toDate: "08-08-2023",
      positionLevel: "Analyst",
      status: "No Decision",
    },
    {
      staffId: 5467,
      name: "Sarah",
      reason: "Travel Plans",
      fromDate: "02-06-2023",
      toDate: "05-06-2023",
      positionLevel: "Manager",
      status: "Rejected",
    },
    {
      staffId: 8355,
      name: "David",
      reason: "Car Troubles",
      fromDate: "08-03-2023",
      toDate: "11-03-2023",
      positionLevel: "Designer",
      status: "Approved",
    },
  ]
};

export const leaveSlice = createSlice({
  name: "staffLeave",
  initialState,
  reducers: {
    // addStaff: (state, action) => {

    //   console.log("action.payload :--- ",action.payload);
    //   state.staff.push(action.payload);
    // },
    // setSelectedStaff: (state, action) => {

    //   console.log("setSelectedStaff reducer",action.payload);
    //   state.selectedStaff = action.payload
    // },
    // setSelectedStaffUpdate: (state, action) => {
      
    //   console.log("setSelectedStaffUpdate reducer",action.payload);
    //   state.selectedStaffUpdate = action.payload
    // },
    deleteSelectedLeave: (state, action) => {

      const {staffId} = action.payload;
      console.log(staffId,"selectedId");

      const filteredStaff = state.staffLeave.filter(staff => staff.staffId !== staffId)
      console.log("filteredStaff::: in deleteSelectedLeave ", filteredStaff);
      state.staffLeave = filteredStaff
    },
    // updateStaff(state, action) {
    //   const updatedStaff = action.payload
    //   console.log(updatedStaff,"<<<:: updatedStaff");
    //   const {employmentStatusFormData} = updatedStaff;
    //   console.log("employmentStatusFormData in update STAFF REDUX::", employmentStatusFormData);
    //   const {staffId} = employmentStatusFormData;
    //   const index = state.staff.findIndex(staffMember => staffMember.employmentStatusFormData.staffId === staffId);
    //   if (index !== -1) {
    //     state.staff[index] = updatedStaff;
    //   }
    //   console.log("state.staff after STAFF_UPDATE >>> ", state.staff);
    //   // console.log("employmentStatusFormData in update STAFF REDUX::", employmentStatusFormData);
    // }

  },
});

// Action creators are generated for each case reducer function
export const { addStaff , setSelectedStaff, setStaffState, setSelectedStaffUpdate, deleteSelectedLeave, updateStaff  } = leaveSlice.actions;

export const staffLeaves = (state) => state.allStaffLeaves;

// export const selectSelectedStaff = (state) => state.allStaffLeaves.selectedStaff;

// export const updateSelectedStaff = (state) => state.allStaffLeaves.selectedStaffUpdate;

export default leaveSlice.reducer;
