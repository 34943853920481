import React, { useEffect, useState } from 'react';
import {
  InputField,
  SelectField,
  InputImageField,
} from '../../../../components/input-fields';
import { Link } from 'react-router-dom';

export default function PersonalInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  personalInfo,
  forView,
  handleFileRemove,
}) {
  let infoObject = 'personal';

  useEffect(() => {
    const keysToExclude = ['birthCertificate', 'b_form'];
    const isAnyKeyEmpty = Object.keys(personalInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return personalInfo[key] === '' || personalInfo[key] === null;
      });
    console.log('is any key empty', isAnyKeyEmpty);
    if (!isAnyKeyEmpty) {
      updateTabComplete(true);
    } else {
      if (tabs[0].complete) {
        updateTabComplete(false);
      }
    }
  }, [personalInfo]);

  return (
    <React.Fragment>
      <form>
        <h6 className="mt-4 poppins-medium">Personal Details</h6>
        <div className="row">
          <InputField
            label="Name"
            compulsory={true}
            disabled={forView}
            type="text"
            value={personalInfo.name}
            placeholder="Type Name"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'name', e.target.value)
            }
          />
          <InputField
            label="Date of Birth"
            compulsory={true}
            disabled={forView}
            type="date"
            value={
              personalInfo.dateOfBirth
                ? new Date(personalInfo.dateOfBirth).toISOString().split('T')[0]
                : ''
            }
            placeholder="Type Date of Birth"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'dateOfBirth', e.target.value)
            }
          />
          {/* <InputField
            label="Age"
            compulsory={true}
            type="number"
            value={personalInfo.age}
            placeholder="Student Age"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'age', e.target.value)
            }
          /> */}
          <InputField
            label="B Form / CNIC"
            compulsory={true}
            disabled={forView}
            type="text"
            value={personalInfo.bForm}
            placeholder="Type B Form"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'bForm', e.target.value)
            }
          />
          <SelectField
            label="Nationality"
            compulsory={true}
            disabled={forView}
            value={personalInfo.nationality}
            selectOptions={['Pakistani', 'American', 'British']}
            onChange={(e) =>
              handleChangeInfo(infoObject, 'nationality', e.target.value)
            }
          />
          <SelectField
            label="Gender"
            compulsory={true}
            disabled={forView}
            value={personalInfo.gender}
            selectOptions={['Male', 'Female']}
            onChange={(e) =>
              handleChangeInfo(infoObject, 'gender', e.target.value)
            }
          />
          <InputField
            label="Permanent Address"
            compulsory={true}
            type="text"
            disabled={forView}
            value={personalInfo.permanentAddress}
            placeholder="Type Permanent Address"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'permanentAddress', e.target.value)
            }
          />
          <InputField
            label="Residential Address"
            compulsory={true}
            disabled={forView}
            type="text"
            value={personalInfo.residentialAddress}
            placeholder="Type Residential Address"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'residentialAddress', e.target.value)
            }
          />
          <InputField
            label="City"
            disabled={forView}
            compulsory={true}
            value={personalInfo.city}
            placeholder="Type City"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'city', e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Area"
            compulsory={true}
            value={personalInfo.area}
            placeholder="Type Area"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'area', e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mobile Number"
            compulsory={true}
            type="tel"
            value={personalInfo.mobileNumber}
            placeholder="Type Mobile Number"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'mobileNumber', e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Email Address"
            compulsory={true}
            type="email"
            value={personalInfo.emailAddress}
            placeholder="Type Email Address"
            onChange={(e) =>
              handleChangeInfo(infoObject, 'emailAddress', e.target.value)
            }
          />
          <InputImageField
            disabled={forView}
            label="Passport Size Picture"
            compulsory={true}
            value={personalInfo.passportSizePicture}
            type="file"
            placeholder="Upload Passport Size Picture"
            handleFileRemove={() =>
              handleFileRemove(infoObject, 'passportSizePicture')
            }
            onChange={(e) =>
              handleFileChange(infoObject, 'passportSizePicture', e)
            }
          />
          <InputImageField
            disabled={forView}
            label="Birth Certificate"
            compulsory={false}
            value={personalInfo.birthCertificate}
            type="file"
            placeholder="Upload Birth Certificate"
            handleFileRemove={() =>
              handleFileRemove(infoObject, 'birthCertificate')
            }
            onChange={(e) =>
              handleFileChange(infoObject, 'birthCertificate', e)
            }
          />
          <InputImageField
            disabled={forView}
            label="B-Form"
            compulsory={false}
            value={personalInfo.b_form}
            type="file"
            placeholder="Upload B-Form"
            handleFileRemove={() => handleFileRemove(infoObject, 'b_form')}
            onChange={(e) => handleFileChange(infoObject, 'b_form', e)}
          />
        </div>
      </form>
    </React.Fragment>
  );
}
