import { axiosInstance, instanceAuth } from "../axiosConfig";

export const fetchTimetable = async (data) => {
  try {
    console.log("Fetch time table in helper", data);
    const response = await instanceAuth.get("CourseTimeTable", {
      params: {
        ...data,
      },
    });
    return response;
  } catch (error) {
    console.log("error fetching time table data...", error);
    throw error;
  }
};

export const createTimetable = async (data) => {
  console.log("Add time table in helper", data);
  try {
    const response = await instanceAuth.post("CourseTimeTable", data);
    return response;
  } catch (error) {
    console.log("error creating time table...", error);
    throw error;
  }
};

export const UpdateTimetable = async ({ id: id, bodyData: bodyData }) => {
  try {
    console.log(`Update Time Table in helper with id: ${id}`, bodyData);
    const response = await instanceAuth.put(`CourseTimeTable/${id}`, bodyData);
    return response;
  } catch (error) {
    console.log("error updating time table...", error);
    throw error;
  }
};

export const deleteTimetable = async (id) => {
  try {
    console.log(`Delete Time Table in helper with id: ${id}`);
    const response = await instanceAuth.delete(`CourseTimeTable/${id}`);
    return response;
  } catch (error) {
    console.log("error deleting time table...", error);
    throw error;
  }
};
