const subjectsData = [
  "ACCOUNTING",
  "ADDITIONAL MATHEMATICS",
  "ARABIC",
  "ART & DESIGN",
  "BIBLICAL STUDIES",
  "BIOLOGY",
  "BUSINESS STUDIES",
  "CHEMISTRY",
  "COMMERCE",
  "COMPUTER SCIENCE",
  "COMPUTER STUDIES",
  "DESIGN & TECHNOLOGY",
  "ECONOMICS",
  "ENGLISH LANGUAGE",
  "ENVIRONMENTAL MANAGEMENT",
  "FASHION & TEXTILES",
  "FOOD AND NUTRITION",
  "FRENCH",
  "FURTHER PURE MATHEMATICS",
  "GERMAN",
  "GLOBAL PERSPECTIVES",
  "HINDUISM",
  "INFORMATION AND COMMUNICATION TECHNOLOGY",
  "ISLAMIYAT",
  "LITERATURE IN ENGLISH",
  "MATHEMATICS",
  "MEDIA STUDIES",
  "OTHER",
  "PAKISTAN STUDIES",
  "PHYSICS",
  "RELIGIOUS STUDIES",
  "SOCIOLOGY",
  "STATISTICS",
  "URDU",
  "WORLD HISTORY",
];

const sessionsData = [
  "Oct/Nov 2022",
  "May/June 2023",
  "Oct/Nov 2023",
  "May/June 2024",
];

const gradesData = ["Pending", "A*", "A", "B", "C", "D", "E", "U", "X"];

const subjectsOffered = [
  "Accounting",
  "Art and Design",
  "Biology",
  "Business",
  "Chemistry",
  "Computer Science",
  "Economics",
  "English Language",
  "Further Mathematics",
  "History",
  "Law",
  "Literature in English",
  "Mathematics",
  "Media Studies",
  "Physics",
  "Psychology",
  "Sociology",
  "Urdu (Accelerated)",
];

const weekDaysWithId = [
  { id: 0, name: "Sunday" },
  { id: 1, name: "Monday" },
  { id: 2, name: "Tuesday" },
  { id: 3, name: "Wednesday" },
  { id: 4, name: "Thursday" },
  { id: 5, name: "Friday" },
  { id: 6, name: "Saturday" },
];

export {
  subjectsData,
  sessionsData,
  gradesData,
  subjectsOffered,
  weekDaysWithId,
};
