import React from "react";
import { Row, Col } from "reactstrap";
import {
  teachingDepartments,
  teachingDesignations,
  nonTeachingDepartments,
} from "../../../../common/data/optionsData";

const BASE_URL =
  window.location.hostname === "localhost"
    ? process.env.REACT_APP_STAFF_API_URL_DEVELOPMENT
    : process.env.REACT_APP_API_URL_STAGING;

function DynamicForm({
  fields,
  formData,
  teachingDepartments,
  nonTeachingDepartments,
  updateStaffData,
  handleChangeInput,
  handleFileChange,
}) {
  console.log(
    fields.map((f) => updateStaffData[f.name]),
    "fieldss"
  );
  console.log(updateStaffData, "updateStaffData");
  return (
    <>
      <Row>
        {fields.map((field, fieldIndex) => (
          <Col md="6" key={fieldIndex}>
            <div className="mb-3">
              <label>{field.label}</label>
              {field.type === "select" ? (
                <select
                  name={field.name}
                  value={updateStaffData[field.name]}
                  onChange={handleChangeInput}
                  className="form-control"
                >
                  <option value={""}>{field.label}</option>
                  //! THIS IS FOR TEACHING.....
                  {(field.name === "department" &&
                    updateStaffData.departmentCategory === "Teaching") ||
                  (field.name === "department" &&
                    updateStaffData.departmentCategory === 1)
                    ? teachingDepartments?.map((option, index) => (
                        <option key={option.id} value={option.id}>
                          {/* {option.opt} */}
                          {option.opt}
                        </option>
                      ))
                    : //! THIS IS FOR NON - TEACHING.....
                    (field.name === "department" &&
                        updateStaffData.departmentCategory ===
                          "Non Teaching") ||
                      (field.name === "department" &&
                        updateStaffData.departmentCategory === 2)
                    ? nonTeachingDepartments?.map((option, index) => (
                        <option key={option.id} value={option.id}>
                          {/* {option.opt} */}
                          {option.opt}
                        </option>
                      ))
                    : //! THIS IS FOR OTHER FIELDS .....
                      field.options.map((option, index) => (
                        <option key={option.id} value={option.id}>
                          {option.opt}
                        </option>
                      ))}
                </select>
              ) : field.type === "file" ? (
                <>
                  <input
                    name={field.name}
                    type={field.type}
                    placeholder={field.placeholder}
                    className={`form-control `}
                    id={`validationCustom${fieldIndex}`}
                    // onBlur={() => handleBlur(field.name, field.required)}
                    onChange={handleFileChange}
                    // value={formData[field.name]}
                    required={field?.required}
                  />
                  {updateStaffData[field.name] && (
                    <img
                      src={BASE_URL + "Images" + updateStaffData[field.name]}
                      style={{ margin: 20 }}
                      width={150}
                      height={150}
                    />
                  )}
                </>
              ) : (
                <input
                  name={field.name}
                  type={field.type}
                  placeholder={field.placeholder}
                  className="form-control"
                  id={`validationCustom${fieldIndex}`}
                  onChange={handleChangeInput}
                  value={updateStaffData[field.name]}
                  required={field.required}
                />
              )}
              <div className="invalid-feedback">{field.invalidMessage}</div>
            </div>
          </Col>
        ))}
      </Row>
    </>
  );
}

export default DynamicForm;
