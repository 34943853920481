import { axiosInstance, instanceAuth } from "../axiosConfig";

export const fetchClassData = async (data) => {
  try {
    // console.log("filteration data to fetch class: ", data);
    const response = await instanceAuth.get("Class", {
      params: {
        ...data,
      },
    });
    return response;
  } catch (error) {
    console.log("error fetching class data...", error);
    throw error;
  }
};

export const fetchClassDataSingle = async (data) => {
  try {
    let response;
    if(data){
      response = await instanceAuth.get(`Class?CampusId=${data}`);
    }
    else{
      response = await instanceAuth.get(`Class`);
    }
    return response;
  } catch (error) {
    console.log("error fetching class data...", error);
    throw error;
  }
};

export const classCreate = async (_data) => {
  console.log("class api data in helper", _data);
  try {
    const response = await instanceAuth.post("Class", _data);
    // console.log("api add response", response);
    return response;
  } catch (error) {
    console.log("error creating class...", error);
    throw error;
  }
};

export const classUpdate = async ({ id, classData }) => {
  try {
    // console.log("class update helper..", classData);
    const response = await instanceAuth.put(`Class/${id}`, classData);
    return response;
  } catch (error) {
    console.log("error updating class...", error);
    throw error;
  }
};

export const classDelete = async (id) => {
  try {
    const response = await instanceAuth.delete(`Class/${id}`);
    return response;
  } catch (error) {
    console.log("error deleting class...", error);
    throw error;
  }
};
