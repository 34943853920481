import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  allClasses,
  ClassesDataSingle,
} from "../../../redux/slice/classesSlice";
import { useSelector, useDispatch } from "react-redux";
import { InputField } from "../../../components/input-fields";

import {
  allCourses,
  createCourse,
  updateCourseError,
  updateSingleCourse,
} from "../../../redux/slice/academics/CoursesSlice";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";

const AddCourse = (props) => {
  const { currentCampus } = useSelector(allCampus);
  const { classes, singleClasses } = useSelector(allClasses);
  const { courseError, course } = useSelector(allCourses);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [courseData, setCourseData] = useState({
    name: "",
    code: "",
    classId: "-",
    description: "",
  });

  useEffect(() => {
    console.log("currentCampus in add course", currentCampus);
    if (currentCampus == "0") {
      const data = null;
      dispatch(ClassesDataSingle(data));
    } else {
      const data = currentCampus;
      dispatch(ClassesDataSingle(data));
    }
  }, [currentCampus]);

  useEffect(() => {
    if (courseError === 200) {
      props.switchTab("View");
      dispatch(updateCourseError());
    }
  }, [courseError, dispatch, props]);

  useEffect(() => {
    if (
      props.selectedCourse &&
      Object.keys(props.selectedCourse).length !== 0
    ) {
      setCourseData({
        name: props.selectedCourse.name,
        code: props.selectedCourse.code,
        classId: props.selectedCourse.classId,
        description: props.selectedCourse.description,
      });
    }
  }, [props.selectedCourse]);

  const handleChangeInfo = (fieldName, value) => {
    setCourseData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSave = () => {
    if (
      courseData.name &&
      courseData.code &&
      courseData.classId !== "-" &&
      courseData.description
    ) {
      if (
        props.selectedCourse &&
        Object.keys(props.selectedCourse).length !== 0
      ) {
        dispatch(
          updateSingleCourse({
            id: props?.id,
            courseData: {
              name: courseData?.name,
              code: courseData?.code,
              description: courseData?.description,
              classId: +courseData?.classId,
            },
          })
        );
        props.ModalToggle();
      } else {
        dispatch(
          createCourse({
            name: courseData?.name,
            code: courseData?.code,
            description: courseData?.description,
            classId: +courseData?.classId,
          })
        );
      }
      setError("");
    } else {
      setError("Please fill all the fields");
    }
  };

  const cancel = () => {
    setCourseData({
      name: "",
      code: "",
      description: "",
      classId: 1,
    });
    props.switchTab("View");
  };

  return (
    <div className="col mt-4">
      <div className="card">
        <div className="card-body">
          {error && (
            <p className="poppins-medium fs-5 text-white text-center bg-danger rounded bold">
              {error}
            </p>
          )}
          <h5 className="card-title">{props.header}</h5>
          <form>
            <div className="row my-4">
              <InputField
                label="Course Name:"
                type="text"
                compulsory={true}
                disabled={false}
                placeholder="Enter Course Name"
                value={courseData.name}
                onChange={(e) => handleChangeInfo("name", e.target.value)}
              />
              <InputField
                label="Course Code:"
                type="text"
                compulsory={true}
                disabled={false}
                placeholder="Enter Course Code"
                value={courseData.code}
                onChange={(e) => handleChangeInfo("code", e.target.value)}
              />

              <InputField
                label="Description:"
                type="text"
                compulsory={true}
                disabled={false}
                placeholder="Enter Description"
                value={courseData.description}
                onChange={(e) =>
                  handleChangeInfo("description", e.target.value)
                }
              />
              <div className="col-md-4 my-2">
                <label>
                  Select Class:<span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  value={courseData.classId}
                  onChange={(e) => handleChangeInfo("classId", e.target.value)}
                >
                  <option value="-" disabled>
                    Select Class
                  </option>
                  {singleClasses.map((v, i) => (
                    <option value={v.id} key={i}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {props.selectedCourse &&
            Object.keys(props.selectedCourse).length !== 0 ? null : (
              <Link className="btn btn-danger" onClick={cancel}>
                Cancel
              </Link>
            )}
            <Link className="btn btn-primary mx-1" onClick={handleSave}>
              {props.selectedCourse &&
              Object.keys(props.selectedCourse).length !== 0
                ? "Update"
                : "Add"}
            </Link>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddCourse;
