import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchTimetable,
  createTimetable,
  deleteTimetable,
  UpdateTimetable,
} from "../../../api/Academics/TimetableApiHelper";
import Notify from "../../../components/Notify-toast";

export const TimetableData = createAsyncThunk(
  "Timetable/FetchTimeTable",
  async (data) => {
    try {
      const response = await fetchTimetable(data);
      const _response = {
        data: response?.data,
        status: response?.status,
      };
      return _response;
    } catch (error) {
      Notify("error", "Failed to fetch time table");
      throw Error("Failed to fetch the Timetable");
    }
  }
);
export const CreateTimetableData = createAsyncThunk(
  "Timetable/CreateNewTimeTable",
  async (data) => {
    try {
      const response = await createTimetable(data);
      if (response?.status === 200) {
        Notify("success", "Timetable added successfully.");
        return response.data;
      } else {
        Notify("error", "Failed to create Timetable");
        throw Error("Failed to add Timetable");
      }
    } catch (error) {
      Notify("error", "Failed to create Timetable");
      throw Error("Failed to add Timetable");
    }
  }
);
export const DeleteTimetableData = createAsyncThunk(
  "Timetable/DeleteTimeTable",
  async (id) => {
    try {
      const response = await deleteTimetable(id);
      if (response?.status === 200) {
        Notify("success", "Timetable deleted.");
        return response.data;
      } else {
        Notify("error", "Failed to delete Timetable");
        throw Error("Failed to delete Timetable");
      }
    } catch (error) {
      Notify("error", "Failed to delete Timetable");
      throw Error("Failed to delete Timetable");
    }
  }
);
export const UpdateTimetableData = createAsyncThunk(
  "Timetable/UpdateTimeTable",
  async ({ id: id, bodyData: bodyData }) => {
    try {
      const response = await UpdateTimetable({ id: id, bodyData: bodyData });
      if (response?.status === 200) {
        Notify("success", "Timetable updated.");
        return response.data;
      } else {
        Notify("error", "Failed to update Timetable");
        throw Error("Failed to update Timetable");
      }
    } catch (error) {
      Notify("error", "Failed to update Timetable");
      throw Error("Failed to update Timetable");
    }
  }
);

const initialState = {
  timetable: [],
  totalPages: 1,
  loading: false,
  error: null,
};

export const Timetable = createSlice({
  name: "Timetable",
  initialState,
  reducers: {
    resetTimetableData: (state, action) => {
      state.error = null;
      state.timetable = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(TimetableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(TimetableData.fulfilled, (state, action) => {
        console.log("timetable fetch payload", action.payload.data?.items);
        state.loading = false;
        state.totalPages = action.payload.data?.totalPages;
        state.timetable = action.payload.data?.items;
      })
      .addCase(TimetableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(CreateTimetableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(CreateTimetableData.fulfilled, (state, action) => {
        console.log("time table created", action.payload);
        state.loading = false;
        state.timetable = [...state.timetable, action.payload];
      })
      .addCase(CreateTimetableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(DeleteTimetableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(DeleteTimetableData.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action payload delete timetable", action.payload);
        state.timetable = state.timetable.filter(
          (i) => i.id !== action.payload
        );
      })
      .addCase(DeleteTimetableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateTimetableData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateTimetableData.fulfilled, (state, action) => {
        state.loading = false;
        console.log("action payload update timetable", action.payload);
        if (action.payload) {
          state.timetable = state.timetable.map((class_time) =>
            class_time.id === action.payload.id ? action.payload : class_time
          );
        }
      })
      .addCase(UpdateTimetableData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetTimetableData } = Timetable.actions;

export const allTimetable = (state) => state.allTimetable;

export default Timetable.reducer;
