import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  exam: [
    {
      id: 1,
      courseName: 'Physics',
      passingMarks: '60',
      maxGrace: '5',
      examPattern: 'MCQs',
      date: '15/01/2025',
    },
    {
      id: 2,
      courseName: 'Chemistry',
      passingMarks: '60',
      maxGrace: '5',
      examPattern: 'Descriptive',
      date: '16/01/2025',
    },
    {
      id: 3,
      courseName: 'Chemistry',
      passingMarks: '60',
      maxGrace: '5',
      examPattern: 'Descriptive',
      date: '16/01/2025',
    },
  ],
  quiz: [
    {
      id: 1,
      courseName: 'Physics',
      passingMarks: '60',
      maxGrace: '5',
      examPattern: 'MCQs',
      date: '15/01/2025',
    },
    {
      id: 2,
      courseName: 'Chemistry',
      passingMarks: '60',
      maxGrace: '5',
      examPattern: 'Descriptive',
      date: '16/01/2025',
    },
    {
      id: 3,
      courseName: 'Chemistry',
      passingMarks: '60',
      maxGrace: '5',
      examPattern: 'Descriptive',
      date: '16/01/2025',
    },
  ],
};

export const ExamQuizSlice = createSlice({
  name: 'ExamQuiz',
  initialState,
  reducers: {
    addExam: (state, action) => {
      state.exam.push(action.payload);
    },
    removeExam: (state, action) => {
      return {
        ...state,
        exam: state.exam.filter((i) => i.id !== action.payload),
      };
    },
    addQuiz: (state, action) => {
      state.quiz.push(action.payload);
    },
    removeQuiz: (state, action) => {
      return {
        ...state,
        quiz: state.quiz.filter((i) => i.id !== action.payload),
      };
    },
    updateCourse: (state, action) => {
      const { id, newData } = action.payload;
      const courseToUpdate = state.exam.find((course) => course.id === id);
      if (courseToUpdate) {
        Object.assign(courseToUpdate, newData);
      }
    },
  },
});

export const { addExam, removeExam, updateCourse, addQuiz, removeQuiz } =
  ExamQuizSlice.actions;
export const ExamQuiz = (state) => state.allExamQuiz;

export default ExamQuizSlice.reducer;
