const MaptoStudentStateObj = (student) => {
  console.log("student map", student);
  // console.log('student refernces', student.studentComunityReferences);
  // console.log(
  //   'A level subject data----',
  //   student?.studentAdmissionStatus?.stdAdmSubInfos
  // );
  const reactStudentObj = {
    Id: student?.id,
    personal: {
      name: student?.name,
      dateOfBirth: student?.dateOfBirth,
      bForm: student?.nic,
      nationality: student?.nationality,
      gender: student?.gender,
      permanentAddress: student?.permenantAddress,
      residentialAddress: student?.residentialAddress,
      city: student?.city,
      area: student?.area,
      mobileNumber: student?.phone,
      emailAddress: student?.email,
      passportSizePicture: [student?.studentImageUrl],
      birthCertificate: [student?.birthCertificateImageUrl],
      b_form: [student?.nicImageUrl],
    },
    parents: {
      fatherName: student?.guardians[0]?.name,
      fatherNICNumber: student?.guardians[0]?.nic,
      fatherOccupation: student?.guardians[0]?.occupation,
      fatherOrganization: student?.guardians[0]?.organization,
      fatherEducation: student?.guardians[0]?.education,
      fatherMobileNumber: student?.guardians[0]?.phone,
      fatherEmailAddress: student?.guardians[0]?.email,
      fatherUploadNIC: [student?.guardians[0]?.nicImageUrl],
      fatherUploadPassportPicture: [student?.guardians[0]?.guardianImageUrl],
      motherName: student?.guardians[1]?.name,
      motherNICNumber: student?.guardians[1]?.nic,
      motherOccupation: student?.guardians[1]?.occupation,
      motherOrganization: student?.guardians[1]?.organization,
      motherEducation: student?.guardians[1]?.education,
      motherMobileNumber: student?.guardians[1]?.phone,
      motherEmailAddress: student?.guardians[1]?.email,
      motherUploadNIC: [student?.guardians[1]?.nicImageUrl],
      motherUploadPassportPicture: [student?.guardians[1]?.guardianImageUrl],
      martialStatus: student?.guardians[1]?.maritalStatus,
    },
    community: {
      isCommunityRef:
        student?.studentComunityReferences.length > 0 ? "Yes" : "No",
      refferenceDetails: student?.studentComunityReferences
        ? student?.studentComunityReferences
        : [{ name: "", relation: "", position: "", campus: "", status: "" }],
    },
    emergency: {
      name: student?.studentEmergencyContactInfo?.name,
      relWithChild: student?.studentEmergencyContactInfo?.relationShipType,
      mobileNumber: student?.studentEmergencyContactInfo?.phone,
      email: student?.studentEmergencyContactInfo?.email,
      homeAddress: student?.studentEmergencyContactInfo?.homeAddress,
    },
    education: {
      previouslyAttended: student?.studentEducationInfo?.previouslyAttended,
      schoolName: student?.studentEducationInfo?.schoolName,
      schoolAddress: student?.studentEducationInfo?.schoolAddress,
      totalNumberOfStudents: student?.studentEducationInfo?.noOfStudents,
      lastAcademicResults: [student?.studentEducationInfo?.lastAcademicResult],
      class: student?.studentAdmissionStatus?.class?.id,
      reasonForLeaving: student?.studentEducationInfo?.reasonForLeaving,
      term: student?.studentAdmissionStatus?.academicYear?.id,
      campus: student?.studentAdmissionStatus?.campus?.id,
      subjectsAppeared: student?.studentEducationInfo?.studentAcademicProfiles
        ? student?.studentEducationInfo?.studentAcademicProfiles
        : [{ subject: "", session: "", grade: "" }],
    },
    ALevelSubject: {
      subjectsTaken: student?.studentAdmissionStatus?.stdAdmSubInfos
        ? student?.studentAdmissionStatus?.stdAdmSubInfos
        : [{ subject: "" }],
    },
    medical: {
      diabetes: student.studentMedicalInfo?.hasDiabetes == false ? "No" : "Yes",
      physicalDisability:
        student.studentMedicalInfo?.hasPhysicalDisability == false
          ? "No"
          : "Yes",
      hearingImpairment:
        student.studentMedicalInfo?.hasHearingImpairment == false
          ? "No"
          : "Yes",
      visualImpairment:
        student.studentMedicalInfo?.hasVisualImpairment == false ? "No" : "Yes",
      anyAllergies:
        student.studentMedicalInfo?.hasAllergy == false ? "No" : "Yes",
      allergies: student.studentMedicalInfo?.allergyReason,
    },
    status: {
      status: student?.currentStatus,
    },
  };

  return reactStudentObj;
};

export { MaptoStudentStateObj };
