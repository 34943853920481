import React, { useEffect, useState } from 'react';
import {
  InputField,
  SelectField,
  InputImageField,
} from '../../../../components/input-fields';
import { subjectsOffered } from '../../../../db/fields-data';

export default function ALevelSubject({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  AlevelSubjectInfo,
  forView,
}) {
  console.log('subject info', AlevelSubjectInfo.subjectsTaken);
  let infoObject = 'ALevelSubject';
  const [warningMessage, setWarningMessage] = useState('');
  const [subjectsAppearedDetails, setSubjectsAppearedDetails] = useState(
    AlevelSubjectInfo.subjectsTaken || [{ subject: '' }]
  );
  //   const [subjectsAppearedDetails, setSubjectsAppearedDetails] = useState(
  //     educationInfo.subjectsAppeared || [{ subject: '', session: '', grade: '' }]
  //   );
  useEffect(() => {
    // let keysToExclude = ['class'];
    // const isAnyKeyEmpty = Object.keys(AlevelSubjectInfo)
    //   .filter((key) => !keysToExclude.includes(key))
    //   .some((key) => {
    //     return AlevelSubjectInfo[key] === '' || AlevelSubjectInfo[key] === null;
    //   });
    // if (subjectsAppearedDetails.length >= 3) {
    //   updateTabComplete(true);
    // } else if (!isAnyKeyEmpty) {
    //   updateTabComplete(true);
    // } else {
    //   if (tabs[3].complete) {
    //     updateTabComplete(false);
    //   }
    // }
    const filterValue = subjectsAppearedDetails.filter((v) => v.subject === '');
    if (subjectsAppearedDetails.length - filterValue.length >= 3) {
      updateTabComplete(true);
      console.log('checked length', subjectsAppearedDetails.length);
    } else {
      console.log('checked length false', subjectsAppearedDetails.length);
      updateTabComplete(false);
    }
  }, [AlevelSubjectInfo]);

  const handleAddSubject = () => {
    if (subjectsAppearedDetails.length >= 5) {
      setWarningMessage('Limit exceeded: You can only add up to 5 subjects.');
    } else {
      setSubjectsAppearedDetails([...subjectsAppearedDetails, { subject: '' }]);
      setWarningMessage(''); // Clear the warning message if a new subject is successfully added
    }
  };
  console.log('subject name', subjectsAppearedDetails);

  //   useEffect(() => {
  //     console.log('all subject details', subjectsAppearedDetails);
  //     console.log('main state education info', AlevelSubjectInfo);
  //     const isSubjectsEmpty = subjectsAppearedDetails.some((subject) =>
  //       Object.values(subject).some((value) => value === '')
  //     );
  //     let emptySubjects = AlevelSubjectInfo.subjectsAppeared.filter((subject) =>
  //       Object.values(subject).some((value) => value === '')
  //     );
  //     console.log('any empty value', emptySubjects);
  //     console.log(
  //       'total subjects provided',
  //       AlevelSubjectInfo.subjectsAppeared.length - emptySubjects.length
  //     );
  //     setAddedNoOfSubjects(
  //       AlevelSubjectInfo.subjectsAppeared.length - emptySubjects.length
  //     );
  //   }, [subjectsAppearedDetails, AlevelSubjectInfo]);

  const handleSubjectChange = (index, key, value) => {
    const updatedSubjects = subjectsAppearedDetails.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setSubjectsAppearedDetails(updatedSubjects);
    handleChangeInfo(infoObject, 'subjectsAppeared', updatedSubjects);
  };

  return (
    <React.Fragment>
      <form>
        <h6 className="mt-4 poppins-medium">A Level Subjects Details</h6>
        <div className="row">
          {warningMessage && <p style={{ color: 'red' }}>{warningMessage}</p>}
          {AlevelSubjectInfo.subjectsTaken.map((subject, index) => (
            <div className="row" key={index}>
              <SelectField
                label={`Subject ${index + 1}`}
                compulsory={true}
                value={subject.subject}
                selectOptions={['Mathematics', 'Physics', 'Chemistry']}
                disabled={forView}
                onChange={(e) =>
                  handleSubjectChange(index, 'subject', e.target.value)
                }
              />
            </div>
          ))}
        </div>
        {/* <button
          type="button"
          disabled={subjectsAppearedDetails.length > 4}
          className="btn btn-success mt-2"
          onClick={handleAddSubject}
        >
          Add Subject
        </button> */}
      </form>
    </React.Fragment>
  );
}
