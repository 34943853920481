import { axiosInstance, instanceAuth } from "../axiosConfig";

export const fetchDepartmentData = async (data) => {
  try {
    console.log("filteration to fetch department: ", data);
    const response = await instanceAuth.get("Department", {
      params: {
        ...data,
      },
    });
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};

export const postDepartmentData = async (data) => {
  try {
    const response = await instanceAuth.post("Department", data);
    return response;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const deleteDepartmentData = async (id) => {
  try {
    const response = await instanceAuth.delete(`Department/${id}`);
    return response;
  } catch (error) {
    console.log("error-fetching-data", error);
    throw error;
  }
};
export const UpdateDepartment = async ({ id, departmentData }) => {
  try {
    const response = await instanceAuth.put(`Department/${id}`, departmentData);
    return response;
  } catch (error) {
    console.log("error updating class...", error);
    throw error;
  }
};
