import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';

//import images
import avatar1 from '../../assets/images/users/avatar-3.jpg'
import avatar2 from '../../assets/images/users/avatar-4.jpg'
import avatar3 from '../../assets/images/users/avatar-5.jpg'
import avatar4 from '../../assets/images/users/avatar-6.jpg'
import avatar5 from '../../assets/images/Profile-Avatar-PNG.png'
import avatar6 from '../../assets/images/avatar-default.png'
import { allApplicant,   ApplicantsData } from '../../redux/slice/admission/applicantSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const Overview = () => {
    const dispatch = useDispatch();
    const { applicants, loading, error, totalPages } = useSelector(allApplicant);

    const inbox = [
        {
            id: 1,
            img: avatar6,
            name: 'Hamza',
            desc: "New Application recieved",
            time: '05 min'
        },
        {
            id: 2,
            img: avatar6,
            name: 'Usama',
            desc: "New Application recieved",
            time: '12 min'
        },
        {
            id: 3,
            img: avatar6,
            name: 'Javeria',
            desc: "New Application recieved",
            time: '18 min'
        },
        {
            id: 4,
            img: avatar6,
            name: 'Sohaib',
            desc: "New Application recieved",
            time: '2hr ago'
        },
    ]
    useEffect(()=>{
        dispatch(ApplicantsData())
    },[])
    return (
        <React.Fragment>
            <Col lg={4}>
                <Card className="rounded-4">
                    <CardBody>
                        <h4 className="card-title mb-4">New Applications</h4>
                        <ul className="inbox-wid list-unstyled">
                        {applicants.slice(0,5).map((inbox, key) => (
                            <li className="inbox-list-item" key={key}>
                                <Link to="#">
                                    <div className="d-flex align-items-start">
                                        <div className="me-3 align-self-center">
                                            <img src={inbox?.studentImageUrl || avatar6 } alt="stdprofile"
                                                className="avatar-sm rounded-circle" />
                                        </div>
                                        <div className="flex-1 overflow-hidden">
                                            <h5 className="font-size-14">{inbox?.name}</h5>
                                            {/* <p className="text-truncate mb-0">{inbox.desc}</p> */}
                                        </div>
                                      
                                    </div>
                                </Link>
                            </li>
                        ))}

                        </ul>

                        <div className="">
                            <Link to="/Admission/Applicants" className="btn btn-primary btn-sm">Load more</Link>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default Overview;