import React, { useEffect, useState } from 'react';
import {
  InputField,
  SelectField,
  InputImageField,
} from '../../../../../components/input-fields';
import { Link } from 'react-router-dom';

export default function ParentsInfoForm({
  tabs,
  updateTabComplete,
  handleFileChange,
  handleChangeInfo,
  parentsInfo,
  forView,
}) {
  let infoObject = 'parents';
  let mobileRegex = /^\+92\d{10}$/;
  let emailRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  let nicRegex = /^[0-9]{5}[0-9]{7}[0-9]$/;

  useEffect(()=>{
    console.log("abc123 Parents")
  }, [parentsInfo])

  useEffect(() => {
    const isAnyKeyEmpty = Object.values(parentsInfo).some((value) => {
      return value === '' || value === null;
    });
    console.log("isAnyKeyEmpty top", isAnyKeyEmpty)
    if (!isAnyKeyEmpty) {
      const isValidMobile =
        mobileRegex.test(parentsInfo.fatherMobileNumber) &
        mobileRegex.test(parentsInfo.motherMobileNumber);
      const isValidEmail =
        emailRegex.test(parentsInfo.fatherEmailAddress) &&
        emailRegex.test(parentsInfo.motherEmailAddress);
      const isValidNic =
        nicRegex.test(parentsInfo.fatherNICNumber) &&
        nicRegex.test(parentsInfo.motherNICNumber);
      if (isValidMobile && isValidEmail && isValidNic) {
        updateTabComplete(true);
      } else {
        console.log("isAnyKeyEmpty", isAnyKeyEmpty)
        updateTabComplete(false);
      }
    } else {
      if (tabs[1].complete) {
        console.log("isAnyKeyEmpty", isAnyKeyEmpty)
        updateTabComplete(false);
      }
    }
  }, [parentsInfo]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h5 className="mt-4">Father's Detail</h5>
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's Name"
              type="text"
              compulsory={true}
              value={parentsInfo.fatherName}
              placeholder="Type Name"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'fatherName', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's Name</label>
              <p className="">{parentsInfo.fatherName}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's NIC Number"
              type="nic"
              compulsory={true}
              value={parentsInfo.fatherNICNumber}
              placeholder="Type NIC Number"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'fatherNICNumber', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's NIC Number</label>
              <p className="">{parentsInfo.fatherNICNumber}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's Occupation"
              type="text"
              compulsory={true}
              value={parentsInfo.fatherOccupation}
              placeholder="Type Occupation"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'fatherOccupation', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's Occupation</label>
              <p className="">{parentsInfo.fatherOccupation}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's Organization"
              type="text"
              compulsory={true}
              value={parentsInfo.fatherOrganization}
              placeholder="Type Organization"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'fatherOrganization',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's Organization</label>
              <p className="">{parentsInfo.fatherOrganization}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's Education"
              type="text"
              compulsory={true}
              value={parentsInfo.fatherEducation}
              placeholder="Type Education"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'fatherEducation', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's Education</label>
              <p className="">{parentsInfo.fatherEducation || '"not given"'}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's Mobile Number"
              type="tel"
              compulsory={true}
              value={parentsInfo.fatherMobileNumber}
              placeholder="Type Mobile Number"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'fatherMobileNumber',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's Mobile Number</label>
              <p className="">{parentsInfo.fatherMobileNumber}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Father's Email Address"
              type="email"
              compulsory={true}
              value={parentsInfo.fatherEmailAddress}
              placeholder="Type Email Address"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'fatherEmailAddress',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Father's Email Address</label>
              <p className="">{parentsInfo.fatherEmailAddress}</p>
            </div>
          )}
          <InputImageField
            disabled={forView}
            label="Upload Father's NIC"
            type="file"
            compulsory={true}
            value={parentsInfo.fatherUploadNIC}
            placeholder="Upload"
            onChange={(e) => handleFileChange(infoObject, 'fatherUploadNIC', e)}
          />
          <InputImageField
            disabled={forView}
            label="Upload Father's Passport Picture"
            type="file"
            compulsory={true}
            value={parentsInfo.fatherUploadPassportPicture}
            placeholder="Upload"
            onChange={(e) =>
              handleFileChange(infoObject, 'fatherUploadPassportPicture', e)
            }
          />
          <h5 className="mt-4">Mother's Details</h5>
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's Name"
              type="text"
              compulsory={true}
              value={parentsInfo.motherName}
              placeholder="Type Name"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'motherName', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's Name</label>
              <p className="">{parentsInfo.motherName}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's NIC Number"
              type="nic"
              compulsory={true}
              value={parentsInfo.motherNICNumber}
              placeholder="Type NIC Number"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'motherNICNumber', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's NIC Number</label>
              <p className="">{parentsInfo.motherNICNumber}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's Occupation"
              type="text"
              compulsory={true}
              value={parentsInfo.motherOccupation}
              placeholder="Type Occupation"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'motherOccupation', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's Occupation</label>
              <p className="">{parentsInfo.motherOccupation}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's Organization"
              type="text"
              compulsory={true}
              value={parentsInfo.motherOrganization}
              placeholder="Type Organization"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'motherOrganization',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's Organization</label>
              <p className="">{parentsInfo.motherOrganization}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's Education"
              type="text"
              compulsory={true}
              value={parentsInfo.motherEducation}
              placeholder="Type Education"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'motherEducation', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's Education</label>
              <p className="">{parentsInfo.motherEducation || '"not given"'}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's Mobile Number"
              type="tel"
              compulsory={true}
              value={parentsInfo.motherMobileNumber}
              placeholder="Type Mobile Number"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'motherMobileNumber',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's Mobile Number</label>
              <p className="">{parentsInfo.motherMobileNumber}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="Mother's Email Address"
              type="email"
              compulsory={true}
              value={parentsInfo.motherEmailAddress}
              placeholder="Type Email Address"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'motherEmailAddress',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Mother's Email Address</label>
              <p className="">{parentsInfo.motherEmailAddress}</p>
            </div>
          )}
          <InputImageField
            disabled={forView}
            label="Upload Mother's NIC"
            type="file"
            compulsory={true}
            value={parentsInfo.motherUploadNIC}
            placeholder="Upload"
            onChange={(e) => handleFileChange(infoObject, 'motherUploadNIC', e)}
          />
          <InputImageField
            disabled={forView}
            label="Upload Mother's passport Picture"
            type="file"
            compulsory={true}
            value={parentsInfo.motherUploadPassportPicture}
            placeholder="upload"
            onChange={(e) =>
              handleFileChange(infoObject, 'motherUploadPassportPicture', e)
            }
          />
          {!forView ? (
            <SelectField
              label="Marital Status"
              compulsory={true}
              disabled={forView}
              value={parentsInfo.martialStatus}
              selectOptions={['Married', 'Seperated', 'Divorced']}
              onChange={(e) =>
                handleChangeInfo(infoObject, 'martialStatus', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Marital Status</label>
              <p className="">{parentsInfo.maritalStatus || ""}</p>
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
