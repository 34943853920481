import React, { useState } from "react";
import AttendanceTable from "../../../components/Staff/Attendance/AttendanceTable";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import SearchBar from "../../../components/Staff/Attendance/SearchBar";
import AttendanceInfoModal from "../../../components/Staff/Attendance/AttendanceInfoModal";

const initialStaffAttendance = [
  {
    staffId: 54353,
    staffName: "John",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54354,
    staffName: "Alice",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "08:30",
          outTime: "16:30",
          timeSpent: "8 hours 30 minutes",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Personal reasons",
          leaveDuration: "2 days",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54355,
    staffName: "Bob",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54356,
    staffName: "Eve",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54357,
    staffName: "Charlie",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  // Add more users here...
  {
    staffId: 54358,
    staffName: "David",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54359,
    staffName: "Emma",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54360,
    staffName: "Frank",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54361,
    staffName: "Grace",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
  {
    staffId: 54362,
    staffName: "Hannah",
    attendance: Array.from({ length: 31 }, () => {
      const randomIndex = Math.floor(Math.random() * 3); // Generate random index for status
      const statuses = ["Present", "Absent", "On Leave"];
      const status = statuses[randomIndex];
      let attendanceData = {};

      if (status === "Present") {
        attendanceData = {
          inTime: "09:00",
          outTime: "17:00",
          timeSpent: "8 hours",
          timeRequired: "8 hours",
          status: "Present",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "Absent") {
        attendanceData = {
          status: "Absent",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "",
          leaveDuration: "",
        };
      } else if (status === "On Leave") {
        attendanceData = {
          status: "On Leave",
          inTime: "",
          outTime: "",
          timeSpent: "",
          timeRequired: "",
          leaveReason: "Vacation",
          leaveDuration: "1 day",
        };
      }

      return {
        status,
        attendanceData,
      };
    }),
  },
];

function Attendance() {
  const [selectedStaff, setSelectedStaff] = useState(null); // State to track selected staff for detailed attendance view

  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [filteredStaffAttendance, setFilteredStaffAttendance] = useState([]);

  // !Modal for VIEW Staff ATTENDANCE
  const [modal, setModal] = useState({ present: false, leave: false });

  const toggleModal = (type) => {
    setModal({ ...modal, [type]: !modal[type] });
    console.log(modal, `${type} modal state`);
  };

  const handleSearch = (searchQuery) => {
    const query = searchQuery.toLowerCase().trim();

    if (query) {
      const filteredStaff = staffAttendance.filter((staff) =>
        staff.staffId.toString().includes(query)
      );
      setFilteredStaffAttendance(filteredStaff);
      setIsFilterApplied(true);
    } else {
      setFilteredStaffAttendance([]);
      setIsFilterApplied(false);
    }
  };

  const handleStaffClick = (attendancedata) => {
    // Logic to fetch and set detailed attendance data for the selected staff
    console.log(attendancedata, "staff selected data");
    setSelectedStaff(attendancedata);
  };

  const [staffAttendance, setstaffAttendance] = useState(
    initialStaffAttendance
  );
  return (
    <>
      <div className="page-content">
        <Breadcrumb title="Staff" breadcrumbItem="Attendance" />

        <SearchBar handleSearch={handleSearch} />
        <AttendanceTable
          staffList={
            isFilterApplied ? filteredStaffAttendance : staffAttendance
          }
          handleStaffClick={handleStaffClick}
          toggleModal={toggleModal}
        />

        <AttendanceInfoModal
          attendanceData={selectedStaff}
          modal={modal}
          toggleModal={toggleModal}
          modalType={"present"}
        />
        <AttendanceInfoModal
          attendanceData={selectedStaff}
          modal={modal}
          toggleModal={toggleModal}
          modalType={"leave"}
        />
      </div>
    </>
  );
}

export default Attendance;
