import React from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

const _AlumniList = [
  {
    enrollment: "/cs/m/2011/259699",
    gradDate: "September 29, 2021",
    name: "Henry",
    dept: "Science",
    grade: "C",
    contact: 92356556566,
  },
  {
    enrollment: "/cs/m/2013/253369",
    gradDate: "April 3, 2022",
    name: "Bush",
    dept: "Science",
    grade: "A+",
    contact: 9235643433,
  },
  {
    enrollment: "/cs/m/2003/113369",
    gradDate: "Octuber 1, 2020",
    name: "Cris",
    dept: "Science",
    grade: "B-",
    contact: 92543546567,
  },

  {
    enrollment: "/cs/m/2013/253369",
    gradDate: "April 10, 2023",
    name: "Bill",
    dept: "Computer",
    grade: "B+",
    contact: 92354143312,
  },
  {
    enrollment: "/cs/m/2013/253369",
    gradDate: "Octuber 6, 2022",
    name: "Mark",
    dept: "Physics",
    grade: "A+",
    contact: 9222256566,
  },

  {
    enrollment: "/cs/m/2013/253369",
    gradDate: "April 6, 2022",
    name: "Mathew",
    dept: "Kindergarden",
    grade: "B",
    contact: 923565545566,
  },
];

function Alumni() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Students" breadcrumbItem="Alumni" />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <h4 className="mb-4 font-weight-bold">Alumni</h4>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Enrollment</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>Graduation Date</th>
                        <th>Grade</th>
                        <th>Contact</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_AlumniList.map((item, ind) => (
                        <tr key={ind}>
                          <th scope="row">{item.enrollment}</th>
                          <td>{item.name}</td>
                          <td>{item.dept}</td>
                          <td>{item.gradDate}</td>
                          <td>{item.grade}</td>
                          <td>{item.contact}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default Alumni;
