import React from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Col, Row, Table } from "reactstrap";

const _TeacherFeedback = [
  {
    id: 24,
    date: "September 29, 2021",
    teacher: "John",
    desc: "Excellent Teacher",
    rating: 8,
    specialize: "Science",
  },
  {
    id: 31,
    date: "April 3, 2022",
    teacher: "Mark",
    desc: "Sports",
    rating: 10,
    specialize: "Cricket",
  },
  {
    id: 33,
    date: "Octuber 1, 2020",
    teacher: "Cris",
    desc: "Good with Maths",
    rating: 9,
    specialize: "Geometry",
  },

  {
    id: 55,
    date: "April 10, 2023",
    teacher: "Bill",
    desc: "Not a good teacher",
    rating: 5,
    specialize: "Visual Basic",
  },
  {
    id: 60,
    date: "Octuber 6, 2022",
    teacher: "Oscar",
    desc: "Doesn't take care of hand writings",
    rating: 7,
    specialize: "Urdu",
  },

  {
    id: 80,
    date: "April 6, 2022",
    teacher: "Mathew",
    desc: "Does not allow students to go for lunch",
    rating: 2,
    specialize: "Prep",
  },
];

function TeacherFeedback() {
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Teacher" breadcrumbItem="Feedback" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <h4 className="mb-4 font-weight-bold">
                  Feedback From Students/Parents
                </h4>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>Student ID</th>
                        <th>Teacher</th>
                        <th>Feedback Desc.</th>
                        <th>Course</th>
                        <th>Date</th>
                        <th>Rating Given</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_TeacherFeedback.map((item, ind) => (
                        <tr key={ind}>
                          <th scope="row">{item.id}</th>
                          <th scope="row">{item.teacher}</th>
                          <td>{item.desc}</td>
                          <td>{item.specialize}</td>
                          <td>{item.date}</td>
                          <th scope="row">{item.rating}</th>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default TeacherFeedback;
