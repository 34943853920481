import React, { useEffect, useState } from 'react';
import {
  InputField,
  SelectField,
  InputImageField,
  SelectFieldForId,
} from '../../../../components/input-fields';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  ClassesData,
  ClassesDataSingle,
  allClasses,
} from '../../../../redux/slice/classesSlice';
import { allCampus } from '../../../../redux/slice/academics/CampusSlice';

export default function EducationInfoForm({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  forView,
  handleFileChange,
  handleFileRemove,
  educationInfo,
}) {
  let infoObject = 'education';
  const { classes, singleClasses } = useSelector(allClasses);
  const { campus } = useSelector(allCampus);
  const [campusOptions, setCampusOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const dispatch = useDispatch();
  const classNames = [...new Set(classes.map((cls) => cls.name))];
  const forViewClass = classes.find((v) => v.id === educationInfo.class);
  const forViewCampus = campus.find((v) => v.id === educationInfo.campus);
  // console.log('forview-class', forViewClass);
  useEffect(() => {
    let keysToExclude = ['class'];
    // if (educationInfo.class == "Nursery") {
    //     keysToExclude = [
    //         "previouslyAttended",
    //         "schoolName",
    //         "schoolAddress",
    //         "lastAcademicResults",
    //         "reasonForLeaving",
    //     ];
    // } else {
    //     keysToExclude = [];
    // }
    const isAnyKeyEmpty = Object.keys(educationInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return educationInfo[key] === '' || educationInfo[key] === null;
      });
    // let isAnyKeyEmpty = Object.values(educationInfo).some(value => {
    //     return value === '' || value === null;
    // });
    if (!isAnyKeyEmpty) {
      updateTabComplete(true);
    } else {
      if (tabs[3].complete) {
        updateTabComplete(false);
      }
    }
  }, [educationInfo]);

  useEffect(() => {
    // console.log('campus', campus);
    const transformedCampusData = campus.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
    console.log('transformedCampusData', transformedCampusData);
    setCampusOptions(transformedCampusData);
  }, [campus, educationInfo.campus]);
  useEffect(() => {
    // console.log('ddddd');
    if (educationInfo.campus != '') {
      dispatch(ClassesDataSingle(Number(educationInfo.campus)));
    }
  }, [educationInfo.campus]);

  useEffect(() => {
    // console.log('classes-data', classOptions);
    const transformedClassData = singleClasses.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
    // console.log('transformedClassData', transformedClassData);
    setClassOptions(transformedClassData);
  }, [singleClasses]);

  console.log('education', educationInfo);
  return (
    <React.Fragment>
      <form>
        <div className="row">
          {/* {singleClasses.length === 0 ? <p className='text-danger text-center'>This campus has no class</p> : null} */}
          {!forView ? (
            <SelectFieldForId
              label="Campus"
              compulsory={true}
              value={educationInfo.campus}
              selectOptions={campusOptions}
              disabled={false}
              onChange={(e) =>
                handleChangeInfo('education', 'campus', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Campus</label>
              <p className="">{forViewCampus?.name || '"not given"'}</p>
            </div>
          )}

          {!forView ? (
            <SelectFieldForId
              label="Class"
              compulsory={true}
              value={educationInfo.class}
              selectOptions={classOptions}
              disabled={false}
              onChange={(e) =>
                handleChangeInfo('education', 'class', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Class</label>
              <p className="">{forViewClass?.name}</p>
            </div>
          )}
          {!forView ? (
            <SelectFieldForId
              label="Academic Year"
              compulsory={true}
              value={educationInfo.term}
              selectOptions={[{ id: 1, name: '2024-25' }]}
              disabled={false}
              onChange={(e) =>
                handleChangeInfo('education', 'term', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Academic Year</label>
              <p className="">2024-25</p>
            </div>
          )}

          {/* {educationInfo.class != "Nursery" && educationInfo.class != "" ? (
                        <> */}
          <h5 className="mt-4">Previously Attended</h5>
          {!forView ? (
            <InputField
              disabled={forView}
              label="Previously Attended"
              type="text"
              compulsory={true}
              value={educationInfo.previouslyAttended}
              placeholder="Last Class Attednded"
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'previouslyAttended',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Previously Attended</label>
              <p className="">{educationInfo.previouslyAttended}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="School Name"
              type="text"
              compulsory={true}
              value={educationInfo.schoolName}
              placeholder="School Name"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'schoolName', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>School Name</label>
              <p className="">{educationInfo.schoolName}</p>
            </div>
          )}
          {!forView ? (
            <InputField
              disabled={forView}
              label="School Address"
              type="text"
              compulsory={true}
              value={educationInfo.schoolAddress}
              placeholder="School Address"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'schoolAddress', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>School Address</label>
              <p className="">{educationInfo.schoolAddress}</p>
            </div>
          )}
          <InputImageField
            disabled={forView}
            label="Last Academic Results"
            type="file"
            compulsory={true}
            value={educationInfo.lastAcademicResults || '"not given"'}
            placeholder="Upload Last Academic Results"
            handleFileRemove={() =>
              handleFileRemove(infoObject, 'lastAcademicResults')
            }
            onChange={(e) =>
              handleFileChange(infoObject, 'lastAcademicResults', e)
            }
          />
          {!forView ? (
            <InputField
              disabled={forView}
              label="Reason for Leaving"
              type="text"
              compulsory={true}
              value={educationInfo.reasonForLeaving}
              placeholder="Reason for Leaving"
              onChange={(e) =>
                handleChangeInfo(infoObject, 'reasonForLeaving', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Reason for Leaving</label>
              <p className="">{educationInfo.reasonForLeaving}</p>
            </div>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
