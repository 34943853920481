import React, { useContext, useState, useEffect } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import { statusClasses } from "../../../common/data/tasks";
import classNames from "classnames";
import {
  addTeacher,
  allTeachers,
} from "../../../redux/slice/teacher/teacherSlice";
import { useSelector, useDispatch } from "react-redux";
import CreateTeacher from "./create-teacher";
import { Link } from "react-router-dom";
import SideBarContext from "../../../context/sidebar.context";
import { allStaffs } from "../../../redux/slice/staff/catalogSlice";
import { getAllStaffDataAsync } from "../../../redux/slice/staff/catalogSlice";




function TeacherCatalog() {
  const { staff } = useSelector(allStaffs);
  const dispatch = useDispatch();


  const { setActiveStates } = useContext(SideBarContext);

  const _filteredTeahers = staff.filter(staff => {
    return staff.employmentDetail && staff.employmentDetail.departmentCategory && staff.employmentDetail.departmentCategory.name === "Teaching";
  });
  
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [selectedClass, setSelectedClass] = useState({});
  // Calculate indexes for pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentList = _filteredTeahers.slice(indexOfFirstItem, indexOfLastItem);
  console.log("_filteredTeahers", _filteredTeahers, "currentList ::",currentList);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //calculate number of pages
  const totalPages = Math.ceil(_filteredTeahers.length / itemsPerPage);

  useEffect(() => {
    // dispatch(fetchDataAsync());
    dispatch(getAllStaffDataAsync());
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Teacher" breadcrumbItem="Catalog" />
        <Row lg={3} className="justify-content-end mb-2">
          <Col className="justify-content-end d-flex">
            <Link
              to={"/staff/catalog"}
              color="success"
              className="btn btn-success waves-effect waves-light"
              onClick={() => {
                setActiveStates({
                  main: "Staff",
                  child: "Catalog",
                });
              }}
            >
              Add Teacher
            </Link>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <h4 className="mb-4 font-weight-bold">Teachers</h4>
                <div className="table-responsive">
                  <Table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Department</th>
                        {/* <th>Specializes</th> */}
                        <th>Joining Date</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentList.map((item, ind) => (
                        <tr key={ind}>
                          <th scope="row">{item.id}</th>
                          <td>{item.name}</td>
                          <td>{item.employmentDetail.departmentCategory.departmentInfos[0].name}</td>
                          {/* <td className="d-flex flex-wrap">
                            {item.specialize.map((_item, i) => (
                              <div className="m-1" key={i}>
                                <span
                                  className={classNames(
                                    "badge badge-pill font-size-11 p-1",
                                    statusClasses["active"]
                                  )}
                                >
                                  {_item.toUpperCase()}
                                </span>
                              </div>
                            ))}
                          </td> */}
                          <td>{item.employmentDetail.employedAt}</td>
                          <td>{item.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              <div className="mt-3">
                <Pagination className="pagination pagination-rounded justify-content-center mb-3">
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => paginate(currentPage - 1)}
                    >
                      <IoArrowBackCircle size={25} />
                    </PaginationLink>
                  </PaginationItem>
                  {[...Array(totalPages).keys()].map((number) => (
                    <PaginationItem
                      key={number}
                      active={number + 1 === currentPage}
                    >
                      <PaginationLink onClick={() => paginate(number + 1)}>
                        {number + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => paginate(currentPage + 1)}
                    >
                      <IoArrowForwardCircle size={25} />
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default TeacherCatalog;
