import React, { useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Button } from "reactstrap";
import CreateNewAssignment from "./createNewForm";
import AssignmentCard from "./assignmentCard";
import { useDispatch } from "react-redux";
import { setViewAssignment } from "../../../redux/slice/academics/assignmentSlice";
import ImportButton from "../../../components/ImportButton";

const componentTab = (activeTab, setActiveTab) => {
  const switchTab = (data) => {
    setActiveTab(data);
  };


  if (activeTab == "View") {
    return <AssignmentCard switchTab={switchTab} header="Assignments" />;
  } else if (activeTab == "Add") {
    return (
      <CreateNewAssignment switchTab={switchTab} header="Add Assignment" />
    );
  }
};
function Assigments() {
  const [activeTab, setActiveTab] = useState("View");
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Academics" breadcrumbItem="Assigments" />
        <div className="container-fluid mt-2">
          <div className="d-flex justify-content-end">
            {activeTab == "Add" ? (
              <Button
                // color="success"
                style={{
                  background: "#37a26a",
                  color: "white",
                  padding: "12px 30px",
                  border: "none",
                  borderRadius: "18px",
                  marginTop: "10px",
                }}
                className={`${activeTab == "View" ? "active" : null}`}
                onClick={() => setActiveTab("View")}
              >
                View Assignments
              </Button>
            ) : (
              <>
                <Button
                  style={{
                    background: "#37a26a",
                    color: "white",
                    padding: "12px 30px",
                    border: "none",
                    borderRadius: "18px",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  className={`${activeTab == "View" ? "active" : null}`}
                  onClick={() => {
                    setActiveTab("Add");
                    dispatch(setViewAssignment(false));
                  }}
                >
                  Create New
                </Button>
                <ImportButton/>
        
              </>
            )}
          </div>
        </div>
        {componentTab(activeTab, setActiveTab)}
      </div>
    </React.Fragment>
  );
}

export default Assigments;
