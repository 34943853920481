import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoginData } from "../../../api/Authentication/LoginhelperApi";
import { decodeJWT } from "../../../helpers/jwt-token-access/tokenMethods";

export const LoginUser = createAsyncThunk("login", async (data) => {
  try {
    const response = await LoginData(data);
    const _response = {
      data: response.data,
      status: response.status,
    };
    return _response;
  } catch (error) {
    if (error.code === "ERR_NETWORK") {
      throw error;
    }
    return error.response;
  }
});

// const token = localStorage.getItem('_schoolware_token_userinfo_');
// console.log('token', token);

const initialState = {
  response: [],
  loading: false,
  userRole: "",
  error: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    UserRoleLogOut: (state, action) => {
      state.userRole = " ";
    },
    SetResponseLogin: (state, action) => {
      state.response = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.loading = false;

        state.response.push(action.payload);
        if (action.payload?.status === 200) {
          localStorage.setItem(
            "_schoolware_token_userinfo_",
            action.payload.data.jwtToken
          );
          let userrole = decodeJWT(action.payload.data.jwtToken);
          localStorage.setItem("userRole", JSON.stringify(userrole));
          state.userRole = userrole;
        }
        state.error = null;
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.loading = false;
        state.response = action.error;
        state.error = action.error; // Store the error message in state
      });
  },
});

export const { UserRoleLogOut, SetResponseLogin } = loginSlice.actions;

export const allLogin = (state) => state.allLogin;

export default loginSlice.reducer;
