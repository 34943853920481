import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Row } from "reactstrap";
import TimeTableView from "./timetable-view";
import TimeTableForm from "./timetable-form";

export default function TimeTable() {
  const [create, setCreate] = useState(false);
  const [timetableData, setTimeTableData] = useState({});
  const [updateId, setUpdateId] = useState();

  const forUpdate = (student_attendance) => {
    console.log("for update time index", student_attendance);
    // setTimeTableData(student_attendance);
    setUpdateId(student_attendance.id)
    setTimeTableData({
      campus: student_attendance.campusId,
      class: student_attendance.classId,
      classroom: student_attendance.roomId,
      course: student_attendance.courseId,
      section:"",
      day: student_attendance.dayId,
      startTime: student_attendance.startTime.slice(0, 5),
      endTime: student_attendance.endTime.slice(0, 5),
    });
    setCreate(true);
  };

  const toggleTab = () => {
    setUpdateId();
    setTimeTableData({});
    setCreate(!create);
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Academics" breadcrumbItem="Timetable" />
      <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          <button
            style={{
              background: "#37a26a",
              color: "white",
              padding: "12px 30px",
              border: "none",
              borderRadius: "18px",
              marginTop: "10px",
              marginRight: "10px",
            }}
            onClick={toggleTab}
          >
            {create ? "View" : "Create"}
          </button>
        </Col>
      </Row>
      {create ? (
        <TimeTableForm timetableData={timetableData} updateId={updateId} toggleTab={toggleTab} />
      ) : (
        <TimeTableView forUpdate={forUpdate} />
      )}
    </div>
  );
}
