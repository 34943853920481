import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CampusData,
  allCampus,
  currentCampusSelect,
} from '../../redux/slice/academics/CampusSlice';
import Loader from '../../components/loader/Loader';
export default function StartupForm({ style }) {
  const dispatch = useDispatch();
  const { campus, loading, currentCampus } = useSelector(allCampus);
  const [campusOptions, setCampusOptions] = useState([]);
  const [selectedCampus, setSelectedCampus] = useState(0);

  useEffect(() => {
    // console.log('campuses availaible', campus);
    if (campus) {
      const transformedCampusData = campus.map((item) => ({
        id: item?.id,
        name: item?.name,
      }));
      setCampusOptions(transformedCampusData);
    }
  }, [campus]);

  useEffect(() => {
    if (currentCampus) {
      setSelectedCampus(currentCampus);
    }
  }, [currentCampus]);
  const handleChange = (e) => {
    const value = e.target.value;
    console.log('selected campus', value);
    setSelectedCampus(value);
    dispatch(currentCampusSelect(value));
  };
  return (
    <div className="m-2">
      {!campus ? (
        <Loader />
      ) : (
        <select
          className="form-select m-auto opacity-80"
          value={selectedCampus}
          onChange={handleChange}
          style={style}
        >
          <option className="text-black" value="" disabled>
            Select Campus
          </option>
          <option className="text-black" value={0}>
            All Campus
          </option>
          {campusOptions.map((option, index) => (
            <option
              key={index}
              className="text-black"
              option={option}
              value={option.id}
            >
              {option.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
}
