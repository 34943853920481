import React, { useEffect, useState, useContext } from 'react';
import SideBarContext from '../../../context/sidebar.context';
import { TabContent, TabPane, Modal } from 'reactstrap';
import { IoIosWarning } from 'react-icons/io';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  addStudent,
  updateStudent,
} from '../../../redux/slice/students/studentsSlice';
import { allClasses } from '../../../redux/slice/classesSlice';
//Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import PersonalInfoForm from './CreateStudent/personal-info';
import ParentsInfoForm from './CreateStudent/parents-info';
import EmergencyContactForm from './CreateStudent/emergency-contact';
import EducationInfoForm from './CreateStudent/education-info';
import MedicalInfoForm from './CreateStudent/medical-info';
import AlevelSubject from './CreateStudent/AlevelSubject';
import CommunityReffernceForm from './CreateStudent/community-refference';

function calculateAge(dateOfBirth) {
  var today = new Date();
  var birthDate = new Date(dateOfBirth);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default function ApplicantDetails(props) {
  const { setActiveStates } = useContext(SideBarContext);
  const [apiresponse, setApiresponse] = useState([]);
  const dispatch = useDispatch();
  const { classes } = useSelector(allClasses);
  // for modal display
  const [modal, setModal] = useState(false);

  // Tabs
  const [activeTab, setactiveTab] = useState(1);
  const [tabs, setTabs] = useState([
    { no: 1, tab: 'Personal Information', complete: false },
    { no: 2, tab: 'Parent Information', complete: false },
    { no: 3, tab: 'City School Association', complete: false },
    { no: 4, tab: 'Emergency Contact', complete: false },
    { no: 5, tab: 'Education History', complete: false },
    { no: 6, tab: 'A-level Subjects', complete: false },
    { no: 7, tab: 'Medical Information', complete: false },
    // { no: 8, tab: 'Current Status', complete: false },
  ]);

  const [createStudentData, setCreateSudentData] = useState({
    personal: {
      studentId: `std#${(Math.random() * 1000).toFixed()}`,
      name: '',
      dateOfBirth: '',
      bForm: '',
      nationality: '',
      gender: '',
      permanentAddress: '',
      residentialAddress: '',
      city: '',
      area: '',
      mobileNumber: '+92',
      emailAddress: '',
      passportSizePicture: [],
      birthCertificate: [],
      b_form: [],
    },
    parents: {
      fatherName: '',
      fatherNICNumber: '',
      fatherOccupation: '',
      fatherOrganization: '',
      fatherEducation: '',
      fatherMobileNumber: '',
      fatherEmailAddress: '',
      fatherUploadNIC: [],
      fatherUploadPassportPicture: [],
      motherName: '',
      motherNICNumber: '',
      motherOccupation: '',
      motherOrganization: '',
      motherEducation: '',
      motherMobileNumber: '',
      motherEmailAddress: '',
      motherUploadNIC: [],
      motherUploadPassportPicture: [],
      martialStatus: '',
    },
    community: {
      isCommunityRef: '',
      refferenceDetails: [
        { name: '', relation: '', position: '', campus: '', status: '' },
      ],
    },
    emergency: {
      name: '',
      relWithChild: '',
      mobileNumber: '',
      email: '',
      homeAddress: '',
    },
    education: {
      // previouslyAttended: "",
      totalNumberOfStudents: '30',
      schoolName: '',
      schoolAddress: '',
      class: '',
      subjectsAppeared: [{ subject: '', session: '', grade: '' }],
      lastAcademicResults: [],
      reasonForLeaving: '',
      term: '',
      campus: '',
    },
    ALevelSubject: {
      subjectsTaken: [{ subject: '' }],
    },
    medical: {
      diabetes: false,
      physicalDisability: false,
      hearingImpairment: false,
      visualImpairment: false,
      anyAllergies: false,
      allergies: false,
    },
    status: {
      status: '',
    },
  });

  console.log('forView', props.forView);
  console.log('props.selectedStudent', props.selectedStudent);
  const sendData = {
    name: createStudentData.personal.name,
    class: createStudentData.education.class,
    age: calculateAge(createStudentData.personal.dateOfBirth),
    email: createStudentData.personal.emailAddress,
    dateOfBirth: createStudentData.personal.dateOfBirth,
    gender: createStudentData.personal.gender,
    nic: createStudentData.personal.bForm,
    nationality: createStudentData.personal.nationality,
    permenantAddress: createStudentData.personal.permanentAddress,
    residentialAddress: createStudentData.personal.residentialAddress,
    city: createStudentData.personal.city,
    area: createStudentData.personal.area,
    phone: createStudentData.personal.mobileNumber,
    currentStatus: createStudentData.status.status,
    studentEmergencyContactInfo: {
      name: createStudentData?.emergency?.name,
      email: createStudentData.emergency?.email,
      relationShipType: createStudentData.emergency?.relWithChild,
      phone: createStudentData.emergency?.mobileNumber,
      homeAddress: createStudentData.emergency?.homeAddress,
    },
    studentEducationInfo: {
      term: createStudentData.education?.term,
      // previouslyAttended: createStudentData.education?.previouslyAttended,
      schoolName: createStudentData.education?.schoolName,
      schoolAddress: createStudentData.education?.schoolAddress,
      reasonForLeaving: createStudentData.education?.reasonForLeaving,
    },
    studentMedicalInfo: {
      hasDiabetes: createStudentData.medical?.diabetes,
      hasPhysicalDisability: createStudentData.medical?.physicalDisability,
      hasHearingImpairment: createStudentData.medical?.hearingImpairment,
      hasVisualImpairment: createStudentData.medical?.visualImpairment,
      hasAllergy: createStudentData.medical?.anyAllergies,
    },
    guardians: [
      {
        name: createStudentData.parents?.fatherName,
        phone: createStudentData.parents?.fatherMobileNumber,
        email: createStudentData.parents?.fatherEmailAddress,
        address: createStudentData.parents.fatherNICNumber,
        gender: 'male',
        type: 'father',
        occupation: createStudentData.parents?.fatherOccupation,
      },
      {
        name: createStudentData.parents?.motherName,
        phone: createStudentData.parents?.motherMobileNumber,
        email: createStudentData.parents?.motherEmailAddress,
        address: createStudentData.parents.fatherNICNumber,
        gender: 'female',
        type: 'mother',
        occupation: createStudentData.parents?.motherOccupation,
      },
    ],
  };
  console.log('peronal-data-for-api', sendData);

  useEffect(() => {
    if (
      props.selectedStudent &&
      Object.keys(props.selectedStudent).length !== 0
    ) {
      console.log('abcdefghijkl', props.selectedStudent);
      setCreateSudentData(props.selectedStudent);
    }
  }, [props.selectedStudent]);

  const handleChangeInfo = (parentObjectName, fieldName, value) => {
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: value,
      },
    }));
  };

  const handleFileChange = (parentObjectName, fieldName, event) => {
    const file = event.target.files[0];
    console.log('file', file);
    console.log('new file', createStudentData[parentObjectName]);
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [...prevState[parentObjectName][fieldName], file],
      },
    }));
  };

  const handleFileRemove = (parentObjectName, fieldName) => {
    console.log('handle file remove', parentObjectName, '@', fieldName);
    setCreateSudentData((prevState) => ({
      ...prevState,
      [parentObjectName]: {
        ...prevState[parentObjectName],
        [fieldName]: [],
      },
    }));
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 8) {
        setactiveTab(tab);
      }
    }
  }

  const updateTabComplete = (boolVal) => {
    console.log('boolVal', boolVal);
    const updated_tabs = [...tabs];
    updated_tabs[activeTab - 1].complete = boolVal;
    setTabs(updated_tabs);
    console.log('tabs', tabs);
  };

  return (
    <React.Fragment>
      <Modal size="sm" isOpen={modal}>
        <div className="modal-header">
          <h2 className="modal-title mt-0" id="myModalLabel">
            <IoIosWarning size={32} color="orange" /> Warning
          </h2>
        </div>
        <div className="modal-body">
          <h6> No Class availaible, Please create class first.</h6>
        </div>
        <div className="modal-footer">
          <Link
            to="/academics/Classes"
            onClick={() =>
              setActiveStates({
                main: 'Academics',
                child: 'Classes',
              })
            }
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Create Class
          </Link>
        </div>
      </Modal>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-capitalize poppins-bold text-blue mb-4">
                {props.forView === true
                  ? 'ADMISSION DETAILS'
                  : props.selectedStudent &&
                    Object.keys(props.selectedStudent).length !== 0
                  ? 'UPDATE STUDENT'
                  : 'CREATE STUDENT'}
              </h4>
              <div
                className="row"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                }}
              >
                {tabs.map((tab, index) => (
                  <div
                    className="col-sm-6 col-md-2 d-grid gap-2 mb-2"
                    style={{ minWidth: '140px', width: '13%' }}
                    key={index}
                  >
                    <button
                      disabled={
                        !tab.complete &&
                        props?.selectedStudent &&
                        Object.keys(props?.selectedStudent).length === 0
                      }
                      className={`btn ${
                        activeTab === tab.no ? 'btn-primary' : 'btn-secondary'
                      }`}
                      style={{ width: '100%' }}
                      onClick={() => setactiveTab(tab.no)}
                    >
                      {tab.tab}
                    </button>
                  </div>
                ))}
              </div>
              <div className="form-wizard-wrapper wizard clearfix">
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <PersonalInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        personalInfo={createStudentData.personal}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={2}>
                      <ParentsInfoForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        parentsInfo={createStudentData.parents}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={3}>
                      <CommunityReffernceForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        community={createStudentData.community}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={4}>
                      <EmergencyContactForm
                        tabs={tabs}
                        updateTabComplete={updateTabComplete}
                        emergencyContact={createStudentData.emergency}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                        handleFileRemove={handleFileRemove}
                        forView={props.forView}
                      />
                    </TabPane>
                    <TabPane tabId={5}>
                      <EducationInfoForm
                        tabs={tabs}
                        forView={props.forView}
                        forUpdate={
                          props.selectedStudent &&
                          Object.keys(props.selectedStudent).length !== 0
                            ? true
                            : false
                        }
                        updateTabComplete={updateTabComplete}
                        educationInfo={createStudentData.education}
                        handleFileChange={handleFileChange}
                        handleFileRemove={handleFileRemove}
                        handleChangeInfo={handleChangeInfo}
                      />
                    </TabPane>
                    <TabPane tabId={6}>
                      <AlevelSubject
                        tabs={tabs}
                        forView={props.forView}
                        forUpdate={
                          props.selectedStudent &&
                          Object.keys(props.selectedStudent).length !== 0
                            ? true
                            : false
                        }
                        updateTabComplete={updateTabComplete}
                        AlevelSubjectInfo={createStudentData.ALevelSubject}
                        handleFileChange={handleFileChange}
                        handleFileRemove={handleFileRemove}
                        handleChangeInfo={handleChangeInfo}
                      />
                      {/* <EducationInfoForm
                        tabs={tabs}
                        forView={props.forView}
                        forUpdate={
                          props.selectedStudent &&
                          Object.keys(props.selectedStudent).length !== 0
                            ? true
                            : false
                        }
                        updateTabComplete={updateTabComplete}
                        educationInfo={createStudentData.education}
                        handleFileChange={handleFileChange}
                        handleFileRemove={handleFileRemove}
                        handleChangeInfo={handleChangeInfo}
                      /> */}
                    </TabPane>
                    <TabPane tabId={7}>
                      <MedicalInfoForm
                        tabs={tabs}
                        forView={props.forView}
                        updateTabComplete={updateTabComplete}
                        medicalInfo={createStudentData.medical}
                        handleFileChange={handleFileChange}
                        handleFileRemove={handleFileRemove}
                        handleChangeInfo={handleChangeInfo}
                      />
                    </TabPane>
                    {/* <TabPane tabId={6}>
                      <CurrrentStatusForm
                        tabs={tabs}
                        forView={props.forView}
                        updateTabComplete={updateTabComplete}
                        currentStatus={createStudentData.status}
                        handleFileChange={handleFileChange}
                        handleChangeInfo={handleChangeInfo}
                      />
                    </TabPane> */}
                    {/* <TabPane tabId={7}>
                      <div className="row justify-content-center">
                        <div className="col-sm-6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Confirm Detail</h5>
                              <p className="text-muted">
                                Are you sure you want to confirm
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane> */}
                  </TabContent>
                </div>
                {/* <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? 'previous disabled' : 'previous'
                      }
                    >
                      {props.selectedStudent &&
                      Object.keys(props.selectedStudent).length !== 0 ? null : (
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={() => {
                            toggleTab(activeTab - 1);
                          }}
                        >
                          Previous
                        </Link>
                      )}
                    </li>
                    {activeTab == 8 ? (
                      <li className={activeTab === 8 ? '' : 'next'}>
                        {props.forView !== true ? (
                          <button
                            // type="submit"
                            className="btn btn-success"
                            disabled={
                              !tabs[activeTab - 1]?.complete &&
                              props.selectedStudent &&
                              Object.keys(props.selectedStudent).length == 0
                            }
                            onClick={handleSubmit}
                          >
                            {props.selectedStudent &&
                            Object.keys(props.selectedStudent).length !== 0
                              ? 'Update'
                              : 'Create'}
                          </button>
                        ) : (
                          ''
                        )}
                      </li>
                    ) : (
                      <li className={activeTab === 8 ? '' : 'next'}>
                        {props.selectedStudent &&
                        Object.keys(props.selectedStudent).length !==
                          0 ? null : (
                          <button
                            to="#"
                            className="btn btn-primary"
                            disabled={!tabs[activeTab - 1]?.complete}
                            onClick={() => {
                              toggleTab(activeTab + 1);
                            }}
                          >
                            Next
                          </button>
                        )}
                      </li>
                    )}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
}
