import { axiosInstance, instanceAuth } from '../axiosConfig';

export const fetchAssignCourseData = async (data) => {
  try {
    const response = await instanceAuth.get('StudentCourse', {
      params: {
        ...data,
      },
    });
    return response;
  } catch (error) {
    console.log('error fetching class data...', error);
    throw error;
  }
};

export const PostAssignCourseData = async ({ id, data }) => {
  try {
    const response = await instanceAuth.post(`StudentCourse/${id}`, data);
    return response;
  } catch (error) {
    console.log('error post assign data...', error);
    throw error;
  }
};
export const UpdateAssignCourseData = async ({ id, data }) => {
  try {
    const response = await instanceAuth.put(`StudentCourse/${id}`, data);
    return response;
  } catch (error) {
    console.log('error post assign data...', error);
    throw error;
  }
};
