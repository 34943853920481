import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
  SelectFieldForId,
  InputNumber,
} from "../../../../../components/input-fields";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ClassesData,
  ClassesDataSingle,
  allClasses,
} from "../../../../../redux/slice/classesSlice";
import {
  subjectsData,
  sessionsData,
  gradesData,
} from "../../../../../db/fields-data";
import { allCampus } from "../../../../../redux/slice/academics/CampusSlice";
import Notify from "../../../../../components/Notify-toast";

export default function EducationInfoForm({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  forView,
  handleFileChange,
  handleFileRemove,
  educationInfo,
}) {
  let infoObject = "education";
  const { campus } = useSelector(allCampus);
  const { classes, singleClasses } = useSelector(allClasses);
  const [campusOptions, setCampusOptions] = useState([]);
  const [classOptions, setClassOptions] = useState([]);
  const [addedNoOfSubjects, setAddedNoOfSubjects] = useState(0);
  const [subjectsAppearedDetails, setSubjectsAppearedDetails] = useState(
    educationInfo.subjectsAppeared || [{ subject: "", session: "", grade: "" }]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("educationInfo?.subjectsAppeared", educationInfo?.subjectsAppeared)
      setSubjectsAppearedDetails(educationInfo?.subjectsAppeared);
  }, [educationInfo.subjectsAppeared]);

  useEffect(() => {
    let keysToExclude = ["class"];
    // if (educationInfo.class == "Nursery") {
    //     keysToExclude = [
    //         "previouslyAttended",
    //         "schoolName",
    //         "schoolAddress",
    //         "lastAcademicResults",
    //         "reasonForLeaving",
    //     ];
    // } else {
    //     keysToExclude = [];
    // }
    const isAnyKeyEmpty = Object.keys(educationInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return educationInfo[key] === "" || educationInfo[key] === null;
      });
    if (!isAnyKeyEmpty) {
      updateTabComplete(true);
    } else {
      if (tabs[3].complete) {
        updateTabComplete(false);
      }
    }
  }, [educationInfo]);

  useEffect(() => {
    const transformedCampusData = campus.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
    console.log("transformedCampusData", transformedCampusData);
    setCampusOptions(transformedCampusData);
  }, [campus, educationInfo.campus]);

  useEffect(() => {
    if (educationInfo.campus != "") {
      console.log("Loading single campus classes data", educationInfo.campus);
      dispatch(ClassesDataSingle(Number(educationInfo.campus)));
    }
  }, [educationInfo.campus]);

  useEffect(() => {
    const transformedClassData = singleClasses?.map((item) => ({
      id: item.id.toString(),
      name: item.name,
    }));
    setClassOptions(transformedClassData);
  }, [singleClasses]);

  const handleAddSubject = () => {
    console.log("adding");
    setSubjectsAppearedDetails([
      ...subjectsAppearedDetails,
      { subject: "", session: "", grade: "" },
    ]);
  };

  const handleSubjectChange = (index, key, value) => {
    const updatedSubjects = subjectsAppearedDetails.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setSubjectsAppearedDetails(updatedSubjects);
    handleChangeInfo(infoObject, "subjectsAppeared", updatedSubjects);
  };

  const handleRemoveLastSubject = () => {
    if (subjectsAppearedDetails?.length > 1) {
      const updatedSubjects = subjectsAppearedDetails.slice(0, -1);
      setSubjectsAppearedDetails(updatedSubjects);
      handleChangeInfo(infoObject, "subjectsAppeared", updatedSubjects);
    } else {
      Notify("error", "At least one subject is required");
    }
  };

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <SelectFieldForId
            label="Campus"
            compulsory={true}
            value={educationInfo.campus}
            selectOptions={campusOptions}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo("education", "campus", e.target.value)
            }
          />
          <SelectFieldForId
            label="Class"
            compulsory={true}
            value={educationInfo.class}
            selectOptions={classOptions}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo("education", "class", e.target.value)
            }
          />
          <SelectFieldForId
            label="Academic Year"
            compulsory={true}
            value={educationInfo.term}
            selectOptions={[
              { id: 1, name: "2024-25" },
              { id: 2, name: "2025-26" },
            ]}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo("education", "term", e.target.value)
            }
          />
          <h6 className="mt-4 poppins-medium">Previously Attended</h6>
          <InputNumber
            disabled={forView}
            label="Total Students in Current Class"
            type="text"
            compulsory={true}
            value={educationInfo?.totalNumberOfStudents}
            placeholder="Total Number of Students"
            step={1}
            min={0}
            max={200}
            onChange={(e) =>
              handleChangeInfo(
                infoObject,
                "totalNumberOfStudents",
                e.target.value
              )
            }
          />
          <InputField
            disabled={forView}
            label="School Name"
            type="text"
            compulsory={true}
            value={educationInfo.schoolName}
            placeholder="School Name"
            onChange={(e) =>
              handleChangeInfo(infoObject, "schoolName", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="School Address"
            type="text"
            compulsory={true}
            value={educationInfo.schoolAddress}
            placeholder="School Address"
            onChange={(e) =>
              handleChangeInfo(infoObject, "schoolAddress", e.target.value)
            }
          />
          <InputImageField
            disabled={forView}
            label="Last Academic Results"
            type="file"
            compulsory={true}
            value={educationInfo?.lastAcademicResults || '"not given"'}
            placeholder="Upload Last Academic Results"
            handleFileRemove={() =>
              handleFileRemove(infoObject, "lastAcademicResults")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "lastAcademicResults", e)
            }
          />
          <InputField
            disabled={forView}
            label="Reason for Leaving"
            type="text"
            compulsory={true}
            value={educationInfo.reasonForLeaving}
            placeholder="Reason for Leaving"
            onChange={(e) =>
              handleChangeInfo(infoObject, "reasonForLeaving", e.target.value)
            }
          />
          <h6 className="mt-4 poppins-medium">Academic Profile</h6>
          <ol className="mx-2">
            <li>
              Please select the subjects you have taken or will be taking final
              CAIE (or equivalent) examinations for.
            </li>
            <li>
              For each of the subjects, please add the relevant school grades.
            </li>
            <li>
              If you have already taken the examination, please add the CAIE
              grade, otherwise, please indicate the grade as "Pending".
            </li>
          </ol>
          {!forView ? (
            <p
              className={`poppins-semibold ${
                addedNoOfSubjects > 7 ? "text-success" : "text-danger"
              }`}
            >
              Provided Subjects: {addedNoOfSubjects}{" "}
              <span className="">{"(Add atleast 8 subjects)"}</span>
            </p>
          ) : (
            <p className="poppins-semibold">
              {/* Subjects Appeared: {subjectsAppearedDetails.length} */}
            </p>
          )}
          {subjectsAppearedDetails?.length > 0
            ? subjectsAppearedDetails.map((subject, index) => (
                <div className="row" key={index}>
                  <SelectField
                    label={`Subject ${index + 1}`}
                    compulsory={true}
                    value={subject.subject}
                    selectOptions={subjectsData}
                    disabled={forView}
                    onChange={(e) =>
                      handleSubjectChange(index, "subject", e.target.value)
                    }
                  />
                  <SelectField
                    label="Session"
                    compulsory={true}
                    value={subject.session}
                    selectOptions={sessionsData}
                    disabled={forView}
                    onChange={(e) =>
                      handleSubjectChange(index, "session", e.target.value)
                    }
                  />
                  <SelectField
                    label="CAIE Grade"
                    compulsory={true}
                    value={subject.grade}
                    selectOptions={gradesData}
                    disabled={forView}
                    onChange={(e) =>
                      handleSubjectChange(index, "grade", e.target.value)
                    }
                  />
                </div>
              ))
            : null}
        </div>
        {!forView ? (
          <>
            <button
              type="button"
              className="btn btn-success my-2"
              onClick={handleAddSubject}
            >
              Add Subject
            </button>
            {subjectsAppearedDetails?.length > 8 ? (
              <button
                type="button"
                className="btn btn-danger mx-1"
                onClick={handleRemoveLastSubject}
              >
                Remove Last
              </button>
            ) : null}
          </>
        ) : null}
      </form>
    </React.Fragment>
  );
}
