import { axiosInstance, instanceAuth } from "../axiosConfig";

export const fetchSectionData = async (_data) => {
  try {
    const response = await instanceAuth.get("Section", {
      params: {
        ..._data,
      },
    });
    return response;
  } catch (error) {
    console.log("error getting section data...", error);
    throw error;
  }
};

export const SectionDataSingleClass = async (data) => {
  try {
    console.log("data", data);
    const response = await instanceAuth.get(`Section`, {
      params: {
        ...data,
      },
    });

    return response.data;
  } catch (error) {
    console.log("error getting specific section data ...", error);
    throw error;
  }
};

export const sectionCreate = async (_data) => {
  console.log("class api helper data coming", _data);
  try {
    const response = await instanceAuth.post("Section", _data);
    return response;
  } catch (error) {
    console.log("error creating section...", error);
    throw error;
  }
};

export const sectionUpdate = async ({ id, sectionData }) => {
  try {
    const response = await instanceAuth.put(`Section/${id}`, sectionData);
    return response;
  } catch (error) {
    console.log("error updating section...", error);
    throw error;
  }
};

export const sectionDelete = async (id) => {
  try {
    console.log("delete id", id);
    const response = await instanceAuth.delete(`Section/${id}`);
    return response;
  } catch (error) {
    console.log("error deleting section...", error);
    throw error;
  }
};

export const fetchClassSectionData = async ({ campusId, classId }) => {
  try {
    const response = await instanceAuth.get(
      `Section?CampusId=${campusId}&ClassId=${classId}`
    );
    return response;
  } catch (error) {
    console.log("error fetching class data...", error);
    throw error;
  }
};

export const postClassSectionData = async ({ id, sectionData }) => {
  console.log("class api helper data coming", sectionData);
  try {
    const response = await instanceAuth.post(`StudentClass/${id}`, sectionData);
    return response;
  } catch (error) {
    console.log("error creating section...", error);
    throw error;
  }
};

export const UpdateClassSectionData = async ({ id, sectionData }) => {
  console.log("class api helper data coming", sectionData);
  try {
    const response = await instanceAuth.put(`StudentClass/${id}`, sectionData);
    return response;
  } catch (error) {
    console.log("error creating section...", error);
    throw error;
  }
};
