import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  allCourses,
  removeCourse,
  updateCourse,
} from "../../../redux/slice/academics/CoursesSlice";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Row,
  Col,
  Modal,
  Button,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import { allStaffs } from "../../../redux/slice/staff/catalogSlice";
import CourseCard from "./course-card";
import AddCourse from "./add-course";
import ImportButton from "../../../components/ImportButton";

const Courses = () => {
  const [activeTab, setActiveTab] = useState("View");

  const componentTab = () => {
    const switchTab = (data) => {
      setActiveTab(data);
    };

    if (activeTab == "View") {
      return <CourseCard switchTab={switchTab} header="Courses" />;
    } else if (activeTab == "Add") {
      return <AddCourse switchTab={switchTab} header="Add Course" />;
    }
  };
  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Academics" breadcrumbItem="Courses" />
        <div className="container-fluid mt-2">
          <div className="d-flex justify-content-end">
            {activeTab == "Add" ? (
              <Button
                // color="success"
                style={{
                  background: "#37a26a",
                  color: "white",
                  padding: "12px 30px",
                  border: "none",
                  borderRadius: "18px",
                  marginTop: "10px",
                }}
                className={`${activeTab == "View" ? "active" : null}`}
                onClick={() => setActiveTab("View")}
              >
                View Courses
              </Button>
            ) : (
              <>
                <Button
                  style={{
                    background: "#37a26a",
                    color: "white",
                    padding: "12px 30px",
                    border: "none",
                    borderRadius: "18px",
                    marginTop: "10px",
                    marginRight: "10px",
                  }}
                  className={`${activeTab == "View" ? "active" : null}`}
                  onClick={() => setActiveTab("Add")}
                >
                  Create New
                </Button>
                <ImportButton />
              </>
            )}
          </div>
        </div>
        {componentTab()}
      </div>
    </>
  );
};

export default Courses;
