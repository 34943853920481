import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
} from "../../../../components/input-fields";
import { Link } from "react-router-dom";

export default function ParentsInfoForm({
  tabs,
  updateTabComplete,
  handleFileChange,
  handleChangeInfo,
  parentsInfo,
  forView,
  handleFileRemove,
}) {
  let infoObject = "parents";

  useEffect(() => {
    const isAnyKeyEmpty = Object.values(parentsInfo).some((value) => {
      return value === "" || value === null;
    });
    if (!isAnyKeyEmpty) {
      updateTabComplete(true);
    } else {
      if (tabs[1].complete) {
        updateTabComplete(false);
      }
    }
  }, [parentsInfo]);

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6 className="mt-4 poppins-medium">Father's Detail</h6>
          <InputField
            disabled={forView}
            label="Father's Name"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherName}
            placeholder="Type Name"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherName", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Father's NIC Number"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherNICNumber}
            placeholder="Type NIC Number"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherNICNumber", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Father's Occupation"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherOccupation}
            placeholder="Type Occupation"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherOccupation", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Father's Organization"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherOrganization}
            placeholder="Type Organization"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherOrganization", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Father's Education"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherEducation}
            placeholder="Type Education"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherEducation", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Father's Mobile Number"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherMobileNumber}
            placeholder="Type Mobile Number"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherMobileNumber", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Father's Email Address"
            type="text"
            compulsory={true}
            value={parentsInfo.fatherEmailAddress}
            placeholder="Type Email Address"
            onChange={(e) =>
              handleChangeInfo(infoObject, "fatherEmailAddress", e.target.value)
            }
          />
          <InputImageField
            disabled={forView}
            label="Upload Father's NIC"
            type="file"
            compulsory={true}
            value={parentsInfo.fatherUploadNIC}
            placeholder="Upload"
            handleFileRemove={() =>
              handleFileRemove(infoObject, "fatherUploadNIC")
            }
            onChange={(e) => handleFileChange(infoObject, "fatherUploadNIC", e)}
          />
          <InputImageField
            disabled={forView}
            label="Upload Father's passport Picture"
            type="file"
            compulsory={true}
            value={parentsInfo.fatherUploadPassportPicture}
            placeholder="Upload"
            handleFileRemove={() =>
              handleFileRemove(infoObject, "fatherUploadPassportPicture")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "fatherUploadPassportPicture", e)
            }
          />
          <h6 className="mt-4 poppins-medium">Mother's Details</h6>
          <InputField
            disabled={forView}
            label="Mother's Name"
            type="text"
            compulsory={true}
            value={parentsInfo.motherName}
            placeholder="Type Name"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherName", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mother's NIC Number"
            type="text"
            compulsory={true}
            value={parentsInfo.motherNICNumber}
            placeholder="Type NIC Number"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherNICNumber", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mother's Occupation"
            type="text"
            compulsory={true}
            value={parentsInfo.motherOccupation}
            placeholder="Type Occupation"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherOccupation", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mother's Organization"
            type="text"
            compulsory={true}
            value={parentsInfo.motherOrganization}
            placeholder="Type Organization"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherOrganization", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mother's Education"
            type="text"
            compulsory={true}
            value={parentsInfo.motherEducation}
            placeholder="Type Education"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherEducation", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mother's Mobile Number"
            type="text"
            compulsory={true}
            value={parentsInfo.motherMobileNumber}
            placeholder="Type Mobile Number"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherMobileNumber", e.target.value)
            }
          />
          <InputField
            disabled={forView}
            label="Mother's Email Address"
            type="text"
            compulsory={true}
            value={parentsInfo.motherEmailAddress}
            placeholder="Type Email Address"
            onChange={(e) =>
              handleChangeInfo(infoObject, "motherEmailAddress", e.target.value)
            }
          />
          <InputImageField
            disabled={forView}
            label="Uplaod Mother's NIC"
            type="file"
            compulsory={true}
            value={parentsInfo.motherUploadNIC}
            placeholder="Upload"
            handleFileRemove={() =>
              handleFileRemove(infoObject, "motherUploadNIC")
            }
            onChange={(e) => handleFileChange(infoObject, "motherUploadNIC", e)}
          />
          <InputImageField
            disabled={forView}
            label="Upload Mother's passport Picture"
            type="file"
            compulsory={true}
            value={parentsInfo.motherUploadPassportPicture}
            placeholder="upload"
            handleFileRemove={() =>
              handleFileRemove(infoObject, "motherUploadPassportPicture")
            }
            onChange={(e) =>
              handleFileChange(infoObject, "motherUploadPassportPicture", e)
            }
          />
          <SelectField
            label="Marital Status"
            compulsory={true}
            disabled={forView}
            value={parentsInfo.martialStatus}
            selectOptions={["Married", "Seperated", "Divorced"]}
            onChange={(e) =>
              handleChangeInfo(infoObject, "martialStatus", e.target.value)
            }
          />
        </div>
      </form>
    </React.Fragment>
  );
}
