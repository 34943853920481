import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchSectionData,
  sectionCreate,
  sectionUpdate,
  sectionDelete,
  SectionDataSingleClass,
  postClassSectionData,
  UpdateClassSectionData,
  fetchClassSectionData,
} from "../../../api/Academics/SectionApiHelper";

import { toast } from "react-toastify";

import { fetchSectionsForAssignSection } from "../../../api/Academics/AssignSectionApiHelper";
import Notify from "../../../components/Notify-toast";

export const SectionsData = createAsyncThunk(
  "section/FetchSectionData",
  async (_data) => {
    try {
      const response = await fetchSectionData(_data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch sections data");
    }
  }
);

export const SectionDataSingle = createAsyncThunk(
  "section/FetchSingleSectionData",
  async (data) => {
    try {
      console.log("data", data);
      const response = await SectionDataSingleClass(data);
      console.log("response", response);

      return response;
    } catch (error) {
      throw Error("Failed to fetch classes data");
    }
  }
);

export const SectionClassesData = createAsyncThunk(
  "section/FetchClassesSection",
  async (data) => {
    try {
      const response = await fetchClassSectionData(data);
      return response.data.items; // Assuming response is an array of students
    } catch (error) {
      throw Error("Failed to fetch classes data");
    }
  }
);

export const createSection = createAsyncThunk(
  "section/CreateSection",
  async (_data) => {
    try {
      const response = await sectionCreate(_data);
      if (response.status === 200) {
        return response.data;
      } else {
        console.log("failed to create section");
      }
      // return {
      //   id: response.data.id,
      //   name: _data.name,
      //   code: _data.code,
      //   description: _data.description,
      // };
    } catch (error) {
      throw Error("Failed to create section");
    }
  }
);

export const updateSingleSection = createAsyncThunk(
  "section/UpdateSection",
  async ({ id, sectionData }) => {
    try {
      const response = await sectionUpdate({ id, sectionData });
      console.log("response.data", response);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to update section");
    }
  }
);

export const deleteSection = createAsyncThunk(
  "section/DeleteSection",
  async (id) => {
    try {
      const response = await sectionDelete(id);
      if (response.status === 200) {
        return response.data;
      } else {
        throw Error("Failed to delete section");
      }
    } catch (error) {
      throw Error("Failed to delete section data");
    }
  }
);

const initialState = {
  sections: [],
  singleSection: [],
  updateClassSection: [],
  loading: false,
  error: null,
  totalPages: 1,
};

export const sectionSlice = createSlice({
  name: "class",
  initialState,
  reducers: {
    addSec: (state, action) => {
      console.log("adding section", action.payload);
      state.sections.push(action.payload);
    },
    updateSec: (state, action) => {
      console.log("updating section", action.payload);
    },
    emptySingleSec: (state, action) => {
      return {
        ...state,
        singleSection: [],
      };
    },
    removeSec: (state, action) => {
      return {
        ...state,
        sections: state.sections.filter((item) => action.payload !== item.name),
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(SectionsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(SectionsData.fulfilled, (state, action) => {
        console.log("section data payload!", action.payload);
        state.loading = false;
        if (action.payload.status === 200) {
          state.sections = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        }
      })
      .addCase(SectionsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    builder
      .addCase(SectionClassesData.pending, (state) => {
        state.loading = true;
      })
      .addCase(SectionClassesData.fulfilled, (state, action) => {
        console.log("section data payload!", action.payload);
        state.loading = false;
        state.singleSection = action.payload;
      })
      .addCase(SectionClassesData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(SectionDataSingle.pending, (state) => {
        state.loading = true;
      })
      .addCase(SectionDataSingle.fulfilled, (state, action) => {
        console.log("section data payload!", action.payload);
        state.loading = false;
        state.singleSection = action.payload.items;
      })
      .addCase(SectionDataSingle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteSection.pending, (state, action) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        console.log("delete section payload!", action.payload);
        state.loading = false;
        if (action.payload) {
          state.sections = state.sections.filter(
            (cl) => cl.id !== action.payload
          );
          Notify("success", "Section has been deleted");
        } else {
          Notify(
            "error",
            "Error on deleting the section. Something went wrong"
          );
        }
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(createSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSection.fulfilled, (state, action) => {
        console.log("new section in payload ", action.payload);
        // const newSectionWithId = { ...action.payload.data, id: action.payload.id };
        state.loading = false;
        if (action.payload) {
          state.sections = [...state.sections, action.payload];
          Notify("success", "Section has been added");
        } else {
          Notify(
            "error",
            "Error on creating the section. Something went wrong"
          );
        }
        state.error = null;
      })
      .addCase(createSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(updateSingleSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSingleSection.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.sections = state.sections.map((cls) =>
            cls.id === action.payload.id ? action.payload : cls
          );
          Notify("success", "Section has been updated");
        } else {
          Notify(
            "error",
            "Error on updating the section. Something went wrong"
          );
        }
      })
      .addCase(updateSingleSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { addSec, updateSec, removeSec, emptySingleSec } =
  sectionSlice.actions;
export const allSections = (state) => state.allSections;
export default sectionSlice.reducer;
