import React, { useState } from 'react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap';
import {
  addSyllabus,
  allSyllabus,
  removeSyllabus,
} from '../../../redux/slice/academics/syllabusSlice';
import { useSelector } from 'react-redux';
import CreateNewInput from '../../../components/inputFieldsCreateNew';
import { useDispatch } from 'react-redux';
import { MdDelete, MdModeEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FaRegEye } from 'react-icons/fa';

function Syllabus() {
  const [createNew, setCreateNew] = useState(false);
  const [newSyllabus, setNewSyllabus] = useState({
    id: Math.floor(Math.random() * (1000 + 1)),
    year: null,
    topic: '',
    startDate: '',
    endDate: '',
    term: '',
    desc: '',
    book_ref: '',
  });
  const _Syllabus = useSelector(allSyllabus);
  const dispatch = useDispatch();

  console.log(_Syllabus);

  const handleInputChange = (target, value) => {
    setNewSyllabus((old) => ({
      ...old,
      [target]: value,
    }));
  };

  const handleSubmit = () => {
    dispatch(addSyllabus(newSyllabus));
    setCreateNew(false);
  };
  const handleDelete = (id) => {
    dispatch(removeSyllabus(id));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Academics" breadcrumbItem="Syllabus" />
        <Row className={`${!createNew ? 'd-none' : ''}`}>
          <Col>
            <Card className="shadow-sm">
              <CardBody>
                <CreateNewInput
                  label="Syllabus Year"
                  type="text"
                  placeholder="2022"
                  handleInputChange={(v) => handleInputChange('year', v)}
                />
                <CreateNewInput
                  label="Topic"
                  type="text"
                  placeholder="Introduction to Python"
                  handleInputChange={(v) => handleInputChange('topic', v)}
                />
                <CreateNewInput
                  label="Term"
                  type="text"
                  placeholder="1"
                  handleInputChange={(v) => handleInputChange('term', v)}
                />
                <CreateNewInput
                  label="Description"
                  type="text"
                  placeholder="About Course..."
                  handleInputChange={(v) => handleInputChange('desc', v)}
                />
                <CreateNewInput
                  label="Reference Book"
                  type="text"
                  placeholder="Computer Programming"
                  handleInputChange={(v) => handleInputChange('book_ref', v)}
                />
                <CreateNewInput
                  label="Start Date"
                  type="date"
                  placeholder="December  31, 2025"
                  handleInputChange={(v) => handleInputChange('startDate', v)}
                />
                <CreateNewInput
                  label="End Date"
                  type="date"
                  placeholder="December  31, 2025"
                  handleInputChange={(v) => handleInputChange('endDate', v)}
                />
                <Row lg={3} className=" mb-2">
                  <Col className="d-flex gap-4">
                    <button
                      type="button"
                      style={{
                        background: '#37a26a',
                        color: 'white',
                        padding: '12px 30px',
                        border: 'none',
                        borderRadius: '18px',
                        marginTop: '10px',
                      }}
                      onClick={() => handleSubmit()}
                    >
                      Add Courses
                    </button>
                    <button
                      type="button"
                      style={{
                        background: '#ff715b',
                        color: 'white',
                        padding: '12px 30px',
                        border: 'none',
                        borderRadius: '18px',
                        marginTop: '10px',
                      }}
                      onClick={() => setCreateNew(false)}
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className={`${createNew ? 'd-none' : ''}`}>
          <Row lg={3} className="justify-content-end mb-2">
            <Col className="justify-content-end d-flex">
              <button
                type="button"
                style={{
                  background: '#37a26a',
                  color: 'white',
                  padding: '12px 30px',
                  border: 'none',
                  borderRadius: '18px',
                  marginTop: '10px',
                  marginBottom: '6px',
                }}
                onClick={() => setCreateNew(true)}
              >
                Add Courses
              </button>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card className="shadow-lg">
                <CardBody>
                  <h2 className="fs-5 mb-4 bold text-uppercase font-weight-bold card-title">
                    Syllabus
                  </h2>
                  <div className="table-responsive">
                    <Table className="table table-striped mb-0 table-hover">
                      <thead>
                        <tr>
                          <th className="text-nowrap">Year</th>
                          <th className="text-nowrap">Topic</th>
                          <th className="text-nowrap">Term</th>
                          <th className="text-nowrap">Description</th>
                          <th className="text-nowrap">Book(s) Reference</th>
                          <th className="text-nowrap">Start Date</th>
                          <th className="text-nowrap">End Date</th>
                          <th className="text-nowrap">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_Syllabus.length > 0 &&
                          _Syllabus.map((item, ind) => (
                            <tr key={ind}>
                              <th scope="row">{item.year}</th>
                              <th scope="row">{item.topic}</th>
                              <td>{item.term}</td>
                              <td>{item.desc}</td>
                              <td>{item.book_ref}</td>
                              <td>{item.startDate}</td>
                              <td>{item.endDate}</td>
                              <td>
                                <div className="d-flex">
                                  <FaRegEye
                                    className="me-2 text-secondary"
                                    size={18}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <MdModeEdit
                                    style={{ cursor: 'pointer' }}
                                    className="me-2 text-primary"
                                    size={18}
                                  />

                                  <MdDelete
                                    style={{ cursor: 'pointer' }}
                                    className="me-2 text-danger"
                                    size={18}
                                    onClick={() => handleDelete(item.id)}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Syllabus;
