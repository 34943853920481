import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardTitle,
  Table,
  Modal,
  CardFooter,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";

import {
  allApplicant,
  ApplicantsData,
  ExportApplicantsData,
  moveStudent,
  postApplicantsData,
  UpdateApplicantStatus,
} from "../../../redux/slice/admission/applicantSlice";
import { MaptoStudentStateObj } from "../../../helpers/map_student_data";

import ApplicantDetails from "./applicant-details";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import FilterInput from "../../../components/FilterInputComponent";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import { IoMdArrowDropdown } from "react-icons/io";
import Notify from "../../../components/Notify-toast";
import { ExportToExcel } from "../../../components/ExcelToImport";
import {MapExportData} from "../../../helpers/map_student_data_Export";

const Applicants = () => {
  const dispatch = useDispatch();
  const { applicants, loading, error, totalPages, AllApplicantsData} = useSelector(allApplicant);
  const { currentCampus } = useSelector(allCampus);
  const { singleClasses } = useSelector(allClasses);
  const [selectedStudent, setSelectedStudent] = useState(false);
  const [modal, setModal] = useState(false);
  const [create, setCreate] = useState(false);
  const [storeId, setStoreId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const [currentData, setCurrentData] = useState({
    currentClass: "0",
    status: "0",
  });
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [emailSend, setEmailSend] = useState("No");
  const [filterApplicants, setFilterApplicants] = useState([]);
  const [actionOnApplicant, setActionOnApplicant] = useState({
    id: "",
    action: "",
    interviewTime: "",
  });

  useEffect(() => {
    let filter_applicants;
    if (currentData.status != "0") {
      filter_applicants = applicants.filter(
        (a) => a?.studentAdmissionStatus?.status == currentData.status
        // a?.studentAdmissionStatus?.status != "Registered"
      );
    } else {
      filter_applicants = applicants;
    }
    setFilterApplicants(filter_applicants);
  }, [applicants]);

  useEffect(() => {
    setCurrentPage(1);
    if (currentCampus == 0) {
      // dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: 1,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        // CampusId: Number(currentCampus),
        AdmissionStatus:
          currentData.status !== "0" ? currentData.status : "NotRegistered",
      };
      console.log("data filters", data);
      dispatch(ApplicantsData(data));
    } else if (currentCampus) {
      // dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: 1,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
        AdmissionStatus:
          currentData.status !== "0" ? currentData.status : "NotRegistered",
      };
      console.log("data filters", data);
      dispatch(ApplicantsData(data));
    }
  }, [itemsPerPage, currentCampus, currentData]);

  useEffect(() => {
    if (currentData.currentClass !== "0") {
      setCurrentData((prev) => ({
        ...prev,
        currentClass: "0",
      }));
    }
    if (currentCampus) {
      dispatch(ClassesDataSingle(currentCampus));
    }
  }, [currentCampus]);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: pageNumber,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        // CampusId: Number(currentCampus),
        AdmissionStatus:
          currentData.status !== "0" ? currentData.status : "NotRegistered",
      };
      dispatch(ApplicantsData(data));
    } else {
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: pageNumber,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
        AdmissionStatus:
          currentData.status !== "0" ? currentData.status : "NotRegistered",
      };
      dispatch(ApplicantsData(data));
    }
  };

  const createToggle = async (id) => {
    setCreate(!create);
  };

  const handleClick = (applicant_action_value, id) => {
    setModal(true);
    setActionOnApplicant((prevState) => ({
      ...prevState,
      id: id,
      action: applicant_action_value,
    }));
  };

  const applicantAction = () => {
    console.log(
      "taking action confirmed on",
      actionOnApplicant,
      "&& email send",
      emailSend
    );
    if (actionOnApplicant.action == "9") {
      dispatch(postApplicantsData(actionOnApplicant.id));
      setModal(false);
    } else {
      const date = new Date(actionOnApplicant?.interviewTime);
      const hours = date.getHours();
      let data = {
        studentId: actionOnApplicant.id,
        isMailSent: emailSend == "Yes" ? true : false,
        statusId: Number(actionOnApplicant.action),
        InterviewScheduleTime: actionOnApplicant.interviewTime,
        ReasonForReschedule: actionOnApplicant.rescheduleReason,
      };
      console.log("Post/Update data for Applicant", data);
      if (actionOnApplicant.action == "1") {
        if (!actionOnApplicant?.interviewTime || hours < 9 || hours >= 17) {
          Notify(
            "error",
            "Please provide interview time between 9 AM and 5 PM"
          );
        } else {
          dispatch(UpdateApplicantStatus(data));
          setModal(false);
        }
      } else {
        dispatch(UpdateApplicantStatus(data));
        setModal(false);
      }
    }
  };

  const formatDateCustom = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };

    const formatter = new Intl.DateTimeFormat("en-US", options);
    return formatter.format(date).replace(",", "");
  };

  const toggleDropdown = (index) => {
    setDropdownsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  useEffect(()=>{
    dispatch(ExportApplicantsData())
  },[])
  const fileName = "Applicants Data";
  const givenData = AllApplicantsData.map((student) => (MapExportData(student,"applicants")));
  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Admission" breadcrumbItem="Applicants" />
        {create && (
          <Row className="mb-2">
            <Col className="justify-content-end d-flex">
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light mx-1"
                onClick={createToggle}
              >
                Show List
              </Button>
            </Col>
          </Row>
        )}
        {!create ? (
          <div>
            <div className="container-fluid mt-2">
              <div className="d-flex justify-content-end mb-2">
                <ExportToExcel apiData={givenData} fileName={fileName} />
              </div>
              <Card style={{ minHeight: "540px" }}>
                <CardBody
                  className="position-relative"
                  style={{ overflowX: "auto" }}
                >
                  <div className="">
                    <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold mt-2">
                      New Applicants
                    </CardTitle>
                    <div className="my-2 w-100 d-flex">
                      <div className="w-75 d-flex">
                        <FilterInput
                          options={singleClasses}
                          value={currentData.currentClass}
                          label="All Classes"
                          onChange={(e) => {
                            setCurrentData((prev) => ({
                              ...prev,
                              currentClass: e.target.value,
                            }));
                          }}
                        />
                        <FilterInput
                          options={[
                            { id: "Pending", name: "Pending" },
                            { id: "OnHold", name: "On Hold" },
                            { id: "Interview", name: "Interview" },
                            { id: "InterviewDone", name: "Interview Done" },
                            { id: "NoShow", name: "No Show" },
                            // { id: "Rejected", name: "Rejected" },
                          ]}
                          value={currentData.status}
                          label="All Status"
                          onChange={(e) => {
                            setCurrentData((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }));
                          }}
                        />
                      </div>
                      <select
                        className="form-control w-25"
                        style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                        value={itemsPerPage}
                        onChange={(e) => {
                          setCurrentPage(1);
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Items per page
                        </option>
                        {/* <option value={""}>All</option> */}
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>
                  <div className="">
                    {loading ? (
                      <Loader />
                    ) :  (
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Applicant's Name</th>
                            <th className="text-nowrap">Father's Name</th>
                            <th className="text-nowrap">Campus</th>
                            <th className="text-nowrap">Interview Time</th>
                            <th className="text-nowrap">Status</th>
                            <th className="text-nowrap">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterApplicants.length > 0 ? filterApplicants?.map((std, ind) => (
                            <tr key={ind}>
                              <td className="text-nowrap">{std?.name}</td>
                              <td className="text-nowrap">
                                {std?.guardians[0]?.name}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentAdmissionStatus?.campus?.name}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentInterviewStatus
                                  ?.interviewReScheduleTime
                                  ? formatDateCustom(
                                      std?.studentInterviewStatus
                                        ?.interviewReScheduleTime
                                    )
                                  : std?.studentInterviewStatus
                                      ?.interviewScheduleTime
                                  ? formatDateCustom(
                                      std?.studentInterviewStatus
                                        ?.interviewScheduleTime
                                    )
                                  : "-"}
                              </td>
                              <td
                                className={`text-nowrap ${
                                  std?.studentAdmissionStatus?.status == "Pending"
                                    ? "text-warning"
                                    : std?.studentAdmissionStatus?.status ==
                                      "Interview"
                                    ? "text-info"
                                    : std?.studentAdmissionStatus?.status ==
                                      "NoShow"
                                    ? "text-danger"
                                    : std?.studentAdmissionStatus?.status ==
                                      "OnHold"
                                    ? "text-secondary"
                                    : "text-success"
                                }`}
                              >
                                {std?.studentAdmissionStatus?.status}
                              </td>
                              <td className="d-flex justify-content-start">
                                <button
                                  onClick={() => {
                                    setSelectedStudent(
                                      MaptoStudentStateObj(std)
                                    );
                                    setCreate(!create);
                                  }}
                                  className="btn btn-primary me-2"
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                >
                                  View
                                </button>
                                <Dropdown
                                  isOpen={dropdownsOpen[ind] || false}
                                  toggle={() => toggleDropdown(ind)}
                                  direction="down"
                                >
                                  <DropdownToggle caret>
                                    Select Action
                                    <IoMdArrowDropdown size={20} />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem
                                      className="text-info text-nowrap"
                                      onClick={() => {
                                        // handleClick("1", std.id);
                                        setActionOnApplicant((prevState) => ({
                                          ...prevState,
                                          id: std.id,
                                          action: "1",
                                          interviewTime:
                                            std?.studentInterviewStatus
                                              ? std.studentInterviewStatus
                                                  ?.interviewReScheduleTime
                                                ? std?.studentInterviewStatus
                                                    ?.interviewReScheduleTime
                                                : std?.studentInterviewStatus
                                                    ?.interviewScheduleTime
                                              : "",
                                        }));
                                        setModal(true);
                                      }}
                                    >
                                      Schedule Interview
                                    </DropdownItem>
                                    <DropdownItem
                                      className="text-secondary"
                                      onClick={() => handleClick("2", std.id)}
                                    >
                                      Hold
                                    </DropdownItem>
                                    <DropdownItem
                                      className="text-success"
                                      onClick={() => handleClick("6", std.id)}
                                    >
                                      Interview Done
                                    </DropdownItem>
                                    <DropdownItem
                                      className="text-danger"
                                      onClick={() => handleClick("7", std.id)}
                                    >
                                      No Show
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </td>
                            </tr>
                          )) : null}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="mt-5">
                    <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                      <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink
                          previous
                          onClick={() => paginate(currentPage - 1)}
                        >
                          <IoArrowBackCircle size={25} />
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPages).keys()].map((number) => (
                        <PaginationItem
                          key={number}
                          active={number + 1 === currentPage}
                        >
                          <PaginationLink onClick={() => paginate(number + 1)}>
                            {number + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink
                          next
                          onClick={() => paginate(currentPage + 1)}
                        >
                          <IoArrowForwardCircle size={25} />
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </CardFooter>
              </Card>
            </div>
            <Modal isOpen={modal} centered>
              <Card>
                <CardBody className="mt-2">
                  <h5>Are You Sure?</h5>
                  {actionOnApplicant.action === "1" && (
                    <>
                      <label>Select time for interview</label>
                      <input
                        className="form-control"
                        value={actionOnApplicant.interviewTime}
                        type="datetime-local"
                        onChange={(e) => {
                          setActionOnApplicant((prevState) => ({
                            ...prevState,
                            interviewTime: e.target.value,
                          }));
                        }}
                      />
                    </>
                  )}
                  <label>Do you want to send email for the action?</label>
                  <select
                    className="form-select"
                    value={emailSend}
                    onChange={(e) => setEmailSend(e.target.value)}
                  >
                    <option option="No">No</option>
                    <option option="Yes">Yes</option>
                  </select>
                </CardBody>
                <CardFooter>
                  <button
                    className="btn btn-success me-2"
                    onClick={() => applicantAction()}
                  >
                    Yes
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      setModal(false);
                      setActionOnApplicant({ id: "", action: "" });
                    }}
                  >
                    No
                  </button>
                </CardFooter>
              </Card>
            </Modal>
          </div>
        ) : (
          <div>
            <ApplicantDetails
              forView={true}
              selectedStudent={selectedStudent}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default Applicants;
