import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  getClassesByCampusId,
  postAssignSectionData,
  getCourseAndSectionByClassAndCampusAndDepartmentIdHelper,
  getAssignSectionByFilteration,
  updateAssignSectionWithId,
  deleteSectionById,
  getAllAssignSectionWithoutFilter,
  getAssignSectionByFilterationForCreate,
} from "../../../api/Academics/AssignSectionApiHelper";
import { toast } from "react-toastify";

import Notify from "../../../components/Notify-toast";
// import { toast } from "react-toastify";

const initialState = {
  classesByCampusId: [],
  AssignSectionWithFilterOrWithoutFilter: [],
  sectionsByClassIdByDepartAndCampusId: [],
  coursesByClassByDepartAndCampusId: [],
  AssignSectionWithFilterForCreate: [],
  classesLoader: false,
  loading: false,
  error: null,
};

export const getClassesWithCampusId = createAsyncThunk(
  "assignedSection/getClassesWithCampusId",
  async (data, { rejectWithValue }) => {
    try {
      console.log("CLASS", data);
      const response = await getClassesByCampusId(data);

      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      const _error = {
        status: error.response?.status,
        data: error.response?.data?.message,
      };
      return rejectWithValue(_error);
    }
  }
);
export const getCourseAndSectionByClassAndCampusAndDepartmentId =
  createAsyncThunk(
    "secAndCourse/getCourseAndSectionByClassAndCampusAndDepartmentId",
    async (data) => {
      try {
        const response =
          await getCourseAndSectionByClassAndCampusAndDepartmentIdHelper(data);
        console.log(
          "getCourseAndSectionByClassAndCampusAndDepartmentId PAYLOAD",
          response
        );
        const course = {
          data: response.courses.data.items,
          status: response.courses.status,
        };
        const section = {
          data: response.sections.data,
          status: response.sections.status,
        };
        const _response = {
          course: course,
          section: section,
        };
        return _response;
      } catch (error) {
        throw Error("Failed to fetch getClassesByCampusAndDepartmentId data");
      }
    }
  );

export const SubmitAssignSection = createAsyncThunk(
  "assignedSection/SubmitAssignSection",
  async (data) => {
    try {
      const response = await postAssignSectionData(data);
      console.log(response, "SubmitAssignSection responseee----");
      if (response.status === 200) {
        Notify(
          "success",
          "The section has been successfully assigned to the class"
        );
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to Submit Assign Section data");
    }
  }
);

export const updateAssignSection = createAsyncThunk(
  "assignedSection/UpdateAssignSection",
  async (data) => {
    try {
      const response = await updateAssignSectionWithId(data);
      console.log(response, "SubmitAssignSection responseee----");
      if (response.status === 200) {
        Notify("success", "Assigned section has been updated");
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to update Assign Section data");
    }
  }
);

export const getAssignSectionByFilter = createAsyncThunk(
  "assignedSection/getAssignSectionByFilter",
  async (url) => {
    try {
      const response = await getAssignSectionByFilteration(url);
      console.log("getAssignSectionByFilteration", response);
      return response.data;
    } catch (error) {
      console.log(
        error.response.data.message,
        "error getAssignSectionByFilter"
      );
      return error.response.data.message;

      // throw Error('Failed to fetch getAssignSectionByFilter data');
    }
  }
);

// ! GETTING ALREADY CREATED Assign Section By Filter For Creating NEW Assigned Sections

export const getAssignSectionByFilterForCreate = createAsyncThunk(
  "assignedSection/getAssignSectionByFilterForCreate",
  async (url) => {
    try {
      const response = await getAssignSectionByFilterationForCreate(url);
      console.log("getAssignSectionByFilterationForCreate", response);
      return response.data;
    } catch (error) {
      console.log(
        error.response.data.message,
        "error getAssignSectionByFilter"
      );
      return error.response.data.message;

      // throw Error('Failed to fetch getAssignSectionByFilter data');
    }
  }
);
export const getAllAssignSection = createAsyncThunk(
  "assignedSection/getAllAssignSection",
  async (campusid) => {
    try {
      const response = await getAllAssignSectionWithoutFilter(campusid);
      return response.data;
    } catch (error) {
      throw Error("Failed to fetch getAssignSectionByFilter data");
    }
  }
);

export const deleteAssignedSection = createAsyncThunk(
  "assignedSection/deleteAssignedSection",
  async (id) => {
    try {
      console.log(id, "in deleteAssignedSection");
      const response = await deleteSectionById(id);
      console.log("response:::", response);
      if (response.status === 200) {
        Notify("success", "Section assignment has been removed");
        return response.data; // Assuming response is an array of students
      } else {
        throw new Error("Failed to Delete....");
      }
    } catch (error) {
      throw Error("Failed to deleteAssignedSection");
    }
  }
);

export const AssignSectionSlice = createSlice({
  name: "assignSection",
  initialState,
  reducers: {
    emptyAssignSection: (state, action) => {
      state.AssignSectionWithFilter = [];
    },
    emptySectionsByClassIdByDepartAndCampusId: (state, action) => {
      state.sectionsByClassIdByDepartAndCampusId = [];
    },
    emptycoursesByClassByDepartAndCampusId: (state, action) => {
      state.coursesByClassByDepartAndCampusId = [];
    },
  },
  extraReducers(builder) {
    builder
      .addCase(
        getCourseAndSectionByClassAndCampusAndDepartmentId.pending,
        (state) => {
          state.loading = true;
          state.error = null;
        }
      )
      .addCase(
        getCourseAndSectionByClassAndCampusAndDepartmentId.fulfilled,
        (state, action) => {
          state.loading = false;
          console.log(
            "getCourseAndSectionByClassAndCampusAndDepartmentId ACTION",
            action.payload
          );
          if (
            action.payload.course.status === 200 &&
            action.payload.section.status === 200
          ) {
            if (action.payload.section.data.items.length > 0) {
              state.coursesByClassByDepartAndCampusId =
                action.payload.course.data;
              state.sectionsByClassIdByDepartAndCampusId =
                action.payload.section.data.items;
            } else {
              state.coursesByClassByDepartAndCampusId =
                action.payload.course.data;
              Notify("error", "Section is not assigned to the selected class");
              // Notify("error", "Section is not assigned to the selected Class");
            }
          }
        }
      )
      .addCase(
        getCourseAndSectionByClassAndCampusAndDepartmentId.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      );
    // ! GETTING CLASSES BY CAMPUS ID AND DEPARTMENT ID
    builder
      .addCase(getClassesWithCampusId.pending, (state) => {
        state.classesLoader = true;
        state.error = null;
      })
      .addCase(getClassesWithCampusId.fulfilled, (state, action) => {
        state.classesLoader = false;
        if (action.payload.status === 200) {
          state.classesByCampusId = action.payload.data.items;
        }
      })
      .addCase(getClassesWithCampusId.rejected, (state, action) => {
        state.classesLoader = false;
        state.error = action.error.message;
      });
    // ! GETTING Assign Section By Filter

    builder
      .addCase(getAssignSectionByFilter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssignSectionByFilter.fulfilled, (state, action) => {
        state.loading = false;
        console.log("getAssignSectionByFilter", action.payload);
        if (action.payload === "No Assigned Section Found") {
          Notify("error", action.payload);
          state.AssignSectionWithFilterOrWithoutFilter = [];
        } else {
          state.AssignSectionWithFilterOrWithoutFilter = action.payload.items;
        }
      })
      .addCase(getAssignSectionByFilter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    // ! GETTING ALREADY CREATED Assign Section By Filter For Creating NEW Assigned Sections

    builder
      .addCase(getAssignSectionByFilterForCreate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAssignSectionByFilterForCreate.fulfilled, (state, action) => {
        state.loading = false;
        console.log("getAssignSectionByFilterForCreate", action.payload);
        if (action.payload === "No Assigned Section Found") {
          Notify("error", action.payload);
          state.AssignSectionWithFilterForCreate = [];
        } else {
          state.AssignSectionWithFilterForCreate = action.payload.items;
          state.AssignSectionWithFilterOrWithoutFilter = [];
        }
      })
      .addCase(getAssignSectionByFilterForCreate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    // ! GETTING ALL Assign Section WITHOUT Filter

    builder
      .addCase(getAllAssignSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllAssignSection.fulfilled, (state, action) => {
        state.loading = false;

        console.log("getAllAssignSection", action.payload);
        state.AssignSectionWithFilterOrWithoutFilter = action.payload.items;
      })
      .addCase(getAllAssignSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    // ! SUBMIT ASSIGN SECTION

    builder
      .addCase(SubmitAssignSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(SubmitAssignSection.fulfilled, (state, action) => {
        state.loading = false;

        console.log(
          action.payload,
          "------ SUBMIT ASSIGN SECTION action.payload"
        );
        const new_assigned_section = action.payload;
        console.log("new_assigned_section-----", new_assigned_section);
        state.AssignSectionWithFilterOrWithoutFilter = [
          ...state.AssignSectionWithFilterOrWithoutFilter,
          ...new_assigned_section,
        ];
      })
      .addCase(SubmitAssignSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    // ! UPDATE ASSIGN SECTION

    builder
      .addCase(updateAssignSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAssignSection.fulfilled, (state, action) => {
        state.loading = false;
        const updatedSectionData = action.payload;

        console.log("updatedSectionData:", updatedSectionData);
        state.AssignSectionWithFilterOrWithoutFilter =
          state.AssignSectionWithFilterOrWithoutFilter.map((section) =>
            section.id === updatedSectionData.id ? updatedSectionData : section
          );
      })
      .addCase(updateAssignSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    // ! DELETE ASSIGN SECTION

    builder
      .addCase(deleteAssignedSection.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteAssignedSection.fulfilled, (state, action) => {
        state.loading = false;

        state.AssignSectionWithFilterOrWithoutFilter =
          state.AssignSectionWithFilterOrWithoutFilter.filter(
            (i) => i.id !== action.payload
          );
      })
      .addCase(deleteAssignedSection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  emptyAssignSection,
  emptySectionsByClassIdByDepartAndCampusId,
  emptycoursesByClassByDepartAndCampusId,
} = AssignSectionSlice.actions;
export const allAssignSection = (state) => state.allAssignSection;

export default AssignSectionSlice.reducer;
