import React, { useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import { MdDelete, MdModeEdit } from "react-icons/md";

import {
  allAssignSection,
  getAssignSectionByFilter,
  getAllAssignSection,
  deleteAssignedSection,
  getClassesWithCampusId
} from "../../../redux/slice/academics/AsssignSectionSlice";
import {
  CampusData,
  allCampus,
  currentCampusSelect,
} from "../../../redux/slice/academics/CampusSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  Modal,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import { IoArrowBackCircle, IoArrowForwardCircle } from "react-icons/io5";
import { MdOutlineClear } from "react-icons/md";
import { CiFilter } from "react-icons/ci";
import { ClassesData, allClasses } from "../../../redux/slice/classesSlice";
import { axiosInstance } from "../../../api/axiosConfig";
import UpdateAssignSection from "./update-assign-section";

function ViewAssignSection(props) {
  const { classes } = useSelector(allClasses);
  const { currentCampus } = useSelector(allCampus);
  const {
    AssignSectionWithFilterOrWithoutFilter, classesByCampusId
  } = useSelector(allAssignSection);
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  // const [selectedCourseId, setSelectedCourseId] = useState(null);
  const [selectedAssignSection, setSelectedAssignSection] = useState({
    campus: "",
    department: "",
    class: "",
    assignSections: [],
  });
  console.log("AssignSectionWithFilterOrWithoutFilter,",AssignSectionWithFilterOrWithoutFilter);
  console.log("currentCampus,",currentCampus);

  const [AssignData, setAssignData] = useState({
    campusId: 0,
    departmentId: 0,
    classesId: 0,
    // sections: [],
  });
  const [Deletemodal, setDeleteModal] = useState(false);
  const [SelectedId, setSelectedId] = useState(0);
  const [allAssignedSection, setallAssignedSection] = useState([]);


  useEffect(() => {
    dispatch(getAllAssignSection(+currentCampus))
    dispatch(getClassesWithCampusId(+currentCampus))
    // setAssignData((prevState) => ({
    //   ...prevState,
    //   campusId: +currentCampus,
    // }));
  }, [currentCampus]);

  // ! NEW CODE......


  useEffect(() => {
    console.log("AssignSectionWithFilterOrWithoutFilter:::",AssignSectionWithFilterOrWithoutFilter);
    setallAssignedSection(AssignSectionWithFilterOrWithoutFilter)
  }, [AssignSectionWithFilterOrWithoutFilter]);


  console.log("AssignData in view SECTION", currentCampus);
  const handleChangeInfo = async (fieldName, value) => {
    console.log("value::", value);
    // if (fieldName === "departmentId") {
    //   dispatch(
    //     getClassesByCampusAndDepartmentId({
    //       campusId: +currentCampus,
    //       departmentId: +value,  
    //     })
    //   );
    // }

    setAssignData((prevState) => ({
      ...prevState,
      [fieldName]: +value,
    }));
  };

  useEffect(() => {

    
    if (AssignData.classesId) {
      
      console.log("the useEffect runs");
      let url = `ClassSection`;
      url += `?campusId=${+currentCampus}&classId=${AssignData.classesId}`;
      dispatch(getAssignSectionByFilter(url))

    }

  }, [AssignData.classesId])


  function ModalToggle() {
    setModal(!modal);
  }

  const handleDeleteConfirmation = (i) => {
    setSelectedId(i);
    setDeleteModal(true);
  };

  function handleDeleteClick() {
    dispatch(deleteAssignedSection(SelectedId));
    setDeleteModal(false);
  }

  function assignSectionForEdit(selected_row) {
    console.log("selected_row:::", selected_row);

    setSelectedAssignSection({
      campus: selected_row.campus.name,
      class: selected_row.class.name,
      section: selected_row.section.name,
      id: selected_row.id,
      capacity: selected_row.capacity,
    });
    ModalToggle();
  }

  console.log("allAssignedSection", allAssignedSection);
  // Calculate indexes for pagination

  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Ensure the end index does not exceed the array length
  const adjustedIndexOfLastItem = Math.min(indexOfLastItem, allAssignedSection.length);

  console.log("indexOfFirstItem:", indexOfFirstItem); // Debugging
  console.log("adjustedIndexOfLastItem:", adjustedIndexOfLastItem); // Debugging
  const currentList = allAssignedSection.slice(
    indexOfFirstItem,
    adjustedIndexOfLastItem
  );
  console.log("currentList::", currentList);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  //calculate number of pages
  const totalPages = Math.ceil(allAssignedSection.length / itemsPerPage);

  return (
    <div className="row mt-4">
      <Card>
        <CardBody>
          <CardTitle className="mb-4">
            <h2>Sections</h2>
          </CardTitle>
          <form>
            <div className="row mt-4">
              {/* <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Select Campus</label>
              <select
                className="form-control border border-secondary"
                value={AssignData.campusId}
                onChange={(e) => handleChangeInfo("campusId", e.target.value)}
              >
                <option value=" ">Select Campus---</option>
                {campus.map((v, i) => (
                  <option selected value={v.id} key={i}>
                    {v.name}
                  </option>
                ))}
              </select>
            </div> */}

              {/* <div className="col-sm-12 col-md-6 col-lg-6 my-2 ">
                <label>Select Department</label>
                <select
                  className="form-control border border-secondary"
                  value={AssignData.departmentId}
                  onChange={(e) =>
                    handleChangeInfo("departmentId", e.target.value)
                  }
                >
                  <option value=" ">Select Department---</option>
                  {departmentByCampusId.map((v, i) => (
                    <option selected value={v.id} key={i}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="col-sm-12 col-md-6 col-lg-6 my-2">
                <label>Select Classes</label>
                <select
                  className="form-control border border-secondary"
                  value={AssignData.classesId}
                  onChange={(e) =>
                    handleChangeInfo("classesId", e.target.value)
                  }
                >
                  <option value=" ">Select Classes---</option>
                  {classesByCampusId.map((v, i) => (
                    <option selected value={v.id} key={i}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
          <Modal size="lg" isOpen={modal} toggle={() => ModalToggle()}>
            <UpdateAssignSection
              selectedAssignSection={selectedAssignSection}
              switchTab={props.switchTab}
              ModalToggle={ModalToggle}
              // id={selectedCourseId}
              header="Update Assign Section"
            />
          </Modal>
          {/* {reduxStoreData.length !== 0 ? ( */}
          <div className="table-responsive mt-4">
            {currentList.length > 0 ? (
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th>Campus ID</th>
                    <th>Campus Name</th>
                    <th className="text-nowrap">Class Name</th>
                    <th className="text-nowrap">Section Name</th>
                    <th className="text-nowrap">Capacity</th>
                    {/* <th className="text-nowrap">Description</th> */}
                    <th className="text-nowrap">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentList?.map((c, index) => {
                    // let _className =
                    //   classes?.find((x) => x.id === c.classId) || "-";
                    return (
                      <tr key={index} className="p-2">
                        <td>{c.campus.id}</td>
                        <td>{c.campus.name}</td>
                        {/* <td>{_className.name}</td> */}
                        <td>{c.class.name}</td>
                        <td>{c.section.name}</td>
                        <td>{c.capacity}</td>
                        <td>
                          <div className="d-flex justify-content-start">
                            <Link
                              to={"#"}
                              className="btn btn-primary btn-sm me-2"
                              onClick={() => assignSectionForEdit(c)}
                            >
                              <MdModeEdit size={15} />
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-danger btn-sm"
                              onClick={() => handleDeleteConfirmation(c.id)}
                            >
                              <MdDelete size={15} />
                            </Link>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <h5 className="text-danger text-center w-100" style={{}}>
                No record found!
              </h5>
            )}
          </div>
          {currentList.length > 0 && (
            <div className="mt-3">
              <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => paginate(currentPage - 1)}
                  >
                    <IoArrowBackCircle size={25} />
                  </PaginationLink>
                </PaginationItem>
                {[...Array(totalPages).keys()].map((number) => (
                  <PaginationItem
                    key={number}
                    active={number + 1 === currentPage}
                  >
                    <PaginationLink onClick={() => paginate(number + 1)}>
                      {number + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => paginate(currentPage + 1)}
                  >
                    <IoArrowForwardCircle size={25} />
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </div>
          )}
        </CardBody>
      </Card>
      <Modal isOpen={Deletemodal}>
        <Card>
          <CardBody className="m-2">
            <h5>Are you sure you want to delete this Section?</h5>
          </CardBody>
          <CardFooter>
            <button className="btn btn-danger me-2" onClick={handleDeleteClick}>
              Delete
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setDeleteModal(false)}
            >
              Cancel
            </button>
          </CardFooter>
        </Card>
      </Modal>
    </div>
  );
}

export default ViewAssignSection;
