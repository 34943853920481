import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
} from "../../../../../components/input-fields";
import { subjectsOffered } from "../../../../../db/fields-data";
import Notify from "../../../../../components/Notify-toast";

export default function ALevelSubject({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  AlevelSubjectInfo,
  forView,
}) {
  console.log("subject info", AlevelSubjectInfo.subjectsTaken);
  let infoObject = "ALevelSubject";
  const [warningMessage, setWarningMessage] = useState("");
  const [subjectsAppearedDetails, setSubjectsAppearedDetails] = useState(
    AlevelSubjectInfo?.subjectsTaken || [{ subject: "" }]
  );

  useEffect(() => {
    console.log("AlevelSubjectInfo.subjectsTaken", AlevelSubjectInfo);
    let data;
    if (
      AlevelSubjectInfo?.subjectsTaken == undefined ||
      AlevelSubjectInfo?.subjectsTaken?.length < 1
    ) {
      console.log("should come here");
      data = [{ subject: "" }];
    } else {
      data = AlevelSubjectInfo?.subjectsTaken;
      data = AlevelSubjectInfo?.subjectsTaken;
    }
    setSubjectsAppearedDetails(data);
  }, [AlevelSubjectInfo.subjectsTaken]);

  useEffect(() => {
    // let keysToExclude = ['class'];
    // const isAnyKeyEmpty = Object.keys(AlevelSubjectInfo)
    //   .filter((key) => !keysToExclude.includes(key))
    //   .some((key) => {
    //     return AlevelSubjectInfo[key] === '' || AlevelSubjectInfo[key] === null;
    //   });
    // if (subjectsAppearedDetails.length >= 3) {
    //   updateTabComplete(true);
    // } else if (!isAnyKeyEmpty) {
    //   updateTabComplete(true);
    // } else {
    //   if (tabs[3].complete) {
    //     updateTabComplete(false);
    //   }
    // }
    const filterValue = subjectsAppearedDetails.filter((v) => v.subject === "");
    if (
      subjectsAppearedDetails &&
      subjectsAppearedDetails.length - filterValue.length >= 3
    ) {
      updateTabComplete(true);
      console.log("checked length", subjectsAppearedDetails.length);
    } else {
      console.log("checked length false", subjectsAppearedDetails?.length);
      updateTabComplete(false);
    }
  }, [AlevelSubjectInfo]);

  const handleAddSubject = () => {
    console.log("AlevelSubjectInfo", AlevelSubjectInfo);
    console.log("subjectsAppearedDetails", subjectsAppearedDetails);
    if (subjectsAppearedDetails?.length >= 5) {
      setWarningMessage("Limit exceeded: You can only add up to 5 subjects.");
    } else if (
      subjectsAppearedDetails &&
      (subjectsAppearedDetails?.length === 0 ||
        subjectsAppearedDetails[subjectsAppearedDetails?.length - 1]
          ?.subject !== "")
    ) {
      setSubjectsAppearedDetails([...subjectsAppearedDetails, { subject: "" }]);
      setWarningMessage(""); // Clear the warning message if a new subject is successfully added
    } else {
      setWarningMessage("Please select a subject before adding a new one.");
    }
  };
  console.log("subject name", subjectsAppearedDetails);

  const handleSubjectChange = (index, key, value) => {
    const updatedSubjects = subjectsAppearedDetails?.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setSubjectsAppearedDetails(updatedSubjects);
    handleChangeInfo(infoObject, "subjectsTaken", updatedSubjects);
  };
  const handleRemoveLastRow = () => {
    if (subjectsAppearedDetails?.length > 1) {
      const updatedSub = subjectsAppearedDetails.slice(0, -1);
      setSubjectsAppearedDetails(updatedSub);
      handleChangeInfo(infoObject, "subjectsTaken", updatedSub);
    } else {
      Notify("error", "At least three subject are required");
    }
  };
  const getFilteredOptions = (currentIndex) => {
    const selectedOptions = subjectsAppearedDetails?.slice(0, currentIndex)?.map((subject) => subject?.subject);
    return subjectsOffered.filter(
      (option) => !selectedOptions?.includes(option)
    );
  };

  return (
    <React.Fragment>
      <form>
        <h6 className="mt-4 poppins-medium">A Level Subjects Details</h6>
        <div className="row">
          {warningMessage && <p style={{ color: "red" }}>{warningMessage}</p>}
          {subjectsAppearedDetails
            ? subjectsAppearedDetails.map((subject, index) => (
                <div className="row" key={index}>
                  <SelectField
                    label={`Subject ${index + 1}`}
                    compulsory={true}
                    value={subject.subject}
                    selectOptions={getFilteredOptions(index)}
                    disabled={forView}
                    onChange={(e) =>
                      handleSubjectChange(index, "subject", e.target.value)
                    }
                  />
                </div>
              ))
            : null}
        </div>
        {forView ? null : (
          <button
            type="button"
            disabled={subjectsAppearedDetails?.length > 4}
            className="btn btn-success mt-2"
            onClick={handleAddSubject}
          >
            Add Subject
          </button>
        )}

        {!forView && subjectsAppearedDetails?.length > 3 ? (
          <button
            type="button"
            className="btn btn-danger mx-1 mt-2"
            onClick={handleRemoveLastRow}
          >
            Remove Last Row
          </button>
        ) : null}
      </form>
    </React.Fragment>
  );
}
