import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  syllabus: [
    {
      id: 0,
      year: 2024,
      topic: "Programming with C",
      startDate: "September 29, 2024",
      endDate: "March 5, 2025",
      term: "Both",
      desc: "Know more about initial days of computer programming",
      book_ref: "Let Us C",
    },
    {
      id: 1,
      year: 2023,
      topic: "Programming with C",
      startDate: "Octuber 1, 2023",
      endDate: "March 2, 2024",
      term: 2,
      desc: "Know more about initial days of computer programming",
      book_ref: "Let Us C",
    },

    {
      id: 2,
      year: 2023,
      topic: "Programming with C",
      startDate: "April 10, 2023",
      endDate: "September 10, 2023",
      term: 1,
      desc: "Know more about initial days of computer programming",
      book_ref: "Let Us C",
    },
    {
      id: 3,
      year: 2022,
      topic: "Programming with C",
      startDate: "Octuber 6, 2022",
      endDate: "March 1, 2023",
      term: "Both",
      desc: "Know more about initial days of computer programming",
      book_ref: "Let Us C",
    },
  ],
};

export const syllabusSlice = createSlice({
  name: "syllabus",
  initialState,
  reducers: {
    addSyllabus: (state, action) => {
      state.syllabus.push(action.payload);
    },
    removeSyllabus: (state, action) => {
      return {
        ...state,
        syllabus: state.syllabus.filter((item) => action.payload !== item.id),
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { addSyllabus, removeSyllabus } = syllabusSlice.actions;

export const allSyllabus = (state) => state.allSyllabus.syllabus;

export default syllabusSlice.reducer;
