import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  CardFooter,
  Modal,
} from 'reactstrap';
import { MdClass } from 'react-icons/md';
import { statusClasses } from '../../../common/data/tasks';
import classNames from 'classnames';
import { MdModeEdit } from 'react-icons/md';
import CreateStudent from './create-student';
import { useSelector, useDispatch } from 'react-redux';
import TableComponent from '../../../components/table';
import { MdInfoOutline } from 'react-icons/md';
import { IoArrowBackCircle } from 'react-icons/io5';
import { IoArrowForwardCircle } from 'react-icons/io5';
import { MdLibraryBooks } from 'react-icons/md';
import { MdDelete } from 'react-icons/md';
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Loader from '../../../components/loader/Loader';
import {
  allStudents,
  StudentData,
  deleteStudent,
  removeStudent,
} from '../../../redux/slice/students/studentsSlice';
import {
  CampusData,
  allCampus,
} from '../../../redux/slice/academics/CampusSlice';
import {
  ClassesDataSingle,
  allClasses,
} from '../../../redux/slice/classesSlice';
import FilterInput from '../../../components/FilterInputComponent';
import {
  UpdateSingleClassSection,
  postSingleClassSection,
} from '../../../redux/slice/students/studentsSlice';
import {
  SectionClassesData,
  allSections,
} from '../../../redux/slice/academics/sectionsSlice';

const MaptoReactStudentObj = (student) => {
  console.log('student-data', student);
  const reactStudentObj = {
    Id: student.id,
    personal: {
      name: student.name,
      dateOfBirth: student.dateOfBirth,
      bForm: student.nic,
      nationality: student.nationality,
      gender: student.gender,
      permanentAddress: student.permenantAddress,
      residentialAddress: student.residentialAddress,
      city: student.city,
      area: student.area,
      mobileNumber: student.phone,
      emailAddress: student.email,
      passportSizePicture: [student?.studentImageUrl],
      birthCertificate: [student?.birthCertificateImageUrl],
      b_form: [student?.nicImageUrl],
    },
    parents: {
      fatherName: student.guardians[0]?.name,
      fatherNICNumber: student?.guardians[0]?.nic,
      fatherOccupation: student?.guardians[0]?.occupation,
      fatherOrganization: student?.guardians[0]?.organization,
      fatherEducation: '',
      fatherMobileNumber: student?.guardians[0]?.phone,
      fatherEmailAddress: student?.guardians[0]?.email,
      fatherUploadNIC: [student?.guardians[0]?.nicImageUrl],
      fatherEducation: student?.guardians[0]?.education,
      fatherUploadPassportPicture: [student?.guardians[0]?.guardianImageUrl],
      motherName: student?.guardians[1]?.name,
      motherNICNumber: student?.guardians[1]?.nic,
      motherOccupation: student?.guardians[1]?.occupation,
      motherOrganization: student?.guardians[1]?.organization,
      motherEducation: student?.guardians[1]?.education,
      motherMobileNumber: student?.guardians[1]?.phone,
      motherEmailAddress: student?.guardians[1]?.email,
      motherUploadNIC: [student?.guardians[1]?.nicImageUrl],
      motherUploadPassportPicture: [student?.guardians[1]?.guardianImageUrl],
      maritalStatus: student?.guardians[1]?.maritalStatus,
    },
    emergency: {
      name: student?.studentEmergencyContactInfo?.name,
      relWithChild: student?.studentEmergencyContactInfo?.relationShipType,
      mobileNumber: student?.studentEmergencyContactInfo?.phone,
      email: student?.studentEmergencyContactInfo?.email,
      homeAddress: student?.studentEmergencyContactInfo?.homeAddress,
    },
    education: {
      previouslyAttended: student.studentEducationInfo?.previouslyAttended,
      schoolName: student.studentEducationInfo?.schoolName,
      schoolAddress: student.studentEducationInfo?.schoolAddress,
      lastAcademicResults: [student?.studentEducationInfo?.lastAcademicResult],
      class: student?.studentAdmissionStatus?.class?.id,
      reasonForLeaving: student.studentEducationInfo?.reasonForLeaving,
      term: student.studentAdmissionStatus?.academicYear?.id,
      campus: student?.studentAdmissionStatus?.campus?.id,
    },
    medical: {
      diabetes: student.studentMedicalInfo?.hasDiabetes == false ? 'No' : 'Yes',
      physicalDisability:
        student.studentMedicalInfo?.hasPhysicalDisability == false
          ? 'No'
          : 'Yes',
      hearingImpairment:
        student.studentMedicalInfo?.hasHearingImpairment == false
          ? 'No'
          : 'Yes',
      visualImpairment:
        student.studentMedicalInfo?.hasVisualImpairment == false ? 'No' : 'Yes',
      anyAllergies:
        student.studentMedicalInfo?.hasAllergy == false ? 'No' : 'Yes',
      allergies: student.studentMedicalInfo?.allergyReason,
    },
    status: {
      status: student.studentAdmissionStatus?.status,
    },
  };
  return reactStudentObj;
};
function StudentAcademics() {
  const dispatch = useDispatch();
  const { students, loading, error, totalPages } = useSelector(allStudents);
  const { singleSection } = useSelector(allSections);
  const { currentCampus, campus } = useSelector(allCampus);
  const { singleClasses } = useSelector(allClasses);

  const [create, setCreate] = useState(false);
  const [disabled, setDisable] = useState(false);
  const [stdid, setStdId] = useState();
  const [selectedStudent, setSelectedStudent] = useState({});
  const [modal, setModal] = useState(false);
  const [AssignModal, setAssignModal] = useState(false);
  const [AssignData, setAssignData] = useState({
    id: '',
    campusId: '',
    classId: '',
    sectionId: '',
    studentClassDetail: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [currentData, setCurrentData] = useState({
    currentClass: '0',
  });

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      const data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        ClassId:
          currentData.currentClass !== '0' ? +currentData.currentClass : null,
        // CampusId: Number(currentCampus),
        AssignClass_Status: false,
      };
      dispatch(StudentData(data));
    } else {
      const data = {
        pageSize: itemsPerPage,
        pageNumber: pageNumber,
        ClassId:
          currentData.currentClass !== '0' ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
        AssignClass_Status: false,
      };
      dispatch(StudentData(data));
    }
  };

  useEffect(() => {
    if (currentCampus == 0) {
      dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage,
        pageNumber: currentPage,
        ClassId:
          currentData.currentClass !== '0' ? +currentData.currentClass : null,
        // CampusId: Number(currentCampus),
        AssignClass_Status: false,
      };
      dispatch(StudentData(data));
      dispatch(CampusData());
    } else if (currentPage === 1 && currentCampus) {
      dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage,
        pageNumber: currentPage,
        ClassId:
          currentData.currentClass !== '0' ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
        AssignClass_Status: false,
      };
      dispatch(StudentData(data));
      dispatch(CampusData());
    }
  }, [itemsPerPage, currentCampus, currentData, currentPage]);

  useEffect(() => {
    setCurrentData((prev) => ({
      ...prev,
      currentClass: '0',
    }));
  }, [currentCampus]);
  // console.log("received-api-data-from-redux", students, loading, error);

  const createToggle = () => {
    setSelectedStudent({});
    setCreate(!create);
    setDisable(false);
  };

  const deleteStudentData = (id) => {
    console.log('receivd-id', id);
    dispatch(deleteStudent(id));
    setModal(false);
  };
  const handleClick = (id) => {
    setStdId(id);
    setModal(true);
  };
  const handleAssign = (data) => {
    console.log('Data', data);
    setAssignModal(true);
    setAssignData({
      id: data?.id,
      campusId: '',
      classId: '',
      sectionId: '',
      studentClassDetail: data.studentClassDetail,
    });
  };
  useEffect(() => {
    if (AssignData.campusId !== null && AssignData.campusId !== undefined) {
      dispatch(ClassesDataSingle(+AssignData.campusId));
    }
    if (
      AssignData.campusId !== null &&
      AssignData.campusId !== undefined &&
      AssignData.classId !== null &&
      AssignData.classId !== undefined
    ) {
      dispatch(
        SectionClassesData({
          campusId: +AssignData.campusId,
          classId: +AssignData.classId,
        })
      );
    }
  }, [AssignData.campusId, AssignData.classId, dispatch]);

  console.log('assign-data', students);
  // console.log("classes-data", singleClasses);
  // console.log("section-data", singleSection);

  const HandleUpdateClassSection = () => {
    if (AssignData.studentClassDetail !== null) {
      console.log('HandleUpdateClassSection if', AssignData.studentClassDetail);
      dispatch(
        UpdateSingleClassSection({
          id: AssignData.id,
          sectionData: {
            campusId: +AssignData.campusId,
            classId: +AssignData.classId,
            sectionId: +AssignData.sectionId,
          },
        })
      );
    }
    if (AssignData.studentClassDetail === null) {
      dispatch(
        postSingleClassSection({
          id: AssignData.id,
          sectionData: {
            campusId: +AssignData.campusId,
            classId: +AssignData.classId,
            sectionId: +AssignData.sectionId,
          },
        })
      );
    }
    // if (students.studentClassDetail === null) {
    // }
    setAssignModal(false);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Students" breadcrumbItem="Academics" />
        <Row className="mb-2">
          <Col className="justify-content-end d-flex">
            <Button
              color="success"
              className={`btn btn-success waves-effect waves-light mx-1 }`}
              onClick={createToggle}
            >
              {create ? 'Show List' : 'Create Student'}
            </Button>
          </Col>
        </Row>
        {!create ? (
          <Row>
            <Col lg={12}>
              {/* <TableComponent
                title={'New Students'}
                headers={newStudentsHeader}
                data={simpleStudentsData}
                editItem={handleUpdate}
              /> */}
              <Card>
                <CardBody>
                  <div className="">
                    <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold">
                      New Students
                    </CardTitle>
                    <div className="my-2 w-100 d-flex">
                      <div className="w-75 d-flex">
                        <FilterInput
                          options={singleClasses}
                          value={currentData.currentClass}
                          label="Filter by Class ---"
                          onChange={(e) => {
                            setCurrentData({
                              currentClass: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <select
                        className="form-control w-25"
                        style={{ borderColor: 'rgb(59 93 231 / 47%)' }}
                        value={itemsPerPage}
                        onChange={(e) => {
                          setCurrentPage(1);
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Items per page
                        </option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {/* {students.filter(
                      (std) => std.admissionStatus?.status === 'Pending'
                    ).length === 0 ? (
                      <p className="text-danger text-center">
                        No records found!!
                      </p>
                    ) : ( */}
                    <Table className="table table-striped mb-0">
                      {loading ? (
                        <Loader />
                      ) : (
                        <>
                          <thead>
                            <tr>
                              <th>Roll Number</th>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Gender</th>
                              <th>Age</th>
                              {/* <th>Class</th> */}
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {students?.map((std, ind) => (
                              <tr key={ind}>
                                <td className="text-nowrap">
                                  {std.registerationNo}
                                </td>
                                <td className="text-nowrap">
                                  {std.prospectiveStudent?.name}
                                </td>
                                <td className="text-nowrap">
                                  {std.prospectiveStudent?.email}
                                </td>
                                <td className="text-nowrap">
                                  {std.prospectiveStudent?.gender}
                                </td>
                                <td className="text-nowrap">
                                  {std.prospectiveStudent?.age}
                                </td>
                                {/* <td>{std.prospectiveStudent.class}</td> */}
                                <td className="d-flex justify-content-start">
                                  <FaRegEye
                                    onClick={() => {
                                      setSelectedStudent(
                                        MaptoReactStudentObj(
                                          std.prospectiveStudent
                                        )
                                      );
                                      setCreate(!create);
                                      setDisable(true);
                                    }}
                                    className="me-2 text-success"
                                    size={15}
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <MdModeEdit
                                    onClick={() => {
                                      setSelectedStudent(
                                        MaptoReactStudentObj(
                                          std.prospectiveStudent
                                        )
                                      );
                                      setCreate(!create);
                                      setDisable(false);
                                    }}
                                    size={15}
                                    style={{ cursor: 'pointer' }}
                                    className="me-2 text-primary"
                                    title="Edit Student"
                                  />
                                  <MdDelete
                                    onClick={() => handleClick(std.id)}
                                    size={15}
                                    style={{ cursor: 'pointer' }}
                                    className="me-2 text-danger"
                                  />
                                  <MdClass
                                    onClick={() => handleAssign(std)}
                                    size={15}
                                    style={{ cursor: 'pointer' }}
                                    className="me-2 text-primary"
                                  />
                                  {/* <Link
                                    style={{ textDecoration: 'none' }}
                                    to={`https://repeng.braincrop.net/api/StudentReport?&rollno=${std.registerationNo}`}
                                    target="_blank"
                                  >
                                    <MdInfoOutline
                                      className="me-2 text-primary"
                                      size={15}
                                      style={{ cursor: 'pointer' }}
                                      title="Student Info"
                                    />
                                  </Link>
                                  <Link
                                    style={{ textDecoration: 'none' }}
                                    to={`https://repeng.braincrop.net/api/PersonalAttendance?&rollno=${std.registerationNo}`}
                                    target="_blank"
                                  >
                                    <MdLibraryBooks
                                      className="me-2 text-secondary"
                                      size={15}
                                      style={{ cursor: 'pointer' }}
                                      title="Student Attendence"
                                    />
                                  </Link> */}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </>
                      )}
                    </Table>
                    <div className="mt-5">
                      <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                        <PaginationItem disabled={currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={() => paginate(currentPage - 1)}
                          >
                            <IoArrowBackCircle size={25} />
                          </PaginationLink>
                        </PaginationItem>
                        {[...Array(totalPages).keys()].map((number) => (
                          <PaginationItem
                            key={number}
                            active={number + 1 === currentPage}
                          >
                            <PaginationLink
                              onClick={() => paginate(number + 1)}
                            >
                              {number + 1}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={currentPage === totalPages}>
                          <PaginationLink
                            next
                            onClick={() => paginate(currentPage + 1)}
                          >
                            <IoArrowForwardCircle size={25} />
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            {/* <Col lg={12}>
              <TableComponent
                title={'Exam Toppers'}
                headers={examToppersHeader}
                data={_ExamTopper}
              />
            </Col>
            <Col lg={12}>
              <TableComponent
                title={'Sports Day Registration'}
                headers={sportsDayHeader}
                data={_SportsDay}
              />
            </Col> */}
          </Row>
        ) : (
          <CreateStudent
            forView={disabled}
            createToggle={createToggle}
            selectedStudent={selectedStudent}
          />
        )}
      </div>
      <Modal isOpen={modal} centered>
        <Card>
          <CardBody className="mt-2">
            <h5 style={{ fontWeight: 'normal' }}>
              Are you sure you want to delete this student ?
            </h5>
          </CardBody>
          <CardFooter>
            <button
              className="btn btn-danger me-2"
              onClick={() => deleteStudentData(stdid)}
            >
              Delete
            </button>
            <button className="btn btn-primary" onClick={() => setModal(false)}>
              Cancel
            </button>
          </CardFooter>
        </Card>
      </Modal>
      <Modal isOpen={AssignModal} size="lg" centered>
        <Card>
          <CardBody className="mt-2">
            <form>
              <div className="row">
                <div className="col-lg-4">
                  <label>Campus</label>
                  <select
                    className="form-control"
                    value={AssignData.campusId}
                    onChange={(e) =>
                      setAssignData({
                        ...AssignData,
                        campusId: e.target.value,
                      })
                    }
                  >
                    <option value=" " selected>
                      Select Campus
                    </option>
                    {campus?.map((v, ind) => (
                      <option key={ind} value={v.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label>Class</label>
                  <select
                    className="form-control"
                    value={AssignData.classId}
                    onChange={(e) =>
                      setAssignData({
                        ...AssignData,
                        classId: e.target.value,
                      })
                    }
                  >
                    <option value=" " selected>
                      Select Class
                    </option>
                    {singleClasses?.map((v, ind) => (
                      <option key={ind} value={v.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label>Section</label>
                  <select
                    className="form-control"
                    value={AssignData.sectionId}
                    onChange={(e) =>
                      setAssignData({
                        ...AssignData,
                        sectionId: e.target.value,
                      })
                    }
                  >
                    <option value=" " selected>
                      Select Section
                    </option>
                    {singleSection?.map((v, index) => (
                      <option key={index} value={v.id}>
                        {v?.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </CardBody>
          <CardFooter>
            <button
              className="btn btn-danger me-2"
              onClick={() => HandleUpdateClassSection()}
            >
              {AssignData.studentClassDetail === null ? 'Add' : 'Update'}
              {/* Update */}
            </button>
            <button
              className="btn btn-primary"
              onClick={() => setAssignModal(false)}
            >
              Cancel
            </button>
          </CardFooter>
        </Card>
      </Modal>
    </React.Fragment>
  );
}

export default StudentAcademics;
