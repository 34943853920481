import React, { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  InputImageField,
  SelectFieldForId,
} from "../../../../../components/input-fields";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allCampus } from "../../../../../redux/slice/academics/CampusSlice";
import { IoMdAdd } from "react-icons/io";
import {
  subjectsData,
  sessionsData,
  gradesData,
} from "../../../../../db/fields-data";

export default function CommunityReffernceForm({
  tabs,
  forUpdate,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  handleFileRemove,
  community,
  forView,
}) {
  let infoObject = "community";
  const { campus } = useSelector(allCampus);
  const [addedNoOfSubjects, setAddedNoOfSubjects] = useState(0);
  const [refferenceDetailsState, setRefferenceDetailsState] = useState(
    community.refferenceDetails || [{
      name: "",
      relation: "",
      position: "",
      campus: "",
      status: "",
    }]
  );

  useEffect(() => {
    console.log("community.refferenceDetails", community.refferenceDetails);
    // if(!community.refferenceDetails || community.refferenceDetails.length < 1){
    //   setRefferenceDetailsState([{
    //     name: "",
    //     relation: "",
    //     position: "",
    //     campus: "",
    //     status: "",
    //   }])
    // }
    // else{
      setRefferenceDetailsState(community.refferenceDetails);
    // }
  }, [community]);

  useEffect(() => {
    let keysToExclude = ["refferenceDetails"];
    const isAnyKeyEmpty = Object.keys(community)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return community[key] === "" || community[key] === null;
      });
    console.log("isanyKeyEmpty", isAnyKeyEmpty);
    let emptyReferences = community.refferenceDetails.filter((subject) =>
      Object.values(subject).some((value) => value === "")
    );
    if (!isAnyKeyEmpty) {
      console.log("isanyKeyEmpty inside");
      if (
        community.isCommunityRef == "Yes" &&
        community.refferenceDetails.length - emptyReferences.length == 0
      ) {
        updateTabComplete(false);
      } else {
        updateTabComplete(true);
      }
    } else {
      if (tabs[3].complete) {
        updateTabComplete(false);
      }
    }
  }, [community]);

  const handleAddReffernece = () => {
    setRefferenceDetailsState([
      ...refferenceDetailsState,
      { subject: "", session: "", grade: "" },
    ]);
  };

  const handleDetailChange = (index, key, value) => {
    const updatedSubjects = refferenceDetailsState.map((subject, i) =>
      i === index ? { ...subject, [key]: value } : subject
    );
    setRefferenceDetailsState(updatedSubjects);
    handleChangeInfo(infoObject, "refferenceDetails", updatedSubjects);
  };

  return (
    <React.Fragment>
      <form>
        <div className="row">
          <h6 className="mt-4 poppins-medium">City School Association</h6>
          <SelectField
            label="Any City School Association"
            compulsory={true}
            value={community.isCommunityRef}
            selectOptions={["No", "Yes"]}
            disabled={forView}
            onChange={(e) =>
              handleChangeInfo(infoObject, "isCommunityRef", e.target.value)
            }
          />
          {community.isCommunityRef == "Yes"
            ? refferenceDetailsState.map((refferPerson, index) => (
                <div key={index}>
                  <p className="fs-6 text-warning mt-2">
                    Reference Detail {index + 1}
                  </p>
                  <div className="row" key={index}>
                    <InputField
                      label="Name"
                      type="text"
                      compulsory={true}
                      value={refferPerson.name}
                      placeholder="Type Name"
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "name", e.target.value)
                      }
                    />
                    <InputField
                      label="Relation"
                      type="text"
                      compulsory={true}
                      value={refferPerson.relation}
                      placeholder="Type Relation"
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "relation", e.target.value)
                      }
                    />
                    <SelectField
                      label="Position"
                      compulsory={true}
                      value={refferPerson.position}
                      selectOptions={["Staff", "Student"]}
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "position", e.target.value)
                      }
                    />
                    <InputField
                      label="Campus"
                      type="text"
                      compulsory={true}
                      value={refferPerson.campus}
                      placeholder="Enter Campus"
                      // selectOptions={campus}
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "campus", e.target.value)
                      }
                    />
                    <SelectField
                      label="Status"
                      compulsory={true}
                      value={refferPerson.status}
                      selectOptions={["Current", "Former", "Alumni"]}
                      disabled={forView}
                      onChange={(e) =>
                        handleDetailChange(index, "status", e.target.value)
                      }
                    />
                  </div>
                </div>
              ))
            : null}
        </div>
        {community.isCommunityRef == "Yes" && !forView ? (
          <button
            type="button"
            className="btn btn-success my-2"
            onClick={handleAddReffernece}
          >
            Add Refference
          </button>
        ) : null}
      </form>
    </React.Fragment>
  );
}
