import { useEffect, useState } from "react";
import {
  InputField,
  SelectField,
  SelectFieldForId,
} from "../../../components/input-fields";
import Notify from "../../../components/Notify-toast";
import { useSelector, useDispatch } from "react-redux";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  CreateTimetableData,
  UpdateTimetableData,
} from "../../../redux/slice/academics/timetableSlice";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import {
  allCourses,
  courseData,
} from "../../../redux/slice/academics/CoursesSlice";
import Select from "react-select";
import { reactSelectStyle } from "../../../helpers/react-select-style";
import "react-datepicker/dist/react-datepicker.css";
import { weekDaysWithId } from "../../../db/fields-data";
import {
  allClassroom,
  ClassRoomFetch,
} from "../../../redux/slice/academics/classroomSlice";

export default function TimeTableForm(props) {
  const dispatch = useDispatch();
  const { currentCampus } = useSelector(allCampus);
  const { singleClasses } = useSelector(allClasses);
  const { course, loading, courseError } = useSelector(allCourses);
  const { classroomsData } = useSelector(allClassroom);
  const [allCourseData, setAllCourseData] = useState([]);
  const [createData, setCreateData] = useState({
    class: "",
    classroom: "",
    course: "",
    section: "",
    day: "",
    startTime: "",
    endTime: "",
  });

  useEffect(() => {
    if (props.timetableData && Object.keys(props.timetableData).length !== 0) {
      console.log("timetableData update", props.timetableData);
      setCreateData(props.timetableData);
    }
  }, []);

  useEffect(() => {
    console.log("dispatching classes data");
    if (currentCampus == 0) {
      let data = null;
      dispatch(ClassesDataSingle(data));
    } else {
      let data = Number(currentCampus);
      dispatch(ClassesDataSingle(data));
    }
  }, [currentCampus]);

  useEffect(() => {
    if (currentCampus == 0) {
      let data = {
        ClassId: createData.class ? createData.class : null,
      };
      dispatch(courseData(data));
    } else {
      let data = {
        CampusId: Number(currentCampus),
        ClassId: createData.class ? createData.class : null,
      };
      dispatch(courseData(data));
    }
  }, [createData.class]);

  useEffect(() => {
    if (currentCampus == 0) {
      let data = {};
      dispatch(ClassRoomFetch(data));
    } else {
      let data = {
        CampusId: Number(currentCampus),
      };
      dispatch(ClassRoomFetch(data));
    }
  }, [currentCampus]);

  useEffect(() => {
    console.log("courses", course);
    const transformedArray = course.map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
    setAllCourseData(transformedArray);
  }, [course]);

  const handleChangeInfo = (fieldName, value) => {
    console.log(fieldName, "change to", value);
    setCreateData((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleSubmit = () => {
    console.log("createData", createData);
    if (currentCampus == "0") {
      Notify("error", "Please Select Campus from top header.");
    } else if (!createData.class) {
      Notify("error", "Please Select Class.");
    } else if (!createData.classroom) {
      Notify("error", "Please Select Classroom.");
    } else if (!createData.course) {
      Notify("error", "Please Select Course.");
    } else if (
      !createData.startTime ||
      !createData.endTime ||
      !createData.day
    ) {
      Notify("error", "Please Select Day and Time");
    } else {
      let bodyData = {
        campusId: Number(currentCampus),
        classId: Number(createData.class),
        roomId: Number(createData.classroom),
        // sectionName: "None",
        courseId: Number(createData.course),
        day: Number(createData.day),
        startTime: createData.startTime + ":00",
        endTime: createData.endTime + ":00",
      };
      console.log("bodyData", bodyData);
      if (
        props.timetableData &&
        Object.keys(props.timetableData).length !== 0
      ) {
        console.log("updating...");
        let id = Number(props?.updateId);
        dispatch(UpdateTimetableData({ id, bodyData }));
      } else {
        console.log("creating...");
        dispatch(CreateTimetableData(bodyData));
      }
      props.toggleTab();
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">
            {props.timetableData &&
            Object.keys(props.timetableData).length !== 0
              ? "Update Timetable"
              : "Create Timetable"}
          </h5>
          <div className="row mt-4">
            <SelectFieldForId
              label="Select Class"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              disabled={false}
              value={createData.class}
              selectOptions={singleClasses}
              onChange={(e) => handleChangeInfo("class", e.target.value)}
            />
            <SelectFieldForId
              label="Select Classroom"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              disabled={false}
              value={createData.classroom}
              selectOptions={classroomsData}
              onChange={(e) => handleChangeInfo("classroom", e.target.value)}
            />
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Select Course</label>
              <Select
                options={allCourseData}
                value={allCourseData.find(
                  (option) => option.value === createData.course
                )}
                onChange={(selectedOption) => {
                  setCreateData((prev) => ({
                    ...prev,
                    course: selectedOption ? selectedOption.value : "",
                  }));
                }}
                placeholder="Select Course"
                isClearable
                className="rounded"
                styles={reactSelectStyle}
              />
            </div>
            <SelectFieldForId
              label="Select Day"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={true}
              disabled={false}
              value={createData.day}
              selectOptions={weekDaysWithId}
              onChange={(e) => handleChangeInfo("day", e.target.value)}
            />
            <InputField
              label="Start Time"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={false}
              disabled={false}
              type="time"
              value={createData.startTime}
              placeholder="Select Start Time"
              onChange={(e) => handleChangeInfo("startTime", e.target.value)}
            />
            <InputField
              label="End Time"
              className="col-sm-12 col-md-6 col-lg-4 my-2"
              compulsory={false}
              disabled={false}
              type="time"
              value={createData.endTime}
              placeholder="Select End Time"
              onChange={(e) => handleChangeInfo("endTime", e.target.value)}
            />
          </div>
          <button
            className="btn btn-success float-end mt-4"
            onClick={handleSubmit}
          >
            {props.timetableData &&
            Object.keys(props.timetableData).length !== 0
              ? "Update"
              : "Create"}
          </button>
        </div>
      </div>
    </div>
  );
}
