import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAssignCourseData,
  PostAssignCourseData,
  UpdateAssignCourseData,
} from "../../../api/Academics/AssignCourses";
import { toast } from "react-toastify";
import Notify from "../../../components/Notify-toast";

export const AssignCourseData = createAsyncThunk(
  "AssignCourse/FetchAssignCourse",
  async (data) => {
    try {
      const response = await fetchAssignCourseData(data);
      const _response = {
        data: response.data,
        status: response.status,
      };
      return _response;
    } catch (error) {
      throw Error("Failed to fetch AssignCourse data");
    }
  }
);
export const PostAssignCourse = createAsyncThunk(
  "AssignCourse/postFetchAssignCourse",
  async ({ id, data }) => {
    try {
      const response = await PostAssignCourseData({ id: id, data: data });
      console.log(response.data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to fetch AssignCourse data");
    }
  }
);
export const UpdateAssignCourse = createAsyncThunk(
  "AssignCourse/UpdateFetchAssignCourse",
  async ({ id, data }) => {
    try {
      const response = await UpdateAssignCourseData({ id: id, data: data });
      console.log(response.data);
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error("Failed to fetch AssignCourse data");
    }
  }
);
const initialState = {
  AssignCourse: [],
  // singleSection: [],
  loading: false,
  error: null,
  totalPages: 1,
};

export const AssignCourse = createSlice({
  name: "AssignCourse",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(AssignCourseData.pending, (state) => {
        state.loading = true;
      })
      .addCase(AssignCourseData.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          console.log("assign-course data", action.payload?.data?.items)
          state.AssignCourse = action.payload.data.items;
          state.totalPages = action.payload.data.totalPages;
        } else {
          Notify("error", "Error on fetching Assigned Course Data");
        }
      })
      .addCase(AssignCourseData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(PostAssignCourse.pending, (state) => {
        state.loading = true;
      })
      .addCase(PostAssignCourse.fulfilled, (state, action) => {
        //   state.loading = false;
        //   if (action.payload) {
        //     state.AssignCourse = [...state.AssignCourse, action.payload];
        //     Notify("success", 'Course Added SuccessFully');
        //   } else {
        //     Notify("error", 'Course Not Created. Something Went Wrong');
        //   }
        // })
        state.loading = false;
        if (action.payload) {
          state.AssignCourse = state.AssignCourse.map((cls) =>
            cls.id === action.payload.id ? action.payload : cls
          );
          Notify("success", "Course Data has been updated for the student");
        } else {
          Notify("error", "Error on updating the course. Something went wrong");
        }
      })
      .addCase(PostAssignCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(UpdateAssignCourse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(UpdateAssignCourse.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.AssignCourse = state.AssignCourse.map((cls) =>
            cls.id === action.payload.id ? action.payload : cls
          );
          Notify("success", "Course Data has been updated for the student");
        } else {
          Notify("error", "Error on updating the course. Something went wrong");
        }
      })
      .addCase(UpdateAssignCourse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// export const { currentCampusSelect } = AssignCourse.actions;

export const allAssignCourse = (state) => state.allAssignCourse;

export default AssignCourse.reducer;
