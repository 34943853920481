

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { emailVerifiaction, otpVerification, RegisterData } from '../../../api/Authentication/RegisterhelperApi';

export const sendEmail = createAsyncThunk('send_email', async (data) => {
  try {
    const response = await emailVerifiaction(data);
    // const response = "OK";
    return response;
  } catch (error) {
    console.log(error)
    throw error?.response ? error.response?.data : error?.message;
  }
});

export const sendOTP = createAsyncThunk('send_otp', async (data) => {
  try {
    const response = await otpVerification(data);
    // const response = "OK";
    return response;
  } catch (error) {
    throw error?.response ? error.response?.data : error?.message;
  }
});

export const Registration = createAsyncThunk('register', async (data) => {
  try {
    const response = await RegisterData(data);
    const _response = {
      data: response.data,
      status: response.status,
    };
    return _response;
  } catch (error) {
    throw error?.response ? error.response?.data : error?.message;
  }
});

const initialState = {
  response: [],
  stage: 1,
  loading: false,
  error: null,
};
export const Registerslice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetStage: (state, action) => {
      state.stage = 1;
      state.response = [];
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        state.error = null;
        console.log("stage 1 response",action?.payload?.status)
        if(action?.payload?.status === 200){
          state.stage = 2;
        }
      })
      .addCase(sendEmail.rejected, (state, action) => {
        console.log('rejected', action);
        state.loading = false;
        state.error = action.error;
      });
    builder
      .addCase(sendOTP.pending, (state) => {
        state.response = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(sendOTP.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        console.log("stage 2 response",action.payload)
        state.error = null;
        if(action?.payload?.status === 200){
          state.stage = 3;
        }
      })
      .addCase(sendOTP.rejected, (state, action) => {
        console.log('rejected', action);
        state.loading = false;
        console.log("action error",action?.error?.message)
        state.error = action?.error;
      });
    builder
      .addCase(Registration.pending, (state) => {
        state.response = [];
        state.loading = true;
        state.error = null;
      })
      .addCase(Registration.fulfilled, (state, action) => {
        state.loading = false;
        state.response = action.payload;
        console.log("stage 3 response",action.payload)
        state.error = null;
        if(action?.payload?.status === 200){
          state.stage = 4;
        }
      })
      .addCase(Registration.rejected, (state, action) => {
        console.log('rejected', action);
        state.loading = false;
        state.error = action?.error;
      });
  },
});
export const { resetStage } = Registerslice.actions;

export const allRegistration = (state) => state.allRegistration;

export default Registerslice.reducer;

