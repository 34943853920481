import React from "react";

const FilterInput = ({
  options,
  value,
  onChange,
  label,
  style = {},
  ...props
}) => {
  return (
    <select
      className="form-control mx-1"
      style={{ borderColor: "rgb(59 93 231 / 47%)", width: "25%", ...style }}
      value={value}
      onChange={onChange}
      {...props}
    >
      <option value={"0"}>{label}</option>
      {options?.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

export default FilterInput;
