import { combineReducers, configureStore } from "@reduxjs/toolkit";
import assignmentSlice from "../slice/academics/assignmentSlice";
import CoursesSlice from "../slice/academics/CoursesSlice";
import ExamQuizSlice from "../slice/academics/ExamQuizSlice";
import studentSlice from "../slice/students/studentsSlice";
import syllabusSlice from "../slice/academics/syllabusSlice";
import staffSlice from "../slice/staff/catalogSlice";
import classSlice from "../slice/classesSlice";
import sectionsSlice from "../slice/academics/sectionsSlice";
import allTeachers from "../slice/teacher/teacherSlice";
import leaveSlice from "../slice/staff/leaveSlice";
import formOptionsSlice from "../slice/staff/formOptionsSlice";
import studentAttendenceSlice from "../slice/students/studentAttendanceSlice";
import applicantSlice from "../slice/admission/applicantSlice";
import Registerslice from "../slice/Authentication/RegisterSlice";
import allLogin from "../slice/Authentication/loginSlice";
import allDepartment from "../slice/academics/DepartmentSlice";
import allCampus from "../slice/academics/CampusSlice";
import allAssignSection from "../slice/academics/AsssignSectionSlice";
import allTimetable from "../slice/academics/timetableSlice";
import allAssignCourse from "../slice/academics/AssignCourses";
import allClassroom from "../slice/academics/classroomSlice";

const rootReducer = combineReducers({
  allAssignments: assignmentSlice,
  allCourses: CoursesSlice,
  allExamQuiz: ExamQuizSlice,
  allStudents: studentSlice,
  allSyllabus: syllabusSlice,
  allStaffs: staffSlice,
  allStaffLeaves: leaveSlice,
  allClasses: classSlice,
  allSections: sectionsSlice,
  allTeachers: allTeachers,
  staffFormOptions: formOptionsSlice,
  allstudentAttendance: studentAttendenceSlice,
  allApplicant: applicantSlice,
  allRegistration: Registerslice,
  allLogin: allLogin,
  allDepartment: allDepartment,
  allCampus: allCampus,
  allTimetable: allTimetable,
  allClassroom: allClassroom,
  allAssignSection: allAssignSection,
  allAssignCourse: allAssignCourse,
});

export const store = configureStore({
  reducer: rootReducer,
  // middleware,
  // middleware: getDefalutMiddleware =>
  //   getDefalutMiddleware({
  //     serializableCheck: false,
  //   }).concat(logger),
});
