import { axiosInstance, instanceAuth } from '../axiosConfig';

export const fetchStudentData = async (data) => {
  try {
    const response = await instanceAuth.get('Student', {
      params: {
        ...data,
        includeAll: true,
      },
    });
    return response;
  } catch (error) {
    console.log('error-fetching-data', error);
    throw error;
  }
};
export const ExportStudentData = async () => {
  try {
    const response = await instanceAuth.get('Student',{
      params:{
        includeAll:true
      },
    });
    return response;
  } catch (error) {
    console.log('error-fetching-data', error);
    throw error;
  }
};

export const deleteStudentData = async (id) => {
  try {
    const response = await instanceAuth.delete(`Student/${id}`);
    return response;
  } catch (error) {
    console.log('error-fetching-data', error);
    throw error;
  }
};

export const createStudent = async (formData) => {
  try {
    const response = await instanceAuth.post('Student', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.log('error-create-student', error);
    throw error;
  }
};

export const updateStudentData = async ({ id, formData }) => {
  try {
    const response = await instanceAuth.put(`Student/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  } catch (error) {
    console.log('error-update-studentData', error);
    throw error;
  }
};

export const addStudentsFileAPI = async (formData) => {
  try {
    const response = await instanceAuth.post(`StudentImport`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log("response", response)
    return response;
  } catch (error) {
    console.log('error-update-studentData', error);
    throw error;
  }
}
