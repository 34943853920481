import { useEffect, useState } from "react";
import { InputField, SelectFieldForId } from "../../../components/input-fields";
import Notify from "../../../components/Notify-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  createStudentAttendance,
  updateStudentAttandance,
} from "../../../redux/slice/students/studentAttendanceSlice";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  allStudents,
  StudentData,
} from "../../../redux/slice/students/studentsSlice";
import Select from "react-select";
import {
  allClassroom,
  ClassRoomFetch,
} from "../../../redux/slice/academics/classroomSlice";
import {
  allTimetable,
  TimetableData,
} from "../../../redux/slice/academics/timetableSlice.js";
import { reactSelectStyle } from "../../../helpers/react-select-style";
import { allCourses, courseData } from "../../../redux/slice/academics/CoursesSlice";
import { weekDaysWithId } from "../../../db/fields-data.js";

export default function AttendanceForm(props) {
  const dispatch = useDispatch();
  const { students, loading, error, totalPages } = useSelector(allStudents);
  const { currentCampus } = useSelector(allCampus);
  const { classroomsData } = useSelector(allClassroom);
  const { course } = useSelector(allCourses);
  const { timetable } = useSelector(allTimetable);
  const [studentData, setStudentData] = useState([]);
  const [roomData, setRoomData] = useState([]);
  const [coursesData, setCoursesData] = useState([]);
  const [filteredTimetable, setFilteredTimetable] = useState([]);
  const [createData, setCreateData] = useState({
    day: "",
    roomId: "",
    courseId: "",
    courseTimeTableId: "",
    startTime: "",
    studentId: "",
    timeIn: "",
    timeOut: "",
  });

  useEffect(() => {
    if (currentCampus == 0) {
      let data = {
        AssignClass_Status: true,
        includeAll: false
      };
      dispatch(StudentData(data));
    } else {
      let data = {
        AssignClass_Status: true,
        includeAll: false,
        CampusId: Number(currentCampus),
      };
      dispatch(StudentData(data));
    }
  }, [currentCampus]);

  useEffect(()=>{
    if(createData.day){
      let data = {
        CampusId: currentCampus != "0" ? currentCampus : null,
        Day: createData.day != "" ? Number(createData.day) : null,
        RoomId: createData.roomId != "" ? Number(createData.roomId) : null,
        CourseId: createData.courseId != "" ? Number(createData.courseId) : null,
      };
      dispatch(TimetableData(data));
    }
  }, [createData.day, createData.roomId, createData.courseId])

  useEffect(()=>{
    let timetableRooms = timetable.map(item => ({
      id: item?.roomId,
      name: item?.roomName,
    }));
    const uniqueRooms = Array.from(new Map(timetableRooms.map(item => [item.id, item])).values());
    setRoomData(uniqueRooms);
    let timetableCourses = timetable.map(item => ({
      id: item?.courseId,
      name: item?.courseName,
    }));
    const uniqueCourses = Array.from(new Map(timetableCourses.map(item => [item.id, item])).values());
    setCoursesData(uniqueCourses);
    if(createData.day && createData.roomId && createData.courseId){
      let filteredSlots = timetable.map(item => ({
        id: item?.id,
        name: item?.startTime,
      }));
      setFilteredTimetable(filteredSlots)
      // setCreateData((prev)=>({
      //   ...prev,
      //   timeIn: filteredSlots[0].name
      // }))
    }
    else{
      setFilteredTimetable([]);
    }
  }, [timetable])

  useEffect(() => {
    if (currentCampus == 0) {
      let data = {};
      dispatch(ClassRoomFetch(data));
      dispatch(courseData(data));
    } else {
      let data = {
        CampusId: Number(currentCampus),
      };
      dispatch(ClassRoomFetch(data));
      dispatch(courseData(data));
    }
  }, [currentCampus]);

  useEffect(() => {
    console.log("students", students);
    const transformedArray = students.map((item) => ({
      value: item?.id,
      label: item?.prospectiveStudent.name,
    }));
    setStudentData(transformedArray);
  }, [students]);

  useEffect(() => {
    // setting old data to state if coming for update
    if (
      props.studentAttendanceData &&
      Object.keys(props.studentAttendanceData).length !== 0
    ) {
      setCreateData(props.studentAttendanceData);
      console.log("data is coming for update");
    } else {
      console.log("data is not coming for create");
    }
  }, []);

  const handleChangeInfo = (fieldName, value) => {
    if(fieldName == "day"){
      setCreateData((prevState) => ({
        ...prevState,
        roomId: "",
        courseId: "",
        [fieldName]: value,
      }));
    }
    else if(fieldName == "roomId"){
      setCreateData((prevState) => ({
        ...prevState,
        courseId: "",
        [fieldName]: value,
      }));
    }
    else{
      setCreateData((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const handleSubmit = () => {
    console.log("createData", createData);
    if (!createData.studentId) {
      Notify("error", "Please Select student.");
    } else if (!createData.timeIn || !createData.timeOut) {
      Notify("error", "Please Select time in & time out.");
    } else {
      dispatch(createStudentAttendance(createData));
      props.toggleTab();
    }
  };

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Attendance Form</h5>
          <div className="row mt-4">
            <SelectFieldForId
              label="Select Day"
              className="col-sm-12 col-md-6 col-lg-6 my-2"
              compulsory={true}
              disabled={false}
              value={createData.day}
              selectOptions={weekDaysWithId}
              onChange={(e) => handleChangeInfo("day", e.target.value)}
            />
              <SelectFieldForId
                label="Select Classroom"
                className="col-sm-12 col-md-6 col-lg-6 my-2"
                compulsory={true}
                disabled={false}
                value={createData.roomId}
                selectOptions={roomData}
                onChange={(e) => handleChangeInfo("roomId", e.target.value)}
              />
            <SelectFieldForId
              label="Select Course"
              className="col-sm-12 col-md-6 col-lg-6 my-2"
              compulsory={true}
              disabled={false}
              value={createData.courseId}
              selectOptions={coursesData}
              onChange={(e) => handleChangeInfo("courseId", e.target.value)}
            />
            <div className="col-sm-12 col-md-6 col-lg-6 my-2">
              <label>Select Student</label>
              <Select
                options={studentData}
                value={studentData.find(
                  (option) => option.value === createData.studentId
                )}
                onChange={(selectedOption) => {
                  setCreateData((prev) => ({
                    ...prev,
                    studentId: selectedOption ? selectedOption.value : "",
                  }));
                }}
                placeholder="Select Student"
                isClearable
                className="rounded"
                styles={reactSelectStyle}
              />
            </div>
            <SelectFieldForId
              label="Select Time Slot"
              className="col-sm-12 col-md-6 col-lg-6 my-2"
              compulsory={true}
              disabled={false}
              value={createData.courseTimeTableId}
              selectOptions={filteredTimetable}
              onChange={(e) => handleChangeInfo("courseTimeTableId", e.target.value)}
            />
            <InputField
              label="Time In"
              className="col-sm-12 col-md-6 col-lg-6 my-2"
              compulsory={false}
              disabled={false}
              type="datetime-local"
              value={createData.timeIn}
              placeholder="Select Time In"
              onChange={(e) => handleChangeInfo("timeIn", e.target.value)}
            />
            <InputField
              label="Time Out"
              className="col-sm-12 col-md-6 col-lg-6 my-2"
              compulsory={false}
              disabled={false}
              type="datetime-local"
              value={createData.timeOut}
              placeholder="Select Time In"
              onChange={(e) => handleChangeInfo("timeOut", e.target.value)}
            />
          </div>
          <button
            className="btn btn-success float-end mt-4"
            onClick={handleSubmit}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}
