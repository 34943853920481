import React, { useEffect, useState } from "react";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Col, Row } from "reactstrap";
import ClassRoomForm from "./classroom-form";
import ClassRoomView from "./classroom-view";

export default function ClassRoom() {
  const [create, setCreate] = useState(false);
  const [classRoomData, setClassRoomData] = useState({});
  const [updateId, setUpdateId] = useState();

  const forUpdate = (class_data) => {
    console.log("for update time index", class_data);
    setUpdateId(class_data.id);
    setClassRoomData(class_data);
    setCreate(true);
  };

  const toggleTab = () => {
    setUpdateId();
    setClassRoomData({});
    setCreate(!create);
  };

  return (
    <div className="page-content">
      <Breadcrumbs title="Academics" breadcrumbItem="Classroom" />
      <Row className="mb-4">
        <Col className="justify-content-end d-flex">
          <button
            style={{
              background: "#37a26a",
              color: "white",
              padding: "12px 30px",
              border: "none",
              borderRadius: "18px",
              marginTop: "10px",
              marginRight: "10px",
            }}
            onClick={toggleTab}
          >
            {create ? "View" : "Create"}
          </button>
        </Col>
      </Row>
      {create ? (
        <ClassRoomForm
          classRoomData={classRoomData}
          updateId={updateId}
          toggleTab={toggleTab}
        />
      ) : (
        <ClassRoomView forUpdate={forUpdate} />
      )}
    </div>
  );
}
