import React from "react";
// Dashboard
import Dashboard from "../pages/Dashboard";
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
// School MS
import Assigments from "../pages/Academics/Assignments";
import Courses from "../pages/Academics/Courses/Courses";
import AcademicYear from "../pages/Academics/AcademicYear";
import Syllabus from "../pages/Academics/Syllabus";
import TeacherCatalog from "../pages/Teachers/Catalog";
import Classes from "../pages/Academics/Classes";
import ExamsQuiz from "../pages/Academics/ExamQuiz";
import TeacherFeedback from "../pages/Teachers/Feedback";
import Alumni from "../pages/Students/Alumni";
import StudentCatalogue from "../pages/Students/Academics";
import TimeTable from "../pages/Academics/TimeTable/TimeTable";
import Finance from "../pages/Finance/Fees/index.jsx";
import InvoiceSearch from "../pages/Finance/Invoice/index.jsx";
import Attendance from "../pages/Staff/Attendance";
import UpdateStaffUi from "../pages/Staff/Catalogue/updateStaff.js";
import ViewStaffUi from "../pages/Staff/Catalogue/viewStaff";
import CreateStaffUi from "../pages/Staff/Catalogue/createStaff.js";
// import UpdateClass from "../pages/Academics/Classes/update-class"

import Catalogue from "../pages/Staff/Catalogue/index";
import Leave from "../pages/Staff/Leave/index";
import CreateLeaveUi from "../pages/Staff/Leave/createLeave.js";
import StudentAttendance from "../pages/Students/Attendance/index.jsx";
import Applicants from "../pages/Admission/Applicants/Applicants.jsx"; // import AdmissionApplicants from "../pages/Admissions/Applicants/index.jsx";
import Sections from "../pages/Academics/Section/index.jsx";
import Department from "../pages/Academics/Department/index.jsx";
import Capmpus from "../pages/Academics/Campus/index.jsx";
import AsignSection from "../pages/Academics/AsignSection/index.jsx";
import Interviews from "../pages/Admission/Interviews/index.jsx";
import AssignCourses from "../pages/Academics/AssignCourse/index.jsx";
import AllStudentData from "../pages/Students/Academics/catalog/index.jsx";
import PostOffer from "../pages/Admission/PostOffer/index.jsx";
import ClassRoom from "../pages/Academics/ClassRoom/index.jsx";

const userRoutes = [
  //! School MS
  //Academics
  // { path: '/academics/campus', component: <Capmpus /> },
  // { path: '/academics/department', component: <Department /> },
  { path: "/academics/classes", component: <Classes /> },
  // { path: '/academics/assignments', component: <Assigments /> },
  // { path: '/academics/sections', component: <Sections /> },
  // { path: '/academics/asignsection', component: <AsignSection /> },
  // { path: '/academics/Syllabus', component: <Syllabus /> },
  // { path: '/academics/academicYear', component: <AcademicYear /> },
  { path: "/academics/courses", component: <Courses /> },
  { path: "/academics/assigncourses", component: <AssignCourses /> },
  // { path: '/academics/ExamsQuiz', component: <ExamsQuiz /> },
  { path: '/academics/timetable', component: <TimeTable /> },
  { path: '/academics/classroom', component: <ClassRoom /> },

  // Admissions
  { path: "/admission/applicants", component: <Applicants /> },
  { path: "/admission/postInterviews", component: <Interviews /> },
  { path: "/admission/postOffer", component: <PostOffer /> },

  //Teacher
  // { path: '/Teachers/Catalog', component: <TeacherCatalog /> },
  // { path: '/Teachers/Feedback', component: <TeacherFeedback /> },

  //Student
  { path: "/Students/Alumni", component: <Alumni /> },
  { path: "/students/newstudent", component: <StudentCatalogue /> },
  { path: "/Students/Catalog", component: <AllStudentData /> },
  { path: "/Students/Attendance", component: <StudentAttendance /> },

  // Staff
  // { path: '/staff/Catalog', component: <Catalogue /> },
  // { path: '/staff/viewStaff', component: <ViewStaffUi /> },
  // { path: '/staff/updateStaff', component: <UpdateStaffUi /> },
  // { path: '/staff/createStaff', component: <CreateStaffUi /> },
  // { path: '/staff/leave', component: <Leave /> },
  // { path: '/staff/createleave', component: <CreateLeaveUi /> },
  // { path: '/staff/attendance', component: <Attendance /> },

  //Finance
  // { path: '/Finance/Fees', component: <Finance /> },
  // { path: '/Finance/Invoices', component: <InvoiceSearch /> },

  //! School MS end

  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", exact: true, component: <Dashboard /> },
];

const AdminRoutes = [
  //! School MS
  //Academics
  // { path: '/academics/campus', component: <Capmpus /> },
  // { path: '/academics/department', component: <Department /> },
  { path: "/academics/classes", component: <Classes /> },
  // { path: '/academics/sections', component: <Sections /> },
  // { path: '/academics/academicYear', component: <AcademicYear /> },
  // { path: '/academics/assignments', component: <Assigments /> },
  // { path: '/academics/asignsection', component: <AsignSection /> },
  // { path: '/academics/Syllabus', component: <Syllabus /> },
  { path: "/academics/courses", component: <Courses /> },
  { path: "/academics/assigncourses", component: <AssignCourses /> },
  // { path: '/academics/ExamsQuiz', component: <ExamsQuiz /> },
  { path: '/academics/timetable', component: <TimeTable /> },
  { path: '/academics/classroom', component: <ClassRoom /> },

  // Admissions
  { path: "/admission/applicants", component: <Applicants /> },
  { path: "/admission/postInterviews", component: <Interviews /> },
  { path: "/admission/postOffer", component: <PostOffer /> },

  // //Teacher
  // { path: '/Teachers/Catalog', component: <TeacherCatalog /> },
  // { path: '/Teachers/Feedback', component: <TeacherFeedback /> },

  //Student
  { path: "/Students/Alumni", component: <Alumni /> },
  { path: "/students/newstudent", component: <StudentCatalogue /> },
  { path: "/Students/Catalog", component: <AllStudentData /> },
  { path: "/Students/Attendance", component: <StudentAttendance /> },

  // Staff
  // { path: '/staff/Catalog', component: <Catalogue /> },
  // { path: '/staff/viewStaff', component: <ViewStaffUi /> },
  // { path: '/staff/updateStaff', component: <UpdateStaffUi /> },
  // { path: '/staff/createStaff', component: <CreateStaffUi /> },
  // { path: '/staff/leave', component: <Leave /> },
  // { path: '/staff/createleave', component: <CreateLeaveUi /> },
  // { path: '/staff/attendance', component: <Attendance /> },

  // //Finance
  // { path: '/Finance/Fees', component: <Finance /> },
  // { path: '/Finance/Invoices', component: <InvoiceSearch /> },

  //! School MS end

  { path: "/dashboard", component: <Dashboard /> },
  { path: "/", exact: true, component: <Dashboard /> },
];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
];

// const roleBase = decoded.role === 'Admin' ? AdminRoutes : userRoutes;
export { authRoutes, userRoutes, AdminRoutes };
