import { instanceAuth } from '../axiosConfig';

export const updateAssignmentHelper = async (_data) => {
  try {
    const formData = new FormData();
    // Append personal data
    _data.isFileUrl
      ? formData.append('assignmentFileUrl', _data.data.assignmentFile)
      : formData.append('assignmentFile', _data.data.assignmentFile);
    formData.append('assignmentName', _data.data.assignmentName);
    formData.append('dueDate', _data.data.dueDate);
    formData.append('totalMarks', _data.data.totalMarks);
    formData.append('reSubmissionDate', _data.data.reSubmissionDate);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1]);
    }
    console.log(_data.isFileUrl);
    const response = await instanceAuth.put(`Assignment/${_data.id}`, formData);
    return response;
  } catch (error) {
    console.log('error updating assignment...', error);
    throw error;
  }
};
export const createNewAssignmentHelper = async (_data) => {
  try {
    const formData = new FormData();
    // Append personal data
    formData.append('campusId', _data.campusId);
    formData.append('sectionId', _data.sectionId);
    formData.append('classId', _data.classId);
    formData.append('courseId', _data.courseId);
    formData.append(
      'assignmentDetails[0].assignmentFile',
      _data.assignmentDetails[0].assignmentFile
    );
    formData.append(
      'assignmentDetails[0].assignmentName',
      _data.assignmentDetails[0].assignmentName
    );
    formData.append(
      'assignmentDetails[0].dueDate',
      _data.assignmentDetails[0].dueDate
    );
    formData.append(
      'assignmentDetails[0].totalMarks',
      _data.assignmentDetails[0].totalMarks
    );
    formData.append(
      'assignmentDetails[0].reSubmissionDate',
      _data.assignmentDetails[0].reSubmissionDate
    );
    const response = await instanceAuth.post(`Assignment`, formData);
    return response;
  } catch (error) {
    console.log('error creating assignment...', error);
    throw error;
  }
};

export const getAllAssignmentHelper = async (data) => {
  try {
    console.log('helper', data);
    const response = await instanceAuth.get(`Assignment`, {
      params: {
        ...data,
      },
    });
    console.log(`assignements in campus${data?.campusId}: `, response);
    return response;
  } catch (error) {
    console.log('error getting assignments...', error);
    throw error;
  }
};

export const deleteAssignmentHelper = async (id) => {
  try {
    const _data = [id];
    console.log('deleteAssignmentHelper', _data);
    const response = await instanceAuth.delete(`Assignment`, {
      data: _data, // Pass the array of IDs in the data object
    });
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('error deleting assignment...', error);
    throw error;
  }
};
