import React, { useEffect, useState } from 'react';
import {
  InputField,
  SelectField,
  SelectFieldForMedical,
} from '../../../../components/input-fields';
import { Link } from 'react-router-dom';

export default function MedicalInfoForm({
  tabs,
  updateTabComplete,
  handleChangeInfo,
  handleFileChange,
  medicalInfo,
  forView,
}) {
  let infoObject = 'medical';

  useEffect(() => {
    const keysToExclude = ['allergies'];
    const isAnyKeyEmpty = Object.keys(medicalInfo)
      .filter((key) => !keysToExclude.includes(key))
      .some((key) => {
        return (
          medicalInfo[key] === '' ||
          medicalInfo[key] === null ||
          medicalInfo[key] === undefined
        );
      });
    // console.log('Medical: ', isAnyKeyEmpty);
    if (!isAnyKeyEmpty) {
      // console.log("tabs", tabs)
      updateTabComplete(true);
    } else {
      if (tabs[4].complete) {
        updateTabComplete(false);
      }
    }
  }, [medicalInfo]);
  console.log('medical-info', medicalInfo);
  return (
    <React.Fragment>
      <form>
        <div className="row">
          {!forView ? (
            <SelectField
              disabled={forView}
              label="Diabetes"
              compulsory={true}
              value={medicalInfo.diabetes}
              selectOptions={["Yes", "No"]}
              onChange={(e) =>
                handleChangeInfo(infoObject, 'diabetes', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Diabetes</label>
              <p className="">{medicalInfo.diabetes}</p>
            </div>
          )}
          {!forView ? (
            <SelectField
              disabled={forView}
              label="Physical Disability"
              compulsory={true}
              value={medicalInfo.physicalDisability}
              selectOptions={["Yes", "No"]}
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'physicalDisability',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Physical Disability</label>
              <p className="">
                {medicalInfo.physicalDisability}
              </p>
            </div>
          )}
          {!forView ? (
            <SelectField
              disabled={forView}
              label="Hearing Impairment"
              compulsory={true}
              value={medicalInfo.hearingImpairment}
              selectOptions={["Yes", "No"]}
              onChange={(e) =>
                handleChangeInfo(
                  infoObject,
                  'hearingImpairment',
                  e.target.value
                )
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Hearing Impairment</label>
              <p className="">{medicalInfo.hearingImpairment}</p>
            </div>
          )}
          {!forView ? (
            <SelectField
              disabled={forView}
              label="Visual Impairment"
              compulsory={true}
              value={medicalInfo.visualImpairment}
              selectOptions={["Yes", "No"]}
              onChange={(e) =>
                handleChangeInfo(infoObject, 'visualImpairment', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Visual Impairment</label>
              <p className="">{medicalInfo.visualImpairment}</p>
            </div>
          )}
          {!forView ? (
            <SelectField
              disabled={forView}
              label="Have Allergy"
              compulsory={true}
              value={medicalInfo.anyAllergies}
              selectOptions={["Yes", "No"]}
              onChange={(e) =>
                handleChangeInfo(infoObject, 'anyAllergies', e.target.value)
              }
            />
          ) : (
            <div className="col-sm-12 col-md-6 col-lg-4 my-2">
              <label>Have Allergy</label>
              <p className="">{medicalInfo.anyAllergies}</p>
            </div>
          )}
          {medicalInfo.anyAllergies === 'Yes' ? (
            <InputField
              label="Allergies"
              disabled={forView}
              type="text"
              value={medicalInfo.allergies}
              placeholder="Allergies.."
              onChange={(e) =>
                handleChangeInfo(infoObject, 'allergies', e.target.value)
              }
            />
          ) : (
            <></>
          )}
        </div>
      </form>
    </React.Fragment>
  );
}
