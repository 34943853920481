import React, { Component, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Modal } from 'reactstrap';
import { UserRoleLogOut } from '../../redux/slice/Authentication/loginSlice';
import { useDispatch } from 'react-redux';

function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      // localStorage.removeItem('token');
      localStorage.removeItem('userRole');
      localStorage.removeItem('_schoolware_token_userinfo_');
      localStorage.removeItem('_schoolware_selected_campus_');
      dispatch(UserRoleLogOut());
      navigate('/login');
    }, 1000);
  }, []);
  return (
    <React.Fragment>
      <Modal isOpen={true}>
        <Card>
          <CardBody className="mt-2">
            <h1 className="text-center">Logging Out...</h1>
          </CardBody>
        </Card>
      </Modal>
    </React.Fragment>
  );
}

export default Logout;
