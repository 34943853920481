import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { postStaffData, getAllStaffData , updateStaffData, deleteStaffData } from '../../../api/satff/catalog/apiHelpers';

const initialState = {
    staff: [],
    selectedStaff: [], // Selected staff data
    selectedStaffUpdate: [], // Selected staff data
    loading: false,
    error: null,
    successMessage:""
};

export const postStaffDataAsync = createAsyncThunk(
  'data/postStaffData',
  async ( requestData, { rejectWithValue }) => {
    try {
      const data = await postStaffData(requestData);
      console.log(data,"data in postStaffDataAsync");
      return data;
    } catch (error) {
      // console.log("error : in optionSlice....",error);
      return rejectWithValue(error.message);
    }
  }
);

export const updateStaffDataAsync = createAsyncThunk(
  'data/updateStaffData',
  async ( requestData, { rejectWithValue }) => {
    try {
      const data = await updateStaffData(requestData);
      console.log(data,"data in updateStaffDataAsync");
      return data;
    } catch (error) {
      // console.log("error : in optionSlice....",error);
      return rejectWithValue(error.message);
    }
  }
);

export const getAllStaffDataAsync = createAsyncThunk(
  'data/fetchStaffData',
  async ( _, { rejectWithValue }) => {
    try {
      const data = await getAllStaffData();
      return data;
    } catch (error) {
      // console.log("error : in optionSlice....",error);
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk to delete staff data
export const deleteStaffDataAsync = createAsyncThunk(
  'data/deleteStaffData',
  async (staffId, { rejectWithValue }) => {
    try {
      const data = await deleteStaffData(staffId);
      return data; 
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);



export const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    setSelectedStaff: (state, action) => {
      console.log('setSelectedStaff reducer', action.payload);
      state.selectedStaff = action.payload;
    },
    setSelectedStaffUpdate: (state, action) => {
      console.log('setSelectedStaffUpdate reducer', action.payload);
      state.selectedStaffUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    // ! FOR GET ALL STAFF DATA
    builder
      .addCase(getAllStaffDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStaffDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.staff= action.payload;
        console.log(action.payload,"data in extraReducers");

        state.error = null; // Reset error when request succeeds
      })
      .addCase(getAllStaffDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // ! FOR POST STAFF DATA
    builder
      .addCase(postStaffDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(postStaffDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        console.log("Action.payload in builder:", action.payload );
        state.staff.push(action.payload.data);
        state.error = null; // Reset error when request succeeds
      })
      .addCase(postStaffDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // ! FOR UPDATE STAFF DATA
    builder
      .addCase(updateStaffDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStaffDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        const updatedStaffData = action.payload.data;
        
        console.log("updatedStaffData:", updatedStaffData );

        state.staff = state.staff.map((staff) =>
          staff.id === updatedStaffData.id ? updatedStaffData : staff
        );
       
        state.successMessage = action.payload.message
        state.error = null; // Reset error when request succeeds
      })
      .addCase(updateStaffDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    // ! FOR DELETE STAFF DATA
    builder
      .addCase(deleteStaffDataAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStaffDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        const deletedStaffDataId = action.payload.data;
        
        console.log("deletedStaffData:", deletedStaffDataId );
        state.staff = state.staff.filter(staff => staff.id !== deletedStaffDataId);
        state.error = null; // Reset error when request succeeds
      })
      .addCase(deleteStaffDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
 
});

// Action creators are generated for each case reducer function
export const {
  addStaff,
  setSelectedStaff,
  setStaffState,
  setSelectedStaffUpdate,
  deleteSelectedStaff,
  updateStaff,
} = staffSlice.actions;

export const allStaffs = (state) => state.allStaffs;

export const selectSelectedStaff = (state) => state.allStaffs.selectedStaff;

export const updateSelectedStaff = (state) =>
  state.allStaffs.selectedStaffUpdate;

export default staffSlice.reducer;
