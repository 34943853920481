import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { allApplicant } from "../../redux/slice/admission/applicantSlice";
import { useSelector } from "react-redux";

const SalesAnalytics = () => {
  const { applicants } = useSelector(allApplicant);
  let pendingCount = 0;
  let Shortlist = 0;
  let interviewCount = 0;

  const [series, setSeries] = useState([0, 0, 0]);
  const [options] = useState({
    labels: ["Pending", "Short List", "Interview"],
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },
    },
    legend: {
      show: false,
    },
    colors: ["#b39b04", "#45cb85", "#0d49d6"],
  });

  // Update series when applicants change
  useEffect(() => {
    pendingCount = applicants.filter(
      (v) => v?.studentAdmissionStatus?.status === "Pending"
    ).length;
    Shortlist = applicants.filter(
      (v) => v?.studentAdmissionStatus?.status === "InterviewDone"
    ).length;
    interviewCount = applicants.filter(
      (v) => v?.studentAdmissionStatus?.status === "Interview"
    ).length;

    setSeries([pendingCount, interviewCount, Shortlist]);
  }, [applicants]);

  return (
    <React.Fragment>
      <Card className="rounded-4">
        <CardBody>
          <h4 className="card-title mb-4">Application Analytics</h4>
          <Row className="align-items-center">
            <Col sm={8}>
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                height={245}
                width={210}
                className="apex-charts"
              />
            </Col>
            <Col sm={4}>
              <div>
                <Row>
                  <div className="col-12">
                    <div className="py-2">
                      <p className="mb-1 text-truncate">
                        <i className="mdi mdi-circle text-warning me-1"></i>{" "}
                        Pending
                      </p>
                      <h5>{series[0]}</h5>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="py-2">
                      <p className="mb-1 text-truncate">
                        <i className="mdi mdi-circle text-success me-1"></i>{" "}
                        Shortlisted
                      </p>
                      <h5>{series[2]}</h5>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="py-2">
                      <p className="mb-1 text-truncate">
                        <i className="mdi mdi-circle text-primary me-1"></i>{" "}
                        Interviewing
                      </p>
                      <h5>{series[1]}</h5>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default SalesAnalytics;
