import React, { useState , useEffect} from "react";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  TabContent,
  TabPane,
} from "reactstrap";

import PersonalInfoForm from "./ViewStaff/personal-info";
import EducationInfoForm from "./ViewStaff/education-info";
import EmployeeStatusForm from "./ViewStaff/employee-status";

import { Link } from "react-router-dom";
import axiosInstance from "../../../api/axiosConfig";

const UpdateStaff = () => {
  // ! UPDATED CODE..............

  // Tabs
  const [activeTab, setactiveTab] = useState(1);
  const [tabs, setTabs] = useState([
    { no: 1, tab: "Personal Information", complete: false },
    { no: 2, tab: "Education information", complete: false },
    { no: 3, tab: "Employment status", complete: false },
  ]);

  function toggleTab(tab) {
    if (activeTab !== tab) {
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
      }
    }
  }
  const updateTabComplete = (boolVal) => {
    console.log("boolVal", boolVal);
    const updated_tabs = [...tabs];
    updated_tabs[activeTab - 1].complete = boolVal;
    setTabs(updated_tabs);
    console.log("tabs", tabs);
  };

  

  // ! UPDATED CODE..............

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title text-capitalize">View Staff</h4>
              <div className="row">
                {tabs.map((tab, index) => (
                  <div className="col-sm-6 col-md-4 d-grid gap-2" key={index}>
                    <button
                      // disabled={!tab.complete}
                      className={`btn py-3 ${
                        activeTab == tab.no ? "btn-primary" : "btn-secondary"
                      }`}
                      onClick={() => setactiveTab(tab.no)}
                    >
                      {tab.tab}
                    </button>
                  </div>
                ))}
              </div>
              <div className="form-wizard-wrapper wizard clearfix">
                <div className="content clearfix">
                  <TabContent activeTab={activeTab} className="body">
                    <TabPane tabId={1}>
                      <PersonalInfoForm />
                    </TabPane>
                    <TabPane tabId={2}>
                      <EducationInfoForm />
                    </TabPane>
                    <TabPane tabId={3}>
                      <EmployeeStatusForm />
                    </TabPane>
{/*                  
                    <TabPane tabId={4}>
                      <div className="row justify-content-center">
                        <div className="col-sm-6">
                          <div className="text-center">
                            <div className="mb-4">
                              <i className="mdi mdi-check-circle-outline text-success display-4" />
                            </div>
                            <div>
                              <h5>Confirm Detail</h5>
                              <p className="text-muted">
                                If several languages coalesce, the grammar of
                                the resulting
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPane> */}
                  </TabContent>
                </div>
                <div className="actions clearfix">
                  <ul>
                    <li
                      className={
                        activeTab === 1 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        className="btn btn-primary"
                        onClick={() => {
                          toggleTab(activeTab - 1);
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li className={activeTab === 3 ? "next disabled" : "next"}>
                        <button
                          to="#"
                          className="btn btn-primary"
                          // disabled={!tabs[activeTab - 1]?.complete}
                          onClick={() => {
                            toggleTab(activeTab + 1);
                          }}
                        >
                          Next
                        </button>
                      </li>
                    {/* {activeTab == 3 ? (
                      <li>
                        <Link
                          to="#"
                          // type="submit"
                          className="btn btn-secondary"
                          onClick={() => {
                            toggleTab(activeTab + 1);
                            // submitToRedux()
                          }}
                        >
                          Submit
                        </Link>
                      </li>
                    ) : (
                      <li className={activeTab === 3 ? "" : "next"}>
                        <button
                          to="#"
                          className="btn btn-primary"
                          // disabled={!tabs[activeTab - 1]?.complete}
                          onClick={() => {
                            toggleTab(activeTab + 1);
                          }}
                        >
                          Next
                        </button>
                      </li>
                    )} */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateStaff;
