import { useRef, useState } from "react";
import Select from "react-select";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const InputField = ({
  label,
  compulsory,
  className,
  type,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  let regex;
  let defaultValue = "";
  let errorMessage = "";
  if (type === "email") {
    // email validation regex if type = email
    regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    errorMessage = "This is not a valid email address.";
  } else if (type === "nic") {
    // cnic validation regex if type = nic
    regex = /^[0-9]{5}[0-9]{7}[0-9]$/;
    type = "text";
    errorMessage = "NIC number should contain 13 digits.";
  } else if (type === "tel") {
    // phone validation regex if type = tel
    regex = /^\+92\d{10}$/;
    defaultValue = "+92";
    errorMessage =
      "Number should start with +92 and contain 10 digits onwards.";
  } else {
    errorMessage = "";
  }

  const isValid = regex && value != defaultValue ? regex.test(value) : true;

  return (
    <div className={className ? className : `col-sm-12 col-md-6 col-lg-4 my-2`}>
      {/* <form> */}
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <input
          className={`form-control text-black ${
            !isValid && !disabled ? "is-invalid" : ""
          }`}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
      ) : (
        <p>{value}</p>
      )}
      {!isValid && !disabled && <p className="text-danger">{errorMessage}</p>}
      {/* </form> */}
    </div>
  );
};

const InputImageField = ({
  label,
  compulsory,
  type,
  value,
  className,
  placeholder,
  disabled,
  onChange,
  handleFileRemove,
}) => {
  const inputRef = useRef(null);
  let imageUrl = null;
  let fieldName = "No file chosen";

  if (value && value instanceof Array) {
    // console.log("value as array ", value);
    let filterValue = value.filter((item) => item != undefined);
    if (filterValue.length > 0) {
      if (filterValue && filterValue[filterValue.length - 1] instanceof Blob) {
        imageUrl = URL.createObjectURL(filterValue[filterValue.length - 1]);
        fieldName = filterValue[filterValue.length - 1].name;
        if (fieldName.length > 18) {
          fieldName = fieldName.substring(0, 18) + "...";
        }
      } else {
        imageUrl = value;
        fieldName = "File Selected";
      }
    }
  } else {
    imageUrl = null;
    fieldName = "No file Chosen";
  }

  const handleRemoveFile = () => {
    handleFileRemove();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const openFileDialog = () => {
    document.getElementById(label).click();
  };

  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <div className="input-image-container" onClick={openFileDialog}>
          <div className="input-image-browse">Browse</div>
          <div className="input-image-name text-black poppins-regular">
            {fieldName}
          </div>
        </div>
      ) : !imageUrl ? (
        <p>No Image</p>
      ) : null}
      <input
        className="form-control text-black d-none"
        id={label}
        type="file"
        accept="image/*"
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        required
      />
      {imageUrl && (
        <div className="d-flex flex-column justify-content-center align-items-center mt-1">
          <img
            className="img-thumbnail"
            style={{ width: "35%", objectFit: "cover", textAlign: "center" }}
            src={imageUrl}
            alt="Uploaded File"
          />
          {!disabled ? (
            <button
              onClick={handleRemoveFile}
              className="btn btn-danger btn-sm"
              style={{ width: "35%" }}
            >
              <MdDelete size={15} />
            </button>
          ) : null}
        </div>
      )}
    </div>
  );
};

const InputDocField = ({
  label,
  compulsory,
  type,
  accept,
  value,
  className,
  placeholder,
  disabled,
  onChange,
  handleFileRemove,
}) => {
  const inputRef = useRef(null);
  let fileUrl = null;
  let fieldName = "No file chosen";

  if (value && value instanceof Array) {
    // console.log("value as array ", value);
    let filterValue = value.filter((item) => item != undefined);
    if (filterValue.length > 0) {
      if (filterValue && filterValue[filterValue.length - 1] instanceof Blob) {
        fileUrl = URL.createObjectURL(filterValue[filterValue.length - 1]);
        fieldName = filterValue[filterValue.length - 1].name;
        if (fieldName.length > 18) {
          fieldName = fieldName.substring(0, 18) + "...";
        }
      } else {
        fileUrl = value;
        fieldName = "File Selected";
      }
    }
  } else {
    fileUrl = null;
    fieldName = "No file Chosen";
  }

  const handleRemoveFile = () => {
    handleFileRemove();
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const openFileDialog = () => {
    document.getElementById(label).click();
  };

  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {/* <label>{label}</label><MdDelete className="float-end text-danger" onClick={handleRemoveFile} /> */}
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <div className="input-image-container" onClick={openFileDialog}>
          <div className="input-image-browse">Browse</div>
          <div className="input-image-name text-black poppins-regular">
            {fieldName}
          </div>
        </div>
      ) : !fileUrl ? (
        <p>No Image</p>
      ) : null}
      <input
        className="form-control text-black d-none"
        id={label}
        type="file"
        accept={ accept ? accept : ".pdf,.doc,.docx,.xls,.xlsx"}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        ref={inputRef}
        required
      />
      {fieldName == "File Selected" ? (
        <a href={fileUrl} target="_blank">
          Availaible File Preview <FaExternalLinkAlt size={10} />
        </a>
      ) : null}
    </div>
  );
};

const SelectField = ({
  label,
  compulsory,
  value,
  selectOptions,
  className,
  onChange,
  disabled,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <select
          className="form-select text-black"
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {selectOptions.map((option, index) => (
            <option key={index} option={option}>
              {option.toString()}
            </option>
          ))}
        </select>
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

const SelectFieldForMedical = ({
  label,
  compulsory,
  value,
  selectOptions,
  onChange,
  disabled,
}) => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 my-2">
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <select
        className="form-select text-black"
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {selectOptions.map((option, index) => (
          <option key={index} option={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

const InputFileField = ({
  label,
  compulsory,
  className,
  type,
  value,
  placeholder,
  disabled,
  onChange,
}) => {
  let fileUrl = null;
  // console.log("input field file", value);
  if (value && value[0] instanceof Blob) {
    let filterValue = value.filter((item) => item != undefined);
    fileUrl = URL.createObjectURL(filterValue[filterValue.length - 1]);
  } else {
    fileUrl = null;
  }

  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <form>
        <label>{label}</label>
        <span className="text-danger">*</span>
        <input
          className={`form-control text-black`}
          type="file"
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          required
        />
      </form>
    </div>
  );
};

const SelectFieldTeacher = ({
  label,
  compulsory,
  value,
  selectOptions,
  onChange,
}) => {
  return (
    <div className="col-sm-12 col-md-6 col-lg-4 my-2">
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      <select
        className="form-select text-black"
        value={value}
        onChange={onChange}
      >
        <option value="" disabled>
          Select {label}
        </option>
        {selectOptions.map((option, index) => (
          <option
            key={index}
            value={option.personalInfoFormData.name}
            option={option.personalInfoFormData.name}
          >
            {option.personalInfoFormData.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const InputNumber = ({
  label,
  compulsory,
  className,
  type,
  value,
  step,
  disabled,
  min,
  max,
  placeholder,
  onChange,
}) => {
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <input
          className="form-control text-black"
          step={step}
          min={min}
          max={max}
          disabled={disabled}
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      ) : (
        <p>{value}</p>
      )}
    </div>
  );
};

const SelectFieldForId = ({
  label,
  compulsory,
  className,
  value,
  selectOptions,
  disabled,
  onChange,
}) => {
  let valueForView = "";
  if (disabled) {
    const selectedOption = selectOptions.find((option) => option.id == value);
    valueForView = selectedOption?.name;
  }
  return (
    <div
      className={`${
        className ? className : "col-sm-12 col-md-6 col-lg-4 my-2"
      }`}
    >
      <label>{label}</label>
      {compulsory ? <span className="text-danger">*</span> : null}
      {!disabled ? (
        <select
          className="form-select text-black"
          value={value}
          disabled={disabled}
          onChange={onChange}
        >
          <option value="" disabled>
            Select {label}
          </option>
          {selectOptions.map((option, index) => (
            <option key={index} option={option} value={option?.id}>
              {option?.name}
            </option>
          ))}
        </select>
      ) : (
        <p>{valueForView}</p>
      )}
    </div>
  );
};

export {
  InputField,
  InputImageField,
  InputDocField,
  SelectField,
  InputNumber,
  SelectFieldForId,
  SelectFieldTeacher,
  SelectFieldForMedical,
  InputFileField,
};
