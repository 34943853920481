import { instanceAuth } from "../axiosConfig";

export const courseCreate = async (_data) => {
  console.log("course api data in helper", _data);
  try {
    const response = await instanceAuth.post("Courses", _data);
    return response;
  } catch (error) {
    console.log("error creating course...", error);
    throw error;
  }
};

export const fetchCourseData = async (data) => {
  try {
    const response = await instanceAuth.get("Courses", {
      params: {
        ...data,
      },
    });
    // console.log("api fetch response", response);

    return response;
  } catch (error) {
    console.log("error fetching Courses data...", error);
    throw error;
  }
};

export const courseDelete = async (id) => {
  try {
    const response = await instanceAuth.delete(`Courses/${id}`);
    return response;
  } catch (error) {
    console.log("error deleting course...", error);
    throw error;
  }
};

export const courseUpdate = async ({ id, courseData }) => {
  try {
    console.log("course update helper..", id, courseData);
    const response = await instanceAuth.put(`Courses/${id}`, courseData);
    return response;
  } catch (error) {
    console.log("error updating course...", error);
    throw error;
  }
};
