import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardTitle,
  Table,
  Modal,
  CardFooter,
} from "reactstrap";
import Loader from "../../../components/loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  allApplicant,
  ApplicantsData,
  StudentDocument,
} from "../../../redux/slice/admission/applicantSlice";
import ApplicantDetails from "../Applicants/applicant-details";
import { IoArrowBackCircle } from "react-icons/io5";
import { IoArrowForwardCircle } from "react-icons/io5";
import { allCampus } from "../../../redux/slice/academics/CampusSlice";
import {
  ClassesDataSingle,
  allClasses,
} from "../../../redux/slice/classesSlice";
import FilterInput from "../../../components/FilterInputComponent";
import {
  InputDocField,
  InputImageField,
  SelectField,
  SelectFieldForId,
} from "../../../components/input-fields";
import { MaptoStudentStateObj } from "../../../helpers/map_student_data";
import axios from "axios";
import { ExportToExcel } from "../../../components/ExcelToImport";
import { BsDashLg } from "react-icons/bs";

const PostOffer = () => {
  const dispatch = useDispatch();
  const { currentCampus } = useSelector(allCampus);
  const { applicants, loading, offerStatus, error, totalPages } =
    useSelector(allApplicant);
  const { singleClasses } = useSelector(allClasses);
  const [data, setData] = React.useState([]);
  const fileName = "PostOffer";
  const [selectedStudent, setSelectedStudent] = useState(false);
  const [modal, setModal] = useState(false);
  const [conditionModal, setConditionModal] = useState(false);
  const [conditionDetails, setConditionDetails] = useState({});
  const [create, setCreate] = useState(false);
  const [storeId, setStoreId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [dropdownsOpen, setDropdownsOpen] = useState({});
  const [emailSend, setEmailSend] = useState("No");
  const [timeValidationError, setTimeValidationError] = useState("");
  const [filterApplicantsInterviews, setFilterApplicantsInterviews] = useState(
    []
  );
  const [actionOnApplicant, setActionOnApplicant] = useState({
    id: "",
    action: "",
    interviewTime: "",
    rescheduleReason: "",
  });
  const [postOfferDetails, setPostOfferDetails] = useState({
    feeVoucher: [],
    letter: [],
  });

  const [currentData, setCurrentData] = useState({
    currentClass: "0",
    currentStatus: "",
  });

  const subjectNames = [
    "Mathematics",
    "Additional Mathematics",
    "Biology",
    "Physics",
    "Chemistry",
  ];
  const typesOfScholarships = [
    "Academic",
    "Sports",
    "Extracurricular",
    "Leadership",
  ];
  const percentages = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const grades = ["A*", "A", "B", "C"];

  useEffect(() => {
    let filter_applicants_interviews = applicants
      .filter((a) => a?.studentAdmissionStatus?.status == "InterviewDone")
      .filter((a) => a?.studentAdmissionOffer !== null);
    setFilterApplicantsInterviews(filter_applicants_interviews);
  }, [applicants]);

  // Change page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentCampus == 0) {
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: pageNumber,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: true,
        AdmissionOfferStatus: currentData.currentStatus
          ? currentData.currentStatus
          : null,
      };
      dispatch(ApplicantsData(data));
    } else {
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: pageNumber,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: true,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
        AdmissionOfferStatus: currentData.currentStatus
          ? currentData.currentStatus
          : null,
      };
      dispatch(ApplicantsData(data));
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    if (currentCampus == 0) {
      dispatch(ClassesDataSingle(currentCampus));
      console.log("status ****", currentData.currentStatus);
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: 1,
        isAdmissionOffer: true,
        AdmissionStatus: "InterviewDone",
        AdmissionOfferStatus: currentData.currentStatus
          ? currentData.currentStatus
          : null,
        // ...(currentData.currentStatus != "" ? { AdmissionOfferStatus: Number(currentData.currentStatus) } : {})
      };
      dispatch(ApplicantsData(data));
    } else if (currentCampus) {
      dispatch(ClassesDataSingle(currentCampus));
      const data = {
        pageSize: itemsPerPage ? itemsPerPage : null,
        pageNumber: 1,
        AdmissionStatus: "InterviewDone",
        isAdmissionOffer: true,
        ClassId:
          currentData.currentClass !== "0" ? +currentData.currentClass : null,
        CampusId: Number(currentCampus),
        AdmissionOfferStatus: currentData.currentStatus
          ? currentData.currentStatus
          : null,
        // ...(currentData.currentStatus ? { AdmissionOfferStatus: Number(currentData.currentStatus) } : {})
      };
      dispatch(ApplicantsData(data));
    }
  }, [itemsPerPage, currentCampus, currentData]);

  const createToggle = async (id) => {
    setCreate(!create);
  };

  const handleChangeInfo = (fieldName, value) => {
    setPostOfferDetails((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleFileChange = (fieldName, event) => {
    const file = event.target.files[0];
    console.log("Added file", file);
    setPostOfferDetails((prevState) => ({
      ...prevState,
      [fieldName]: [file],
    }));
  };
  const handleFileRemove = (fieldName) => {
    setPostOfferDetails((prevState) => ({
      ...prevState,
      [fieldName]: [],
    }));
  };

  const handleClick = (applicant_action_value, offer_details, id) => {
    setModal(true);
    setActionOnApplicant((prevState) => ({
      ...prevState,
      id: id,
      action: applicant_action_value,
    }));
    setPostOfferDetails((prevState) => ({
      ...prevState,
      feeVoucher: [offer_details?.admissionFeeUrl],
      letter: [offer_details?.admissionLetterUrl],
    }));
  };

  const handleModalForConditionDetails = (std) => {
    console.log(
      "condition details",
      std?.studentAdmissionOffer?.conditionalOffer
    );
    setConditionDetails(std?.studentAdmissionOffer?.conditionalOffer);
    setConditionModal(!conditionModal);
  };

  useEffect(() => {
    if (offerStatus.status === 200) {
      console.log("response for sending documents", offerStatus.status);
      setModal(false);
    }
  }, [offerStatus]);

  const applicantAction = () => {
    console.log(
      "taking action confirmed on",
      postOfferDetails,
      " && email send",
      emailSend
    );
    const formData = new FormData();
    formData.append("StudentId", actionOnApplicant.id);
    formData.append(
      "AdmissionFee",
      postOfferDetails.feeVoucher[postOfferDetails?.feeVoucher.length - 1]
    );
    formData.append(
      "AdmissionLetter",
      postOfferDetails.letter[postOfferDetails?.letter.length - 1]
    );
    formData.append("sendEmail", emailSend == "Yes" ? true : false);
    dispatch(StudentDocument(formData));
  };

  const givenData = applicants.map((item) => ({
    ApplicantsName: item?.name,
    ApplicantEmail: item?.email,
    Status:item?.studentAdmissionOffer?.status,
    FatherName: item?.guardians[0]?.name,
    className: item?.studentAdmissionStatus?.class?.name,
    PhoneNumber: item?.phone,
    age: item?.age,
    gender: item?.gender,
    campus: item?.studentAdmissionStatus?.campus?.name,
    LastEmailSend:item?.studentDocuments?.lastEmailSent, 
    FeeVoucher:item?.studentDocuments?.admissionFeeUrl,
    Letter:item?.studentDocuments?.admissionLetterUrl,
  }));
  
  const formatDateCustom = (isoString) => {
    const date = new Date(isoString);
    const options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formatter = new Intl.DateTimeFormat("en-US", options);
    const formattedDate = formatter.format(date);
    return formattedDate.replace(",", "").replace(" ", " ");
  };

  const resetData = () => {
    setModal(false);
    setActionOnApplicant((prevState) => ({
      ...prevState,
      id: "",
      action: "",
    }));
    setPostOfferDetails((prevState) => ({
      ...prevState,
      feeVoucher: [],
      letter: [],
    }));
  };

  return (
    <>
      <div className="page-content">
        <Breadcrumbs title="Admission" breadcrumbItem="Post Interview" />
        <Row className="mb-2">
          <Col className="justify-content-end d-flex">
            {create ? (
              <Button
                color="success"
                className="btn btn-success waves-effect waves-light mx-1"
                onClick={createToggle}
              >
                Show List
              </Button>
            ) : (
              <ExportToExcel apiData={givenData} fileName={fileName} />
            )}
          </Col>
        </Row> 
        {!create ? (
          <div>
            <div className="container-fluid mt-2">
              <Card style={{ minHeight: "540px" }}>
                <CardBody>
                  <div className="">
                    <CardTitle className="fs-5 mb-4 bold text-uppercase font-weight-bold">
                      Post Offer
                    </CardTitle>
                    <div className="my-2 w-100 d-flex">
                      <div className="w-75 d-flex">
                        <FilterInput
                          options={singleClasses}
                          value={currentData.currentClass}
                          label="All Classes"
                          onChange={(e) => {
                            setCurrentData((prev) => ({
                              ...prev,
                              currentClass: e.target.value,
                            }));
                          }}
                        />
                        <select
                          className="form-control mx-1"
                          style={{
                            borderColor: "rgb(59 93 231 / 47%)",
                            width: "25%",
                          }}
                          value={currentData.currentStatus}
                          onChange={(e) => {
                            setCurrentData((prev) => ({
                              ...prev,
                              currentStatus: e.target.value,
                            }));
                          }}
                        >
                          <option value={""}>All Status</option>
                          {[
                            { id: "0", name: "Conditional" },
                            { id: "1", name: "Unconditional" },
                          ]?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <select
                        className="form-control w-25"
                        style={{ borderColor: "rgb(59 93 231 / 47%)" }}
                        value={itemsPerPage}
                        onChange={(e) => {
                          setCurrentPage(1);
                          setItemsPerPage(e.target.value);
                        }}
                      >
                        <option value="" disabled>
                          Items per page
                        </option>
                        <option value={""}>All</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>
                  <div className="table-responsive">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Table className="table table-striped mb-0">
                        <thead>
                          <tr>
                            <th className="text-nowrap">Applicants's Name</th>
                            <th className="text-nowrap">Father's Name</th>
                            <th className="text-nowrap">Campus</th>
                            <th className="text-nowrap">Status</th>
                            <th className="text-nowrap">Last Email Sent</th>
                            <th className="text-nowrap">Fee Voucher</th>
                            <th className="text-nowrap">Letter</th>
                            <th className="text-nowrap">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterApplicantsInterviews?.map((std, ind) => (
                            <tr key={ind}>
                              <td className="text-nowrap">{std?.name}</td>
                              <td className="text-nowrap">
                                {std?.guardians[0]?.name}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentAdmissionStatus?.campus?.name}
                              </td>
                              <td
                                className={`text-nowrap ${
                                  std?.studentAdmissionOffer?.status ==
                                  "UnConditional"
                                    ? "text-warning"
                                    : "text-danger"
                                }`}
                              >
                                {std?.studentAdmissionOffer?.status ? (
                                  std?.studentAdmissionOffer?.status
                                ) : (
                                  <BsDashLg />
                                )}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentDocuments?.lastEmailSent ? (
                                  formatDateCustom(
                                    std?.studentDocuments?.lastEmailSent
                                  )
                                ) : (
                                  <BsDashLg />
                                )}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentDocuments?.admissionFeeUrl ? (
                                  <a
                                    href={
                                      std?.studentDocuments?.admissionFeeUrl
                                    }
                                    target="_blank"
                                  >
                                    Link
                                  </a>
                                ) : (
                                  <BsDashLg />
                                )}
                              </td>
                              <td className="text-nowrap">
                                {std?.studentDocuments?.admissionLetterUrl ? (
                                  <a
                                    href={
                                      std?.studentDocuments?.admissionLetterUrl
                                    }
                                    target="_blank"
                                  >
                                    Link
                                  </a>
                                ) : (
                                  <BsDashLg />
                                )}
                              </td>
                              {/* <td className="text-nowrap"><a>Not availaible</a></td> */}
                              <td className="d-flex justify-content-start">
                                <button
                                  onClick={() => {
                                    setSelectedStudent(
                                      MaptoStudentStateObj(std)
                                    );
                                    setCreate(!create);
                                  }}
                                  className="btn btn-primary me-2"
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                >
                                  View
                                </button>
                                <button
                                  onClick={() => {
                                    handleModalForConditionDetails(std);
                                  }}
                                  className="btn btn-info me-2 text-nowrap"
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    std?.studentAdmissionOffer?.status ==
                                    "UnConditional"
                                  }
                                >
                                  Offer Details
                                </button>
                                <button
                                  className="btn btn-dark me-2"
                                  size={15}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleClick(
                                      "Conditional",
                                      std?.studentDocuments,
                                      std.id
                                    )
                                  }
                                >
                                  Action
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="mt-5">
                    <Pagination className="pagination pagination-rounded justify-content-center mb-0">
                      <PaginationItem disabled={currentPage === 1}>
                        <PaginationLink
                          previous
                          onClick={() => paginate(currentPage - 1)}
                        >
                          <IoArrowBackCircle size={25} />
                        </PaginationLink>
                      </PaginationItem>
                      {[...Array(totalPages).keys()].map((number) => (
                        <PaginationItem
                          key={number}
                          active={number + 1 === currentPage}
                        >
                          <PaginationLink onClick={() => paginate(number + 1)}>
                            {number + 1}
                          </PaginationLink>
                        </PaginationItem>
                      ))}
                      <PaginationItem disabled={currentPage === totalPages}>
                        <PaginationLink
                          next
                          onClick={() => paginate(currentPage + 1)}
                        >
                          <IoArrowForwardCircle size={25} />
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </div>
                </CardFooter>
              </Card>
            </div>
            <Modal isOpen={modal} centered>
              <Card>
                <CardBody className="mt-2">
                  <h5>Are You Sure?</h5>
                  <div className="row mb-2">
                    <InputDocField
                      className="col-sm-12 col-md-6 col-lg-6 my-2"
                      label="Upload Fee Voucher"
                      disabled={false}
                      compulsory={false}
                      value={postOfferDetails.feeVoucher}
                      type="file"
                      placeholder="Upload Fee Voucher"
                      handleFileRemove={() => handleFileRemove("feeVoucher")}
                      onChange={(e) => handleFileChange("feeVoucher", e)}
                    />
                    <InputDocField
                      className="col-sm-12 col-md-6 col-lg-6 my-2"
                      label="Upload Letter"
                      disabled={false}
                      compulsory={false}
                      value={postOfferDetails.letter}
                      type="file"
                      placeholder="Upload Letter"
                      handleFileRemove={() => handleFileRemove("letter")}
                      onChange={(e) => handleFileChange("letter", e)}
                    />
                  </div>
                  <label>Do you want to send email for the action?</label>
                  <select
                    className="form-select"
                    value={emailSend}
                    onChange={(e) => setEmailSend(e.target.value)}
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                </CardBody>
                <CardFooter>
                  <button
                    className="btn btn-success me-2"
                    onClick={() => applicantAction()}
                    disabled={loading}
                  >
                    {loading ? (
                      <div
                        className="spinner-border text-light"
                        style={{ height: "16px", width: "16px" }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Yes"
                    )}
                  </button>
                  <button className="btn btn-primary" onClick={resetData}>
                    No
                  </button>
                </CardFooter>
              </Card>
            </Modal>
            <Modal isOpen={conditionModal} centered>
              <Card>
                <CardBody>
                  {conditionDetails?.stdAdmOfrSubDetails?.length < 1 &&
                  !conditionDetails?.stdAdmOffrScholarshipDetail ? (
                    <p className="fs-4 text-black">No Offer Details</p>
                  ) : null}
                  {conditionDetails?.stdAdmOfrSubDetails?.length > 0 && (
                    <>
                      <h4 className="fs-5 text-blue">
                        Student Subjects Detail
                      </h4>
                      {conditionDetails.stdAdmOfrSubDetails.map(
                        (dtl, index) => (
                          <div className="row" key={index}>
                            <p className="text-blue fs-6">
                              Subject {index + 1}
                            </p>
                            <SelectField
                              className="col-sm-12 col-md-6 col-lg-6"
                              disabled={true}
                              label={`Name`}
                              compulsory={true}
                              value={dtl.subjectName}
                              selectOptions={subjectNames}
                              onChange={(e) =>
                                console.log("new value", e.target.value)
                              }
                            />
                            <SelectField
                              className="col-sm-12 col-md-6 col-lg-6"
                              disabled={true}
                              label="Grade"
                              compulsory={true}
                              value={dtl.grade}
                              selectOptions={grades}
                              onChange={(e) =>
                                console.log("new value", e.target.value)
                              }
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                  {conditionDetails?.stdAdmOffrScholarshipDetail && (
                    <>
                      <h4 className="fs-5 text-blue">
                        Student Scholarship Detail
                      </h4>
                      <div className="row">
                        <SelectField
                          className="col-sm-12 col-md-6 col-lg-6"
                          disabled={true}
                          label="Scholarship Type"
                          compulsory={true}
                          value={
                            conditionDetails.stdAdmOffrScholarshipDetail
                              .scholarShipType
                          }
                          selectOptions={typesOfScholarships}
                          onChange={(e) =>
                            console.log("new value", e.target.value)
                          }
                        />
                        <SelectField
                          className="col-sm-12 col-md-6 col-lg-6"
                          disabled={true}
                          label="Scholarship Percentage"
                          compulsory={true}
                          value={
                            conditionDetails.stdAdmOffrScholarshipDetail
                              .percentage
                          }
                          selectOptions={percentages}
                          onChange={(e) =>
                            console.log("new value", e.target.value)
                          }
                        />
                      </div>
                    </>
                  )}
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      setConditionModal(false);
                    }}
                  >
                    Close
                  </button>
                </CardBody>
              </Card>
            </Modal>
          </div>
        ) : (
          <div>
            <ApplicantDetails
              forView={true}
              selectedStudent={selectedStudent}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default PostOffer;
